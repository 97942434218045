import PropTypes from 'prop-types';
import { Button, Tab, Tabs, Stack, TextField } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputAdornment from '@mui/material/InputAdornment';
import Label from '../../../../../components/label';
import Iconify from '../../../../../components/iconify';
import { countBy } from '../../../../../utils/withCategoryMaths';
import { useLocales } from '../../../../../locales';
import useCategoriesContext from '../../../../../contexts/Categories/useCategoriesContext';
import { responsiveFontSizes } from '../../../../../utils/fontSizes';
import { useCategoryFilterContext } from '../hooks/useCategoryFilterContext';
import { ASIDEBAR_ADD_CATEGORY, useLayoutSettingsContext } from './LayoutSettings';

const CategoriesFilter = ({ variant }) => {
    const { t } = useLocales();
    const { onToggleAsidebar } = useLayoutSettingsContext();
    const { getVariantCategoriesByLevels, onToggleRefresh } = useCategoriesContext();
    const { selectedCategoryType, searchFilter, onUpdateSelectedCategoryType, onUpdateSearchFilter } = useCategoryFilterContext();
    const variantCategories = getVariantCategoriesByLevels(variant);

    const DEFAULT_TABS = [
        {
            value: 'system_all_category_types',
            label: 'bookkeeping.filter_all',
            color: 'default',
            count: countBy(variantCategories, '', 'system_all_category_types'),
        },
        {
            value: 'system_all_category_primary',
            label: 'bookkeeping.filter_primary',
            color: 'success',
            count: countBy(variantCategories, 'primary', 'true') },
        {
            value: 'system_all_category_secondary',
            label: 'bookkeeping.filter_secondary',
            color: 'warning',
            count: countBy(variantCategories, 'primary', 'false'),
        },
        {
            value: 'system_all_category_fixed',
            label: 'bookkeeping.filter_fixed',
            color: 'warning',
            count: countBy(variantCategories, 'costType', 'fixed') },
        {
            value: 'system_all_category_variable',
            label: 'bookkeeping.filter_variable',
            color: 'error',
            count: countBy(variantCategories, 'costType', 'variable'),
        },
    ];

    const handleFilterStatus = (event, newValue) => {
        onUpdateSelectedCategoryType(newValue);
    };
    const handleFilterSearch = (event) => {
        onUpdateSearchFilter(event.target.value);
    };

    const renderTab = (tab) => {
        const length = tab.count;
        return (
            <Tab
                key={tab.value}
                value={tab.value}
                label={t(tab.label)}
                icon={
                    <Label color={tab.color} sx={{ mr: 1 }}>
                        {length}
                    </Label>
                }
                sx={{
                    ...responsiveFontSizes({ sm: 16, md: 16, lg: 18 }),
                }}
            />
        );
    };

    const view = 'list';
    const handleChangeView = () => {};

    return (
        <Stack spacing={2}>
            <Tabs
                value={selectedCategoryType}
                sx={{
                    px: 2,
                    bgcolor: 'background.neutral',
                }}
                onChange={handleFilterStatus}
            >
                {DEFAULT_TABS.map((tab) => renderTab(tab))}
            </Tabs>

            <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-end', md: 'center' }}>

                <Button
                    variant="contained"
                    sx={{
                        textTransform: 'unset',
                        minWidth: 160,
                        fontWeight: 'fontWeightMedium',
                        height: 48,
                    }}
                    startIcon={<Iconify icon="solar:add-square-linear" />}
                    onClick={() => onToggleAsidebar(ASIDEBAR_ADD_CATEGORY, {})}
                >
                    {t('bookkeeping.categories.new_category')}
                </Button>

                <ToggleButtonGroup size="small" value={view} exclusive onChange={handleChangeView}>
                    <ToggleButton value="list">
                        <Iconify icon="solar:list-bold" />
                    </ToggleButton>

                    <ToggleButton value="grid">
                        <Iconify icon="mingcute:dot-grid-fill" />
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButton
                    value="check"
                    selected={false}
                    onClick={() => onToggleRefresh()}
                >
                    <Iconify icon="solar:refresh-bold" />
                </ToggleButton>

                <TextField
                    fullWidth
                    value={searchFilter}
                    onChange={handleFilterSearch}
                    placeholder={t('bookkeeping.filter_search')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                            </InputAdornment>
                        ),
                    }}
                />

            </Stack>
        </Stack>
    );
};
CategoriesFilter.propTypes = {
    variant: PropTypes.oneOf(['income', 'expense']).isRequired,
};

export default CategoriesFilter;