import type { Category } from '../api/CategoriesApi';

export const countBy = (data: Array<Category>, key: string, value: string) : number => {
    if (!Array.isArray(data)) {
        return 0;
    }
    if (value.toLowerCase() === 'system_all_category_types') {
        return data.length;
    }
    return data.filter(r => r[key].toString().toLowerCase() === value.toLowerCase()).length;
}