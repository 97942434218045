import PropTypes from "prop-types";
import {SkeletonBarLoad} from "../../../../../components/skeleton";
import {TransactionReportsUrlBuilder} from "../../../../../api/TransactionReportsApi";
import {withWidgetSwr} from "../components";
import NoData from "./NoData";
import ExpensesByClassification from "./ExpensesByClassification";

const ExpensesFixedVariableTrend = ({data, isRTL}) => (
    <ExpensesByClassification
        data={data}
        isRTL={isRTL}
        firstClassificationDataTitle="widgets.expensesClassification_fixed_title"
        secondClassificationDataTitle="widgets.expensesClassification_variable_title"
        title="widgets.expensesClassification_fixed_variable_title"
        noData={
            <NoData
                help="widgets.expensesClassification_fixed_variable_help"
                title="widgets.expensesClassification_fixed_variable_title"
            />
        }
    />
)
ExpensesFixedVariableTrend.propTypes = {
    data: PropTypes.object.isRequired,
    isRTL: PropTypes.bool.isRequired,
};

export default withWidgetSwr(ExpensesFixedVariableTrend, TransactionReportsUrlBuilder.getExpensesFixedVariableTrend, <SkeletonBarLoad />);
