import Button from "@mui/material/Button";
import { Grid, Stack, Typography } from "@mui/material";
import { useLocales } from "../../../../locales";
import { useFormYup } from "../../../../components/hook-form/useFormYup";
import {currencies} from "../../../../assets/data";
import ReviewHero from "./ReviewHero";
import { MotivationIllustration } from "../../../../assets/illustrations";
import { fDate2 } from "../../../../utils/formatTime";
import { getHelperText } from './utils';

const AccountReview = () => {
    const {translate, currentLanguage, languageCode} = useLocales();
    const {getValue} = useFormYup();

    const accountName = getValue('accountName');
    const accountOpeningBalance = getValue('accountOpeningBalance');
    const accountOpeningDate = getValue('accountOpeningDate');
    const accountTypeDescription = getValue('accountTypeDescription');
    const accountDescription = getValue('accountDescription');
    const accountCurrency = getValue('accountCurrency');
    const accountClassType = getValue('accountClassType');
    const accountTypeName = getValue('accountTypeName');

    const accountCurrencyName = currencies[currentLanguage.value].findIndex(r => r.value === accountCurrency) === -1
        ? undefined
        : currencies[currentLanguage.value].find(r => r.value === accountCurrency).name;

    return (
        <Stack spacing={4} sx={{pt: 3, px: 3, pb: 15}}>
            <Typography variant="h4">
                {translate('management.account.reviewAccount_header')}
            </Typography>
            <ReviewHero
                details={[
                    {title: translate('management.account.name_title'), description: accountName || translate('management.account.name_label')},
                    // {title: translate('management.account.openingBalance_title'), description: accountOpeningBalance || translate('management.account.openingBalance_title')},
                    {title: translate('management.account.openingBalance_title'), description: Number(accountOpeningBalance) !== 0 ? getHelperText(translate, accountClassType, accountOpeningBalance) : translate('management.account.openingBalance_default')},
                    {title: translate('management.account.openingDate_title'), description: fDate2(accountOpeningDate, 'MMMM yyyy', languageCode) || translate('management.account.openingDate_title')},
                    {title: translate('management.account.currency_title'), description: accountCurrencyName || translate('management.account.currency_title')},
                ]}
                img={
                    <MotivationIllustration
                        sx={{
                            p: 3,
                            width: 360,
                            margin: { xs: 'auto', md: 'inherit' },
                        }}
                    />
                }
                action={
                    <Button variant="contained" size="large" type="submit" sx={{
                        m: 4,
                        right: 20,
                        position: 'absolute',
                        margin: { xs: 'auto', md: 'inherit' },
                    }}>
                        {translate('management.account.reviewAccount_submit')}
                    </Button>
                }
            />
            <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                <Grid item xs={12}>
                    <Typography paragraph variant="h4" sx={{whiteSpace: 'pre-line'}}>
                        {translate('management.account.reviewAccount_title')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography paragraph variant="h6" sx={{whiteSpace: 'pre-line'}}>
                        {translate('management.account.description_title')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography paragraph variant="body1" sx={{whiteSpace: 'pre-line'}}>
                        {accountDescription || translate('management.account.reviewAccount_noDescription')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography paragraph variant="h6" sx={{whiteSpace: 'pre-line'}}>
                        {translate('management.account.reviewAccount_accountType_title')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography paragraph variant="body1" sx={{whiteSpace: 'pre-line'}}>
                        {accountTypeName}: {accountTypeDescription}
                    </Typography>
                </Grid>
            </Grid>
        </Stack>
    )
}

export default AccountReview;