import { useLocation } from 'react-router-dom';
import { Breadcrumbs } from '../../../components/custom-breadcrumbs';
import { Page } from '../../../components/page';
import { useSubscriptionsContext } from '../../../hooks/useSubscriptionsContext';
import CategoriesOverview from '../../../views/subscription/features/Bookkeeping/categories';


function Root() {
    const { pathname } = useLocation();
    const { subscriptionName } = useSubscriptionsContext();
    return (
        <Page title="Bookkeeping | Wyzr">
            <Breadcrumbs
                heading="breadcrumbs.bookkeeping_incomes_info"
                links={[
                    {
                        name: subscriptionName,
                    },
                    {
                        name: 'breadcrumbs.bookkeeping',
                    },
                    {
                        name: 'breadcrumbs.bookkeeping_incomes',
                        href: pathname,
                    },
                ]}
            />
            <CategoriesOverview variant="income"/>
        </Page>
    );
}

export default Root;