import {createSelector} from "reselect";
import {subscriptionAccountsSelector} from "./subscriptionAccountsSelector";

export const subscriptionAccountsTotalSelector = createSelector(
    subscriptionAccountsSelector,
    (accounts) => accountsTotalSelector(accounts)
);
const accountsTotalSelector = (accounts) =>
    accounts.reduce((sum, item) => {
        const { accountClassType } = item;
        if (accountClassType === 'Asset') {
            return sum + item.currentBalanceCurrency;
        }
        if (accountClassType === 'Liability') {
            return sum - item.currentBalanceCurrency;
        }
        return sum;
    }, 0);