import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { matchSorter } from 'match-sorter';
import { Stack, Typography, TextField, FormHelperText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { responsiveFontSizes } from '../../utils/fontSizes';
import { useLocales } from '../../locales';
import { ComingSoonBadge } from '../badge';

const matchFilterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, {
        keys: ['label'],
        threshold: matchSorter.rankings.CONTAINS,
    });

const StyledAutoComplete = ({ header, name, options, dialogTitle, dialogLabel, dialogContent, renderOption, disabled, dialogDisabled, ...props }) => {
    const { t } = useLocales();
    const { control } = useFormContext();
    const [value, setValue] = useState('');
    const [open, toggleOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState({
        label: '',
    });

    const fieldValue = useWatch({
        name,
    });

    const handleClose = () => {
        setDialogValue({
            label: '',
        });
        toggleOpen(false);
    };

    const defaultInput = (inputProps, field, params, error) => (
        <TextField
            InputProps={{
                sx: {
                    ...responsiveFontSizes({ sm: 16, md: 18, lg: 20 }),
                    height: 50,
                },
            }}
            sx={{
                '& .MuiInputBase-root': {
                    height: 83,
                },
                '& .MuiInputBase-input': {
                    height: 50,
                },
            }}
            {...inputProps}
            {...field}
            {...params}
            error={error !== undefined}
            helperText={error !== undefined && error.message}
        />
    );

    useEffect(() => {
        if (fieldValue === '') {
            setValue('');
        }
    }, [fieldValue]);

    useEffect(() => {
        if (fieldValue !== '') {
            const option = options.find((r) => r.id === fieldValue);
            setValue(option);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Stack spacing={1} p={0}>
            <Typography variant="h6">{header}</Typography>

            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => {
                    const { onChange } = field;
                    return (
                        <>
                            <Autocomplete
                                disabled={disabled || false}
                                value={value}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        // timeout to avoid instant validation of the dialog's form.
                                        setTimeout(() => {
                                            toggleOpen(true);
                                            setDialogValue({
                                                label: newValue,
                                            });
                                        });
                                    } else if (newValue && newValue.inputValue) {
                                        toggleOpen(true);
                                        setDialogValue({
                                            label: newValue.inputValue,
                                        });
                                    } else if (newValue) {
                                        setValue(newValue);
                                        onChange(newValue.id);
                                    } else {
                                        setValue(newValue);
                                        onChange('');
                                    }
                                }}
                                filterOptions={(filterOptions, params) => {
                                    const filtered = matchFilterOptions(filterOptions, params);
                                    if (dialogDisabled) {
                                        return filtered;
                                    }
                                    if (params.inputValue !== '' && options.findIndex((r) => r.label.toLowerCase() === params.inputValue.toLowerCase()) === -1) {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            label: `${t('common.add')} "${params.inputValue}"`,
                                            id: params.inputValue,
                                        });
                                    }
                                    return filtered;
                                }}
                                options={options}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.label;
                                    }
                                    return option.label;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                fullWidth
                                freeSolo
                                renderInput={(params) => defaultInput(props, field, params, error)}
                                renderOption={(params, option, state) =>
                                    renderOption ? (
                                        renderOption(params, option, state)
                                    ) : (
                                        <li {...params}>
                                            <Typography sx={{ ...responsiveFontSizes({ sm: 12, md: 14, lg: 16 }) }}>{option.label}</Typography>
                                        </li>
                                    )
                                }
                            />
                            {!error && props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
                        </>
                    );
                }}
            />

            {dialogDisabled === false && (
                <Dialog open={open}>
                    <form>
                        <DialogTitle>
                            <ComingSoonBadge>{dialogTitle}</ComingSoonBadge>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>{dialogContent}</DialogContentText>
                            <TextField autoFocus margin="dense" id="name" value={dialogValue.label} label={dialogLabel} type="text" variant="standard" />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>{t('common.cancel')}</Button>
                            <Button type="submit" disabled>
                                {t('common.add')}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
        </Stack>
    );
};

StyledAutoComplete.propTypes = {
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
    options: PropTypes.array,

    dialogTitle: PropTypes.string,
    dialogContent: PropTypes.string,
    dialogLabel: PropTypes.string,
    dialogDisabled: PropTypes.bool,

    renderOption: PropTypes.func,
};
StyledAutoComplete.defaultProps = {
    variant: 'filled',
    options: [],
    renderOption: undefined,

    dialogDisabled: false,
};

export default StyledAutoComplete;
