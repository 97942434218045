import PropTypes from 'prop-types';
import {useState, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {Typography} from "@mui/material";
// hooks
import useActiveLink from '../../../hooks/useActiveLink';
//
import {StyledPopover} from './styles';
import NavItem from './NavItem';
import {useSettingsContext} from "../../settings";
import {useLocales} from "../../../locales";

// ----------------------------------------------------------------------

NavList.propTypes = {
    data: PropTypes.object,
    depth: PropTypes.number,
    hasChild: PropTypes.bool,
};

export default function NavList({data, depth, hasChild}) {
    const {isRTL} = useSettingsContext();
    const navRef = useRef(null);
    const {pathname} = useLocation();
    const {active, isExternalLink} = useActiveLink(data.path);
    const [open, setOpen] = useState(false);
    const {translate} = useLocales();

    useEffect(() => {
        if (open) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    useEffect(() => {
        if (open) {
            document.body.style.overflow = '';
        } else {
            document.body.style.overflow = '';
        }
    }, [open]);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <NavItem
                ref={navRef}
                item={data}
                depth={depth}
                open={open}
                active={active}
                isExternalLink={isExternalLink}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
            />

            {hasChild && (
                <StyledPopover
                    open={open}
                    anchorEl={navRef.current}
                    anchorOrigin={{vertical: 'center', horizontal: isRTL ? 'left' : 'right'}}
                    transformOrigin={{vertical: 'center', horizontal: isRTL ? 'right': 'left'}}
                    PaperProps={{
                        onMouseEnter: handleOpen,
                        onMouseLeave: handleClose,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        {translate(data.languageCode)}
                    </Typography>
                    <NavSubList data={data.children} depth={depth}/>
                </StyledPopover>
            )}
        </>
    );
}

// ----------------------------------------------------------------------

NavSubList.propTypes = {
    data: PropTypes.array,
    depth: PropTypes.number,
};

function NavSubList({data, depth}) {
    return (
        <>
            {data.map((list) => (
                <NavList key={list.languageCode + list.path} data={list} depth={depth + 1} hasChild={!!list.children}/>
            ))}
        </>
    );
}
