import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { alpha, Card, Typography, CardActionArea, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { sumArray } from '../../../../../utils/withMaths';
import { useLocales } from '../../../../../locales';
import { fCurrencyBy100 } from '../../../../../utils/formatNumber';
import AccountBalancesChart from './AccountBalancesChart';
import DigitTypography from '../../../../../components/typography/DigitTypography';
import AccountingTypography from "../../../../../components/typography/AccountingTypography";

const AccountCard = ({
    alias,
    currentBalance,
    previousBalances,
    accountNumber,
    currency,
    currentBalanceCurrency,
    subscriptionCurrency,
    accountClassType,
    ...props
}) => {
    const { t } = useLocales();
    const navigate = useNavigate();
    const theme = useTheme();
    const sumBalances = sumArray(previousBalances, 'amount');
    const showExchange = currency !== subscriptionCurrency;

    const handleCardSelect = () => {
        navigate(`/subscription/accounts/${props.id}/transactions`);
    };

    return (
        <Card
            sx={{
                display: 'flex',
                pt: 3,
                pl: 3,
                pr: 3,
                flexDirection: 'column',
                '&:hover': {
                    // boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`
                    boxShadow: `-4px 4px 4px 2px ${alpha(theme.palette.grey[900], 0.16)}`,
                },
            }}
        >
            <CardActionArea sx={{ color: 'transparent' }} onClick={handleCardSelect}>
                <Stack sx={{ color: theme.palette.common.black }}>
                    <Typography sx={{ mb: 1, typography: 'subtitle2', opacity: 0.48  }}>
                        {alias}
                    </Typography>

                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="h3" sx={{ opacity: 0.48 }}>
                            {currency}
                        </Typography>
                        <AccountingTypography amount={currentBalance} accountClassType={accountClassType} variant="h3"/>
                        {showExchange && (
                            <Stack direction="row" spacing={0} sx={{ opacity: 0.68 }}>
                                <Typography variant="body1">
                                    (
                                </Typography>
                                <DigitTypography variant="body1">
                                    {fCurrencyBy100(currentBalanceCurrency)}
                                </DigitTypography>
                                <Typography variant="body1" ml={1}>
                                    {subscriptionCurrency}
                                </Typography>
                                <Typography variant="body1">
                                    )
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                </Stack>

                {sumBalances === 0 && (
                    <Stack sx={{ height: 295, pt: 6 }} color="black" spacing={1}>
                        <Typography variant="h5" gutterBottom component="span">
                            {t('accounts.emptyTransactions')}
                        </Typography>
                        <Typography variant="body1" component="span">
                            {t('accounts.emptyTransactionsAdd')}
                        </Typography>
                    </Stack>
                )}

                {sumBalances !== 0 && <AccountBalancesChart data={previousBalances} />}
            </CardActionArea>
        </Card>
    );
};
AccountCard.propTypes = {
    id: PropTypes.string.isRequired,
    alias: PropTypes.string.isRequired,
    accountNumber: PropTypes.string,
    currentBalance: PropTypes.number.isRequired,
    currentBalanceCurrency: PropTypes.number.isRequired,
    previousBalances: PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired,
    subscriptionCurrency: PropTypes.string.isRequired,
    accountClassType: PropTypes.string.isRequired,
};

export default AccountCard;
