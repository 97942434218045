import { Tab, Tabs } from "@mui/material";
import Label from "../../../../../components/label";
import { bankTypes, countBy } from "../../../../../utils/withAccountMaths";
import { useLocales } from "../../../../../locales";
import { useAccountsContext } from "../../../../../hooks/useAccountsContext";
import { responsiveFontSizes } from "../../../../../utils/fontSizes";
import { useAccountsFilterContext } from "../hooks/useAccountsFilterContext";

const DEFAULT_TABS = [
    {value: 'system_all_account_types', label: 'accounts.filter_all', color: 'info', count: 0},
];

const AccountsFilter = () => {
    const {t} = useLocales();
    const {accounts} = useAccountsContext();
    const {selectedAccountType, onUpdateSelectedAccountType} = useAccountsFilterContext();
    const types = bankTypes(accounts);

    const typeTabs = types.map(r => ({
        value: r,
        label: `enums.accountTypes.${r.toLowerCase()}`,
        color: 'default',
        count: 0
    }));

    const handleFilterStatus = (event, newValue) => {
        onUpdateSelectedAccountType(newValue);
    };

    const renderTab = tab => {
        const length = countBy(accounts, 'accountType', tab.value);
        return (
            <Tab
                key={tab.value}
                value={tab.value}
                label={t(tab.label)}
                icon={
                    <Label color={tab.color} sx={{mr: 1}}>
                        {length}
                    </Label>
                }
                sx={{
                    ...responsiveFontSizes({sm: 16, md: 16, lg: 18})
                }}
            />
        )
    }
    return (
        <Tabs
            value={selectedAccountType}
            sx={{
                px: 2,
                bgcolor: 'background.neutral',
            }}
            onChange={handleFilterStatus}
        >
            {DEFAULT_TABS.map((tab) => renderTab(tab))}
            {typeTabs.map((tab) => renderTab(tab))}
        </Tabs>
    )
}
AccountsFilter.propTypes = {};

export default AccountsFilter;