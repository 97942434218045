import PropTypes from 'prop-types';
// @mui
import {Paper, Typography, Box, Stack} from '@mui/material';

const SummaryWidget = ({children, title, subtitle, showHeader}) => (
    <Paper
        elevation={4}
        sx={{
            width: 1,
            borderRadius: 2,
            overflow: 'hidden',
            position: 'relative',
            '&:hover': {
                boxShadow: (theme) => theme.customShadows.z24,
            },
            // TODO make height variable?
            height: 450
        }}
    >
        <Box sx={{
            // cursor: 'pointer',
            height: 1
        }}>
            <Stack spacing={1} sx={{px: 2, pt: 1, pb: 1, height: 1}}>
                {showHeader &&
                    <Stack spacing={1}>
                        <Typography variant="h3" gutterBottom>{title}</Typography>
                        {subtitle && <Typography variant="body1">subtitle</Typography>}
                    </Stack>
                }
                {children}
            </Stack>
        </Box>
    </Paper>
)
SummaryWidget.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    showHeader: PropTypes.bool,
};
SummaryWidget.defaultProps = {
    showHeader: true
}
export default SummaryWidget;