import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector, TimelineOppositeContent } from '@mui/lab';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useLocales } from '../../../../../../locales';
import { fMonthKeyToDate } from '../../../../../../utils/formatTime';
import { useSettingsContext } from '../../../../../../components/settings';
import TimelineTypography from './TimelineTypography';
import useTransactionsContext from '../../../../../../contexts/Transactions/useTransactionsContext';

const ExtendedTimelineTypography = ({ title1, balance1, title2, balance2, justifyItems, hideBalance1 }) => (
    <Box
        alignItems="center"
        sx={{
            height: 1,
            display: 'inline-grid',
            justifyItems,
        }}
    >
        <TimelineTypography balance={balance1} title={title1} showBalance={hideBalance1} />
        <TimelineTypography balance={balance2} title={title2} showBalance />
    </Box>
);
ExtendedTimelineTypography.propTypes = {
    justifyItems: PropTypes.string.isRequired,
    title1: PropTypes.string.isRequired,
    title2: PropTypes.string.isRequired,
    balance1: PropTypes.number.isRequired,
    balance2: PropTypes.number.isRequired,
    hideBalance1: PropTypes.bool,
};

const TimelineBalanceEnd = ({ balance, monthKey, totalExpenses, totalIncomes }) => {
    const { currentLanguage, t } = useLocales();
    const { isRTL } = useSettingsContext();
    const { selectedAccountClassType } = useTransactionsContext();
    const isDebit = !isRTL ? balance < 0 : balance >= 0;
    const isCredit = !isRTL ? balance >= 0 : balance < 0;

    const date = fMonthKeyToDate(monthKey, currentLanguage.value);
    const title = `${t('transactions.labelEndingBalance')} ${date}`;

    const debitLabel = selectedAccountClassType === 'Asset' ? t('transactions.labelSpentOn') : t('transactions.labelReceived_liability');
    const debitBalance = selectedAccountClassType === 'Asset' ? totalExpenses : totalIncomes;

    const creditLabel = selectedAccountClassType === 'Asset' ? t('transactions.labelReceived') : t('transactions.labelSpentOn_liability');
    const creditBalance = selectedAccountClassType === 'Asset' ? totalIncomes : totalExpenses;

    const subTitle1 = !isRTL ? `${debitLabel} ${date}` : `${creditLabel} ${date}`;
    const balance1 = !isRTL ? debitBalance : creditBalance;

    const subTitle2 = !isRTL ? `${creditLabel} ${date}` : `${debitLabel} ${date}`;
    const balance2 = !isRTL ? creditBalance : debitBalance;

    return (
        <TimelineItem>
            <TimelineOppositeContent>
                <ExtendedTimelineTypography
                    title1={isDebit ? title : ''}
                    balance1={balance}
                    hideBalance1={isDebit}
                    title2={subTitle1}
                    balance2={balance1}
                    justifyItems="end"
                />
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color="info">
                    <TrendingUpIcon />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <ExtendedTimelineTypography
                    title1={isCredit ? title : ''}
                    balance1={balance}
                    title2={subTitle2}
                    balance2={balance2}
                    hideBalance1={isCredit}
                    justifyItems="start"
                />
            </TimelineContent>
        </TimelineItem>
    );
};
TimelineBalanceEnd.propTypes = {
    balance: PropTypes.number,
    monthKey: PropTypes.number,
    totalExpenses: PropTypes.number,
    totalIncomes: PropTypes.number,
};

export default TimelineBalanceEnd;
