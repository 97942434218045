import {useState, useEffect} from "react";
import * as states from '../utils/dataStates';
import { STATE_FULFILLED } from "../utils/dataStates";

// TODO replace with SWR
// TODO assert argument is ref to a promise function
const usePromiseFetch = (onDataFetching, ...args) => {
    const [state, setState] = useState(states.STATE_IDLE);
    const [fetchedData, setFetchedData] = useState(undefined);

    useEffect(() => {
        if (state === states.STATE_IDLE) {
            setState(states.STATE_BUSY);
            onDataFetching(...args)
                .then(response => {
                    setFetchedData(response);
                })
                .then(() => {
                    setState(states.STATE_FULFILLED)
                })
                .catch(err => {
                    console.log("Oops, something went wrong.", err);
                    setState(states.STATE_ERROR);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return {
        status: state,
        data: fetchedData,
        loading: state !== STATE_FULFILLED,
        refresh: () => setState(states.STATE_IDLE)
    }
};

export default usePromiseFetch;