import Grid from '@mui/material/Unstable_Grid2';
import {withSummaryWidget} from "../components";
import RecurringSpendTrend from "./RecurringSpendTrend";

const RecurringSpendWidget = () => (
    <Grid container sx={{flexGrow: 1, p: 0}}>
        <Grid xs={12}>
            <RecurringSpendTrend />
        </Grid>
    </Grid>
);

export default withSummaryWidget(
    RecurringSpendWidget,
    'spent_recurring_title',
    'spent_recurring_subtitle',
    true
);