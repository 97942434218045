import PropTypes from "prop-types";
import {Stack} from '@mui/material';
import {TemplateStoreUrlBuilder} from "../../../../api/TemplateStoreApi";
import SubscriptionBookTemplate from "./SubscriptionBookTemplate";
import {SkeletonFormSwr} from "../../../../components/async-components";
import {useFormYup} from "../../../../components/hook-form/useFormYup";

const SubscriptionBookTemplates = ({bookLanguage}) => {
    const swrKey = TemplateStoreUrlBuilder.getDefaultCatalogUrl(bookLanguage);
    const {swrValidating} = useFormYup();

    return (
        <Stack spacing={4} flexWrap direction="row">
            <SkeletonFormSwr
                dataKey="books"
                mode="multiple"
                swrKey={swrKey}
                swrOptions={{
                    revalidateIfStale: false,
                    revalidateOnFocus: false,
                    revalidateOnReconnect: false
                }}
                onSwrValidating={swrValidating}
            >
                {({id, name, description, index}) => (
                    <SubscriptionBookTemplate id={id} name={name} description={description} key={index}/>
                )}
            </SkeletonFormSwr>
        </Stack>
    )
}
SubscriptionBookTemplates.propTypes = {
    bookLanguage: PropTypes.string.isRequired
};
export default SubscriptionBookTemplates;

// const fetcher = url => axios.get(url).then(res => res.data);
// const SubscriptionBookTemplates = ({bookLanguage}) => {
//     const {data, error} = useSWR(TemplateStoreApi.getDefaultCatalogSWR(bookLanguage), fetcher);
//     if (error) return <div>failed to load</div>
//     if (!data) return <SkeletonLoad/>
//
//     const books = data ? data.books || [] : [];
//     return (
//         <>
//             {books.map((item, index) => (
//                 <SubscriptionBookTemplate {...item} key={index} />
//             ))}
//         </>
//     )
// }
// SubscriptionBookTemplates.propTypes = {
//     bookLanguage: PropTypes.string.isRequired
// };
// export default SubscriptionBookTemplates;