import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Stack, Box, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useLocales } from '../../../../../../locales';
import useReportDataContext from '../../components/useReportDataContext';
import DateRangeTypography from '../../../../../../components/typography/DateRangeTypography';
import AmountTypography from '../../../../../../components/typography/AmountTypography';
import Iconify from '../../../../../../components/iconify';
import { reportChartViews } from '../../../../../../assets/data/reports';

const RootStyle = styled(Box)(({ theme }) => ({
    boxShadow: 'none',
}));

const ChartOptions = ({ total, chartView, chartType, onChartViewChange, onChartTypeChange }) => {
    const { t, currentLanguage } = useLocales();
    const { selectedDate } = useReportDataContext();
    const views = reportChartViews[currentLanguage.value];

    return (
        <RootStyle>
            <Stack
                spacing={2}
                alignItems="start"
                direction={{
                    xs: 'column',
                    md: 'row',
                }}
                sx={{
                    px: 1.5,
                    py: 2,
                }}
            >
                <Stack direction="column" sx={{ flexGrow: 1 }}>
                    <Typography>{t('reports.transactions.totalExpenses')}</Typography>
                    <AmountTypography amount={total} useInt variant="h3" />
                    <DateRangeTypography date={selectedDate} />
                </Stack>

                <TextField
                    select
                    value={chartView}
                    size="small"
                    variant="standard"
                    onChange={e => onChartViewChange(e.target.value)}
                    sx={{display: 'none'}}
                >
                    {views.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>

                {chartView === 'by_total' && (
                    <ToggleButtonGroup size="small" value={chartType} color="primary" exclusive onChange={(e,v) => onChartTypeChange(v)}>
                        <ToggleButton value="by_total_pie">
                            <Iconify icon="solar:pie-chart-2-bold-duotone" />
                        </ToggleButton>

                        <ToggleButton value="by_total_bar">
                            <Iconify icon="solar:chart-bold-duotone" />
                        </ToggleButton>
                    </ToggleButtonGroup>
                )}
                {chartView === 'by_time' && (
                    <ToggleButtonGroup size="small" value={chartType} color="primary" exclusive onChange={(e,v) => onChartTypeChange(v)}>
                        <ToggleButton value="by_time_column">
                            <Iconify icon="solar:chart-2-bold-duotone" />
                        </ToggleButton>

                        <ToggleButton value="by_time_stacked">
                            <Iconify icon="solar:chart-bold-duotone" />
                        </ToggleButton>
                    </ToggleButtonGroup>
                )}
            </Stack>
        </RootStyle>
    );
};
ChartOptions.propTypes = {
    total: PropTypes.object,
    chartView: PropTypes.string,
    chartType: PropTypes.string,

    onChartViewChange: PropTypes.func,
    onChartTypeChange: PropTypes.func,
};

export default ChartOptions;