import PropTypes from 'prop-types';
import { Controller, useFormContext, useFormState, useWatch } from 'react-hook-form';
import { Card, CardContent, CardActionArea, Typography, Stack, Divider } from '@mui/material';
import Filter2Icon from '@mui/icons-material/Filter2';
import { useLocales } from '../../../../locales';
import { useFormYup } from '../../../../components/hook-form/useFormYup';
import { ComingSoonBadge } from '../../../../components/badge';

const Accounts = ({ name, data }) => {
    const { t } = useLocales();
    const { setValue } = useFormYup();
    const { control } = useFormContext();
    const accountCode = useWatch({
        name: 'accountCode',
    });
    const accountTypeCode = useWatch({
        name,
    });
    const { dirtyFields } = useFormState({
        control,
    });

    const { accounts, limit, accountClassType } = data.find((r) => r.code === accountCode) || { accounts: [] };
    const { accountsLimited } = limit || { accountsLimited: false };

    const validAccounts = data.reduce((acc, item) => {
        const { accounts: accs, name: acc_name, limit: accs_limit } = item;
        if (accs_limit && accs_limit.accountsLimited) {
            return acc;
        }
        accs.filter((r) => r.enabled).map((account) => acc.push(`(${acc_name}) ${account.name}`));
        return acc;
    }, []);

    return (
        <>
            <Divider variant="fullWidth" style={{ width: '100%' }} />

            {accountCode && !accountsLimited && (
                <Stack direction="row" spacing={2} alignItems="center" sx={{ color: '#22C55E' }}>
                    <Filter2Icon />
                    <Typography variant="h5" gutterBottom>
                        {t('management.account.accountTypes_label')}
                    </Typography>
                </Stack>
            )}
            {accountCode && accountsLimited && (
                <Typography variant="h5" gutterBottom sx={{ color: 'warning.main' }}>
                    {t('management.account.accountTypes_limited')}
                </Typography>
            )}
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <Stack spacing={2}>
                        <Stack direction="row" spacing={4}>
                            {accounts.map((account, index) => (
                                <Card
                                    key={index}
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: accountTypeCode === account.code ? 'action.selected' : 'inherit',
                                    }}
                                >
                                    <CardActionArea
                                        disabled={!account.enabled || (limit && limit.accountsLimited)}
                                        onClick={() => {
                                            // TODO business logic creeping in
                                            if (account.code === 'CR_CSH') {
                                                setValue('accountClassType', 'liability');
                                            } else {
                                                setValue('accountClassType', accountClassType);
                                            }
                                            setValue('accountTypeCode', account.code);
                                            setValue('accountTypeDescription', account.description);
                                            setValue('accountTypeName', account.name);
                                        }}
                                        sx={{
                                            color: (theme) => (account.enabled ? 'inherit' : theme.palette.warning.main),
                                            height: 1,
                                        }}
                                    >
                                        <CardContent>
                                            {!account.enabled && <ComingSoonBadge sx={{ ml: 1 }} />}

                                            <Typography variant="h5" sx={{ pt: account.enabled ? 0 : 2 }}>
                                                {account.name}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                                sx={{
                                                    color: account.enabled ? 'text.secondary' : 'palette.warning.main',
                                                }}
                                            >
                                                {account.description}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            ))}
                        </Stack>
                        {error && (
                            <>
                                <Typography variant="h6" sx={{ color: (theme) => theme.palette.error.main, mb: { xs: 3, xl: 5 } }}>
                                    {error?.message}
                                </Typography>
                                {validAccounts.map((r, index) => (
                                    <Typography variant="subtitle1" sx={{ color: (theme) => theme.palette.error.main, mb: { xs: 3, xl: 5 } }} key={index}>
                                        {r}
                                    </Typography>
                                ))}
                            </>
                        )}
                        {dirtyFields.accountTypeCode && dirtyFields.accountCode && (
                            <Typography variant="subtitle1" sx={{ color: (theme) => theme.palette.success.main, mb: { xs: 3, xl: 5 } }}>
                                {t('management.account.proceedToNextStep')}
                            </Typography>
                        )}
                    </Stack>
                )}
            />
        </>
    );
};
Accounts.propTypes = {
    name: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
};

export default Accounts;
