import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import * as states from '../../utils/dataStates';

const initialState = {};

export const startFetchAsync = createAsyncThunk(
    'post/async',
    async payload => payload
)
export const endFetchAsync = createAsyncThunk(
    'del/async',
    async payload => payload
)

const slice = createSlice({
    name: 'asyncPages',
    initialState,
    reducers: {},
    extraReducers: {
        // [startFetchAsync.pending]: (state, action) => {
        //     const {payload: key} = action;
        //     state[key] = states.STATE_BUSY;
        // },
        [startFetchAsync.fulfilled]: (state, action) => {
            const {payload: key} = action;
            state[key] = states.STATE_BUSY;
        },
        [endFetchAsync.fulfilled]: (state, action) => {
            const {payload: key} = action;
            state[key] = states.STATE_FULFILLED;
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;