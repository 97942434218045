import { createContext, useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {useAuth0} from '@auth0/auth0-react';
import {AUTH0_API} from '../../config';

const initialState = {
    userToken: null,
    userId: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        const {userId, token} = action.payload;
        return {...state, userId, userToken: token};
    },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthMetadataContext = createContext({
    ...initialState,
});

const AuthMetadataProvider = ({children}) => {
    const {getAccessTokenSilently, user, isLoading, isAuthenticated} = useAuth0();
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            try {
                if (isLoading || !isAuthenticated) {
                    return;
                }

                const {domain} = AUTH0_API;
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: `https://${domain}/api/v2/`,
                        scope: 'read:current_user',
                    },
                });

                // NOTE SET auth token
                // TODO investigate usage of setSession in utils
                // TODO remove settings
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                localStorage.setItem('accessToken', token);

                const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
                const metadataResponse = await fetch(userDetailsByIdUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const {app_metadata: appMetaData} = await metadataResponse.json();
                dispatch({
                    type: 'INITIALIZE',
                    payload: {token, ...appMetaData}
                });
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {token: null, userId: null},
                });
            }
        };
        initialize().then(() => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAccessTokenSilently, isLoading]);

    const values = useMemo(() => ({
        ...state
    }), [state]);

    return (
        <AuthMetadataContext.Provider value={values}>
            {children}
        </AuthMetadataContext.Provider>
    );
};
AuthMetadataProvider.propTypes = {
    children: PropTypes.node,
};

export {AuthMetadataProvider, AuthMetadataContext};
