import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@mui/material';
import Iconify from '../../../../components/iconify';
import { useLocales } from '../../../../locales';
import { responsiveFontSizes } from '../../../../utils/fontSizes';

const FilterSearch = ({ searchValue, setSearchValue }) => {
    const { t } = useLocales();
    return (
        <TextField
            label={t('common.search')}
            variant="filled"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                ),
                sx: {
                    ...responsiveFontSizes({ sm: 16, md: 18, lg: 20 }),
                    height: 70,
                },
            }}
            value={searchValue}
            onChange={(event) => {
                setSearchValue(event.target.value);
            }}
        />
    );
};
FilterSearch.propTypes = {
    searchValue: PropTypes.string,
    setSearchValue: PropTypes.func,
};

export default FilterSearch;