import PropTypes from "prop-types";
import { Children } from 'react';
import { styled } from '@mui/material/styles';
import { Divider, Box } from '@mui/material';
import ListFilterChip from "./ListFilterChip";
import useFilterContext from "./useFilterContext";
import MegaMenuButton from "./MegaMenuButton";

const RootStyle = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 1, 0, 3),
    backgroundColor: '#eef5fd'
}));

const MenuButtonStyle = styled(Box)(() => ({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: 6
}));

const FilterGroup = ({children}) => {
    const {chips, handleDeleteChip, handleToggleFilter} = useFilterContext();
    return (
        <RootStyle data-tour="tour__txn_filter_action">
            <ListFilterChip chips={chips} handleChipDelete={handleDeleteChip}/>
            <Divider/>
            <MenuButtonStyle>
                {Children.map(children, (child, index) => (
                    <MegaMenuButton
                        key={index}
                        labelLanguageCode={child.props.labelLanguageCode}
                        label={child.props.label}
                        id={child.props.filterId}
                        toggleFilter={handleToggleFilter}/>
                ))}
            </MenuButtonStyle>
            {children}
        </RootStyle>
    )
};
FilterGroup.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export default FilterGroup;
