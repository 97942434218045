import PropTypes from "prop-types";
import { createContext, useCallback, useMemo, useReducer } from "react";
import reducer from "./reducer";
import * as types from "./types";

const initialState = {
    selectedIds: []
};

const TransactionsPayeeContext = createContext();
const TransactionsPayeeProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const handlePayeeChange = useCallback((id) => {
        if (state.selectedIds.findIndex(r => r === id) !== -1) {
            const filteredItems = state.selectedIds.filter(item => item !== id)
            dispatch({type: types.UPDATE_SELECTED_PAYEES, payload: filteredItems});
        } else {
            const payload = [...state.selectedIds, id];
            dispatch({type: types.UPDATE_SELECTED_PAYEES, payload});
        }
    }, [state.selectedIds]);
    const handlePayeeRemove = useCallback((id) => {
        if (state.selectedIds.includes(id)) {
            const payload = state.selectedIds.filter(item => item !== id);
            dispatch({type: types.UPDATE_SELECTED_PAYEES, payload});
        }
    }, [state.selectedIds]);
    const onClearAll = useCallback(() => {
        if (state.selectedIds.length > 0) {
            dispatch({type: types.UPDATE_SELECTED_PAYEES, payload: []});
        }
    }, [state.selectedIds]);

    
    const value = useMemo(() => ({
        ...state,
        handlePayeeChange,
        handlePayeeRemove,
        onClearAll,
    }), [
        state,
        handlePayeeChange,
        handlePayeeRemove,
        onClearAll,
    ]);
    return (
        <TransactionsPayeeContext.Provider value={value}>
            {children}
        </TransactionsPayeeContext.Provider>
    )
};
TransactionsPayeeProvider.propTypes = {
    children: PropTypes.node,
};

export default TransactionsPayeeProvider;
export { TransactionsPayeeContext };