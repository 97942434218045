import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useMemo } from "react";
import useLocalStorage from '../../../../../hooks/useLocalStorage';

const ASIDEBAR_NONE = undefined;
const ASIDEBAR_ADD_PAYEE = 'ADD_PAYEE';
const ASIDEBAR_UPDATE_PAYEE = 'UPDATE_PAYEE';
const ASIDEBAR_ADD_CATEGORY = 'ADD_CATEGORY';
const ASIDEBAR_UPDATE_CATEGORY = 'UPDATE_CATEGORY';


const initialState = {
    showAsidebar: false,
    asidebarType: ASIDEBAR_NONE,
    asidebarPayload: undefined,
};
const LayoutSettingsContext = createContext(initialState);
const useLayoutSettingsContext = () => {
    const context = useContext(LayoutSettingsContext);
    if (!context) throw new Error('useLayoutSettingsContext must be use inside LayoutSettingsContext');
    return context;
};


const LayoutSettingsProvider = ({ children }) => {
    const [settings, setSettings] = useLocalStorage('bookkeeping_settings', initialState);
    const onToggleAsidebar = useCallback((asidebarType, payload = undefined) => {
        const showAsidebar = !settings.showAsidebar;
        setSettings({...settings, showAsidebar, asidebarType, asidebarPayload: payload});
    }, [setSettings, settings]);

    const value = useMemo(() => ({
        ...settings,
        onToggleAsidebar
    }), [
        settings,
        onToggleAsidebar
    ]);

    return (
        <LayoutSettingsContext.Provider value={value}>
            {children}
        </LayoutSettingsContext.Provider>
    )
};
LayoutSettingsProvider.propTypes = {
    children: PropTypes.node,
};

export default LayoutSettingsProvider;
export {
    LayoutSettingsContext,
    useLayoutSettingsContext,
    ASIDEBAR_NONE,
    ASIDEBAR_UPDATE_PAYEE,
    ASIDEBAR_ADD_PAYEE,
    ASIDEBAR_ADD_CATEGORY,
    ASIDEBAR_UPDATE_CATEGORY
};