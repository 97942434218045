import PropTypes from 'prop-types';
import { Stack, Box } from '@mui/material';
import { TimelineView } from './Views/Timeline';
import useTransactionsContext from '../../../../contexts/Transactions/useTransactionsContext';
import EmptyTransactions from './components/EmptyTransactions';
import StatsView from './Views/StatsView';
import FilterView from './Views/FilterView';
import TransactionsToolbar from './components/TransactionsToolbar';
import { HEADER } from '../../../../config';
import useResponsive from '../../../../hooks/useResponsive';

const StickyHeader = ({ children }) => {
    const isDesktop = useResponsive('up', 'lg');
    return (
        <Box
            sx={{
                p: 0,
                position: 'sticky',
                top: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
                ...(isDesktop && {
                    top: HEADER.H_DASHBOARD_DESKTOP,
                }),
                zIndex: 1000,
                backgroundColor: '#fff',
            }}
        >
            {children}
        </Box>
    );
};
StickyHeader.propTypes = {
    children: PropTypes.node,
};

// eslint-disable-next-line react/prop-types
const TransactionListTop = ({ data, selectedAccountId }) => (
    <StickyHeader>
        <TransactionsToolbar />
        <FilterView />
        <StatsView data={data} selectedAccountId={selectedAccountId} />
    </StickyHeader>
);

const TransactionsList = () => {
    const { hasNoTransactions, data, selectedAccountId, isMutating } = useTransactionsContext();
    if (hasNoTransactions) {
        return (
            <Stack spacing={2}>
                <TransactionListTop data={data} selectedAccountId={selectedAccountId} />
                <EmptyTransactions />
            </Stack>
        );
    }
    return (
        <Stack spacing={2}>
            <TransactionListTop data={data} selectedAccountId={selectedAccountId} />
            <TimelineView data={data} isMutating={isMutating} />
        </Stack>
    );
};

export default TransactionsList;
