import { Suspense } from 'react';
import { SplashScreen } from '../loading-screen';

// TODO use SWR or Relay instead?
// https://blog.logrocket.com/react-suspense-data-fetching/
const withPageSplash = (WrappedComponent) => {
  function HOC({ ...props }) {
    return (
      <Suspense fallback={<SplashScreen />}>
        <WrappedComponent {...props} />
      </Suspense>
    );
  }

  HOC.propTypes = {};

  return HOC;
};
export default withPageSplash;
