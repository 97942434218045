import axios from "axios";
import {SiteConfig} from "./ConfigApi";
import type { Amount } from './SubscriptionsApi';

const buildBasePath = (userId, subscriptionId, currency, url) => {
    const defaultQueryParam = `basecurrency=${currency}`;
    const rootUrl = `${SiteConfig.TxnWebApiUrl}/api/users/${userId}/subscriptions/${subscriptionId}/reports/${url}`;
    if (rootUrl.includes('?')) {
        return `${rootUrl}&${defaultQueryParam}`;
    }
     return `${rootUrl}?${defaultQueryParam}`;
}
const buildBasePath2 = (userId, subscriptionId, url) => {
    const rootUrl = `${SiteConfig.TxnWebApiUrl}/api/users/${userId}/subscriptions/${subscriptionId}/reports/${url}`;
    return `${rootUrl}`;
}

const TransactionReportsUrlBuilder = {
    getNetWorth: (userId, subscriptionId, currency) =>                              buildBasePath(userId, subscriptionId, currency, `netWorth`),
    getNetWorthTrend: (userId, subscriptionId, currency) =>                         buildBasePath(userId, subscriptionId, currency, `netWorth/trend`),

    getExpensesFixedVariableTrend: (userId, subscriptionId, currency) =>            buildBasePath(userId, subscriptionId, currency, `categories/expenses/compare?classification=fixedAndVariable`),
    getExpensesEssentialNonEssentialTrend: (userId, subscriptionId, currency) =>    buildBasePath(userId, subscriptionId, currency, `categories/expenses/compare?classification=essentialAndNonEssential`),
    
    getExpensesSecondaryTrend: (userId, subscriptionId, currency) =>                buildBasePath(userId, subscriptionId, currency, `categories/expenses/secondary`),
    getSpendingTrend: (userId, subscriptionId, currency) =>                         buildBasePath(userId, subscriptionId, currency, `categories/expenses`),
    getRecurringSpend: (userId, subscriptionId, currency) =>                        buildBasePath(userId, subscriptionId, currency, `categories/expenses/recurring`),
    getUpcomingSpend: (userId, subscriptionId, currency) =>                         buildBasePath(userId, subscriptionId, currency, `categories/expenses/upcoming`),

    getRecentSpend: (userId, subscriptionId, currency) =>                           buildBasePath(userId, subscriptionId, currency, `categories/expenses/recent`),

    getIncomeTrend: (userId, subscriptionId, currency) =>                           buildBasePath(userId, subscriptionId, currency, `categories/incomes`),

    getDailyAverageSpendingTrend: (userId, subscriptionId, currency) =>             buildBasePath(userId, subscriptionId, currency, `averages/daily/categories`),
    getDailyAverageVariableSpendingTrend: (userId, subscriptionId, currency) =>     buildBasePath(userId, subscriptionId, currency, `averages/daily/categories/variable`),

    getReportExpenses: (userId, subscriptionId) =>                                  buildBasePath2(userId, subscriptionId, 'categories/expenses'),
    getReportIncomes: (userId, subscriptionId) =>                                   buildBasePath2(userId, subscriptionId, 'categories/incomes'),
    getReportNetIncome: (userId, subscriptionId) =>                                 buildBasePath2(userId, subscriptionId, 'netIncome'),
    getReportNetWorth: (userId, subscriptionId) =>                                  buildBasePath2(userId, subscriptionId, 'netWorth'),
}

export type Transaction = {
    account: string,
    date: string,
    payee: string,
    primaryCategory: string,
    secondaryCategory: string,
    amount: Amount,
    // tags: Array<any>,
};

export type ByTime = {
    total: Amount,
    day: number,
    data: Array<ByTotal>,
};

export type ByTotal = {
    total: Amount,
    name: string,
    data: Array<ByTotal>,
};

export type TransactionsReport = {
    transactions: Array<Transaction>,
    chartByTime: Array<ByTime>,
    chartByTotal: Array<ByTotal>,
    total: Amount
};

export type NetIncome = {
    monthKey: number,
    dayKey: number,
    name: string,
    total: Amount,
};

export type NetIncomeReport = {
    transactions: Array<Transaction>,
    incomes: Array<NetIncome>,
    expenses: Array<NetIncome>,
    netIncomes: Array<NetIncome>,
    totalIncomes: Amount,
    totalExpenses: Amount,
};

export type NetWorthReport = {
    totalAssets: Array<NetWorthPeriod>,
    totalLiabilities: Array<NetWorthPeriod>,
    totalNetWorth: Array<NetWorthPeriod>,
    assets: Array<NetWorthByAccount>,
    debts: Array<NetWorthByAccount>,
    stats: any,
};

export type NetWorthPeriod = {
    monthKey: number,
    dayKey: number,
    name: string,
    total: Amount,
};

export type NetWorthByAccount = {
    accountTypeName: string,
    accountName: string,
    accountTypeCode: string,
    classType: string,
    periods: Array<NetWorthPeriod>,
};

class TransactionReportsApi {

    // WIDGETS

    static getNetWorth(userId, subscriptionId, currency) {
        const url = TransactionReportsUrlBuilder.getNetWorth(userId, subscriptionId, currency);
        return axios
            .get(url)
            .then(response => response.data);
    }

    static getNetWorthTrend(userId, subscriptionId, currency) {
        const url = TransactionReportsUrlBuilder.getNetWorthTrend(userId, subscriptionId, currency);
        return axios
            .get(url)
            .then(response => response.data);
    }

    static getDailyAverageSpendingTrend(userId, subscriptionId, currency) {
        const url = TransactionReportsUrlBuilder.getDailyAverageSpendingTrend(userId, subscriptionId, currency);
        return axios
            .get(url)
            .then(response => response.data);
    }

    static getDailyAverageVariableTrend(userId, subscriptionId, currency) {
        const url = TransactionReportsUrlBuilder.getDailyAverageVariableSpendingTrend(userId, subscriptionId, currency);
        return axios
            .get(url)
            .then(response => response.data);
    }

    static getExpensesFixedVariableTrend(userId, subscriptionId, currency) {
        const url = TransactionReportsUrlBuilder.getExpensesFixedVariableTrend(userId, subscriptionId, currency);
        return axios
            .get(url)
            .then(response => response.data);
    }

    static getExpensesEssentialNonEssentialTrend(userId, subscriptionId, currency) {
        const url = TransactionReportsUrlBuilder.getExpensesEssentialNonEssentialTrend(userId, subscriptionId, currency);
        return axios
            .get(url)
            .then(response => response.data);
    }

    // REPORTS

    static getReportExpenses(url: string, {...props}) : TransactionsReport {
        const {body, query} = props.arg;
        const urlWithQuery = `${url}${query}`;
        return axios
            .post(urlWithQuery, body)
            .then(response => response.data);
    }

    static getReportIncomes(url: string, {...props}) : TransactionsReport {
        const {body, query} = props.arg;
        const urlWithQuery = `${url}${query}`;
        return axios
            .post(urlWithQuery, body)
            .then(response => response.data);
    }

    static getReportNetIncome(url: string, {...props}) : NetIncomeReport {
        const {body, query} = props.arg;
        const urlWithQuery = `${url}${query}`;
        return axios
            .post(urlWithQuery, body)
            .then(response => response.data);
    }

    static getReportNetWorth(url: string, {...props}) : NetWorthReport {
        const {body, query} = props.arg;
        const urlWithQuery = `${url}${query}`;
        return axios
            .post(urlWithQuery, body)
            .then(response => response.data);
    }
}

export default TransactionReportsApi;
export {
    TransactionReportsUrlBuilder
}