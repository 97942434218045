import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import useCategoriesContext from '../../../../../contexts/Categories/useCategoriesContext';
import CategoryList from './CategoryList';
import { useCategoryFilterContext } from '../hooks/useCategoryFilterContext';
import TableSkeleton from './TableSkeleton';
import type { SimplifiedCategory } from '../../../../../contexts/Categories/types';
import CanReset from './CanReset';

const filterData = (data: Array<SimplifiedCategory>, filter: string): Array<SimplifiedCategory> => {
    switch (filter) {
        case 'system_all_category_types': {
            return data;
        }
        case 'system_all_category_primary': {
            return data.filter(r => r.primary === true);
        }
        case 'system_all_category_secondary': {
            return data.filter(r => r.primary === false);
        }
        case 'system_all_category_fixed': {
            return data.filter(r => r.costType === 'Fixed');
        }
        case 'system_all_category_variable': {
            return data.filter(r => r.costType === 'Variable');
        }
        default:
            return [];
    }
};
const filterDataByText = (data: Array<SimplifiedCategory>, filter: string) : Array<SimplifiedCategory> => {
    if (filter.length < 3) {
        return data;
    }
    const searchString = filter.toLowerCase();
    return data.filter(r => r.name.toLowerCase().includes(searchString));
}

const CategoryListHoc = ({ variant }) => {
    const { getCategoriesForTable, isMutating } = useCategoriesContext();
    const { searchFilter, selectedCategoryType, onUpdateSearchFilter, onUpdateSelectedCategoryType } = useCategoryFilterContext();
    const canReset = !!searchFilter || !!(selectedCategoryType !== 'system_all_category_types');

    if (isMutating) {
        return <TableSkeleton />;
    }

    const mappedData = filterData(getCategoriesForTable(variant), selectedCategoryType);
    const mappedSearchData = filterDataByText(mappedData, searchFilter);

    const onResetFilters = () => {
        onUpdateSearchFilter('');
        onUpdateSelectedCategoryType('system_all_category_types');
    };

    return (
        <Stack spacing={1.5}>
            <CanReset canReset={canReset} dataCount={mappedSearchData.length} onResetFilters={onResetFilters} />
            <CategoryList isMutating={isMutating} data={mappedSearchData} canReset={canReset} />
        </Stack>
    );
};

CategoryListHoc.propTypes = {
    variant: PropTypes.oneOf(['income', 'expense']).isRequired,
};

export default CategoryListHoc;