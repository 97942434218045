function applyFilter({ inputData, comparator }) {
    const stabilizedThis = inputData.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    inputData = stabilizedThis.map((el) => el[0]);

    return inputData;
}

function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
}

function stringAvatar(name) {
    const mappedName = name.trim() || 'Unknown';
    const split = mappedName.split(' ');
    return {
        sx: {
            bgcolor: stringToColor(mappedName),
            color: '#fff',
        },
        // children: split.length > 1 ? `${split[0][0]}${split[1] [0]}` : `${split[0][0]}`,
        children: `${split[0][0]}`,
    };
}

export {
    applyFilter,
    stringToColor,
    stringAvatar,
};