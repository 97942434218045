import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { fCurrencyBy100, fCurrency } from '../../../../../../utils/formatNumber';
import TransactionDate from './TransactionDate';
import { useLocales } from '../../../../../../locales';
import { useSettingsContext } from '../../../../../../components/settings';
import { useLayoutSettingsContext } from '../../components/TransactionLayoutSettings';
import * as asidebarTypes from '../../components/TransactionLayoutTypes';
import DigitTypography from "../../../../../../components/typography/DigitTypography";

const bull = (
    <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
        •
    </Box>
);

const TransactionCard = (props) => {
    const {
        date,
        totalAmount,
        cumulativeBalance,
        primaryCategoryName,
        categoryName,
        payeeName,
        isTransfer,
        transferToAccount,
        transferFromAccount,
        pending,
        accountClassType,
        entryType,
        ...other
    } = props;
    const balance = totalAmount.asDouble;

    const theme = useTheme();
    const { t } = useLocales();
    const { isRTL } = useSettingsContext();
    const { onToggleAsidebar } = useLayoutSettingsContext();

    let dynamicFlexDirection = isRTL ? balance < 0 : balance >= 0;
    if (accountClassType === 'Liability') {
       dynamicFlexDirection = isRTL ? balance >= 0 : balance < 0;
    }

    let transferLabelNegative = t('transactions.transferTo');
    let transferAccountNegative = transferToAccount;
    let transferDirectionNegative = dynamicFlexDirection ? 'row' : 'row-reverse';
    let transferLabelPositive = t('transactions.transferFrom');
    let transferAccountPositive = transferFromAccount;
    let transferDirectionPositive = dynamicFlexDirection ? 'row-reverse' : 'row';

    if (accountClassType === 'Liability') {
        transferLabelNegative = t('transactions.transferFrom');
        transferAccountNegative = transferFromAccount;
        transferDirectionNegative = dynamicFlexDirection ? 'row-reverse' : 'row';
        transferLabelPositive = t('transactions.transferTo');
        transferAccountPositive = transferToAccount;
        transferDirectionPositive = dynamicFlexDirection ? 'row' : 'row-reverse';
    }

    const handleEdit = (e) => {
        e.preventDefault();
        if (isTransfer) {
            onToggleAsidebar(asidebarTypes.ASIDEBAR_EDIT_TRANSFER, {
                transactionId: other.correlationId,
                transactionDate: date,
                transactionAmount: `${Math.abs(totalAmount.asDouble)}`,
                transactionMemo: other.memo || '',
                transferTo: other.transferTo,
                transferFrom: other.transferFrom,
                transferFromAmount: `${Math.abs(other.fromAmount.asDouble)}`,
                transferFromExchangeRate: `${other.transferFromExchangeRate}`,
            });
        } else {
            let transactionCategoryType = 'Expense';
            if (entryType === 'LiabilityCredit') {
                transactionCategoryType = 'Income';
            }
            if (entryType === 'AssetCredit') {
                transactionCategoryType = 'Income';
            }
            onToggleAsidebar(asidebarTypes.ASIDEBAR_EDIT_TRANSACTION, {
                transactionId: other.correlationId,
                transactionDate: date,
                transactionAmount: `${Math.abs(totalAmount.asDouble)}`,
                transactionPayeeId: other.payeeId,
                transactionCategoryId: categoryName ? other.allocations[0].categoryId : '',
                transactionMemo: other.memo || '',
                transactionCategoryType,
                transactionAccountClassType: accountClassType,
            });
        }
    };
    return (
        <Card
            elevation={1}
            sx={{
                boxShadow: 'none',
                backgroundColor: pending ? theme.palette.warning.light : 'inherit',
                transition: 'background-color 1s',
            }}
        >
            <Box
                component="div"
                onClick={handleEdit}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: dynamicFlexDirection ? 'row' : 'row-reverse',
                    p: 1,
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: theme.palette.grey.A100,
                    },
                }}
            >
                <Box sx={{ flexGrow: 0, p: 1 }}>
                    <TransactionDate date={date} pending={pending} />
                </Box>
                <Box sx={{ flexGrow: 0, py: 1, px: 2 }}>
                    <Stack direction="column" spacing={{ xs: 0.5 }}>
                        <DigitTypography variant="h3">
                            {fCurrency(balance)}
                        </DigitTypography>
                        <DigitTypography variant="h5">
                            {fCurrencyBy100(cumulativeBalance)}
                        </DigitTypography>
                        {isTransfer ? (
                            <>
                                {balance < 0 && (
                                    <Stack direction="row" spacing={1} flexDirection={transferDirectionNegative}>
                                        <Typography variant="body2" color="textSecondary">
                                            {transferLabelNegative}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {transferAccountNegative}
                                        </Typography>
                                    </Stack>
                                )}
                                {balance >= 0 && (
                                    <Stack direction="row" spacing={1} flexDirection={transferDirectionPositive}>
                                        <Typography variant="body2" color="textSecondary">
                                            {transferLabelPositive}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {transferAccountPositive}
                                        </Typography>
                                    </Stack>
                                )}
                            </>
                        ) : (
                            <Stack direction={isRTL ? 'row-reverse' : 'row'} spacing={1}>
                                <Typography variant="body1" color="textSecondary" sx={{ fontStyle: 'italic' }}>
                                    {payeeName}
                                </Typography>
                                {bull}
                                <Typography variant="body1" color="textSecondary">
                                    {primaryCategoryName}
                                    {categoryName ? ' - ' : ''}
                                    {categoryName}
                                </Typography>
                            </Stack>
                        )}
                        {pending && (
                            <Typography variant="body2" color="textSecondary">
                                {t('transactions.pending')}
                            </Typography>
                        )}
                    </Stack>
                </Box>
            </Box>
        </Card>
    );
};
TransactionCard.propTypes = {
    accountClassType: PropTypes.string,
    date: PropTypes.string,
    totalAmount: PropTypes.object,
    cumulativeBalance: PropTypes.number,
    primaryCategoryName: PropTypes.string,
    categoryName: PropTypes.string,
    payeeName: PropTypes.string,
    isTransfer: PropTypes.bool,
    transferToAccount: PropTypes.string,
    transferFromAccount: PropTypes.string,
    pending: PropTypes.bool,
    entryType: PropTypes.string,
    order: PropTypes.number,
    // modified: PropTypes.string
};

export default TransactionCard;
