import PropTypes from 'prop-types';
import { Typography } from "@mui/material";

const DigitTypography = ({children, sx, ...props}) => (
    <Typography {...props} sx={{
        ...sx,
        fontFamily: 'DM Sans, sans-serif'
    }}>
        <span style={{ direction: 'ltr', display: 'inline-block' }}>
            {children}
        </span>
    </Typography>
)
DigitTypography.propTypes = {
    children: PropTypes.node,
    sx: PropTypes.object,
};

export default DigitTypography;