import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useWatch } from 'react-hook-form';
import Iconify from '../../../../components/iconify';
import { useLocales } from '../../../../locales';
import { useFormYup } from '../../../../components/hook-form/useFormYup';
import { SkeletonFormSwr } from '../../../../components/async-components';
import { TemplateStoreUrlBuilder } from '../../../../api/TemplateStoreApi';
import { useBoolean } from '../../../../hooks/useBoolean';

const DisplayItems = ({ list, emptyMessage }) => {
    const listItems = list || [];

    if (listItems.length === 0) {
        return (
            <Stack flexWrap direction="row" spacing={4}>
                <Typography variant="subtitle1">{emptyMessage}</Typography>
            </Stack>
        );
    }

    return (
        <Stack flexWrap direction="column" spacing={2}>
            {listItems.map((item, index) => (
                <Typography variant="h6" key={index}>
                    {item.name}
                </Typography>
            ))}
        </Stack>
    );
};
DisplayItems.propTypes = {
    list: PropTypes.array.isRequired,
    emptyMessage: PropTypes.string.isRequired,
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(15),
        border: '1px solid #dadde9',
    },
}));

const DisplayItemsAndChildrenRow = ({ name, subCategories }) => {
    const collapse = useBoolean();

    const renderSecondary = (
        <TableRow>
            <TableCell sx={{ p: 0, border: 'none' }} colSpan={3}>
                <Collapse in={collapse.value} timeout="auto" unmountOnExit sx={{ bgcolor: 'background.neutral' }}>
                    <Stack component={Paper} sx={{ m: 1.5 }}>
                        {subCategories.map((item, index) => (
                            <Stack
                                key={index}
                                direction="row"
                                alignItems="center"
                                sx={{
                                    p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
                                    '&:not(:last-of-type)': {
                                        borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}`,
                                    },
                                }}
                            >
                                <ListItemText
                                    primary={item.name}
                                    primaryTypographyProps={{
                                        typography: 'body2',
                                    }}
                                    secondaryTypographyProps={{
                                        component: 'span',
                                        color: 'text.disabled',
                                        mt: 0.5,
                                    }}
                                />
                                <Box sx={{ textAlign: 'right' }}>
                                    {item.descriptions && item.descriptions.length > 0 && (
                                        <LightTooltip
                                            sx={{
                                                backgroundColor: (theme) => theme.palette.common.white,
                                                color: 'rgba(0, 0, 0, 0.87)',
                                                boxShadow: (theme) => theme.shadows[1],
                                                fontSize: 16,
                                            }}
                                            title={
                                                <>
                                                    {item.descriptions &&
                                                        item.descriptions.map((desc, descIndex) => (
                                                            <Stack spacing={1}>
                                                                <Typography color="inherit" key={`desc_${descIndex}`}>
                                                                    {desc}
                                                                </Typography>
                                                                {descIndex === 0 && item.descriptions.length > 1 && <Typography>&nbsp;</Typography>}
                                                            </Stack>
                                                        ))}
                                                </>
                                            }
                                        >
                                            <Iconify icon="solar:info-square-bold" />
                                        </LightTooltip>
                                    )}
                                </Box>
                            </Stack>
                        ))}
                    </Stack>
                </Collapse>
            </TableCell>
        </TableRow>
    );

    return (
        <>
            <TableRow hover>
                <TableCell>
                    <Typography variant="h6">{name}</Typography>
                </TableCell>
                <TableCell align="center"> {subCategories.length} </TableCell>
                <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    {subCategories.length > 0 && (
                        <IconButton
                            color={collapse.value ? 'inherit' : 'default'}
                            onClick={collapse.onToggle}
                            sx={{
                                ...(collapse.value && {
                                    bgcolor: 'action.hover',
                                }),
                            }}
                        >
                            <Iconify icon="eva:arrow-ios-downward-fill" />
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>

            {renderSecondary}
        </>
    );
};
DisplayItemsAndChildrenRow.propTypes = {
    name: PropTypes.string,
    subCategories: PropTypes.array,
};

const DisplayItemsAndChildren = ({ list, emptyMessage }) => {
    const listItems = list || [];
    if (listItems.length === 0) {
        return (
            <Stack flexWrap direction="row" spacing={4}>
                <Typography variant="subtitle1">{emptyMessage}</Typography>
            </Stack>
        );
    }

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {listItems.map((item, index) => (
                        <DisplayItemsAndChildrenRow {...item} key={index} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
DisplayItemsAndChildren.propTypes = {
    list: PropTypes.array.isRequired,
    emptyMessage: PropTypes.string.isRequired,
};

const SubscriptionBookDetails = () => {
    const { translate } = useLocales();

    const { getValue, swrValidating } = useFormYup();
    const bookLanguage = getValue('planBookLanguage');
    const bookTemplateId = useWatch({
        name: 'planBookTemplate',
    });
    const swrKey = TemplateStoreUrlBuilder.getBookUrl(bookTemplateId, bookLanguage);

    const [currentTab, setCurrentTab] = useState('expenses');
    const handleChangeTab = useCallback((event, newValue) => {
        setCurrentTab(newValue);
    }, []);
    const TABS = [
        {
            value: 'expenses',
            label: translate('subscriptions.subscriptionBookDetails_expenses'),
            icon: <Iconify icon="solar:card-send-bold" width={24} />,
        },
        {
            value: 'incomes',
            label: translate('subscriptions.subscriptionBookDetails_incomes'),
            icon: <Iconify icon="solar:card-recive-bold" width={24} />,
        },
        {
            value: 'payees',
            label: translate('subscriptions.subscriptionBookDetails_payees'),
            icon: <Iconify icon="solar:buildings-bold" width={24} />,
        },
    ];

    return (
        <Stack spacing={4} direction="column">
            <SkeletonFormSwr
                dataKey="not_required"
                mode="single"
                swrKey={swrKey}
                swrOptions={{
                    revalidateIfStale: false,
                    revalidateOnFocus: false,
                    revalidateOnReconnect: false,
                }}
                onSwrValidating={swrValidating}
            >
                {({ payees, creditCategories, debitCategories }) => (
                    <>
                        <Alert severity="info">
                            <Typography variant="h5" gutterBottom sx={{ color: 'text.secondary' }}>
                                {translate('subscriptions.subscriptionBookDetails_title1')}
                            </Typography>
                        </Alert>

                        <Tabs
                            value={currentTab}
                            onChange={handleChangeTab}
                            sx={{
                                mb: { xs: 3, md: 5 },
                            }}
                        >
                            {TABS.map((tab) => (
                                <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
                            ))}
                        </Tabs>

                        {currentTab === 'expenses' && <DisplayItemsAndChildren list={debitCategories} emptyMessage={translate('subscriptions.subscriptionBookDetails_payees_none')} />}
                        {currentTab === 'incomes' && <DisplayItemsAndChildren list={creditCategories} emptyMessage={translate('subscriptions.subscriptionBookDetails_payees_none')} />}
                        {currentTab === 'payees' && <DisplayItems list={payees} emptyMessage={translate('subscriptions.subscriptionBookDetails_payees_none')} />}
                    </>
                )}
            </SkeletonFormSwr>
        </Stack>
    );
};

export default SubscriptionBookDetails;
