import {useLocation} from "react-router-dom";
import {Page} from "../../components/page";
import Overview from "../../views/subscription";
import {Breadcrumbs} from "../../components/custom-breadcrumbs";
import { useSubscriptionsContext } from "../../hooks/useSubscriptionsContext";

function Root() {
    const {pathname} = useLocation();
    const {subscriptionName} = useSubscriptionsContext();
    return (
        <Page title="Dashboard | Wyzr">
            <Breadcrumbs
                heading="breadcrumbs.dashboard"
                links={[
                    {
                        name: subscriptionName,
                    },
                    {
                        name: 'breadcrumbs.dashboard_link',
                        href: pathname,
                    },
                    { name: 'breadcrumbs.overview_link' },
                ]}
            />
            <Overview/>
        </Page>
    )
}

export default Root;