import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { PATH_SUBSCRIPTION } from '../../routes/paths';
import { useLocales } from '../../locales';

const LoginButton = () => {
    const { t } = useLocales();
    const navigate = useNavigate();

    return (
        <Button variant="outlined" sx={{ mr: 1 }} onClick={() => navigate(PATH_SUBSCRIPTION.root)}>
            {t('homepage.landingHero_Login')}
        </Button>
    );
};

export default LoginButton;