import PropTypes from 'prop-types';
// @mui
import {alpha, useTheme} from '@mui/material/styles';
import {Card, Stack, Typography} from '@mui/material';
// utils
import {bgGradient} from '../../../../../utils/cssStyles';
import {fNumber} from '../../../../../utils/formatNumber';
// components
import Iconify from '../../../../../components/iconify';

// ----------------------------------------------------------------------

AnalyticsSummary.propTypes = {
    sx: PropTypes.object,
    icon: PropTypes.string,
    color: PropTypes.string,
    title: PropTypes.string,
    total: PropTypes.number,
};

export default function AnalyticsSummary({title, total, icon, color = 'primary', sx, ...other}) {
    const theme = useTheme();
    return (
        <Card
            sx={{
                py: 8,
                px: 2,
                boxShadow: 0,
                display: 'flex',
                textAlign: 'center',
                color: theme.palette[color].darker,
                bgcolor: theme.palette[color].lighter,
                ...sx,
                height: 1
            }}
            {...other}
        >
            <Stack spacing={1} alignItems="center" sx={{width: '100%'}}>
                <Iconify
                    icon={icon}
                    sx={{
                        p: 2.5,
                        width: 92,
                        height: 92,
                        borderRadius: '50%',
                        color: theme.palette[color].dark,
                        ...bgGradient({
                            direction: '135deg',
                            startColor: `${alpha(theme.palette[color].dark, 0)} 0%`,
                            endColor: `${alpha(theme.palette[color].dark, 0.24)} 100%`,
                        }),
                    }}
                />
                <Typography variant="h1">{fNumber(total)}</Typography>
                <Typography variant="subtitle2" sx={{opacity: 0.64}}>
                    {title}
                </Typography>
            </Stack>
        </Card>
    );
}
