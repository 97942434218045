// routes
import {PATH_SUBSCRIPTION} from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>;
const icon2 = (name) => <Iconify icon={name} sx={{width: 1, height: 1}}/>

// eslint-disable-next-line no-unused-vars
const ICONS = {
    blog: icon('ic_blog'),
    cart: icon('ic_cart'),
    chat: icon('ic_chat'),
    mail: icon('ic_mail'),
    user: icon('ic_user'),
    file: icon('ic_file'),
    lock: icon('ic_lock'),
    label: icon('ic_label'),
    blank: icon('ic_blank'),
    kanban: icon('ic_kanban'),
    folder: icon('ic_folder'),
    banking: icon('ic_banking'),
    booking: icon('ic_booking'),
    invoice: icon('ic_invoice'),
    calendar: icon('ic_calendar'),
    disabled: icon('ic_disabled'),
    external: icon('ic_external'),
    menuItem: icon('ic_menu_item'),
    ecommerce: icon('ic_ecommerce'),
    analytics: icon('ic_analytics'),
    dashboard: icon2('ant-design:layout-outlined'),
};

const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
        subheader: 'Main',
        showSubheader: false,
        items: [
            {title: 'dashboard', path: PATH_SUBSCRIPTION.root, icon: ICONS.dashboard, languageCode: 'navigation.dashboard'},
            {title: 'accounts', path: PATH_SUBSCRIPTION.accounts.all, icon: ICONS.banking, languageCode: 'navigation.accounts'},
            {title: 'reports', path: PATH_SUBSCRIPTION.reports.all, icon: ICONS.analytics, languageCode: 'navigation.reports'},
            {
                title: 'bookkeeping',
                languageCode: 'navigation.bookkeeping',
                path: PATH_SUBSCRIPTION.bookkeeping.expenses,
                icon: ICONS.file,
                children: [
                    {title: 'bookkeeping_expenses', path: PATH_SUBSCRIPTION.bookkeeping.expenses, languageCode: 'navigation.bookkeeping_expenses'},
                    {title: 'bookkeeping_incomes', path: PATH_SUBSCRIPTION.bookkeeping.incomes, languageCode: 'navigation.bookkeeping_incomes'},
                    {title: 'bookkeeping_payees', path: PATH_SUBSCRIPTION.bookkeeping.payees, languageCode: 'navigation.bookkeeping_payees'},
                ]
            }
        ],
    },
];

export default navConfig;
