import * as types from './types';
import type { PayeesContextState } from "./types";

const reducer = (state: PayeesContextState, action) => {
    const {type, payload} = action;
    switch (type) {
        case types.INIT_DATA: {
            return {
                ...state,
                data: payload
            }
        }
        default:
            return {
                ...state
            }
    }
}

export default reducer;