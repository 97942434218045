// @mui
import {Stack, Box} from '@mui/material';
// config
import {NAV} from '../../../config';
// utils
import {hideScrollbarX} from '../../../utils/cssStyles';
// components
import Logo from '../../../components/logo';
import {NavSectionMini} from '../../../components/nav-section';
//
import navConfig from './config';
import NavToggleButton from './NavToggleButton';

// ----------------------------------------------------------------------

export default function NavMini() {
    return (
        <Box
            component="nav"
            sx={{
                flexShrink: {lg: 0},
                width: {lg: NAV.W_DASHBOARD_MINI},
            }}
        >
            <Stack
                sx={{
                    pb: 2,
                    height: 1,
                    position: 'fixed',
                    width: NAV.W_DASHBOARD_MINI,
                    borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
                    ...hideScrollbarX,
                }}
            >
                <Logo sx={{mx: 'auto', my: 2}}/>

                <NavSectionMini data={navConfig}/>
                <Box sx={{flexGrow: 1}}/>
                <Stack
                    alignItems="center"
                    sx={{
                        pt: 3,
                        pb: 2,
                        px: 2.5,
                        flexShrink: 0,
                    }}
                >
                    <NavToggleButton/>
                </Stack>

            </Stack>
        </Box>
    );
}
