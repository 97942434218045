import PropTypes from 'prop-types';
import AmountTypography from './AmountTypography';

const red = '#f44336';
const green = '#00c853';

const AccountingTypography = ({ amount, accountClassType, sx, ...props }) => {
    let color = 'inherited';

    if (accountClassType === 'Asset' && amount >= 0) {
        color = green;
    }
    if (accountClassType === 'Asset' && amount < 0) {
        color = red;
    }
    if (accountClassType === 'Liability' && amount >= 0) {
        color = red;
    }
    if (accountClassType === 'Liability' && amount < 0) {
        color = green;
    }

    return (
        <AmountTypography
            amount={Math.abs(amount)}
            useInt
            sx={{
                ...sx,
                color,
            }}
            {...props}
        />
    );
};

AccountingTypography.propTypes = {
    amount: PropTypes.number.isRequired,
    accountClassType: PropTypes.string.isRequired,
    sx: PropTypes.object,
};

export default AccountingTypography;
