import PropTypes from "prop-types";
import { createContext, useReducer, useMemo, useCallback, useEffect } from "react";
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import reducer from "./reducer";
import withPageSuspense from "../../components/async-pages/withPageSuspense";
import * as types from "./types";
import type { PayeesContextState } from "./types";

const initialState: PayeesContextState = {
    onToggleRefresh: () => {},
    onCreatePayee: () => {},
    onUpdatePayee: () => {},
    refresh: false,
    data: [],
    isMutating: false,
};
const PayeesContext = createContext();

const PayeesProvider = ({resource, children}) => {
    const { data, mutate } = useSWR(resource.readKey, resource.readAction, {
        suspense: true,
        revalidateOnFocus: false,
        // revalidateIfStale: false,
    });
    const { trigger: createTrigger, isMutating: isCreateMutating } = useSWRMutation(resource.writeKey, resource.writeAction, {
        suspense: true,
    });
    const { trigger: updateTrigger, isMutating: isUpdateMutating } = useSWRMutation(resource.updateKey, resource.updateAction, {
        suspense: true,
    });

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            const payees = data || [];
            dispatch({
                type: types.INIT_DATA,
                payload: payees
            })
        };
        initialize().then(() => {
            console.log("Payees loaded.");
        });
    }, [data]);

    const onToggleRefresh = useCallback(() => {
        mutate();
    }, [mutate]);
    const onCreatePayee = useCallback((payload) => {
        createTrigger(payload, {
            rollbackOnError: true,
        }).then(() => {
            // TODO do not pass data to this method as this changes the SWR data
            // read SWR documentation re the usage of mutate method
            mutate();
        });
    }, [createTrigger, mutate]);
    const onUpdatePayee = useCallback((payload) => {
        updateTrigger(payload, {
            rollbackOnError: true,
        }).then(() => {
            // TODO do not pass data to this method as this changes the SWR data
            // read SWR documentation re the usage of mutate method
            mutate();
        });
    }, [updateTrigger, mutate]);

    const values : PayeesContextState = useMemo(() => ({
        ...state,
        onToggleRefresh,
        onCreatePayee,
        onUpdatePayee,
        isMutating: isUpdateMutating || isCreateMutating,
    }), [
        state,
        onToggleRefresh,
        onCreatePayee,
        onUpdatePayee,
        isCreateMutating,
        isUpdateMutating
    ]);

    return (
        <PayeesContext.Provider value={values}>
            {children}
        </PayeesContext.Provider>
    )
};
PayeesProvider.propTypes = {
    children: PropTypes.node,
    resource: PropTypes.any,
};



export default withPageSuspense(PayeesProvider);
export {
    PayeesContext
};