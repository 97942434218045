import {configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import {createLogger} from 'redux-logger'
import {rootPersistConfig, rootReducer} from './reducer';

const middlewareOptions = {
    serializableCheck: false,
    immutableCheck: false
};

const store = configureStore({
    reducer: persistReducer(rootPersistConfig, rootReducer),
    devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true',
    middleware: (getDefaultMiddleware) => process.env.NODE_ENV === 'production'
        ? getDefaultMiddleware(middlewareOptions)
        : getDefaultMiddleware(middlewareOptions).concat(createLogger())
});

const persistor = persistStore(store);

export {store, persistor};