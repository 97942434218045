import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Iconify from '../../../../../components/iconify';
import { useLocales } from '../../../../../locales';

const CanReset = ({ canReset, dataCount, onResetFilters }) => {
    const { t } = useLocales();
    return (
        <>
            {canReset && (
                <Stack spacing={1.5}>
                    <Box sx={{ typography: 'body2' }}>
                        <strong>{dataCount}</strong>
                        <Box component="span" sx={{ color: 'text.secondary', ml: 0.25, mr: 0.25 }}>
                            {t('common.resultsFound')}
                        </Box>
                    </Box>

                    <Stack flexGrow={1} spacing={1} direction="row" flexWrap="wrap" alignItems="center">
                        {canReset && (
                            <Button color="error" onClick={onResetFilters} startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}>
                                {t('common.reset')}
                            </Button>
                        )}
                    </Stack>
                </Stack>
            )}
        </>
    );
};
CanReset.propTypes = {
    canReset: PropTypes.bool.isRequired,
    dataCount: PropTypes.number.isRequired,
    onResetFilters: PropTypes.func,
}

export default CanReset;