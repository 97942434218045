import PropTypes from 'prop-types';
import { useState } from 'react';
import { Checkbox, Divider, FormControlLabel, Stack, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import useCategoriesContext from '../../../../contexts/Categories/useCategoriesContext';
import { useLocales } from '../../../../locales';
import Iconify from '../../../../components/iconify';
import OptionStyle from '../Filter/styles';
import FilterSearch from './FilterSearch';
import { matchFilterOptions, crudeMatch } from './utils';
import type { FilterCategory } from '../ClassificationFilter/types';

type Props = {
    variant: string,
    selectedItems: Array<FilterCategory>,
    onFiltersSelected(key: string, items: [any]): void,
    onFiltersCleared(key: string): void,
};

const CategoriesFilter = ({ variant, selectedItems, onFiltersSelected, onFiltersCleared, ...props } : Props) => {
    const { t } = useLocales();
    const [searchValue, setSearchValue] = useState('');
    const [selections, setSelections] = useState(() => []);

    const { getVariantCategories } = useCategoriesContext();
    const mappedData = getVariantCategories(variant);
    mappedData.splice(0, 0, {
        label: t('common.no_category'),
        id: variant === 'income' ? 'system_no_category_credit' : 'system_no_category_debit',
        categories: [],
        type: variant,
    });

    const filteredData = matchFilterOptions(mappedData, { inputValue: searchValue }, ['label', 'categories.*.name']);
    const selectedIds = selectedItems.map(r => r.value);

    const handleOnChange = (value, primary, secondary) => {
        onFiltersSelected(variant, [{
            value,
            primary,
            secondary
        }]);
    };
    const handleOnSelectPrimary = (event, toggle) => {
        if (selections.includes(toggle)) {
            setSelections(() => selections.filter(r => r !== toggle));
        } else {
            setSelections([...selections, toggle]);
        }
        const mappedPrimaries = mappedData.map(item => ({
            value: item.id,
            primary: item.label,
            secondary: undefined
        }));
        onFiltersSelected(variant, mappedPrimaries);
    };
    const handleOnSelectSecondary = (event, toggle) => {
        if (selections.includes(toggle)) {
            setSelections(() => selections.filter(r => r !== toggle));
        } else {
            setSelections([...selections, toggle]);
        }
        const mappedSecondaries = mappedData.reduce((acc, primaryItem) => {
            const {categories} = primaryItem;
            if (categories && categories.length > 0) {
                const secondary = categories.map(item => ({
                    value: item.id,
                    primary: primaryItem.label,
                    secondary: item.name
                }));
                acc.push(...secondary);
            }
            return acc;
        }, []);
        onFiltersSelected(variant, mappedSecondaries);
    };

    return (
        <>
            <Stack direction="row" sx={{ my: 1 }} spacing={1} alignItems={{ xs: 'center' }}>
                <ToggleButton value="list" onChange={() => {
                    setSelections([]);
                    onFiltersCleared(variant)
                }}>
                    <Iconify icon="solar:close-square-linear" mr={1}/>
                    {t('common.clear_all')}
                </ToggleButton>
                <ToggleButton value="primary_all" onChange={handleOnSelectPrimary} selected={selections.includes("primary_all")} color="primary">
                    <Iconify icon="solar:check-square-linear" mr={1}/>
                    {t('transactions.toolbar.select_category_primary')}
                </ToggleButton>
                <ToggleButton value="secondary_all" onChange={handleOnSelectSecondary} selected={selections.includes("secondary_all")} color="primary">
                    <Iconify icon="solar:checklist-minimalistic-linear" mr={1}/>
                    {t('transactions.toolbar.select_category_secondary')}
                </ToggleButton>
            </Stack>

            <Stack sx={{ my: 1 }}>
                <FilterSearch searchValue={searchValue} setSearchValue={setSearchValue} />
            </Stack>

            <Divider />
            <Stack direction="column" spacing={0} alignItems="stretch">
                {filteredData.map((item, primaryIndex) => {
                    const { label, categories, id } = item;
                    return (
                        <OptionStyle
                            key={primaryIndex}
                            sx={{
                                display: 'block',
                                mb: 3,
                                // ...(selectedIds.includes(id) && {
                                //     boxShadow: (theme) => theme.customShadows.z8
                                // })
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedIds.includes(id)}
                                        color="primary"
                                        onChange={() => handleOnChange(id, label)}
                                        value={id}
                                    />
                                }
                                label={<Typography variant="subtitle1">{crudeMatch(label, searchValue)}</Typography>}
                            />

                            <Stack direction="row" spacing={0} flexWrap="wrap" justifyContent="flex-start" alignItems="baseline">
                                {categories && categories.map((category, secondaryIndex) => {
                                    const { id: categoryId, name } = category;
                                    return (
                                        <FormControlLabel
                                            key={secondaryIndex}
                                            control={
                                                <Checkbox
                                                    checked={selectedIds.includes(categoryId)}
                                                    color="primary"
                                                    onChange={() => handleOnChange(categoryId, label, name)}
                                                    value={categoryId}
                                                    sx={{ m: 0 }}
                                                />
                                            }
                                            label={<Typography variant="body1">{crudeMatch(name, searchValue)}</Typography>}
                                        />
                                    );
                                })}
                            </Stack>
                        </OptionStyle>
                    );
                })}
            </Stack>
        </>
    );
};

CategoriesFilter.propTypes = {
    variant: PropTypes.oneOf(['income', 'expense']).isRequired,
    selectedItems: PropTypes.array,

    // funcs
    onFiltersSelected: PropTypes.func,
    onFiltersCleared: PropTypes.func,
};

export default CategoriesFilter;