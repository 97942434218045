import parseISO from "date-fns/parseISO";
import differenceInMonths from "date-fns/differenceInMonths";
import differenceInDays from "date-fns/differenceInDays";

const magicKey = parseISO("1980-01-01");
const getPartitionKey = date => differenceInMonths(date, magicKey);
const getDayKey = date => differenceInDays(date, magicKey);

export {
    getPartitionKey,
    getDayKey
};