import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";
import { useLayoutSettingsContext } from "../components/TransactionLayoutSettings";
import FilterGroup from "../../../components/Filter/FilterGroup";
import DateRangeFilter from "../../../components/Filter/DateRangeFilter";
import CategoriesFilter from "../../../components/Filter/CategoriesFilter";
import PayeesFilter from "../../../components/Filter/PayeesFilter";

const RootStyle = styled(Card)(({theme, ownerState}) => ({
    boxShadow: 'none',
    color: theme.palette.primary.darker,
    backgroundColor: '#eef5fd',
    display: ownerState ? 'block' : 'none'
}));

const FilterView = () => {
    const {showFilter} = useLayoutSettingsContext();

    useEffect(() => {
        if (showFilter) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }, [showFilter]);

    return (
        <RootStyle ownerState={showFilter}>
            <FilterGroup>
                <DateRangeFilter/>
                <CategoriesFilter filterId="ExpenseFilter" label="Expense Categories" labelLanguageCode="filter.report_Expenses_MenuButton" variant="expense"/>
                <CategoriesFilter filterId="IncomeFilter" label="Income Categories" labelLanguageCode="filter.report_Incomes_MenuButton" variant="income"/>
                <PayeesFilter filterId="Payee" label="Payees" labelLanguageCode="filter.report_Payees_MenuButton" />
            </FilterGroup>
        </RootStyle>
    )
};
FilterView.propTypes = {};

export default FilterView;