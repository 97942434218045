import { useEffect } from "react";
import PropTypes from "prop-types";
import { useWatch } from "react-hook-form";
import { useLocales } from "../../../../../locales";
import { useAccountsContext } from "../../../../../hooks/useAccountsContext";
import { StyledNumeric, StyledTextField } from "../../../../../components/styled-inputs";
import { useFormYup } from "../../../../../components/hook-form/useFormYup";
import { currencies } from "../../../../../assets/data";
import { fCurrency } from "../../../../../utils/formatNumber";

const TransferCurrency = ({name}) => {
    const {translate, languageCode} = useLocales();
    const {setValue} = useFormYup();

    const currencyText = currencyCode => {
        if (currencyCode) {
            const langCurrencies = currencies[languageCode];
            return langCurrencies.find(r => r.value === currencyCode).name;
        }
        return '';
    }

    const {accounts: accountData} = useAccountsContext();

    const transferToFieldValue = useWatch({
        name: "transferTo"
    });
    const transferToCurrencyFieldValue = useWatch({
        name: "transferToCurrency"
    });
    const transferFromCurrencyFieldValue = useWatch({
        name: "transferFromCurrency"
    });
    const transferAmountFieldValue = useWatch({
        name: "transactionAmount"
    });
    const transferFromExchangeRateFieldValue = useWatch({
        name: "transferFromExchangeRate"
    });

    const calculateExchange = () => {
        if (transferAmountFieldValue && transferFromExchangeRateFieldValue) {
            return `${translate('management.transaction.transfer_exchange_help')} ${fCurrency(transferAmountFieldValue * transferFromExchangeRateFieldValue)}`;
        }
        return undefined;
    }

    useEffect(() => {
        if (transferToFieldValue.trim() === '') {
            setValue("transferToCurrency", '');
        }
        if (transferToFieldValue.trim() !== '') {
            const toCurrency = accountData.find(r => r.id === transferToFieldValue);
            if (toCurrency) {
                setValue("transferToCurrency", toCurrency.currency);
            }
        }
        // eslint-disable-next-line
    }, [transferToFieldValue]);

    return (
        <>
            {transferToFieldValue && transferToCurrencyFieldValue.toLowerCase() !== transferFromCurrencyFieldValue.toLowerCase() &&
                <StyledTextField
                    disabled
                    name={name}
                    header={translate('management.transaction.transfer_to_currency')}
                    label={currencyText(transferToCurrencyFieldValue)}
                    fullWidth
                />
            }
            {transferToFieldValue && transferFromCurrencyFieldValue.toLowerCase() !== transferToCurrencyFieldValue.toLowerCase() &&
                <StyledNumeric
                    name="transferFromExchangeRate"
                    header={translate('management.transaction.transfer_exchange_title')}
                    label={translate('management.transaction.transfer_exchange_label')}
                    required
                    allowNegative={false}
                    helperText={calculateExchange()}
                    decimalScale={10}
                />
            }
        </>
    )
};
TransferCurrency.propTypes = {
    name: PropTypes.string.isRequired,
};

export default TransferCurrency;