import PropTypes from 'prop-types';
import { Stack, Typography, Box } from '@mui/material';
import withComponentSwr from '../../../../../components/swr/withComponentSwr';
import { SubscriptionUrlBuilder } from '../../../../../api/SubscriptionsApi';
import { sumNetWorth, bankTypes, currencyTypes, totalNetWorth } from '../../../../../utils/withAccountMaths';
import { fNumberBy100 } from '../../../../../utils/formatNumber';
import NetWorthTotal from '../../Summary/NetWorth/NetWorthTotal';
import { useLocales } from '../../../../../locales';

const CurrencySummary = ({ netWorthByType, title, currency, netWorthByTypeCurrency }) => {
    if (netWorthByType === 0.0) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>
    }
    return (
        <Stack pb={3} alignItems="start">
            <Typography variant="h4" gutterBottom>
                {title}
            </Typography>
            <NetWorthTotal net={netWorthByType} showTitle={false} netTitleVariant="h5" sx={{ textAlign: 'left' }} />
            {currency &&
                <Stack direction="row" sx={{}} spacing={2}>
                    (
                    <NetWorthTotal net={netWorthByTypeCurrency} showTitle={false} netTitleVariant="h5" sx={{ textAlign: 'left' }} />
                    <Typography variant="h5" gutterBottom sx={{ opacity: 0.48 }}>
                        {currency}
                    </Typography>
                    )
                </Stack>

            }
        </Stack>
    );
};
CurrencySummary.propTypes = {
    netWorthByType: PropTypes.number,
    netWorthByTypeCurrency: PropTypes.number,
    title: PropTypes.string,
    currency: PropTypes.string,
};

const AccountsSummary = ({ data, defaultCurrency }) => {
    const { t } = useLocales();
    const netWorth = fNumberBy100(totalNetWorth(data));
    const types = bankTypes(data);
    const currencies = currencyTypes(data).filter((r) => r.toLowerCase() !== defaultCurrency.toLowerCase());

    return (
        <Stack direction="column">
            <Box pb={3}>
                <Typography variant="h3" gutterBottom>
                    {t('widgets.networth_title')}
                </Typography>
                <NetWorthTotal net={netWorth} showTitle={false} netTitleVariant="h4" sx={{ textAlign: 'left' }} />
            </Box>

            {types.map((item, index) => {
                const accounts = data.filter((r) => r.accountType.toLowerCase() === item.toLowerCase() && r.currency === defaultCurrency);
                const netWorthByType = fNumberBy100(sumNetWorth(accounts));
                return <CurrencySummary
                    netWorthByType={netWorthByType}
                    title={t(`enums.accountTypes.${item.toLowerCase()}`)}
                    key={`def_summ_${index}`}
                    currency={undefined}
                />;
            })}

            {currencies.map((currencyItem, currencyIndex) => (
                <div key={currencyIndex}>
                    {types.map((item, index) => {
                        const accounts = data.filter((r) => r.accountType.toLowerCase() === item.toLowerCase() && r.currency === currencyItem);
                        const netWorthByType = fNumberBy100(sumNetWorth(accounts));
                        const netWorthByTypeCurrency = fNumberBy100(sumNetWorth(accounts, true));

                        const title = `${t(`enums.accountTypes.${item.toLowerCase()}`)} ${currencyItem}`;
                        return <CurrencySummary
                            netWorthByType={netWorthByType}
                            title={title}
                            key={`curr_summ_${currencyIndex}_${index}`}
                            currency={currencyItem}
                            netWorthByTypeCurrency={netWorthByTypeCurrency}
                        />;
                    })}
                </div>
            ))}

            {/*
            {currencies
                .filter((r) => r !== defaultCurrency)
                .map((item, index) => {
                    const accounts = data.filter((r) => r.currency === item);
                    const netWorthByCurrency = fNumberBy100(sumNetWorth(accounts));
                    const netWorthInCurrency = fNumberBy100(sumNetWorth(accounts, true));
                    return (
                        <Stack key={index} pb={3} alignItems="start">
                            <Typography variant="h4" gutterBottom>
                                {t(`currencies.${item}`)}
                            </Typography>

                            <NetWorthTotal net={netWorthByCurrency} showTitle={false} netTitleVariant="h5" />
                            <Typography variant="subtitle1" gutterBottom color="textSecondary">
                                {t('accounts.balanceInCurrency')}
                            </Typography>
                            <NetWorthTotal net={netWorthInCurrency} showTitle={false} netTitleVariant="body1"  />
                        </Stack>
                    );
                })}
            */}
        </Stack>
    );
};

AccountsSummary.propTypes = {
    data: PropTypes.array,
    defaultCurrency: PropTypes.string,
};

export default withComponentSwr(AccountsSummary, SubscriptionUrlBuilder.getSubscriptionAccounts);
