import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo } from 'react';
import useSWRMutation from 'swr/mutation';
import withPageSuspense from '../../../../../components/async-pages/withPageSuspense';
import type { SelectedFilters } from '../../../components/ClassificationFilter/types';
import { TransactionsReport } from '../../../../../api/TransactionReportsApi';
import type { DateRange } from '../../../../../api/FilterApi';

const ReportDataContext = createContext();

export type ReportDataContextState = {
    data: TransactionsReport,
    isMutating: boolean,
    onFilterReport(filters: SelectedFilters): void,
    selectedDate: DateRange,
};

const ReportDataProvider = ({ resource, body, query, selectedDate, children }) => {
    const { trigger, data, isMutating } = useSWRMutation(resource.readKey, resource.readAction);

    useEffect(() => {
        trigger({
            body,
            query,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, trigger]);

    const onFilterReport = useCallback(
        (filters: SelectedFilters) => {
            trigger({
                body: filters,
                query,
            });
        },
        [query, trigger]
    );

    const values: ReportDataContextState = useMemo(
        () => ({
            data,
            isMutating: data === undefined || isMutating,
            selectedDate,
            onFilterReport,
        }),
        [data, isMutating, selectedDate, onFilterReport]
    );

    if (isMutating) {
        return <>Loading...</>;
    }

    return <ReportDataContext.Provider value={values}>{children}</ReportDataContext.Provider>;
};
ReportDataProvider.propTypes = {
    children: PropTypes.node,
    resource: PropTypes.any.isRequired,
    body: PropTypes.any,
    query: PropTypes.string,
    selectedDate: PropTypes.object,
};

export default withPageSuspense(ReportDataProvider);
export { ReportDataContext };