import {useSelector} from "react-redux";
import {useSubscriptionsContext} from "../../../../../hooks/useSubscriptionsContext";
import {useAuthContext} from "../../../../../hooks/useAuthContext";
import {subscriptionSelector} from "../../../selectors";


const withWidgetPrefetch = (WrappedComponent) => {
    function HOC(props) {
        const {userId} = useAuthContext();
        const {defaultSubscriptionId} = useSubscriptionsContext();
        const subscription = useSelector(state => subscriptionSelector(state, defaultSubscriptionId));
        const reportProps = {
            userId,
            subscriptionId: defaultSubscriptionId,
            currency: subscription.currency
        }
        return (
            <WrappedComponent {...props} {...reportProps}/>
        )
    }
    return HOC;
};

export default withWidgetPrefetch;