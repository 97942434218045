import PropTypes from 'prop-types';
import { createContext, useReducer, useMemo, useCallback, useEffect } from 'react';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import reducer from './reducer';
import withPageSuspense from '../../components/async-pages/withPageSuspense';
import * as types from './types';
import type { CategoriesContextState, PrimaryCategory } from './types';
import { mapData, reduceIdsAndPrimaryName, reduceSubCategories } from './utils';
import type { Category } from '../../api/CategoriesApi';

const initialState: CategoriesContextState = {
    onToggleRefresh: () => {},
    onCreateCategory: () => {},
    onUpdateCategory: () => {},
    getVariantCategories: () => {},
    getVariantCategoriesByLevels: () => {},
    getCategoriesForTable: () => {},
    refresh: false,
    data: [],
    isMutating: false,
};
const CategoriesContext = createContext();

const CategoriesProvider = ({ resource, children }) => {
    const { data, mutate } = useSWR(resource.readKey, resource.readAction, {
        suspense: true,
        revalidateOnFocus: false,
        // revalidateIfStale: false,
    });
    const { trigger: createTrigger, isMutating: isCreateMutating } = useSWRMutation(resource.writeKey, resource.writeAction, {
        suspense: true,
    });
    const { trigger: updateTrigger, isMutating: isUpdateMutating } = useSWRMutation(resource.updateKey, resource.updateAction, {
        suspense: true,
    });

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            const categories = data || [];
            dispatch({
                type: types.INIT_DATA,
                payload: categories,
            });
        };
        initialize().then(() => {
            console.log('Categries loaded.');
        });
    }, [data]);

    const onToggleRefresh = useCallback(() => {
        mutate();
    }, [mutate]);
    const onCreateCategory = useCallback(
        (payload) => {
            createTrigger(payload, {
                rollbackOnError: true,
            }).then(() => {
                // TODO do not pass data to this method as this changes the SWR data
                // read SWR documentation re the usage of mutate method
                mutate();
            });
        },
        [createTrigger, mutate]
    );
    const onUpdateCategory = useCallback(
        (payload) => {
            updateTrigger(payload, {
                rollbackOnError: true,
            }).then(() => {
                // TODO do not pass data to this method as this changes the SWR data
                // read SWR documentation re the usage of mutate method
                mutate();
            });
        },
        [updateTrigger, mutate]
    );

    const getVariantCategories: Array<PrimaryCategory> = useCallback(
        (variant: string) => {
            const mappedData = mapData(state.data);
            return variant.toLowerCase() === 'income' ? mappedData.filter((r) => r.type.toLowerCase() === 'income') : mappedData.filter((r) => r.type.toLowerCase() === 'expense');
        },
        [state.data]
    );
    const getCategoryIdsByType = useCallback(
        (categoryType: string, variant: string) => {
            switch (categoryType) {
                case 'Primary':
                    return state.data
                        .filter((r) => r.isPrimary && r.type.toLowerCase() === variant)
                        .reduce((acc, item) => {
                            acc.push(item.id);
                            return acc;
                        }, []);
                case 'Secondary':
                    return state.data
                        .filter((r) => r.isPrimary === false && r.type.toLowerCase() === variant)
                        .reduce((acc, item) => {
                            acc.push(item.id);
                            return acc;
                        }, []);
                default:
                    return [];
            }
        },
        [state.data]
    );

    // Used by CategorySelect drop down
    const getVariantCategoriesByLevels = useCallback(
        (variant) => {
            const variantCategories = getVariantCategories(variant || '');
            return variantCategories.reduce((acc: Array<Category>, item) => {
                const { categories, label, id, costImportance, costType } = item;
                acc.push({
                    id,
                    name: label,
                    primary: true,
                    costImportance,
                    costType,
                });
                if (categories && categories.length > 0) {
                    const subCategories = reduceIdsAndPrimaryName(label, categories);
                    Array.prototype.push.apply(acc, subCategories);
                }
                return acc;
            }, []);
        },
        [getVariantCategories]
    );

    // Used by bookkeeping table
    const getCategoriesForTable = useCallback(
        (variant) => {
            const variantCategories = getVariantCategories(variant || '');
            return variantCategories.reduce((acc: Array<Category>, item) => {
                const { categories, label, id, costImportance, costType } = item;
                const subCategoryIds = categories.reduce((categoryIds, category) => {
                    categoryIds.push(category.id);
                    return categoryIds;
                }, []);
                acc.push({
                    id,
                    name: label,
                    primary: true,
                    costImportance,
                    costType,
                    categoryIds: subCategoryIds,
                    count: subCategoryIds.length,
                });
                if (categories && categories.length > 0) {
                    const subCategories = reduceSubCategories(label, categories);
                    Array.prototype.push.apply(acc, subCategories);
                }
                return acc;
            }, []);
        },
        [getVariantCategories]
    );

    const values: CategoriesContextState = useMemo(
        () => ({
            ...state,
            onToggleRefresh,
            getVariantCategories,
            getCategoryIdsByType,
            getVariantCategoriesByLevels,
            onCreateCategory,
            onUpdateCategory,
            isMutating: isUpdateMutating || isCreateMutating,
            getCategoriesForTable,
        }),
        [
            state,
            onToggleRefresh,
            onCreateCategory,
            onUpdateCategory,
            getVariantCategories,
            getCategoryIdsByType,
            getVariantCategoriesByLevels,
            isCreateMutating,
            isUpdateMutating,
            getCategoriesForTable
        ]
    );

    return <CategoriesContext.Provider value={values}>{children}</CategoriesContext.Provider>;
};
CategoriesProvider.propTypes = {
    children: PropTypes.node,
    resource: PropTypes.any,
};


export default withPageSuspense(CategoriesProvider);
export {
    CategoriesContext,
};