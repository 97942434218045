import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { Icon } from '@iconify/react';
import checkmarkCircle2Fill from '@iconify/icons-ant-design/check-circle-fill';
import { Box, Grid, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import * as utils from './utils';
import MegaMenu from './MegaMenu';
import OptionStyle from './styles';
import useFilterContext from './useFilterContext';
import useDateRangeContext from '../../../../contexts/DateRange/useDateRangeContext';
import { useTransactionDatesContext } from '../../../../contexts/TransactionsDate';
import { useLocales } from '../../../../locales';
import DateRangeTypography from '../../../../components/typography/DateRangeTypography';

const DateRangeFilter = ({ filterId, ...props }) => {
    const { t } = useLocales();
    const { reportDates: dates } = useDateRangeContext();
    const { selectedDateId, updateDate } = useTransactionDatesContext();
    const { selectedFilterViewId, chips, handleUpdateChip } = useFilterContext();
    const open = filterId === selectedFilterViewId;

    const filterName = useCallback(
        (date) => {
            const { languageCode, name } = date;
            return t(languageCode, name);
        },
        [t]
    );

    const buildChipItem = useCallback(
        (value) => {
            const key = utils.buildFilterKey(filterId, value);
            const date = dates.filter((r) => r.id === value);
            const chip = {
                key,
                value: filterName(date[0]),
                isSticky: true,
            };
            return chip;
        },
        [dates, filterId, filterName]
    );
    const handleOnChange = (event) => {
        const { value } = event.target;
        const chip = buildChipItem(value);
        updateDate(value);
        handleUpdateChip(chip);
    };

    useEffect(() => {
        if (chips.findIndex((r) => r.key.startsWith(filterId)) === -1) {
            const chip = buildChipItem(selectedDateId);
            handleUpdateChip(chip);
        }
    }, [buildChipItem, chips, filterId, handleUpdateChip, selectedDateId]);

    useEffect(() => {
        const key = utils.buildFilterKey(filterId, selectedDateId);
        if (chips.findIndex((r) => r.key.endsWith(key)) === -1) {
            const chip = buildChipItem(selectedDateId);
            handleUpdateChip(chip);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDateId]);

    return (
        <>
            {open && (
                <MegaMenu id={filterId} open={open}>
                    <RadioGroup name="dates" value={selectedDateId} onChange={handleOnChange}>
                        <Grid container spacing={2}>
                            {dates
                                .filter((r) => r.id !== 'REPORT_CUSTOM_DATES')
                                .map((date, index) => {
                                    const { id } = date;
                                    return (
                                        <Grid key={index} item xs={12} md={6} lg={3}>
                                            <OptionStyle
                                                sx={{
                                                    ...(selectedDateId === id && {
                                                        boxShadow: (theme) => theme.customShadows.z8,
                                                    }),
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={id}
                                                    control={<Radio checkedIcon={<Icon icon={checkmarkCircle2Fill} />} sx={{ fontSize: '1.5rem' }} />}
                                                    label={
                                                        <Box sx={{ ml: 1 }}>
                                                            <Typography variant="subtitle2">{filterName(date)}</Typography>
                                                            <DateRangeTypography date={date} variant="body2" sx={{ color: 'text.secondary' }}/>
                                                        </Box>
                                                    }
                                                    sx={{ py: 2, mr: 0 }}
                                                />
                                            </OptionStyle>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </RadioGroup>
                </MegaMenu>
            )}
        </>
    );
};
DateRangeFilter.propTypes = {
    label: PropTypes.string,
    labelLanguageCode: PropTypes.string,
    filterId: PropTypes.string,
};
DateRangeFilter.defaultProps = {
    label: 'Dates',
    labelLanguageCode: 'common.dates',
    filterId: 'DateRangeFilterV2',
};

export default DateRangeFilter;