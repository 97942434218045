import PropTypes from "prop-types";
import { Box, Chip } from '@mui/material';

const ListFilterChip = ({chips, handleChipDelete}) => (
    <Box
        sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            p: 2
        }}>
        {chips.map(({key, value, label, isSticky}, index) => (
            <Chip
                key={index}
                label={label}
                onDelete={() => handleChipDelete(key, value)}
                sx={{m: 1}}
                variant="outlined"
                disabled={isSticky}
            />
        ))}
    </Box>
);
ListFilterChip.propTypes = {
    chips: PropTypes.array.isRequired,
    handleChipDelete: PropTypes.func.isRequired
}
export default ListFilterChip;