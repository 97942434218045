import PropTypes from 'prop-types';
import ReportDataProvider from '../components/ReportDataProvider';
import { useAuthContext } from '../../../../../hooks/useAuthContext';
import { useSubscriptionsContext } from '../../../../../hooks/useSubscriptionsContext';
import useDateRangeContext from '../../../../../contexts/DateRange/useDateRangeContext';
import Api, { TransactionReportsUrlBuilder } from '../../../../../api/TransactionReportsApi';
import { useClassificationFilterContext } from '../../../components/ClassificationFilter';
import type { DateRange } from '../../../../../api/FilterApi';

const createSwrResourceSpending = (userId, subscriptionId) => ({
    readKey: TransactionReportsUrlBuilder.getReportExpenses(userId, subscriptionId),
    readAction: Api.getReportExpenses,
});
const createSwrResourceIncome = (userId, subscriptionId) => ({
    readKey: TransactionReportsUrlBuilder.getReportIncomes(userId, subscriptionId),
    readAction: Api.getReportIncomes,
});

const ReportDataProviderHoc = ({ children, reportType }) => {
    const { userId } = useAuthContext();
    const { defaultSubscriptionId, defaultCurrency } = useSubscriptionsContext();
    const { reportDates: dates } = useDateRangeContext();
    const { selectedDates, selectedClassificationType, getFilters } = useClassificationFilterContext();
    const selectedDate: DateRange = dates.find((r) => r.id === selectedDates);
    const query = `?basecurrency=${defaultCurrency}&classificationType=${selectedClassificationType}&startDate=${selectedDate.from.toISOString()}&endDate=${selectedDate.to.toISOString()}`;
    const body = getFilters();

    let resource = null;
    switch (reportType) {
        case 'Spending':
            resource = createSwrResourceSpending(userId, defaultSubscriptionId, body, query);
            break;
        case 'Income':
            resource = createSwrResourceIncome(userId, defaultSubscriptionId, body, query);
            break;
        default:
            throw new Error('What are you trying to do?');
    }

    return (
        <ReportDataProvider resource={resource} body={body} query={query} selectedDate={selectedDate}>
            {children}
        </ReportDataProvider>
    );
};
ReportDataProviderHoc.propTypes = {
    children: PropTypes.node,
    reportType: PropTypes.oneOf(['Spending', 'Income']).isRequired,
};

export default ReportDataProviderHoc;