export const reportBreakdowns = {
    en: [
        { value: 'BY_CAT', name: 'By category' },
        { value: 'BY_PAY', name: 'By payee' },
        { value: 'BY_TAG', name: 'By tag', disabled: true },
        { value: 'BY_NON', name: 'By none', disabled: true },
    ],
    ar: [
        { value: 'BY_CAT', name: 'بالفئة' },
        { value: 'BY_PAY', name: 'بالمستفيد' },
        { value: 'BY_TAG', name: 'بالعلامة', disabled: true },
        { value: 'BY_NON', name: 'بدون تصنيف', disabled: true },
    ],
};

export const reportChartViews = {
    en: [
        { value: 'by_total', name: 'Total' },
        { value: 'by_time', name: 'Over time' },
    ],
    ar: [
        { value: 'by_total', name: 'الرسم البياني بالمجموع' },
        { value: 'by_time', name: 'الرسم البياني بمرور الوقت' },
    ],
};

export const reportChartTypesByTotal = {
    en: [
        { value: 'by_total_pie', name: 'Pie' },
        { value: 'by_total_bar', name: 'Bar' },
    ],
    ar: [
        { value: 'by_total_pie', name: 'فطيرة' },
        { value: 'by_total_bar', name: 'شريط' },
    ],
};

export const reportChartTypesByTime = {
    en: [
        { value: 'by_time_column', name: 'Column' },
        { value: 'by_time_stacked', name: 'Column Stacked' },
    ],
    ar: [
        { value: 'by_time_column', name: 'عمود' },
        { value: 'by_time_stacked', name: 'مرصوصة' },
    ],
};

