import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Box, Typography, FormControlLabel, Checkbox, Stack, Button } from '@mui/material';
import Iconify from "../../../../components/iconify";
import MegaMenu from "./MegaMenu";
import OptionStyle from "./styles";
import * as utils from './utils';
import useFilterContext from "./useFilterContext";
import { useLocales } from "../../../../locales";
import usePayeesContext from "../../../../contexts/Payees/usePayeesContext";
import { useTransactionsPayeeContext } from "../../../../contexts/TransactionsPayee";

const PayeesFilter = ({filterId, ...props}) => {
    const {t} = useLocales();
    const {selectedIds, handlePayeeChange, handlePayeeRemove, onClearAll} = useTransactionsPayeeContext();
    const {data} = usePayeesContext();
    const {selectedFilterViewId, handleAppendChip, chipsToggle, onChipClearAll} = useFilterContext();
    const open = filterId === selectedFilterViewId;

    const handleOnChange = event => {
        const {value} = event.target;
        handlePayeeChange(value);

        const key = utils.buildFilterKey(filterId, value);
        handleAppendChip({
            key,
            value: value === 'system_no_payee' ? t('common.no_payee') : data.find(r => r.id === value).name,
            isSticky: false
        });
    }

    useEffect(() => {
        if (chipsToggle && chipsToggle.startsWith(filterId)) {
            const key = chipsToggle.replace(`${filterId}_`, '');
            handlePayeeRemove(key);
        }
    }, [chipsToggle, filterId, handlePayeeRemove]);

    const mappedData = [
        {
            name: t('common.no_payee'),
            id: 'system_no_payee',
        },
        ...data
    ];

    return (
        <>
            {open &&
                <MegaMenu id={filterId} open={open}>
                    <Stack direction="row" sx={{mb: 1}} spacing={1}>
                        <Button variant="soft" startIcon={<Iconify icon="tabler:square-off"/>} onClick={() => {
                            onClearAll();
                            onChipClearAll(filterId);
                        }}>
                            {t('common.clear_all')}
                        </Button>
                    </Stack>
                    <Stack direction="row" spacing={0} flexWrap="wrap" justifyContent="flex-start" alignItems="baseline">
                        {mappedData.map((payee, index) => {
                            const {id, name} = payee;
                            return (
                                <OptionStyle
                                    key={index}
                                    sx={{
                                        display: 'block',
                                        // ...(selectedIds.includes(id) && {
                                        //     boxShadow: (theme) => theme.customShadows.z8
                                        // })
                                    }}>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                checked={selectedIds.includes(id)}
                                                color="primary"
                                                onChange={handleOnChange}
                                                value={id}
                                            />
                                        )}
                                        label={
                                            <Box sx={{ml: 1}}>
                                                <Typography variant="subtitle2">{name}</Typography>
                                            </Box>
                                        }
                                        sx={{py: 2, mr: 0}}
                                    />
                                </OptionStyle>
                            )
                        })}
                    </Stack>
                </MegaMenu>
            }
        </>
    )
};
PayeesFilter.propTypes = {
    label: PropTypes.string,
    labelLanguageCode: PropTypes.string,
    filterId: PropTypes.string.isRequired
};

export default PayeesFilter;