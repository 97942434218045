import Grid from '@mui/material/Unstable_Grid2';
import {withSummaryWidget} from "../components";
import NetWorthTrend from "./NetWorthTrend";
import NetWorth from "./NetWorth";

const NetWorthWidget = () => (
    <Grid container sx={{flexGrow: 1, p: 0}}>
        <Grid xs={12} md={6}>
            <NetWorth/>
        </Grid>
        <Grid xs={12} md={6}>
            <NetWorthTrend/>
        </Grid>
    </Grid>
)

export default withSummaryWidget(
    NetWorthWidget,
    'networth_title',
    'networth_subtitle');