import PropTypes from 'prop-types';
import {withAuthenticationRequired} from '@auth0/auth0-react';
import {useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import LoadingScreen from '../components/loading-screen';
import {useAuthContext} from "../hooks/useAuthContext";

const AuthGuard = ({children}) => {
    const {pathname} = useLocation();
    const {userId} = useAuthContext();
    const [requestedLocation, setRequestedLocation] = useState(null);

    if (!userId) {
        return <LoadingScreen/>
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation}/>;
    }
    return <>{children}</>;
}

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default withAuthenticationRequired(AuthGuard, {
    onRedirecting: () => <LoadingScreen/>,
});
