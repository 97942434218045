import Grid from '@mui/material/Unstable_Grid2';
import {withSummaryWidget} from "../components";
import NewWatchlist from "./NewWatchlist";

const SpendingWatchlistWidget = () => (
    <Grid container sx={{height: '100%'}}>
        <Grid xs={12} md={6}>
            <NewWatchlist/>
        </Grid>
    </Grid>
)

export default withSummaryWidget(
    SpendingWatchlistWidget,
    'spending_watchlist_title',
    'spending_watchlist_subtitle');