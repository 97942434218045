import ToolbarItem from './ToolbarItem';
import useDateRangeContext from '../../../../../contexts/DateRange/useDateRangeContext';
import { useTransactionDatesContext } from '../../../../../contexts/TransactionsDate';
import { useLocales } from '../../../../../locales';

const DatesSwitcher = () => {
    const { t } = useLocales();
    const { reportDates: dates } = useDateRangeContext();
    const { selectedDateId, updateDate } = useTransactionDatesContext();

    const otherDates = dates.filter((r) => r.id !== selectedDateId);
    const toolbarItems = otherDates.map((item) => ({
        languageCode: t(item.languageCode),
        action: () => {
            updateDate(item.id);
        },
    }));
    return <ToolbarItem icon="tabler:calendar-search" items={toolbarItems} />;
};

export default DatesSwitcher;