import type { Payee } from "../../api/PayeesApi";

export type PayeesContextState = {
    onToggleRefresh: () => void,
    onUpdatePayee: () => void,
    onCreatePayee: () => void,
    refresh: boolean,
    data: Array<Payee>,
    isMutating: boolean,
}

export const INIT_DATA = 'INIT_DATA';
export const REFRESH = 'REFRESH';
export const UPDATE_SELECTED_PAYEES = 'UPDATE_SELECTED_PAYEES';