import PropTypes from 'prop-types';
import { reverse } from 'lodash';
import { useTheme } from '@mui/material/styles';
import Chart, { useChart } from '../../../../../components/chart';
import { fDate2 } from '../../../../../utils/formatTime';
import { useLocales } from '../../../../../locales';
import { fNumber } from '../../../../../utils/formatNumber';
import { useSettingsContext } from '../../../../../components/settings';
import useResponsive from '../../../../../hooks/useResponsive';

const AccountBalancesChart = ({ data }) => {
    const { isRTL } = useSettingsContext();
    const { languageCode } = useLocales();
    const theme = useTheme();
    const isDesktop = useResponsive('up', 'md');

    const chartDataItems = !isRTL ? data.map((item) => item.amount.asDouble) : reverse(data.map((item) => item.amount.asDouble));
    const categories = !isRTL
        ? data.map((item) => fDate2(item.period, 'MMM yy', languageCode))
        : reverse(data.map((item) => fDate2(item.period, 'MMM yy', languageCode)));

    const chartData = [
        {
            name: '',
            data: chartDataItems,
        },
    ];
    const chartOptions = useChart({
        // colors: [theme.palette.info.main],
        plotOptions: {
            bar: {
                colors: {
                    ranges: [
                        {
                            from: -999999999999999,
                            to: 0,
                            color: theme.palette.warning.main,
                        },
                        {
                            from: 0,
                            to: 999999999999999,
                            color: theme.palette.info.main,
                        },

                    ],
                },
                columnWidth: '60%',
                borderRadius: 4,
            },
        },
        xaxis: {
            categories,
        },
        tooltip: {
            enabled: true,
            marker: { show: false },
            style: {
                fontSize: isDesktop ? '16px' : '12px',
            },
            y: {
                title: {
                    formatter: () => '',
                },
                formatter: (value) => `${fNumber(value)}`,
            },
            theme: 'dark',
        },
        yaxis: {
            opposite: isRTL,
            labels: {
                show: false,
                // align: isRTL ? 'center' : 'right',
                // formatter: value => fNumber(value)
            },
        },
        grid: {
            show: false,
        },
        stroke: {
            width: 0,
        },
        // fill: {gradient: {opacityFrom: 0.56, opacityTo: 0.76}},
    });

    return <Chart type="bar" series={chartData} options={chartOptions} height={280} />;
};
AccountBalancesChart.propTypes = {
    data: PropTypes.array.isRequired,
};

export default AccountBalancesChart;
