import PropTypes from 'prop-types';
import { useRef } from 'react';
import * as Yup from 'yup';
import { Stack } from '@mui/material';
import { parseISO } from 'date-fns';
import { useLocales } from '../../../../../../locales';
import { FormYupProvider } from '../../../../../../components/hook-form';
import { StyledAutoComplete, StyledPicker, StyledTextField } from '../../../../../../components/styled-inputs';
import usePayeesContext from '../../../../../../contexts/Payees/usePayeesContext';
import CategoryTypeToggle from '../../components/CategoryTypeToggle';
import CategorySelect from '../../components/CategorySelect';
import TransactionAmount from '../../components/TransactionAmount';
import { useLayoutSettingsContext } from '../../components/TransactionLayoutSettings';
import TransactionFormSubmit from '../../components/TransactionFormSubmit';
import useTransactionsContext from '../../../../../../contexts/Transactions/useTransactionsContext';
import * as asidebarTypes from '../../components/TransactionLayoutTypes';

const CATEGORY_TYPE_OPTION = ['Expense', 'Income'];

const TransactionForm = ({ transaction }) => {
    const { translate } = useLocales();
    const { data: payeeData } = usePayeesContext();
    const { onToggleAsidebar } = useLayoutSettingsContext();
    const { onCreateTransaction, onUpdateTransaction } = useTransactionsContext();

    const defaultDate = useRef(new Date());
    const defaultValues = {
        transactionId: transaction.transactionId || '',
        transactionDate: parseISO(transaction.transactionDate || defaultDate.current.toISOString()),
        transactionAmount: transaction.transactionAmount || '',
        transactionPayeeId: transaction.transactionPayeeId || '',
        transactionCategoryId: transaction.transactionCategoryId || '',
        transactionMemo: transaction.transactionMemo || '',
        transactionCategoryType: transaction.transactionCategoryType || CATEGORY_TYPE_OPTION[0],
        transactionAccountClassType: transaction.transactionAccountClassType
    };
    const Schema = Yup.object().shape({
        transactionId: Yup.string(),
        transactionDate: Yup.date().required(translate('management.transaction.date_validation')),
        transactionAmount: Yup.string().required(translate('management.transaction.amount_validation')),
        transactionPayeeId: Yup.string(),
        transactionCategoryId: Yup.string(),
        transactionMemo: Yup.string(),
        transactionCategoryType: Yup.string(),
        transactionAccountClassType: Yup.string(),
    });

    const payeeOptions = payeeData.map((item) => ({
        id: item.id,
        label: item.name,
    }));

    const handleSubmit = async (data) => {
        const {
            transactionDate,
            transactionAmount,
            transactionPayeeId,
            transactionCategoryId,
            transactionMemo,
            transactionCategoryType,
            transactionAccountClassType,
        } = data;
        if (transaction.transactionId !== undefined) {
            await onUpdateTransaction({
                transactionId: defaultValues.transactionId,
                previousTransactionDate: defaultValues.transactionDate,
                previousTransactionAmount: defaultValues.transactionAmount,
                previousTransactionCategoryType: defaultValues.transactionCategoryType,
                transactionDate,
                transactionAmount,
                transactionPayeeId,
                transactionCategoryId,
                transactionMemo,
                transactionCategoryType,
                transactionAccountClassType,
            });
        }
        if (transaction.transactionId === undefined) {
            await onCreateTransaction({
                transactionDate,
                transactionAmount,
                transactionPayeeId,
                transactionCategoryId,
                transactionMemo,
                transactionCategoryType,
                transactionAccountClassType,
            });
        }
        onToggleAsidebar(asidebarTypes.ASIDEBAR_NONE);
    };

    return (
        <FormYupProvider schema={Schema} onSubmit={handleSubmit} defaultValues={defaultValues}>
            <Stack spacing={6} p={2}>
                <StyledPicker
                    name="transactionDate"
                    header={translate('management.transaction.date_title')}
                    label={translate('management.transaction.date_label')}
                />
                <TransactionAmount />
                <StyledAutoComplete
                    name="transactionPayeeId"
                    header={translate('management.transaction.payee_title')}
                    label={translate('management.transaction.payee_label')}
                    options={payeeOptions}
                    dialogTitle={translate('management.payee.dialog_title')}
                    dialogContent={translate('management.payee.dialog_content')}
                    dialogLabel={translate('management.payee.dialog_label')}
                />
                <CategoryTypeToggle name="transactionCategoryType" />
                <CategorySelect name="transactionCategoryId" />
                <StyledTextField
                    name="transactionMemo"
                    header={translate('management.transaction.memo_title')}
                    fullWidth
                    multiline
                    rows={4}
                    label={translate('management.transaction.memo_label')}
                />
                <TransactionFormSubmit isUpdate={transaction.transactionId !== undefined} />
            </Stack>
        </FormYupProvider>
    );
};
TransactionForm.propTypes = {
    transaction: PropTypes.object.isRequired,
};

export default TransactionForm;
