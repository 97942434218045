import { Suspense } from 'react';
import LoadingScreen from '../loading-screen';

// TODO use SWR or Relay instead?
// https://blog.logrocket.com/react-suspense-data-fetching/
const withPageSuspense = (WrappedComponent) => {
    function HOC({ ...props }) {
        return (
            <Suspense fallback={<LoadingScreen />}>
                <WrappedComponent {...props} />
            </Suspense>
        );
    }

    HOC.propTypes = {};

    return HOC;
};
export default withPageSuspense;
