import PropTypes from "prop-types";
import PayeesProvider from "./PayeesProvider";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useSubscriptionsContext } from "../../hooks/useSubscriptionsContext";
import Api, { PayeesUrlBuilder } from "../../api/PayeesApi";


const createSwrResource = (userId, subscriptionId, bookId) => ({
    readKey: PayeesUrlBuilder.getPayees(userId, subscriptionId, bookId),
    readAction: Api.getPayees,

    writeKey: PayeesUrlBuilder.addPayee(userId, subscriptionId, bookId),
    writeAction: Api.addPayee,

    updateKey: PayeesUrlBuilder.updatePayee(userId, subscriptionId, bookId),
    updateAction: Api.updatePayee,
});

const PayeesProviderHoc = ({children}) => {
    const {userId} = useAuthContext()
    const {defaultSubscriptionId, defaultBookId} = useSubscriptionsContext();
    const resource = createSwrResource(userId, defaultSubscriptionId, defaultBookId);

    return (
        <PayeesProvider resource={resource}>
            {children}
        </PayeesProvider>
    )
}
PayeesProviderHoc.propTypes = {
    children: PropTypes.node,
};

export default PayeesProviderHoc;