import React from "react";
import PropTypes from "prop-types";
import {
    TimelineDot,
    TimelineItem,
    TimelineContent,
    TimelineSeparator,
    TimelineConnector,
    TimelineOppositeContent
} from '@mui/lab';
import { Box, Stack, Typography } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { useLocales } from "../../../../../locales";
import { useSettingsContext } from "../../../../../components/settings";
import { fCurrencyBy100 } from "../../../../../utils/formatNumber";
import { fDate2 } from "../../../../../utils/formatTime";


const TimelineTypography = ({title, subTitle}) => (
    <Box
        sx={{
            p: 2,
        }}>
        <Typography variant="subtitle1" sx={{color: 'text.secondary'}}>
            {title}
        </Typography>
        <Typography variant="body1" sx={{color: 'text.secondary'}}>
            {subTitle}
        </Typography>
    </Box>
);
TimelineTypography.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string
};

const EmptyFilterTransactions = ({balance}) => {
    const {currentLanguage, t} = useLocales();
    const {isRTL} = useSettingsContext();

    const title = `${t('transactions.labelEndingBalance')} ${fDate2(new Date(), 'dd MMMM yyyy', currentLanguage.value)} ${fCurrencyBy100(balance)}`;
    const isDebit = !isRTL === 'ltr' ? balance < 0 : balance >= 0;
    const isCredit = !isRTL === 'ltr' ? balance >= 0 : balance < 0;

    return (
        <Stack spacing={2}>
            <TimelineItem>
                <TimelineOppositeContent>
                    <TimelineTypography title={isDebit ? title : ""}/>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot color="info">
                        <TrendingUpIcon/>
                    </TimelineDot>
                    <TimelineConnector/>
                </TimelineSeparator>
                <TimelineContent>
                    <TimelineTypography title={isCredit ? title : ""}/>
                </TimelineContent>
            </TimelineItem>

            <Typography variant="h6" sx={{color: 'text.secondary', textAlign: "center"}}>
                {t('filter.no_transactions')}
            </Typography>
        </Stack>
    )
};
EmptyFilterTransactions.propTypes = {
    balance: PropTypes.number.isRequired
}

export default EmptyFilterTransactions;