import {
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
} from "@mui/material";
import ToolbarItem from "./ToolbarItem";
import Iconify from "../../../../../components/iconify";
import { useLocales } from "../../../../../locales";
import { useLayoutSettingsContext } from "./TransactionLayoutSettings";
import AccountsSwitcher from "./AccountsSwitcher";
import useTransactionsContext from "../../../../../contexts/Transactions/useTransactionsContext";
import { useSortableProvider } from "../../../../../contexts/Generic/SortableProvider";
import DatesSwitcher from "./DatesSwitcher";
import useFilterContext from "../../../components/Filter/useFilterContext";
import * as asidebarTypes from "./TransactionLayoutTypes";

const TransactionsToolbar = () => {
    const {t} = useLocales();
    const {layoutViews, onToggleViewMode, onToggleFilter, showFilter, onToggleAsidebar} = useLayoutSettingsContext();
    const {handleToggleFilter} = useFilterContext();
    const {hasNoTransactions, onToggleRefresh} = useTransactionsContext();
    const {sortOrder, toggleOrder} = useSortableProvider();
    const isAsecnding = sortOrder === 'asc';

    const handleViews = (event, newViews) => {
        onToggleViewMode(newViews);
    };

    return (
        <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            flexShrink={0}
            sx={{
                px: 2,
                mb: 1,
                height: 80,
                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
        >
            <Stack direction="row" alignItems="center" flexGrow={1} spacing={2}>
                <ToolbarItem
                    icon="tabler:square-plus"
                    items={[
                        {
                            languageCode: 'transactions.toolbar.new_standard',
                            action: () => {
                                onToggleAsidebar(asidebarTypes.ASIDEBAR_ADD_TRANSACTION);
                            }
                        },
                        {
                            languageCode: 'transactions.toolbar.new_split',
                            action: () => {
                                console.log('clicky clicky...2')
                            }
                        },
                        {
                            languageCode: 'transactions.toolbar.new_transfer',
                            action: () => {
                                onToggleAsidebar(asidebarTypes.ASIDEBAR_ADD_TRANSFER);
                            }
                        },
                    ]}
                />

                <ToolbarItem
                    icon="tabler:refresh"
                    tooltip="common.refresh"
                    action={onToggleRefresh}
                />

                <ToolbarItem
                    icon={isAsecnding ? "tabler:sort-ascending" : "tabler:sort-descending"}
                    tooltip={isAsecnding ? "common.sort_ascending" : "common.sort_descending"}
                    action={toggleOrder}
                />

                <DatesSwitcher/>

                <ToolbarItem
                    icon="tabler:filter"
                    tooltip="common.toggle_filter"
                    action={() => {
                        onToggleFilter();
                        if (!showFilter) {
                            handleToggleFilter(undefined);
                        }
                    }}
                />

                <ToolbarItem
                    icon="tabler:adjustments-horizontal"
                    tooltip="transactions.toolbar.account_options"
                    action={() => console.log('refresh')}
                />

                <ToggleButtonGroup
                    size="small"
                    value={layoutViews}
                    onChange={handleViews}
                    color="primary"
                    orientation="horizontal"
                    disabled={hasNoTransactions}
                >
                    <ToggleButton value={asidebarTypes.LAYOUT_VIEWS_TOGGLE_STATS}>
                        <Tooltip title={t('common.toggle_stats')}>
                            <Iconify icon="tabler:activity-heartbeat"/>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={asidebarTypes.LAYOUT_VIEWS_MONTHLY_TOTALS}>
                        <Tooltip title={t('transactions.toolbar.show_monthly_totals')}>
                            <Iconify icon="tabler:box-multiple-0"/>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={asidebarTypes.LAYOUT_VIEWS_DAILY_TOTALS}>
                        <Tooltip title={t('transactions.toolbar.show_daily_totals')}>
                            <Iconify icon="tabler:box-multiple-1"/>
                        </Tooltip>
                    </ToggleButton>
                </ToggleButtonGroup>

                <AccountsSwitcher/>

            </Stack>
        </Stack>
    )
}

export default TransactionsToolbar;