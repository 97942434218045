import {m} from "framer-motion";
import {experimentalStyled as styled} from "@mui/material/styles";
import {Box, Button, Card, CardContent, Typography} from "@mui/material";
import {useLocales} from "../../../../../locales";
import {varHover, varTranHover} from "../../../../../components/animate";
import Image from "../../../../../components/image";
import {ComingSoonBadge} from "../../../../../components/badge";


const RootStyle = styled(Card)(({theme}) => ({
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.lighter,
    height: '100%',
}));

const NewGoal = () => {
    const {t} = useLocales();
    return (
        <RootStyle>
            <CardContent
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box component={m.div} >
                    <m.div variants={varHover(1.1)} transition={varTranHover()}>
                        <Image src="assets/icons/wyzr/ic_savings.png" alt="" sx={{width: 170}}/>
                    </m.div>
                </Box>

                <Typography variant="h6" sx={{color: 'text.secondary'}}>
                    {t('widgets.savings_goal_create_info')}
                </Typography>

                <ComingSoonBadge>
                    <Button
                        variant="contained"
                        size="large"
                    >
                        {t('widgets.savings_goal_create_title')}
                    </Button>
                </ComingSoonBadge>

            </CardContent>
        </RootStyle>
    )
}

export default NewGoal;