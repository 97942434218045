import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Stack, Box, Typography } from '@mui/material';
import { useLocales } from '../../../../../../locales';
import useReportDataContext from '../../components/useReportDataContext';
import DateRangeTypography from '../../../../../../components/typography/DateRangeTypography';
import AmountTypography from '../../../../../../components/typography/AmountTypography';

const RootStyle = styled(Box)(({ theme }) => ({
    boxShadow: 'none',
}));

const ChartOptions = ({ totalIncomes, totalExpenses }) => {
    const { t } = useLocales();
    const { selectedDate } = useReportDataContext();

    return (
        <RootStyle>
            <Stack
                spacing={2}
                alignItems="start"
                direction={{
                    xs: 'column',
                    md: 'row',
                }}
                sx={{
                    px: 1.5,
                    py: 2,
                }}
            >
                <Stack direction="column" sx={{ flexGrow: 1 }}>
                    <Typography>{t('reports.transactions.totalNetIncome')}</Typography>
                    <AmountTypography amount={totalIncomes.asInt - totalExpenses.asInt} useInt variant="h3" />
                    <DateRangeTypography date={selectedDate} />
                </Stack>
            </Stack>
        </RootStyle>
    );
};
ChartOptions.propTypes = {
    totalIncomes: PropTypes.object,
    totalExpenses: PropTypes.object,
};

export default ChartOptions;