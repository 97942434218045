import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { useLocales } from "../../../../../locales";

const TransferFormSubmit = ({isUpdate}) => {
    const {translate} = useLocales();
    const label = isUpdate
        ? translate('management.transaction.submit_update_transfer')
        : translate('management.transaction.submit_add_transfer');

    return (
        <Button variant="contained" size="large" type="submit">
            {label}
        </Button>
    )
};
TransferFormSubmit.propTypes = {
    isUpdate: PropTypes.bool.isRequired
}

export default TransferFormSubmit;