import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { useFormYup } from '../../../../components/hook-form/useFormYup';
import AccountTypes from './AccountTypes';
import TemplateStoreApi from '../../../../api/TemplateStoreApi';
import { useLocales } from '../../../../locales';
import usePromiseFetch from '../../../../hooks/usePromiseFetch';
import SkeletonLoad from './SkeletonLoad';
import Accounts from './Accounts';
import { useSubscriptionsContext } from '../../../../hooks/useSubscriptionsContext';
import { subscriptionAccountsLimitSelector } from '../../../subscription/selectors';

const AccountDetails = () => {
    const { registerFieldValidations } = useFormYup();
    const { currentLanguage } = useLocales();
    const { defaultSubscriptionId } = useSubscriptionsContext();
    const [...limits] = useSelector((state) => subscriptionAccountsLimitSelector(state, defaultSubscriptionId, 'system_all_account_types'));

    useEffect(() => {
        registerFieldValidations(['accountCode', 'accountTypeDescription', 'accountTypeCode']);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { loading, data } = usePromiseFetch(TemplateStoreApi.getAccountTypes, currentLanguage.value);
    const mappedData = data || [];

    const types = mappedData.map((item) => {
        const limit = limits.find((r) => r.accountCode.toLowerCase() === item.code.toLowerCase());
        return {
            name: item.name,
            code: item.code,
            accountClassType: item.accountClassType.toLowerCase(),
            description: item.description,
            accounts: item.accounts,
            limit,
        };
    });

    return (
        <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 15 }}>
            {loading && <SkeletonLoad />}
            {!loading && <AccountTypes data={types} name="accountCode" />}
            {!loading && <Accounts data={types} name="accountTypeCode" />}
        </Stack>
    );
};

export default AccountDetails;
