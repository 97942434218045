import React from "react";
import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import { Stack, TextField, Typography } from "@mui/material";
import { NumericFormat } from 'react-number-format';
import { responsiveFontSizes } from "../../utils/fontSizes";

const NumericFormatCustom = React.forwardRef((props, ref) => {
    const {onChange, allowNegative, decimalScale, ...other} = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator=","
            valueIsNumericString
            decimalSeparator="."
            decimalScale={decimalScale || 2}
            fixedDecimalScale
            allowNegative={allowNegative}
        />
    );
});
NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    allowNegative: PropTypes.bool,
    decimalScale: PropTypes.number,
};

const StyledNumeric = ({header, name, allowNegative, helperText, decimalScale, ...props}) => {
    const {control} = useFormContext();
    return (
        <Stack spacing={1} p={0}>
            <Typography variant="h6">
                {header}
            </Typography>

            <Controller
                name={name}
                control={control}
                render={({field, fieldState: {error}}) => (
                    <TextField
                        fullWidth
                        // inputComponent={NumericFormatCustom}
                        InputProps={{
                            inputComponent: NumericFormatCustom,
                            inputProps: {
                                allowNegative,
                                decimalScale,
                            },
                            sx: {
                                ...responsiveFontSizes({sm: 16, md: 18, lg: 20}),
                                height: 50
                            },
                        }}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: 83
                            },
                            '& .MuiInputBase-input': {
                                fontFamily: ['DM Sans, sans-serif'],
                                height: 50
                            },
                        }}
                        error={!!error}
                        helperText={error?.message || helperText}
                        {...field}
                        {...props}
                    />
                )}
            />
        </Stack>
    )
}

StyledNumeric.propTypes = {
    name: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(["standard", "filled", "outlined"]),
    allowNegative: PropTypes.bool,
    helperText: PropTypes.string,
    decimalScale: PropTypes.number,
};
StyledNumeric.defaultProps = {
    variant: "filled",
    allowNegative: true,
    decimalScale: 2,
};

export default StyledNumeric;