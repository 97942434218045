import axios from 'axios';
import { SiteConfig } from './ConfigApi';

export type Category = {
    costImportance: string,
    costType: string,
    id: string,
    isPrimary: boolean,
    primaryName: string,
    secondaryName: string | null,
    type: string,
};

const CategoriesUrlBuilder = {
    getCategories: (userId: string, subscriptionId: string, bookId: string) : string => `${SiteConfig.SubscriptionsWebApiUrl}/api/users/${userId}/subscriptions/${subscriptionId}/books/${bookId}/categories`,
    addCategory: (userId: string, subscriptionId: string, bookId: string) : string => `${SiteConfig.SubscriptionsWebApiUrl}/api/users/${userId}/subscriptions/${subscriptionId}/books/${bookId}/categories`,
    updateCategory: (userId: string, subscriptionId: string, bookId: string) : string => `${SiteConfig.SubscriptionsWebApiUrl}/api/users/${userId}/subscriptions/${subscriptionId}/books/${bookId}/categories`,
};

class CategoriesApi {
    static getAllCategories(url: string): Array<Category> {
        return axios.get(url).then((response) => response.data);
    }

    // static getCategories(userId: string, subscriptionId: string, bookId: string, type: string): Array<Category> {
    //     const url = `${SiteConfig.SubscriptionsWebApiUrl}/api/users/${userId}/subscriptions/${subscriptionId}/books/${bookId}/categories/${type}`;
    //     return axios.get(url).then((response) => response.data);
    // }

    static putCategory(url: string, body) {
        // TODO SWR should be doing this
        const { categoryType } = body.arg;
        url = `${url}/${categoryType}`;
        return axios.put(url, body.arg).then((response) => response.data);
    }

    static postCategory(url: string, body) {
        // TODO SWR should be doing this
        const { type: categoryType } = body.arg;
        url = `${url}/${categoryType}`;
        return axios.post(url, body.arg).then((response) => response.data);
    }
}

export default CategoriesApi;
export { CategoriesUrlBuilder };