import * as types from './types';
import type { CategoriesContextState } from "./types";

const reducer = (state: CategoriesContextState, action) => {
    const {type, payload} = action;
    switch (type) {
        case types.INIT_DATA: {
            return {
                ...state,
                data: payload,
            }
        }
        default:
            return {
                ...state
            }
    }
}

export default reducer;