import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { fCurrency } from "../../../../../utils/formatNumber";
import { useLocales } from "../../../../../locales";
import DigitTypography from "../../../../../components/typography/DigitTypography";


const NetWorthTotal = ({net, sx, showTitle, titleVariant, netTitleVariant}) => {
    const {t} = useLocales();
    return (
        <Box sx={{
            textAlign: 'center',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            ...sx
        }}>
            {showTitle &&
                <Typography variant={titleVariant} gutterBottom sx={{color: 'text.secondary'}}>
                    {t('widgets.networth_total_title')}
                </Typography>
            }
            <DigitTypography
                variant={netTitleVariant}
                gutterBottom
                sx={{color: net < 0 ? "#f44336" : "#00c853"}}>
                {fCurrency(net)}
            </DigitTypography>
        </Box>
    )
}

NetWorthTotal.propTypes = {
    net: PropTypes.number.isRequired,
    sx: PropTypes.object,
    showTitle: PropTypes.bool,
    titleVariant: PropTypes.string,
    netTitleVariant: PropTypes.string
};
NetWorthTotal.defaultProps = {
    showTitle: true,
    titleVariant: 'h6',
    netTitleVariant: 'h1'
}

export default NetWorthTotal;