import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Stack } from '@mui/material';
import { useLocales } from '../../../../../locales';
import { FormYupProvider } from '../../../../../components/hook-form';
import { StyledTextField } from '../../../../../components/styled-inputs';
import FormSubmit from './FormSubmit';
import { ASIDEBAR_NONE, useLayoutSettingsContext } from '../components/LayoutSettings';
import usePayeesContext from '../../../../../contexts/Payees/usePayeesContext';

const PayeeForm = ({ payee }) => {
    const { t } = useLocales();
    const { onToggleAsidebar } = useLayoutSettingsContext();
    const { onCreatePayee, onUpdatePayee } = usePayeesContext();

    const defaultValues = {
        payeeId: payee.id || undefined,
        payeeName: payee.name || '',
    };
    const Schema = Yup.object().shape({
        payeeName: Yup.string().required(t('management.payee.name_validation')),
    });

    const handleSubmit = async (data) => {
        const { payeeId, payeeName } = data;
        if (payeeId === undefined) {
            await onCreatePayee({
                id: 'payee_id',
                name: payeeName
            });
        }
        if (payeeId !== undefined) {
            await onUpdatePayee({
                id: payeeId,
                name: payeeName
            });
        }

        onToggleAsidebar(ASIDEBAR_NONE);
    };
    return (
        <FormYupProvider schema={Schema} onSubmit={handleSubmit} defaultValues={defaultValues}>
            <Stack spacing={6} p={2}>
                <StyledTextField name="payeeName" header={t('management.payee.name_header')} fullWidth label={t('management.payee.name_label')} />
                <FormSubmit isUpdate={payee.id !== undefined} />
            </Stack>
        </FormYupProvider>
    );
};
PayeeForm.propTypes = {
    payee: PropTypes.object.isRequired,
};

export default PayeeForm;