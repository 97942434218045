import PropTypes from 'prop-types';
import {useState} from "react";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import MobileStepper from "@mui/material/MobileStepper";
import StepperLayoutSelector from "./StepperLayoutSelector";
import {useLocales} from "../../locales";

// TODO should next button type be configurable: submit & validate or standard?
// TODO connect layout to form methods?
const StepperLayout = ({children, validationTrigger, enableNext}) => {
    const theme = useTheme();
    const {translate} = useLocales();

    const stepsCount = Array.isArray(children) ? children.length : 0;
    const [activeStep, setActiveStep] = useState(0);
    const handleNext = async () => {
        const result = await validationTrigger();
        if (result === true) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <>
            <MobileStepper
                variant="progress"
                steps={stepsCount}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button size="medium" onClick={handleNext} disabled={enableNext || activeStep === stepsCount - 1}>
                        {translate('common.next')}
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft/>
                        ) : (
                            <KeyboardArrowRight/>
                        )}
                    </Button>
                }
                backButton={
                    <Button size="medium" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight/>
                        ) : (
                            <KeyboardArrowLeft/>
                        )}
                        {translate('common.previous')}
                    </Button>
                }
            />
            <StepperLayoutSelector activeStep={activeStep}>
                {children}
            </StepperLayoutSelector>
        </>
    )
}
StepperLayout.propTypes = {
    children: PropTypes.node.isRequired,
    validationTrigger: PropTypes.func,
    enableNext: PropTypes.bool,
};

StepperLayout.defaultProps = {
    validationTrigger: async () => true,
    enableNext: true,
}

export default StepperLayout;