import type { SubscriptionAccount } from "../api/SubscriptionsApi";

export const totalNetWorth = (data: Array<SubscriptionAccount>): number => {
    if (!Array.isArray(data)) {
        return 0;
    }

    const assetAccounts = data.filter(r => r.accountClassType.toLowerCase() === 'asset');
    const liabilityAccounts = data.filter(r => r.accountClassType.toLowerCase() === 'liability');
    const totalAsset = sumNetWorth(assetAccounts, false);
    const totalLiability = sumNetWorth(liabilityAccounts, false);
    return totalAsset - totalLiability;
}

export const sumNetWorth = (data: Array<SubscriptionAccount>, useCurrency: boolean = false): number => {
    if (!Array.isArray(data)) {
        return 0;
    }

    const reduce = data.reduce((r: number, next: SubscriptionAccount) => {
        const {currentBalance, currentBalanceCurrency} = next;
        const valueToAdd = useCurrency ? currentBalance : currentBalanceCurrency;
        return r + valueToAdd;
    }, 0.0);
    return reduce;
}

const filterTypes = (data: Array<SubscriptionAccount>, key: string): Array<string> => {
    if (!Array.isArray(data)) {
        return [];
    }
    const reduce = data.reduce((acc: Array<string>, next: SubscriptionAccount) => {
        const value = next[key];
        if (value && acc.findIndex(r => r.toLowerCase() === value.toLowerCase()) === -1) {
            acc.push(value);
        }
        return acc;
    }, []);
    return reduce;
}

export const bankTypes = (data: Array<SubscriptionAccount>): Array<string> => filterTypes(data, "accountType");
export const currencyTypes = (data: Array<SubscriptionAccount>): Array<string> => filterTypes(data, "currency");

export const countBy = (data: Array<SubscriptionAccount>, key: string, value: string) => {
    if (!Array.isArray(data)) {
        return [];
    }
    if (value.toLowerCase() === 'system_all_account_types') {
        return data.length;
    }
    return data.filter(r => r[key].toLowerCase() === value.toLowerCase()).length;
}