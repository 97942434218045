import PropTypes from 'prop-types';
import { useMemo } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useLocales } from '../../../../../locales';

const FormSubmit = ({ isUpdate }) => {
    const { translate } = useLocales();
    const label = useMemo(() => {
        if (isUpdate) {
            return translate('management.common.submit_update');
        }
        return translate('management.common.submit_new');
    }, [translate, isUpdate]);

    return (
        <Box pt={5} sx={{ width: '100%' }}>
            <Button variant="contained" size="large" type="submit" fullWidth>
                {label}
            </Button>
        </Box>
    );
};
FormSubmit.propTypes = {
    isUpdate: PropTypes.bool.isRequired,
};
export default FormSubmit;