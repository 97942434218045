import { Outlet } from 'react-router-dom';
// @mui
import { Stack, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// config
import { HEADER } from '../../config';
//
import Header from './Header';
import {responsiveWidthSizes} from "../../utils/widthSizes";

// ----------------------------------------------------------------------

export default function CompactLayout() {
    const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

    return (
        <>
            <Header isOffset={isOffset} />

            <Container component="main">
                <Stack
                    sx={{
                        py: 12,
                        m: 'auto',
                        ...responsiveWidthSizes({sm: 600, md: 800, lg: 1200}),
                        minHeight: '100vh',
                        // textAlign: 'center',
                        // justifyContent: 'center',
                    }}
                >
                    <Outlet />
                </Stack>
            </Container>
        </>
    );
}
