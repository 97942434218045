export const buildFilterKey = (filterId, value) => `${filterId}_${value}`;

// TODO consider refactoring, too many parameters
export const buildSelectedFilterItems = (selectedIds, selectedId, filterId, filterValues, chipName) => {
    const key = buildFilterKey(filterId, selectedId);
    const selectedIdIndex = selectedIds.findIndex(r => r === selectedId);
    if (selectedIdIndex >= 0) {
        // removing
        return [...filterValues.filter(r => r.key !== key)];
    }

    // adding
    const filterItem = {
        key,
        value: chipName
    };
    const newFilterValues = [...filterValues];
    newFilterValues.push(filterItem);
    return newFilterValues;
};

export const buildFromKeys = (filterId, selectedIds, data, filterValues = []) => {
    const x = selectedIds.reduce((acc, item) => {
        const dataItem = data.find(r => r.id === item);
        const chipName = `${dataItem.primaryName} ${dataItem.secondaryName ? ` - ${  dataItem.secondaryName}` : ''}`;
        const filterItem = {
            key: buildFilterKey(filterId, item),
            value: chipName,
        }
        acc.push(filterItem);
        return acc;
    }, [...filterValues]);
    return x;
}