import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { Breadcrumbs } from "../../../../../components/custom-breadcrumbs";
import { useAccountsContext } from "../../../../../hooks/useAccountsContext";
import { PATH_SUBSCRIPTION } from "../../../../../routes/paths";
import { useSubscriptionsContext } from "../../../../../hooks/useSubscriptionsContext";

const TransactionsLayout = ({children}) => {
    const {accountId} = useParams();
    const {accounts} = useAccountsContext();
    const {subscriptionName} = useSubscriptionsContext();
    const selectedAccount = accounts.find(r => r.id === accountId);

    return (
        <>
            <Breadcrumbs
                heading={selectedAccount.alias}
                links={[
                    {
                        name: subscriptionName,
                    },
                    {
                        name: 'breadcrumbs.accounts',
                        href: PATH_SUBSCRIPTION.accounts.all,
                    },
                    {
                        name: selectedAccount.alias
                    }
                ]}
            />
            <Box>
                {children}
            </Box>
        </>
    )
};

TransactionsLayout.propTypes = {
    children: PropTypes.any.isRequired,
};

export default TransactionsLayout;
