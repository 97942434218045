import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { StyledNumeric } from "../../../../../components/styled-inputs";
import { useLocales } from "../../../../../locales";


const TransactionAmount = () => {
    const {translate} = useLocales();
    const categoryType = useWatch({
        name: 'transactionCategoryType'
    });

    const label = useMemo(() => categoryType.toLowerCase() === 'expense'
        ? translate('management.transaction.amount_label_expense')
        : translate('management.transaction.amount_label_income'), [categoryType, translate]);

    return (
        <StyledNumeric
            name="transactionAmount"
            header={translate('management.transaction.amount_title')}
            label={label}
            required
            allowNegative={false}
        />
    )
};

export default TransactionAmount;