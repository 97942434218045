import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import Label from '../../../../../components/label';
import { useLocales } from '../../../../../locales';
import type { Payee } from '../../../../../api/PayeesApi';
import { ASIDEBAR_UPDATE_PAYEE, useLayoutSettingsContext } from './LayoutSettings';
import { stringAvatar } from './utils';

type Props = {
    row: Payee,
};

const PayeeTableRow = ({ row }: Props) => {
    const theme = useTheme();
    const { t } = useLocales();
    const { onToggleAsidebar } = useLayoutSettingsContext();
    const { name } = row;
    const active = 'Active';

    const mapStatus = (actual) => {
        if (actual === 'None') return '';
        return t(`bookkeeping.categories.status_${actual}`);
    };

    return (
        <TableRow hover>
            <TableCell padding="checkbox" sx={{ cursor: 'pointer' }} onClick={() => onToggleAsidebar(ASIDEBAR_UPDATE_PAYEE, row)}>
                <Avatar {...stringAvatar(name)} variant="square" />
            </TableCell>
            <TableCell>
                <ListItemText primary={name} primaryTypographyProps={{}} />
            </TableCell>
            <TableCell>
                <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={(active === 'banned' && 'error') || 'success'}>
                    {mapStatus(active)}
                </Label>
            </TableCell>
        </TableRow>
    );
};

export default PayeeTableRow;
