import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import Label from '../../../../../components/label';
import { useLocales } from '../../../../../locales';
import type { SimplifiedCategory } from '../../../../../contexts/Categories/types';
import Iconify from '../../../../../components/iconify';
import { useBoolean } from '../../../../../hooks/useBoolean';
import { ASIDEBAR_UPDATE_CATEGORY, useLayoutSettingsContext } from './LayoutSettings';
import { stringAvatar } from './utils';

type Props = {
    row: SimplifiedCategory,
    onToggle: void,
    canReset: boolean,
};

const CategoryTableRow = ({ row, onToggle, canReset }: Props) => {
    const theme = useTheme();
    const { t } = useLocales();
    const collapse = useBoolean();
    const { onToggleAsidebar } = useLayoutSettingsContext();
    const { name, categoryIds, costType, costImportance, count, primary } = row;
    const active = 'Active';

    const mapCostType = (actual) => {
        if (actual === 'None') return '';
        return t(`bookkeeping.categories.costType_${actual}`);
    };
    const mapCostImportance = (actual) => {
        if (actual === 'None') return '';
        return t(`bookkeeping.categories.costImportance_${actual}`);
    };
    const mapStatus = (actual) => {
        if (actual === 'None') return '';
        return t(`bookkeeping.categories.status_${actual}`);
    };

    return (
        <>
            <TableRow hover>
                <TableCell padding="checkbox" sx={{ cursor: 'pointer' }} onClick={() => onToggleAsidebar(ASIDEBAR_UPDATE_CATEGORY, row)} align="right">
                    <Avatar {...stringAvatar(primary ? name : row.primaryName)} variant="square" />
                </TableCell>
                <TableCell sx={{ pl: primary ? 2 : 6}}>
                    {primary &&
                        <ListItemText
                            primary={name}
                            primaryTypographyProps={{ fontWeight: categoryIds && categoryIds.length > 0 ? 'bold' : 'inherit' }}
                        />
                    }
                    {!primary &&
                        <ListItemText
                            primary={row.secondaryName}
                            primaryTypographyProps={{ fontWeight: categoryIds && categoryIds.length > 0 ? 'bold' : 'inherit' }}
                            secondary={row.primaryName}
                            secondaryTypographyProps={{ fontWeight: 'bold', fontStyle: 'italic' }}
                        />
                    }
                </TableCell>
                <TableCell>{mapCostType(costType)}</TableCell>
                <TableCell>{mapCostImportance(costImportance)}</TableCell>
                <TableCell>{count}</TableCell>
                <TableCell>
                    <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={(active === 'banned' && 'error') || 'success'}>
                        {mapStatus(active)}
                    </Label>
                </TableCell>
                <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    {!canReset && categoryIds && categoryIds.length > 0 && (
                        <IconButton
                            color={collapse.value ? 'inherit' : 'default'}
                            onClick={() => {
                                collapse.onToggle();
                                onToggle(categoryIds);
                            }}
                            sx={{
                                ...(collapse.value && {
                                    bgcolor: 'action.hover',
                                }),
                            }}
                        >
                            <Iconify icon={collapse.value ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-upward-fill'} />
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>

            {/* {renderSecondary} */}

        </>
    );
};

export default CategoryTableRow;