import {createAsyncThunk} from '@reduxjs/toolkit'

export const initSubscriptionsAsync = createAsyncThunk(
    'init/subscription',
    async payload => payload
)

export const initSubscriptionAccountsAsync = createAsyncThunk(
    'init/subscriptionAccounts',
    async payload => payload
)

export const resetSubscriptionsAsync = createAsyncThunk(
    'del/subscriptions',
    async payload => payload
)