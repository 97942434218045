import { useContext } from "react";
import { TransactionsContext } from "./TransactionsProvider";
import type { TransactionsContextState } from "./types";

const useTransactionsContext = () : TransactionsContextState => {
    const context: TransactionsContextState= useContext(TransactionsContext);
    if (context === undefined) {
        throw new Error('useTransactionsContext must be used within a Transactions Provider')
    }
    return context;
}

export default useTransactionsContext;