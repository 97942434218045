import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback } from 'react';
import { Stack } from '@mui/material';

export type CategoryFilterContextState = {
    selectedCategoryType: string,
    searchFilter: string,
    onUpdateSelectedCategoryType: (selectedCategoryType: string) => void,
    onUpdateSearchFilter: (searchFilter: string) => void,
};

const initialState: CategoryFilterContextState = {
    selectedCategoryType: 'system_all_category_types',
    searchFilter: '',
    onUpdateSelectedCategoryType: (selectedCategoryType) => {},
    onUpdateSearchFilter: (searchFilter) => {},
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { selectedCategoryType } = action.payload;
        return { ...state, selectedCategoryType };
    },
    INITIALIZE_SEARCH_FILTER: (state, action) => {
        const { searchFilter } = action.payload;
        return { ...state, searchFilter };
    }
};
const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const CategoryFilterContext = createContext(initialState);
const CategoryFilterProvider = ({ children }) => {
    const [state, setState] = useReducer(reducer, initialState);

    const onUpdateSelectedCategoryType = useCallback((selectedCategoryType: string) => {
        setState({
            type: 'INITIALIZE',
            payload: {
                selectedCategoryType,
            },
        });
    }, []);
    const onUpdateSearchFilter = useCallback((searchFilter: string) => {
        setState({
            type: 'INITIALIZE_SEARCH_FILTER',
            payload: {
                searchFilter,
            },
        });
    }, []);

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const values = {
        ...state,
        onUpdateSelectedCategoryType,
        onUpdateSearchFilter,
    };

    return (
        <CategoryFilterContext.Provider value={values}>
            <Stack spacing={1}>{children}</Stack>
        </CategoryFilterContext.Provider>
    );
};
CategoryFilterProvider.propTypes = {
    children: PropTypes.node,
};

export default CategoryFilterProvider;
export { CategoryFilterContext };