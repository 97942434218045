import SubscriptionAccountsProvider from "./SubscriptionAccountsProvider";
import {useAuthContext} from "../../hooks/useAuthContext";
import {useSubscriptionsContext} from "../../hooks/useSubscriptionsContext";
import Api, { SubscriptionUrlBuilder } from "../../api/SubscriptionsApi";

const createSwrResource = (userId, subscriptionId, currency) => ({
    key: SubscriptionUrlBuilder.getSubscriptionAccounts(userId, subscriptionId, currency),
    action: Api.getSubscriptionAccounts
})

const withSubscriptionAccounts = (WrappedComponent) => {
    function HOC(props) {
        const {userId} = useAuthContext();
        const {defaultSubscriptionId, defaultCurrency} = useSubscriptionsContext();
        const resource = createSwrResource(userId, defaultSubscriptionId, defaultCurrency);
        return (
            <SubscriptionAccountsProvider resource={resource}>
                <WrappedComponent {...props} />
            </SubscriptionAccountsProvider>
        )
    }

    HOC.propTypes = {}
    return HOC;
}

export default withSubscriptionAccounts;