import { createSlice } from '@reduxjs/toolkit';
import * as Api from '../../api/FilterApi';

const initialState = {
    reportDates: [],
    transactionDates: [],
    reportCompareDates: [],
    excludeCurrentMonthDates: [],
};

const slice = createSlice({
    name: 'dates',
    initialState,
    reducers: {
        initDates(state, action) {
            const reportDates = Api.getReportDateOptions();

            state.reportDates = reportDates;
            state.transactionDates = Api.getAccountTransactionDateOptions();
            state.reportCompareDates = Api.getReportCompareDateOptions();

            const exclude = ['REPORT_CURRENT_MONTH', 'REPORT_MONTH_TO_DATE', 'REPORT_PREVIOUS_MONTH', 'REPORT_LAST_30_DAYS'];
            state.excludeCurrentMonthDates = (reportDates || []).filter((r) => !exclude.includes(r.id));
        },
    },
});
// Reducer
export default slice.reducer;

// Actions
export const { initDates } = slice.actions;