import { useContext } from "react";
import { TransactionsDateContext } from "./TransactionsDateProvider";


const useTransactionDatesContext = () => {
    const context = useContext(TransactionsDateContext)
    if (context === undefined) {
        throw new Error('useTransactionDatesContext must be used within a TransactionsDate Provider')
    }
    return context;
}

export default useTransactionDatesContext;