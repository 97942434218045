import endOfMonth from 'date-fns/endOfMonth';
import endOfDay from 'date-fns/endOfDay';
import startOfYear from 'date-fns/startOfYear';
import startOfMonth from 'date-fns/startOfMonth';
import endOfYear from 'date-fns/endOfYear';
import subMonths from 'date-fns/subMonths';
import addMonths from 'date-fns/addMonths';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import subYears from 'date-fns/subYears';
import { getDayKey } from './PartitionHelper';

const currentDate = () => new Date();

const CURRENT_MONTH = 'current-month';
const PREVIOUS_MONTH = 'previous-month';
const MONTHS_3 = '3-months';
const MONTHS_6 = '6-months';
const CURRENT_YEAR = 'current-year';
const PREVIOUS_YEAR = 'previous-year';
// const ALL = 'all';

const DATE_OPTIONS = [
    {id: CURRENT_MONTH, name: 'current Month', default: true, languageCode: 'dates.periodCurrentMonth'},
    {id: PREVIOUS_MONTH, name: 'previous Month', languageCode: 'dates.periodPrvMonth'},
    {id: MONTHS_3, name: 'last 3 Months', languageCode: 'dates.periodPrv3Months'},
    {id: MONTHS_6, name: 'last 6 Months', languageCode: 'dates.periodPrv6Months'},
    {id: CURRENT_YEAR, name: 'current Year', languageCode: 'dates.periodCurrentYear'},
    {id: PREVIOUS_YEAR, name: 'previous Year', default: false, languageCode: 'dates.periodPrvYear'},
    // TODO add more date options
    // TODO add more date options
    // TODO all dates disabled. data load kills performance
    // {id: ALL, label: 'all dates'}
];

// const PERIOD_ID = {
//     [`${CURRENT_MONTH}`]: {
//         from: getPartitionKey(startOfMonth(currentDate())),
//         to: getPartitionKey(endOfMonth(currentDate()))
//     },
//     [`${PREVIOUS_MONTH}`]: {
//         from: getPartitionKey(startOfMonth(subMonths(currentDate(), 1))),
//         to: getPartitionKey(endOfMonth(subMonths(currentDate(), 1)))
//     },
//     [`${MONTHS_3}`]: {
//         from: getPartitionKey(startOfMonth(subMonths(currentDate(), 3))),
//         to: getPartitionKey(endOfMonth(currentDate()))
//     },
//     [`${MONTHS_6}`]: {
//         from: getPartitionKey(startOfMonth(subMonths(currentDate(), 6))),
//         to: getPartitionKey(endOfMonth(currentDate()))
//     },
//     [`${CURRENT_YEAR}`]: {
//         from: getPartitionKey(startOfYear(currentDate())),
//         to: getPartitionKey(endOfYear(currentDate()))
//     },
//     [`${PREVIOUS_YEAR}`]: {
//         from: getPartitionKey(startOfYear(subYears(currentDate(), 1))),
//         to: getPartitionKey(endOfYear(subYears(currentDate(), 1)))
//     },
// };

// export const getDateOptions = () => {
//     return DATE_OPTIONS.map(item => {
//         const periodId = PERIOD_ID[item.value];
//         return {
//             ...item,
//             periodId
//         }
//     });
// };

// export const DATE_OPTIONS_DEFAULT = DATE_OPTIONS.find(r => r.default);

const REPORT_ALL_DATES = "REPORT_ALL_DATES";
const REPORT_ALL_TO_DATE = "REPORT_ALL_TO_DATE";
const REPORT_CURRENT_MONTH = "REPORT_CURRENT_MONTH";
const REPORT_CURRENT_YEAR = "REPORT_CURRENT_YEAR";
const REPORT_CURRENT_FISCAL_YEAR = "REPORT_CURRENT_FISCAL_YEAR";
const REPORT_MONTH_TO_DATE = "REPORT_MONTH_TO_DATE";
const REPORT_YEAR_TO_DATE = "REPORT_YEAR_TO_DATE";
const REPORT_FISCAL_YEAR_TO_DATE = "REPORT_FISCAL_YEAR_TO_DATE";
const REPORT_PREVIOUS_MONTH = "REPORT_PREVIOUS_MONTH";
const REPORT_PREVIOUS_YEAR = "REPORT_PREVIOUS_YEAR";
const REPORT_PREVIOUS_FISCAL_YEAR = "REPORT_PREVIOUS_FISCAL_YEAR";
const REPORT_LAST_30_DAYS = "REPORT_LAST_30_DAYS";
const REPORT_LAST_3_MONTHS = "REPORT_LAST_3_MONTHS";
const REPORT_LAST_6_MONTHS = "REPORT_LAST_6_MONTHS";
const REPORT_LAST_12_MONTHS = "REPORT_LAST_12_MONTHS";
const REPORT_NEXT_30_DAYS = "REPORT_NEXT_30_DAYS";
const REPORT_NEXT_3_MONTHS = "REPORT_NEXT_3_MONTHS";
const REPORT_NEXT_6_MONTHS = "REPORT_NEXT_6_MONTHS";
const REPORT_NEXT_12_MONTHS = "REPORT_NEXT_12_MONTHS";
const REPORT_CUSTOM_DATES = "REPORT_CUSTOM_DATES";
const REPORT_PREVIOUS_3_MONTHS = "REPORT_PREVIOUS_3_MONTHS";
const REPORT_PREVIOUS_6_MONTHS = "REPORT_PREVIOUS_6_MONTHS";
const REPORT_PREVIOUS_9_MONTHS = "REPORT_PREVIOUS_9_MONTHS";
const REPORT_YEAR_TO_PREVIOUS_MONTH = "REPORT_YEAR_TO_PREVIOUS_MONTH";

const REPORT_DATE_OPTIONS = [
    // {id: REPORT_ALL_DATES, name: "All dates", languageCode: "dates.report_AllDates"},
    // {id: REPORT_ALL_TO_DATE, name: "All to date", languageCode: "dates.report_AllToDates"},
    {id: REPORT_CURRENT_MONTH, name: "Current Month", default: true, languageCode: "dates.report_CurrentMonth"},
    {id: REPORT_CURRENT_YEAR, name: "Current Year", languageCode: "dates.report_CurrentYear"},
    {id: REPORT_CURRENT_FISCAL_YEAR, name: "Current fiscal year", languageCode: "dates.report_CurrentFiscalYear"},
    {id: REPORT_MONTH_TO_DATE, name: "Month to date", languageCode: "dates.report_MonthToDate"},
    {id: REPORT_YEAR_TO_DATE, name: "Year to date", languageCode: "dates.report_YearToDate"},
    {id: REPORT_FISCAL_YEAR_TO_DATE, name: "Fiscal year to date", languageCode: "dates.report_FiscalYearToDate"},
    {id: REPORT_PREVIOUS_MONTH, name: "Previous month", languageCode: "dates.report_PreviousMonth"},
    {id: REPORT_PREVIOUS_YEAR, name: "Previous year", languageCode: "dates.report_PreviousYear"},
    {id: REPORT_PREVIOUS_FISCAL_YEAR, name: "Previous fiscal year", languageCode: "dates.report_PreviousFiscalYear"},
    {id: REPORT_LAST_30_DAYS, name: "Last 30 Days", languageCode: "dates.report_Last30Days"},
    {id: REPORT_LAST_3_MONTHS, name: "Last 3 months", languageCode: "dates.report_Last3Months"},
    {id: REPORT_LAST_6_MONTHS, name: "Last 6 months", languageCode: "dates.report_Last6Months"},
    {id: REPORT_LAST_12_MONTHS, name: "Last 12 months", languageCode: "dates.report_Last12Months"},
    // {id: REPORT_NEXT_30_DAYS, name: "Next 30 Days", languageCode: "dates.report_Next30Days"},
    // {id: REPORT_NEXT_3_MONTHS, name: "Next 3 months", languageCode: "dates.report_Next3Months"},
    // {id: REPORT_NEXT_6_MONTHS, name: "Next 6 months", languageCode: "dates.report_Next6Months"},
    // {id: REPORT_NEXT_12_MONTHS, name: "Next 12 months", languageCode: "dates.report_Next12Months"},
    // {id: REPORT_CUSTOM_DATES, name: "Custom dates", languageCode: "dates.report_CustomDates"},
];
const REPORT_COMPARE_DATE_OPTIONS = [
    {id: REPORT_PREVIOUS_MONTH, name: "Previous month", default: true, languageCode: "report_Dates_PreviousMonth"},
    {id: REPORT_PREVIOUS_3_MONTHS, name: "Previous 3 months", languageCode: "report_Dates_Previous3Months"},
    {id: REPORT_PREVIOUS_6_MONTHS, name: "Previous 6 months", languageCode: "report_Dates_Previous6Months"},
    {id: REPORT_PREVIOUS_9_MONTHS, name: "Previous 9 months", languageCode: "report_Dates_Previous9Months"},
    {id: REPORT_YEAR_TO_PREVIOUS_MONTH, name: "Year to Previous month", languageCode: "report_Dates_YearToPreviousMonth"},
    {id: REPORT_PREVIOUS_YEAR, name: "Previous year", languageCode: "report_Dates_PreviousYear"},
];
const REPORT_DATE_FUNCTIONS = {
    [`${CURRENT_MONTH}`]: {
        from: startOfMonth(currentDate()),
        to: endOfMonth(currentDate())
    },
    [`${PREVIOUS_MONTH}`]: {
        from: startOfMonth(subMonths(currentDate(), 1)),
        to: endOfMonth(subMonths(currentDate(), 1)),
    },
    [`${MONTHS_3}`]: {
        from: subMonths(currentDate(), 3),
        to: currentDate()
    },
    [`${MONTHS_6}`]: {
        from: subMonths(currentDate(), 6),
        to: currentDate()
    },
    [`${CURRENT_YEAR}`]: {
        from: startOfYear(currentDate()),
        to: endOfYear(currentDate())
    },
    [`${PREVIOUS_YEAR}`]: {
        from: startOfYear(subYears(currentDate(), 1)),
        to: endOfYear(subYears(currentDate(), 1)),
    },


    [`${REPORT_ALL_DATES}`]: {
        from: null,
        to: null
    },
    [`${REPORT_ALL_TO_DATE}`]: {
        from: null,
        to: endOfDay(currentDate())
    },
    [`${REPORT_CURRENT_MONTH}`]: {
        from: startOfMonth(currentDate()),
        to: endOfMonth(currentDate())
    },
    [`${REPORT_CURRENT_YEAR}`]: {
        from: startOfYear(currentDate()),
        to: endOfYear(currentDate())
    },
    [`${REPORT_CURRENT_FISCAL_YEAR}`]: {
        from: startOfYear(currentDate()),
        to: endOfYear(currentDate())
    },
    [`${REPORT_MONTH_TO_DATE}`]: {
        from: startOfMonth(currentDate()),
        to: currentDate()
    },
    [`${REPORT_YEAR_TO_DATE}`]: {
        from: startOfYear(currentDate()),
        to: currentDate()
    },
    [`${REPORT_FISCAL_YEAR_TO_DATE}`]: {
        from: startOfYear(currentDate()),
        to: currentDate()
    },
    [`${REPORT_PREVIOUS_MONTH}`]: {
        from: startOfMonth(subMonths(currentDate(), 1)),
        to: endOfMonth(subMonths(currentDate(), 1)),
    },
    [`${REPORT_PREVIOUS_YEAR}`]: {
        from: startOfMonth(subYears(currentDate(), 1)),
        to: endOfMonth(subMonths(currentDate(), 1)),
    },
    [`${REPORT_PREVIOUS_FISCAL_YEAR}`]: {
        from: startOfYear(subYears(currentDate(), 1)),
        to: endOfYear(subMonths(currentDate(), 1)),
    },
    [`${REPORT_LAST_30_DAYS}`]: {
        from: subDays(currentDate(), 30),
        to: currentDate()
    },
    [`${REPORT_LAST_3_MONTHS}`]: {
        from: subMonths(currentDate(), 3),
        to: currentDate()
    },
    [`${REPORT_LAST_6_MONTHS}`]: {
        from: subMonths(currentDate(), 6),
        to: currentDate()
    },
    [`${REPORT_LAST_12_MONTHS}`]: {
        from: subMonths(currentDate(), 12),
        to: currentDate()
    },
    [`${REPORT_NEXT_30_DAYS}`]: {
        from: addDays(currentDate(), 1),
        to: addDays(currentDate(), 31)
    },
    [`${REPORT_NEXT_3_MONTHS}`]: {
        from: addDays(currentDate(), 1),
        to: addMonths(addDays(currentDate(), 1), 3)
    },
    [`${REPORT_NEXT_6_MONTHS}`]: {
        from: addDays(currentDate(), 1),
        to: addMonths(addDays(currentDate(), 1), 6)
    },
    [`${REPORT_NEXT_12_MONTHS}`]: {
        from: addDays(currentDate(), 1),
        to: addMonths(addDays(currentDate(), 1), 12)
    },
    [`${REPORT_CUSTOM_DATES}`]: {
        from: undefined,
        to: undefined
    },
    [`${REPORT_PREVIOUS_3_MONTHS}`]: {
        from: startOfMonth(subMonths(currentDate(), 3)),
        to: endOfMonth(subMonths(currentDate(), 1)),
    },
    [`${REPORT_PREVIOUS_6_MONTHS}`]: {
        from: startOfMonth(subMonths(currentDate(), 6)),
        to: endOfMonth(subMonths(currentDate(), 1)),
    },
    [`${REPORT_PREVIOUS_9_MONTHS}`]: {
        from: startOfMonth(subMonths(currentDate(), 9)),
        to: endOfMonth(subMonths(currentDate(), 1)),
    },
    [`${REPORT_YEAR_TO_PREVIOUS_MONTH}`]: {
        from: startOfYear(currentDate()),
        to: endOfMonth(subMonths(currentDate(), 1))
    },
};

export type DateRange = {
    id: string,
    name: string,
    default: boolean | undefined,
    languageCode: string,
    from: Date,
    to: Date,
    fromDayKey: number,
    toDayKey: number
};

function extracted(item) : DateRange {
    const range = REPORT_DATE_FUNCTIONS[item.id]
    const from = range.from ? range.from : null;
    const to = range.to ? range.to : null;
    const fromDayKey = range.from ? getDayKey(range.from) : 0;
    const toDayKey = range.to ? getDayKey(range.to) : 99999;
    return {
        ...item,
        from,
        to,
        fromDayKey,
        toDayKey
    }
}

export const getReportDateOptions = () : Array<DateRange> => REPORT_DATE_OPTIONS.map(item => extracted(item));
export const getReportCompareDateOptions = () : Array<DateRange> => REPORT_COMPARE_DATE_OPTIONS.map(item => extracted(item));
export const getAccountTransactionDateOptions = () : Array<DateRange> => DATE_OPTIONS.map(item => extracted(item));