import PropTypes from 'prop-types';
import CategoriesProvider from '../../../../contexts/Categories';
import CategoriesFilter from './components/CategoriesFilter';
import CategoryFilterProvider from './CategoryFilterProvider';
import CategoryList from './components/CategoryListHoc';
import LayoutSettings from './components/LayoutSettings';
import Asidebar from './Asidebar';

const CategoriesOverview = ({ variant }) => (
    <CategoriesProvider>
        <LayoutSettings>
            <CategoryFilterProvider>
                <Asidebar variant={variant} />
                <CategoriesFilter variant={variant} />
                <CategoryList variant={variant} />
            </CategoryFilterProvider>
        </LayoutSettings>
    </CategoriesProvider>
);
CategoriesOverview.propTypes = {
    variant: PropTypes.oneOf(['income', 'expense']).isRequired,
};

export default CategoriesOverview;