import useSWR from 'swr';
import axios from "axios";
import { useSubscriptionsContext } from "../../hooks/useSubscriptionsContext";
import { useAuthContext } from "../../hooks/useAuthContext";

const fetcher = url => axios.get(url).then(res => res.data);
const withComponentSwr = (WrappedComponent, action) => {
    function HOC(props) {
        const {defaultSubscriptionId, defaultCurrency} = useSubscriptionsContext();
        const {userId} = useAuthContext();
        const swrKey = action(userId, defaultSubscriptionId, defaultCurrency);

        const {data, error, isLoading} = useSWR(swrKey, fetcher);
        if (isLoading) return <>TODO loading...</>;
        if (error) return <>TODO Ooops, something went wrong...</>;

        return (
            <WrappedComponent
                data={data}
                defaultCurrency={defaultCurrency}
                {...props}
            />
        )
    }

    HOC.propTypes = {};
    return HOC;
}

export default withComponentSwr;
