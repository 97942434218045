import { createBrowserRouter, Navigate } from 'react-router-dom';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import CompactLayout from '../layouts/compact';
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import { PATH_HOME, PATH_SUBSCRIPTION, PATH_MANAGEMENT } from "./paths";
import {
    LandingPage,
    RegisterPage,
    RegisterSuccessPage,
    SubscriptionOverview,
    NewSubscription,
    AccountsOverview,
    NewAccount,
    Page404,
    Page500,
    TransactionsOverview,
    ReportsOverview,
    IncomeOverview,
    NetIncomeOverview,
    NetWorthOverview,
    SavingsOverview,
    MonthlySummaryOverview,
    RefundsOverview,
    BookkeepingExpensesOverview,
    BookkeepingIncomesOverview,
    BookkeepingPayeesOverview,
} from './elements';
import AuthClassicLayout from '../layouts/main/auth/classic';

// ----------------------------------------------------------------------

export const routes = [

    // MAIN ROUTES
    {
        path: PATH_HOME.root,
        element:
            <GuestGuard>
                <MainLayout/>
            </GuestGuard>,
        children: [
            {
                element: <LandingPage/>,
                index: true
            }
        ],
    },

    // AUTH
    {
        path: PATH_HOME.root,
        children: [
            {
                path: PATH_HOME.register,
                element: (
                    <AuthClassicLayout>
                        <RegisterPage/>
                    </AuthClassicLayout>
                )
            },
            {
                path: PATH_HOME.registerSuccess,
                element: (
                    <AuthClassicLayout>
                        <RegisterSuccessPage/>
                    </AuthClassicLayout>
                )
            },
        ]
    },

    // CONTROLLER ROUTES
    {
        path: PATH_MANAGEMENT.root,
        element: (
            <AuthGuard>
                <CompactLayout/>
            </AuthGuard>
        ),
        children: [
            {
                path: PATH_MANAGEMENT.subscriptions.new,
                element: <NewSubscription/>
            }
        ]
    },

    // SUBSCRIPTION ROUTES
    {
        path: PATH_SUBSCRIPTION.root,
        element: (
            <AuthGuard>
                <DashboardLayout/>
            </AuthGuard>
        ),
        loader: async ({ request, params }) => ({}),
        children: [
            {
                index: true,
                element: <SubscriptionOverview/>
            },
            {
                path: PATH_SUBSCRIPTION.accounts.all,
                element: <AccountsOverview/>
            },
            {
                path: PATH_SUBSCRIPTION.accounts.new,
                element: <NewAccount/>
            },
            {
                path: PATH_SUBSCRIPTION.transactions.all,
                element: <TransactionsOverview/>
            },
            {
                path: PATH_SUBSCRIPTION.reports.all,
                element: <ReportsOverview/>
            },
            {
                path: PATH_SUBSCRIPTION.reports.incomes,
                element: <IncomeOverview/>
            },
            {
                path: PATH_SUBSCRIPTION.reports.netIncome,
                element: <NetIncomeOverview/>
            },
            {
                path: PATH_SUBSCRIPTION.reports.netWorth,
                element: <NetWorthOverview/>
            },
            {
                path: PATH_SUBSCRIPTION.reports.savings,
                element: <SavingsOverview/>
            },
            {
                path: PATH_SUBSCRIPTION.reports.monthlySummary,
                element: <MonthlySummaryOverview/>
            },
            {
                path: PATH_SUBSCRIPTION.reports.refunds,
                element: <RefundsOverview/>
            },
            {
                path: PATH_SUBSCRIPTION.bookkeeping.expenses,
                element: <BookkeepingExpensesOverview/>
            },
            {
                path: PATH_SUBSCRIPTION.bookkeeping.incomes,
                element: <BookkeepingIncomesOverview/>
            },
            {
                path: PATH_SUBSCRIPTION.bookkeeping.payees,
                element: <BookkeepingPayeesOverview/>
            },
        ],
    },

    // OTHER ROUTES
    {
        element: <CompactLayout/>,
        children: [
            {
                path: '404',
                element: <Page404/>
            },
            {
                path: '500',
                element: <Page500/>
            },
        ],
    },
    {path: '*', element: <Navigate to="/404" replace/>},
];

const router = createBrowserRouter(routes);
export default router;
