import { Box, Button, Tab, Tabs, Stack, TextField } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputAdornment from '@mui/material/InputAdornment';
import Iconify from '../../../../../components/iconify';
import { useLocales } from '../../../../../locales';
import { usePayeeFilterContext } from '../hooks/usePayeeFilterContext';
import usePayeesContext from '../../../../../contexts/Payees/usePayeesContext';
import Label from '../../../../../components/label';
import { responsiveFontSizes } from '../../../../../utils/fontSizes';
import { ASIDEBAR_ADD_PAYEE, useLayoutSettingsContext } from './LayoutSettings';

function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}
const groupIt = (array: Array) => {
    const result = array.reduce((acc, item) => {
        const currentWord = item.name;
        const firstChar = currentWord[0].toLowerCase();
        const innerArr = [];
        if (acc[firstChar] === undefined) {
            innerArr.push(currentWord);
            acc[firstChar] = innerArr;
        } else {
            acc[firstChar].push(currentWord);
        }
        return acc;
    }, {});
    const chunks = sliceIntoChunks(Object.keys(result), 4);

    const alphabetChunks = chunks.reduce((acc, item) => {
        if (item.length > 0) {

            const count = item.reduce((countAcc, itemKey) => {
                const itemsOfIntereset = result[itemKey].length;
                countAcc += itemsOfIntereset;
                return countAcc;
            }, 0);

            acc.push({
                value: `${item[0]}|${item[item.length - 1]}`,
                label: `${item[0]} - ${item[item.length - 1]}`,
                color: 'warning',
                count
            });
        }
        return acc;
    }, []);
    return alphabetChunks;
};

const PayeesFilter = () => {
    const { t } = useLocales();
    const { onToggleAsidebar } = useLayoutSettingsContext();
    const { data, onToggleRefresh } = usePayeesContext();
    const { searchFilter, selectedPayeeLetter, onUpdateSearchFilter, onUpdateSelectedPayeeLetter } = usePayeeFilterContext();

    const names = groupIt(data);
    const defaultTab = {
        value: 'system_all_payee_names',
        label: 'bookkeeping.filter_all',
        color: 'default',
        count: data.length,
    };
    const DEFAULT_TABS = [defaultTab, ...names];

    const handleFilterSearch = (event) => {
        onUpdateSearchFilter(event.target.value);
    };

    const view = 'list';
    const handleChangeView = () => {};

    const handleFilterStatus = (event, newValue) => {
        onUpdateSelectedPayeeLetter(newValue);
    };

    const renderTab = (tab) => {
        const length = tab.count;
        return (
            <Tab
                key={tab.value}
                value={tab.value}
                label={t(tab.label)}
                icon={
                    <Label color={tab.color} sx={{ mr: 1 }}>
                        {length}
                    </Label>
                }
                sx={{
                    ...responsiveFontSizes({ sm: 16, md: 16, lg: 18 }),
                }}
            />
        );
    };

    return (
        <Stack spacing={2}>
            {DEFAULT_TABS.length > 2 && (
                <Tabs
                    value={selectedPayeeLetter}
                    sx={{
                        px: 2,
                        bgcolor: 'background.neutral',
                    }}
                    onChange={handleFilterStatus}
                >
                    {DEFAULT_TABS.map((tab) => renderTab(tab))}
                </Tabs>
            )}

            <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-end', md: 'center' }}>

                    <Button
                        variant="contained"
                        sx={{
                            textTransform: 'unset',
                            minWidth: 160,
                            fontWeight: 'fontWeightMedium',
                            height: 48,
                        }}
                        startIcon={<Iconify icon="solar:add-square-linear" />}
                        onClick={() => onToggleAsidebar(ASIDEBAR_ADD_PAYEE, {})}
                    >
                        {t('bookkeeping.payees.new_payee')}
                    </Button>

                    <ToggleButtonGroup size="small" value={view} exclusive onChange={handleChangeView}>
                        <ToggleButton value="list">
                            <Iconify icon="solar:list-bold" />
                        </ToggleButton>

                        <ToggleButton value="grid">
                            <Iconify icon="mingcute:dot-grid-fill" />
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButton
                        value="check"
                        selected={false}
                        onClick={() => onToggleRefresh()}
                    >
                        <Iconify icon="solar:refresh-bold" />
                    </ToggleButton>

                    <Box sx={{ flexGrow: 1 }} />

                    <TextField
                        fullWidth
                        value={searchFilter}
                        onChange={handleFilterSearch}
                        placeholder={t('bookkeeping.filter_search_payee')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                                </InputAdornment>
                            ),
                        }}
                    />

            </Stack>
        </Stack>
    );
};

export default PayeesFilter;
