import {createSlice} from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
    subscriptionsCount: 0,
    defaultSubscriptionId: null,
    subscriptions: {}
};
const initialiseSubscriptions = (state, action) => {
    const {payload} = action;
    if (Array.isArray(payload)) {
        const defaultSubscription = payload.find(r => r.isDefault === true);
        state.subscriptionsCount = payload.length;
        state.defaultSubscriptionId = defaultSubscription.subscriptionId;
        state.subscriptions = payload.reduce((accumulator, currentValue) => {
            accumulator[currentValue.subscriptionId] = {
                name: currentValue.name,
                bookId: currentValue.bookId,
                currency: currentValue.currency,
                isDefault: currentValue.isDefault
            };
            return accumulator;
        }, {});
    }
}
const initialiseSubscriptionAccounts = (state, action) => {
    const {payload} = action;
    const {subscriptionAccounts, subscriptionId} = payload;

    if (!subscriptionId) {
        throw new Error('Dispatch action does not have the required SubscriptionId');
    }
    if (!Array.isArray(subscriptionAccounts)) {
        throw new Error('Dispatch action does not have the required Subscription Accounts array');
    }

    if (Array.isArray(subscriptionAccounts)) {
        if (!(`${subscriptionId}` in state.subscriptions)) {
            // TODO how should this be handled?
            console.warn(`Subscription ${subscriptionId} is not initialised in store`);
            state.subscriptions[subscriptionId] = {};
        }
        state.subscriptions[subscriptionId].accounts = [...subscriptionAccounts];
    }
}

const slice = createSlice({
    name: 'plans',
    initialState,
    reducers: {

    },
    extraReducers: {
        [actions.initSubscriptionsAsync.fulfilled]: (state, action) => {
            initialiseSubscriptions(state, action);
        },
        [actions.initSubscriptionAccountsAsync.fulfilled]: (state, action) => {
            initialiseSubscriptionAccounts(state, action);
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
// eslint-disable-next-line no-empty-pattern
export const {
} = slice.actions;