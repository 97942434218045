import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useLocales } from '../../../../../locales';

const ClassificationFilterSummary = ({ selectedAccounts, selectedCategories, selectedPayees }) => {
    const { t } = useLocales();
    return (
        <Stack direction="column" spacing={3}>
            <Typography variant="subtitle1">{t('filter.filter_panel_selections')}</Typography>

            {selectedCategories.length > 0 && (
                <>
                    <Typography variant="subtitle2">{t('filter.filter_panel_selections_categories')}</Typography>
                    <Stack direction="row" spacing={1} flexWrap="wrap" justifyContent="flex-start" alignItems="baseline">
                        {selectedCategories.map((item, index) => (
                            <Typography key={index}>{item.secondary || item.primary}</Typography>
                        ))}
                    </Stack>
                    <Divider/>
                </>
            )}

            {selectedPayees.length > 0 && (
                <>
                    <Typography variant="subtitle2">{t('filter.filter_panel_selections_payees')}</Typography>
                    <Stack direction="row" spacing={1} flexWrap="wrap" justifyContent="flex-start" alignItems="baseline">
                        {selectedPayees.map((item, index) => (
                            <Typography key={index}>{item.label}</Typography>
                        ))}
                    </Stack>
                    <Divider/>
                </>
            )}

            {selectedAccounts.length > 0 && (
                <>
                    <Typography variant="subtitle2">{t('filter.filter_panel_selections_accounts')}</Typography>
                    <Stack direction="row" spacing={1} flexWrap="wrap" justifyContent="flex-start" alignItems="baseline">
                        {selectedAccounts.map((item, index) => (
                            <Typography key={index}>{item.label}</Typography>
                        ))}
                    </Stack>
                    <Divider/>
                </>
            )}
        </Stack>
    );
};
ClassificationFilterSummary.propTypes = {
    selectedAccounts: PropTypes.array,
    selectedPayees: PropTypes.array,
    selectedCategories: PropTypes.array,
};

export default ClassificationFilterSummary;

