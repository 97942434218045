import {useState} from 'react';
// @mui
import {MenuItem, Stack, Typography, Box} from '@mui/material';
// locales
import {useLocales} from '../../../locales';
// components
import MenuPopover from '../../../components/menu-popover';
import {IconButtonAnimate} from '../../../components/animate';
import Iconify from '../../../components/iconify';
import {responsiveFontSizes} from "../../../utils/fontSizes";

// ----------------------------------------------------------------------

export default function LanguagePopover() {
    const {allLanguages, currentLanguage, onChangeLanguage, translate} = useLocales();
    const [openPopover, setOpenPopover] = useState(null);

    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleChangeLang = (newLang) => {
        onChangeLanguage(newLang);
        handleClosePopover();
    };

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpenPopover}
                sx={{
                    width: 40,
                    height: 40,
                    ...(openPopover && {
                        bgcolor: 'action.selected',
                    }),
                }}
            >
                {/* <Image disabledEffect src={currentLanguage.icon} alt={currentLanguage.label}/> */}
                <Iconify icon="ic:baseline-language" width={24}/>
            </IconButtonAnimate>

            <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{width: 220}}>
                <Stack spacing={0.75}>
                    {allLanguages.map((option) => (
                        <MenuItem
                            key={option.value}
                            selected={option.value === currentLanguage.value}
                            onClick={() => handleChangeLang(option.value)}
                        >
                            {option.value === currentLanguage.value &&
                                <Iconify icon="ant-design:check-outlined" width={24}/>
                            }
                            {option.value !== currentLanguage.value &&
                                <Box sx={{width: 20, marginLeft: '16px'}}/>
                            }

                            <Typography sx={{...responsiveFontSizes({sm: 16, md: 16, lg: 18})}}>
                                {translate(`common.${option.languageCode}`)}
                            </Typography>
                        </MenuItem>
                    ))}
                </Stack>
            </MenuPopover>
        </>
    );
}
