// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const defaultSettings = {
    themeMode: 'light',
    themeDirection: 'rtl',
    themeContrast: 'default',
    themeLayout: 'mini',
    themeColorPresets: 'default',
    themeStretch: true,
};
