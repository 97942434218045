import { useEffect } from 'react';
import { Typography, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import { useLocales } from '../../../../locales';
import { regions, currencies, languages } from '../../../../assets/data';
import { StyledTextField } from '../../../../components/styled-inputs';
import { useFormYup } from '../../../../components/hook-form/useFormYup';

const SubscriptionBasics = () => {
    const { translate, currentLanguage } = useLocales();
    const { registerFieldValidations } = useFormYup();
    useEffect(() => {
        registerFieldValidations(['planName', 'planRegion', 'planCurrency', 'planBookLanguage']);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 15 }}>
            <Alert severity="info">
                <Typography gutterBottom variant="h5" sx={{ color: 'text.secondary' }}>
                    {translate('subscriptions.subscriptionBasics_title1')}
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {translate('subscriptions.subscriptionBasics_title2')}
                </Typography>
            </Alert>

            <StyledTextField
                name="planName"
                header={translate('subscriptions.planName_title')}
                fullWidth
                required
                label={translate('subscriptions.planName_label')}
            />

            <StyledTextField
                name="planRegion"
                header={translate('subscriptions.planRegion_title')}
                select
                required
                label={translate('subscriptions.planRegion_label')}
                placeholder={translate('subscriptions.planRegion_label')}
            >
                {regions[currentLanguage.value].map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                        {option.name}
                    </MenuItem>
                ))}
            </StyledTextField>

            <StyledTextField
                name="planCurrency"
                header={translate('subscriptions.planCurrency_title')}
                select
                required
                label={translate('subscriptions.planCurrency_label')}
                placeholder={translate('subscriptions.planCurrency_label')}
            >
                {currencies[currentLanguage.value].map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                        {option.name}
                    </MenuItem>
                ))}
            </StyledTextField>

            <StyledTextField
                name="planBookLanguage"
                header={translate('subscriptions.planBookLanguage_title')}
                select
                fullWidth
                label={translate('subscriptions.planBookLanguage_label')}
                placeholder={translate('subscriptions.planBookLanguage_label')}
            >
                {languages.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                        {option.name}
                    </MenuItem>
                ))}
            </StyledTextField>
        </Stack>
    );
};

export default SubscriptionBasics;
