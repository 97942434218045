import PropTypes from 'prop-types';
import StepperLayout from "../stepper-layout";
import {useFormYup} from "./useFormYup";


const RHFStepperLayout = ({children}) => {
    const {onRegisteredFieldsValidation, swrInProgress} = useFormYup();
    return (
        <StepperLayout validationTrigger={onRegisteredFieldsValidation} enableNext={swrInProgress}>
            {children}
        </StepperLayout>
    )
};
RHFStepperLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
export default RHFStepperLayout;