import PropTypes from 'prop-types';
import { withWidgetSwr } from '../components';
import { TransactionReportsUrlBuilder } from '../../../../../api/TransactionReportsApi';
import { SkeletonBarLoad } from '../../../../../components/skeleton';
import Chart, { useChart } from '../../../../../components/chart';
import { fNumeral, fNumber } from '../../../../../utils/formatNumber';
import useResponsive from '../../../../../hooks/useResponsive';
import NoTransactions from '../components/NoTransactions';
import { useLocales } from '../../../../../locales';

const MAX_DATA_POINTS = 6;
const RecurringSpendTrend = ({ data, isRTL }) => {
    const { t } = useLocales();
    const maxDataPoints = data.length > MAX_DATA_POINTS ? MAX_DATA_POINTS : data.length;
    const filteredData = data.slice(0, maxDataPoints);
    const chartData = [{ name: 'Amount', data: filteredData.map((item) => fNumeral(item.amount.asDouble)) }];
    const isDesktop = useResponsive('up', 'md');
    const chartOptions = useChart({
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: isDesktop ? '70px' : '50px',
                borderRadius: 0,
                distributed: true,
            },
        },
        stroke: { show: false },
        xaxis: {
            categories: filteredData.map((item) => (item.category.toLowerCase() === 'unknown categories' ? t('common.noCategory') : item.category)),
        },
        yaxis: {
            show: false,
            reversed: isRTL,
            opposite: isRTL,
        },
        tooltip: {
            style: {
                fontSize: isDesktop ? '16px' : '12px',
            },
            x: {
                show: false,
            },
            y: {
                title: {
                    formatter(value, { dataPointIndex, w }) {
                        return `&nbsp; ${w.globals.labels[dataPointIndex]}  `;
                    },
                },
                formatter(value) {
                    return fNumber(value);
                },
            },
        },
        legend: {
            markers: {
                offsetX: isRTL ? 5 : 0,
                width: 6,
                height: 6,
            },
        },
        grid: {
            show: false,
        },
    });

    if (data.length === 0) {
        return <NoTransactions />;
    }

    return <Chart type="bar" series={chartData} options={chartOptions} height={340} />;
};
RecurringSpendTrend.propTypes = {
    data: PropTypes.array.isRequired,
    isRTL: PropTypes.bool.isRequired,
};

export default withWidgetSwr(RecurringSpendTrend, TransactionReportsUrlBuilder.getRecurringSpend, <SkeletonBarLoad />);
