import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import {m} from "framer-motion";
import {varHover, varTranHover} from "../../../../../components/animate";
import Image from "../../../../../components/image";
import {useLocales} from "../../../../../locales";


const NoTransactions = () => {
    const {t} = useLocales();
    return (
        <Box sx={{display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'column'}}>
            <Typography variant="h4" sx={{color: 'text.secondary'}} gutterBottom>
                {t('widgets.expensesClassification_nodata_title')}
            </Typography>
            <Box component={m.div}>
                <m.div variants={varHover(1.1)} transition={varTranHover()}>
                    <Image src="assets/icons/wyzr/ic_nocontent.png" alt="" sx={{width: 300}}/>
                </m.div>
            </Box>
        </Box>
    )
}

export default NoTransactions;