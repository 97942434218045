import { useContext } from "react";
import { TransactionsPayeeContext } from "./TransactionsPayeeProvider";

const useTransactionsPayeeContext = () => {
    const context = useContext(TransactionsPayeeContext)
    if (context === undefined) {
        throw new Error('useTransactionsPayeeContext must be used within a TransactionsPayee Provider');
    }
    return context;
}

export default useTransactionsPayeeContext;