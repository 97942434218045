import PropTypes from 'prop-types';
import PayeesProvider from '../../../../../../contexts/Payees';
import DateRangeProvider from '../../../../../../contexts/DateRange';
import CategoriesProvider from '../../../../../../contexts/Categories';
import { ClassificationFilterProvider } from '../../../../components/ClassificationFilter';
import ReportDataProviderHoc from '../ReportDataProviderHoc';

const withReportView = (WrappedComponent) => {
    function HOC(props) {
        const { reportType } = props;
        if (!reportType) {
            throw new Error('report type not specified.');
        }
        return (
            <DateRangeProvider datesType="reports">
                <CategoriesProvider>
                    <PayeesProvider>
                        <ClassificationFilterProvider>
                            <ReportDataProviderHoc reportType={reportType}>
                                <WrappedComponent {...props} />
                            </ReportDataProviderHoc>
                        </ClassificationFilterProvider>
                    </PayeesProvider>
                </CategoriesProvider>
            </DateRangeProvider>
        );
    }

    HOC.propTypes = {
        reportType: PropTypes.string.isRequired,
    };

    return HOC;
};

export default withReportView;
