import axios from 'axios';
import { SiteConfig } from './ConfigApi';

const buildBasePath = (userId: string, currency: string, url: string): string => {
    const defaultQueryParam = `basecurrency=${currency}`;
    const rootUrl = `${SiteConfig.SubscriptionsWebApiUrl}/api/users/${userId}/subscriptions/${url}`;
    if (rootUrl.includes('?')) {
        return `${rootUrl}&${defaultQueryParam}`;
    }
    return `${rootUrl}?${defaultQueryParam}`;
};

const SubscriptionUrlBuilder = {
    getSettings: (): string => `${SiteConfig.SubscriptionsWebApiUrl}/api/settings`,
    getSubscriptions: (userId: string): string => `${SiteConfig.SubscriptionsWebApiUrl}/api/users/${userId}/subscriptions`,
    getSubscriptionAccounts: (userId: string, subscriptionId: string, currency: string): string => buildBasePath(userId, currency, `${subscriptionId}/accounts`),
    addUser: (): void => `${SiteConfig.SubscriptionsWebApiUrl}/api/users`,
};

export type Amount = {
    asInt: number,
    asDouble: number,
};
export type PreviousBalance = {
    periodKey: number,
    period: string,
    amount: Amount,
    currencyAmount: Amount,
};
export type SubscriptionAccount = {
    accountNumber: string,
    accountSortCode: ?string,
    alias: string,
    id: string,
    openingBalance: number,
    openingDate: string,
    accountType: string,
    accountClassType: string,
    currentBalance: number,
    currentBalanceCurrency: number,
    currency: string,
    previousBalances: Array<PreviousBalance>,
};
export type Subscription = {
    name: string,
    subscriptionId: string,
    bookId: string,
    currency: string,
    isDefault: boolean,
};
export type Settings = {
    maxSubscriptions: number,
    maxBankingAccounts: number,
    maxCreditAccounts: number,
    maxCashAccounts: number,
};
export type SubscriptionCreate = {
    bookName: string,
    planName: string,
    planRegion: string,
    planLanguage: string,
    currency: string,
    templateId: string,
};

class SubscriptionsApi {
    static getSettings(url: string): Settings {
        return axios.get(url).then((response) => response.data);
    }

    static getSubscriptions(url: string): Array<Subscription> {
        return axios.get(url).then((response) => response.data);
    }

    static addSubscription(userId: string, subscription: SubscriptionCreate): Subscription {
        const url = `${SiteConfig.SubscriptionsWebApiUrl}/api/users/${userId}/subscriptions`;
        return axios.post(url, subscription).then((response) => response.data);
    }

    static getSubscriptionAccounts(url: string): Array<SubscriptionAccount> {
        return axios.get(url).then((response) => response.data);
    }

    static addSubscriptionAccount(userId: string, subscriptionId: string, account: SubscriptionAccount) {
        const url = `${SiteConfig.SubscriptionsWebApiUrl}/api/users/${userId}/subscriptions/${subscriptionId}/accounts`;
        return axios.post(url, account).then((response) => response.data);
    }

    static addUser(url, body) {
        return axios
            .post(url, body.arg)
            .then(response => response.data);
    }
}

export default SubscriptionsApi;
export { SubscriptionUrlBuilder };
