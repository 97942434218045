import Grid from '@mui/material/Unstable_Grid2';
import {withSummaryWidget} from "../components";
import SpendList from "./SpendList";


const RecurringSpendWidget = () => (
    <Grid container sx={{flexGrow: 1, p: 0}}>
        <Grid xs={12}>
            <SpendList/>
        </Grid>
    </Grid>
);

export default withSummaryWidget(
    RecurringSpendWidget,
    'spend_upcoming_title',
    'spend_upcoming_subtitle',
    true
);