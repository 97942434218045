import PropTypes from "prop-types";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { Box, Typography } from '@mui/material';
import { useLocales } from "../../../../../locales";
import { StyledAutoComplete } from "../../../../../components/styled-inputs";
import useCategoriesContext from "../../../../../contexts/Categories/useCategoriesContext";
import { responsiveFontSizes } from "../../../../../utils/fontSizes";

const labels = [
    {
        color: '#7057ff',
    },
    {
        color: '#008672',
    },
    {
        color: '#b60205',
    },
    {
        color: '#d93f0b',
    },
    {
        color: '#0e8a16',
    },
    {
        color: '#fbca04',
    },
    {
        color: '#fec1c1',
    },
    {
        color: '#215cea',
    },
    {
        color: '#cfd3d7',
    },
    {
        color: '#fef2c0',
    },
    {
        color: '#eeeeee',
    },
    {
        color: '#d73a4a',
    },
    {
        color: '#d4c5f9',
    },
    {
        color: '#006b75',
    },
    {
        color: '#84b6eb',
    },
    {
        color: '#3e4b9e',
    },
    {
        color: '#fbca04',
    },
    {
        color: '#d876e3',
    },
];

const StyledInput = ({params, option, state}) => {
    const {label, color, primary} = option;
    return (
        <li {...params}>
            <Box
                component="span"
                sx={{
                    width: 8,
                    height: 8,
                    flexShrink: 0,
                    borderRadius: '3px',
                    mr: 1,
                    mt: '2px',
                }}
                style={{ backgroundColor: color }}
            />
            <Typography sx={{...responsiveFontSizes({sm: 12, md: 14, lg: 16})}}>
                {primary
                    ? <b>{label}</b>
                    : label
                }
            </Typography>
        </li>
    )
};
StyledInput.propTypes = {
    params: PropTypes.any,
    option: PropTypes.any,
    state: PropTypes.any
};

const CategorySelect = ({name}) => {
    const {translate} = useLocales();

    const {getVariantCategoriesByLevels} = useCategoriesContext();
    const categoryType = useWatch({
        name: "transactionCategoryType"
    });

    const options = useMemo(() => {
        let ind = 0;
        return getVariantCategoriesByLevels(categoryType)
            .map((item, index) => {
                if (item.primary) {
                    ind += 1;
                }
                if (ind >= labels.length) {
                    ind = 0;
                }
                return {
                    id: item.id,
                    label: item.name,
                    primary: item.primary,
                    color: labels[ind].color,
                }
            });
    }, [categoryType, getVariantCategoriesByLevels])

    const defaultInput = (params, option, state) => <StyledInput params={params} option={option} state={state} key={option.id}/>;

    return (
        <StyledAutoComplete
            name={name}
            header={translate('management.transaction.category_title')}
            label={translate('management.transaction.category_label')}
            options={options}

            dialogTitle={translate('management.payee.dialog_title')}
            dialogContent={translate('management.payee.dialog_content')}
            dialogLabel={translate('management.payee.dialog_label')}

            renderOption={defaultInput}
        />
    )
};
CategorySelect.propTypes = {
    name: PropTypes.string.isRequired
};

export default CategorySelect;