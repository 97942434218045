import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useLocales } from '../../../../../locales';
import { PATH_SUBSCRIPTION } from '../../../../../routes/paths';

const TabsBar = ({ defaultTabIndex }) => {
    const { t } = useLocales();
    const navigate = useNavigate();
    const handleChange = (event, newValue: number) => {
        if (defaultTabIndex !== 0 && newValue === 0) {
            navigate(PATH_SUBSCRIPTION.reports.all);
        }
        if (defaultTabIndex !== 1 && newValue === 1) {
            navigate(PATH_SUBSCRIPTION.reports.incomes);
        }
        if (defaultTabIndex !== 2 && newValue === 2) {
            navigate(PATH_SUBSCRIPTION.reports.netIncome);
        }
        if (defaultTabIndex !== 3 && newValue === 3) {
            navigate(PATH_SUBSCRIPTION.reports.netWorth);
        }
        if (defaultTabIndex !== 4 && newValue === 4) {
            navigate(PATH_SUBSCRIPTION.reports.savings);
        }
        if (defaultTabIndex !== 5 && newValue === 5) {
            navigate(PATH_SUBSCRIPTION.reports.monthlySummary);
        }
        if (defaultTabIndex !== 6 && newValue === 6) {
            navigate(PATH_SUBSCRIPTION.reports.refunds);
        }
    };
    return (
        <Box sx={{ width: '100%', mb: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={defaultTabIndex} onChange={handleChange}>
                    <Tab label={t('reports.spending')} id="tab_0" />
                    <Tab label={t('reports.income')} id="tab_1" />
                    <Tab label={t('reports.netIncome')} id="tab_2" />
                    <Tab label={t('reports.netWorth')} id="tab_3" />
                    <Tab label={t('reports.savings')} id="tab_4" />
                    <Tab label={t('reports.monthlySummary')} id="tab_5" />
                    <Tab label={t('reports.refundTracker')} id="tab_6" />
                </Tabs>
            </Box>
        </Box>
    );
};

TabsBar.propTypes = {
    defaultTabIndex: PropTypes.number.isRequired,
};

export default TabsBar;