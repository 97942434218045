const en = {
    common: {
        logout: 'Logout',
        coming_soon: 'coming soon',
        coming_soon_help: 'We are currently working hard on this page!',
        profile: 'Profile',
        help: 'Help',
        settings: 'Settings',
        notifications: 'Notifications',
        notifications_count_pre: 'You have',
        notifications_count_post: 'unread messages',
        notifications_view_all: 'Views All Notifications',
        english: 'English',
        arabic: 'Arabic',
        search: 'Search...',
        search_not_found: 'Not found',
        search_no_results: 'No results found for',
        search_tip1: 'Try checking for typos or using complete words.',
        search_tip2: 'Please enter keywords',
        page_500_title: '500 Internal Server Error',
        page_500_description: 'There was an error, please try again later.',
        page_404_title: 'Sorry, page not found!',
        page_404_description: 'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
        return_home: 'Go to Home',
        next: 'Next',
        previous: 'Back',
        info: 'Information',
        refersh: 'Refresh',
        sort_ascending: 'Sort ascending',
        sort_descending: 'Sort descending',
        toggle_filter: 'Toggle filter',
        toggle_stats: 'Toggle stats',
        from: 'from',
        to: 'to',
        remaining: 'remaining',
        showEverything: 'Show everything',
        everythingTo: 'Everything to',
        dates: 'Dates',
        clear_all: 'Clear all',
        no_category: 'No Category',
        no_payee: 'No Payee',
        add: 'Add',
        cancel: 'Cancel',
        today: 'Today',
        filters: 'Filters',
        reset: 'Reset',
        noCategory: 'no category set',
        noPayee: 'no payee set',
        noClassification: 'no tags set',
        resultsFound: 'results found',
    },
    homepage: {
        landingHero_Title: 'Welcome to the alpha release of',
        landingHero_Description: 'Scroll below to discover more of what to expect from this release.',
        landingHero_Login: 'Trial Login',
        landingHero_Register: 'Trial Register',
        features_title: 'Feature List',
        p1_title: 'Plans',
        p1_desc: 'Plans allow you to separate accounts, e.g. if you have personal or business accounts.',
        p1_feature_1: 'Two plans per user',
        p2_title: 'Accounts',
        p2_desc: 'Accounts are your banks and credit cards to loans and investments.',
        p2_feature_1: 'upto 2 Banking accounts',
        p2_feature_2: 'Upto 2 Credit accounts',
        p2_feature_3: 'manual Account creation',
        p3_title: 'Transactions',
        p3_desc: 'Transactions are your day to day spending and incomes.',
        p3_feature_1: 'manual Transaction entry',
        p3_feature_2: 'no limits on Transactions that can be added',
        p3_feature_3: 'supports Debits, Credits, and Transfers',
        p4_title: 'Bookkeeping',
        p4_desc: 'Bookkeeping keeps track of the money coming in and going out of your accounts.',
        p4_feature_1: 'Unlimited number of payees',
        p4_feature_2: 'Unlimited number of income categories',
        p4_feature_3: 'Unlimited number of expense categories',
        p5_title: 'Reports',
        p5_desc: 'Reports allow you to get insights into where your money is going.',
        p5_feature_1: 'Transactions by category',
        p5_feature_2: 'Transactions by payee',
        p5_feature_3: 'Compare Expenses',
        p5_feature_4: 'Compare Fixed/Variable Expenses',
        register_title: 'Manage your accounts more effectively',
        register_user_title: 'Get started absolutely free',
        register_user_existing: 'Already have an account?',
        register_user_existing_title: 'Sign in',
        register_user_firstName: 'First name',
        register_user_lastName: 'Last name',
        register_user_email: 'Email address',
        register_user_password: 'Password',
        register_user_create: 'Create account',
        register_success_email: 'Please check your email!',
        register_success_email_info: 'We have emailed you a confirmation link, please click the link to verify your email. You can sign in without verifying your email.',
        validations: {
            register_user_firstName: 'First name required',
            register_user_lastName: 'Last name required',
            register_user_email: 'Email is required',
            register_user_email_regex: 'Email must be a valid email address',
            register_user_password: 'Password is required',
        },
    },
    subscriptions: {
        addYourPlan_welcome: 'Welcome,',
        addYourPlan: 'Lets get started by adding your free plan',

        planName_title: 'Lets get to know your plan',
        planName_label: 'Plan name',
        planName_validation: 'name is required to identify the subscription in the apps',

        planRegion_title: 'Region',
        planRegion_label: 'What\u0027s your region ?',
        planRegion_validation: 'region is required to receive offers and promotions applicable in your region',

        planCurrency_title: 'What\u0027s your main currency ?',
        planCurrency_label: 'Currency',
        planCurrency_validation: 'currency is required so that multi currency accounts are converted to this currency',

        planBookLanguage_title: 'What language do you want to use for your accounting book ?',
        planBookLanguage_label: 'Book language',
        planBookLanguage_validation: '',

        subscriptionBasics_title1: 'What does the free plan mean?',
        subscriptionBasics_title2:
            'The main functionality is accessible for free for the trial. The number of accounts and transactions are limited per month, you can then pay to upgrade your experience by accessing additional features, or unlocking extra content, when ready.',

        subscriptionBook_title1: 'Select your accounting book',
        subscriptionBook_title2: 'Your accounting book defines your expenses and incomes',
        subscriptionBook_title3: 'Popular accounting books',

        subscriptionBookDetails_title1: 'Contents of your accounting book',
        subscriptionBookDetails_payees: 'Payees',
        subscriptionBookDetails_payees_none: 'This selected accounting book does not have any payees',
        subscriptionBookDetails_incomes: 'Incomes',
        subscriptionBookDetails_expenses: 'Expenses',

        subscription_step5_title1: 'Review your plan',
        subscription_step5_title2: 'Your plan name',
        subscription_step5_title3: 'Your region',
        subscription_step5_title4: 'Your accounting book template',
        subscription_step5_title5: 'Your accounting book language',
        subscription_step5_title6: 'Your plan currency',
        subscription_step5_validate_planBookName: 'You need to choose an empty accounting book or select one from our templates',
        subscription_step5_validate_planCurrencyName: 'We need to know the main currency of your plan',
        subscription_step5_validate_planName: 'We need to know the name of your plan',
        subscription_step5_validate_planRegionName: 'We need to know the region that your are in for accounting purposes',

        subscription_submit: 'Looks good, create my plan!',
    },
    management: {
        common: {
            submit_new: 'Add',
            submit_update: 'Update',
        },
        account: {
            name_validation: 'Account name is required',
            openingBalance_validation: 'Opening balance is required',
            openingDate_validation: '',
            code_validation: 'Account type is required. The types that are available are listed below:',
            currency_validation: 'Account currency is required',

            addYourAccount: 'Add a new Account',
            name_title: 'Account Name',
            name_label: 'We need to know the name of your account',
            description_title: 'Description',
            description_label: 'You can add a description for your account',
            openingDate_title: 'Account opening date',
            openingDate_label: '',
            currency_title: 'Account Currency',
            currency_label: 'Currency',
            openingBalance_title: 'Opening Balance',
            openingBalance_label: 'You can add an opening balance for your account',
            openingBalance_default: 'Your opening balance is 0',

            accountTypes_header: 'Choose an account type',
            accountTypes_title: 'Account Types',
            accountTypes_label: 'then choose an account',
            accountTypes_limited: 'You have reached the limits allowed and can no longer create from these account types',

            reviewAccount_header: 'Review your account',
            reviewAccount_title: 'Review other details',
            reviewAccount_submit: 'Looks good, create my account!',
            reviewAccount_noDescription: 'You have not added a description for this account',
            reviewAccount_accountType_title: 'Account type',

            proceedToNextStep: 'You can proceed to the next step!',
            assetInDebt: 'As this is an asset account, you are in arrears for',
            assetInCredit: 'As this is an asset account, you have',
            liabilityInDebt: 'As this is an liability account, You are owed',
            liabilityInCredit: 'As this is an liability account, you are in arrears for',
        },
        transaction: {
            date_validation: 'Date is required',
            amount_validation: 'Amount is required',
            transfer_from_validation: 'From account is required',
            transfer_to_validation: 'To account is required',
            transfer_from_currency_validation: 'From currency is required',
            transfer_from_exchange_validation: 'Exchange rate is required',
            transfer_to_currency_validation: 'To currency is required',

            date_title: 'Transaction date',
            date_label: '',
            amount_title: 'Transaction Amount',
            amount_label_expense: 'how much have you spent?',
            amount_label_income: 'how much is the income?',
            payee_title: 'Payees',
            payee_label: 'allocate a payee (optional)',
            category_title: 'Categories',
            category_label: 'allocate a category (optional)',

            category_type_title: 'Transaction type',
            category_type_expense: 'Expense',
            category_type_expense_info: 'an expense decreases your asset balance',
            category_type_income: 'Income',
            category_type_income_info: 'an income increases your asset balance',
            category_type_expense_liability: 'Charge',
            category_type_expense_liability_info: 'a charge increases how much you owe',
            category_type_income_liability: 'Payment',
            category_type_income_liability_info: 'a payment decreases how much you owe',

            category_type_info_liability: '',
            memo_title: 'Memo',
            memo_label: 'add a description (option)',
            submit_add_expense: 'Add Expense',
            submit_add_income: 'Add Income',
            submit_update_expense: 'Update Expense',
            submit_update_income: 'Update Income',
            submit_update_transfer: 'Update Transfer',
            submit_add_transfer: 'Add Transfer',

            transfer_amount_title: 'Transfer Amount',
            transfer_amount_label: 'how much are you transferring?',
            transferFrom_title: 'Transfer From',
            transferFrom_label: 'Choose the account you are transferring from',
            transferTo_title: 'Transfer To',
            transferTo_label: 'Choose the account you are transferring to',
            transfer_to_currency: 'Currency of the account you are transferring to',
            transfer_exchange_title: 'Exchange Rate',
            transfer_exchange_label: 'enter the exchange rate for this transfer',
            transfer_exchange_help: 'total amount transferred is: ',
        },
        payee: {
            dialog_title: 'Add a new payee',
            dialog_content: 'Is the payee name not in the list? Please add it.',
            dialog_label: 'Name',

            name_validation: 'The name of the payee is required and needs to be unique',
            name_header: 'Payee name',
            name_label: 'Any person, business, or companyy who receive money from you',
        },
        category: {
            name_validation: 'The name of the category is required',
            name_header: 'Category name',
            name_label: 'The categories into which transactions are classified',
            primary_title: 'Add to primary category if you want to add a subcategory',
            primary_label: 'Choose a primary category',
            cost_type_title: 'Choose a classification',
            cost_type_label: 'Provides characteristics to a category',
            cost_importance_title: 'Choose a tag',
            cost_importance_label: 'Provide user-defined tags to track transactions',
            cost_type_default: 'Not set',
            cost_type_fixed: 'Fixed',
            cost_type_variable: 'Variable',
            cost_importance_default: 'Not set',
            cost_importance_essential: 'Essential',
            cost_importance_non_essential: 'Non Essential',
        },
    },
    accounts: {
        emptyAccounts_title: 'You do not have any accounts set up yet!',
        emptyAccounts_note: 'Try adding some accounts and transactions to get insights into your spending.',
        emptyAccounts_description: 'Add more accounts if your plan allows it.',
        emptyAccounts_addNow: 'Add Now',
        balanceInCurrency: 'Balance in Currency',
        emptyTransactions: 'No transactions in last 6 months for this account.',
        emptyTransactionsAdd: 'Adding transactions will show your balance history',
        filter_all: 'All',
        subscription_limits: 'Limits',
        message_AccountsRemaining1: 'You can add',
        message_AccountsRemaining2: 'more accounts with your plan',
        message_AccountsLimitReached: 'Upgrade your plan to add more accounts',
        accountsStats_change: 'change since last month',
        accountsStats_totals: 'Total Balance for all accounts',
        action_add_new_title: 'New acount',
        action_add_new_tooltip: '',
        action_toggle_account_limits: 'Show account limits',
        action_toggle_account_summary: 'Show accounts summary',
    },
    navigation: {
        dashboard: 'Dashboards',
        accounts: 'Accounts',
        reports: 'Reports',
        bookkeeping: 'Bookkeeping',
        bookkeeping_expenses: 'Manage Expenses',
        bookkeeping_incomes: 'Manage Incomes',
        bookkeeping_payees: 'Manage Payees',
    },
    breadcrumbs: {
        dashboard: 'Welcome back',
        dashboard_link: 'Dashboards',
        overview_link: 'Summary',
        accounts: 'Your Accounts',
        accounts_link: '',
        reports: 'Reports',
        bookkeeping: 'Bookkeeping',
        bookkeeping_expenses: 'Manage Expenses',
        bookkeeping_expenses_info: 'Your book expenses',
        bookkeeping_incomes: 'Manage Incomes',
        bookkeeping_incomes_info: 'Your book incomes',
        bookkeeping_payees: 'Manage Payees',
        bookkeeping_payees_info: 'Your book payeee',
    },
    reports: {
        spending: 'Spending',
        income: 'Income',
        netIncome: 'Net Income',
        netWorth: 'Net Worth',
        savings: 'Savings',
        monthlySummary: 'Monthly Summary',
        refundTracker: 'Refund Tracker',

        classificationFilter: {
            breakdown: 'Breakdown',
            dates: 'Date Range',
        },
        transactions: {
            totalExpenses: 'Total Expenses',
            totalIncomes: 'Total Incomes',
            totalNetIncome: 'Total net income',
            table_head: {
                accountName: 'Account',
                date: 'Date',
                payee: 'Payee',
                categoryName: 'Category',
                tags: 'Tags',
                amount: 'Amount',
            },
            empty_chart_header: 'None yet for this month',
            empty_chart_label: 'try choosing a different date range',
            empty: 'No transactions',
            all_other_categories: 'All others',
            chart_legend_income: 'Income',
            chart_legend_expense: 'Expense',
            chart_legend_net_income: 'Net income',
        },
        netWorthView: {
            header_accounts: 'Accounts',
            header_netWorth: 'Net Worth',
        },
    },
    reporting: {
        netWorth: {
            other_assets: 'Other assets',
            other_debts: 'Other debts',
        },
    },
    widgets: {
        networth_title: 'Your net worth',
        networth_subtitle: '',
        networth_yaxis_tooltip: 'Your net worth',
        networth_total_title: 'Total',
        networth_asset_title: 'assets',
        networth_liability_title: 'liabilities',
        networth_help: 'Knowing your net worth helps assess the health of your financial situation and plan for the future',
        networth_nodata_title: 'There is not enough data to work out your net worth!',
        networth_trend_nodata_title: 'Try adding some transactions to get some insights',
        spent_title: 'Spent',
        spent_subtitle: '',
        spent_period_from: 'From',
        spent_period_to: 'Today',
        spent_average_title: '',
        spent_dailyAverage_title: 'Average Daily Spend',
        spent_dailyAverage_title_empty: 'We do not have enough data to analyse your average spending',
        spent_dailyAverageVariable_title: 'Average Variable Spend',
        spent_dailyAverageVariable_title_empty: 'We do not have enough data to analyse your average variable spending',
        expensesClassification_fixed_title: 'Fixed',
        expensesClassification_variable_title: 'Variable',
        expensesClassification_nodata_title: 'Try adding some transactions to get some insights',
        expensesClassification_fixed_variable_help: 'Shows the expenses split between essential and non essential categories',
        expensesClassification_fixed_variable_title: 'Fixed / Variable costs trend',
        expensesClassification_essential_title: 'Essential',
        expensesClassification_non_essential_title: 'Non Essential',
        expensesClassification_essential_non_essential_help: 'Shows the expenses split between essential and non essential categories',
        expensesClassification_essential_non_essential_title: 'Essential / Non Essential Trend',
        spent_recurring_title: 'Your Recurring Spend',
        spent_recurring_subtitle: '',
        expensesCategory_secondary_title: 'Top Spending Expenses',
        transactions_income_title: 'Income',
        transactions_expense_title: 'Spending',
        savings_goal_title: 'Savings Goals',
        savings_goal_subtitle: '',
        savings_goal_create_info: 'Save toward items you care about',
        savings_goal_create_title: 'Create Goal',
        spending_watchlist_title: 'Spending Watchlist',
        spending_watchlist_subtitle: '',
        spending_watchlist_create_info: 'Easily track your spending by creating a watch list',
        spending_watchlist_create_title: 'Create Watchlist',
        spend_upcoming_title: 'Upcoming',
        spend_upcoming_subtitle: '',
        spend_upcoming_no_spending: 'Woohoo! You dont have any upcoming transactions',
    },
    enums: {
        accountTypes: {
            banking: 'Banking',
            credit: 'Credit',
            cash: 'Cash',
            other: 'Other',
        },
    },
    currencies: {
        USD: 'US Dollar',
        GBP: 'British Pound',
        EUR: 'Euro',
        SYP: 'Syrian Pound',
        SAR: 'Saudi Riyal',
        JOD: 'Jordanian Dinar',
        AED: 'Emirati Dirham',
        TRY: 'Turkish Lira',
    },
    transactions: {
        emptyStateTxn1: 'No Transactions found for period',
        emptyStateTxn2: 'Try adding a transaction or change your filter settings.',
        emptyTransactions_addNow: 'Add Now',
        transferFrom: 'Transfer From',
        transferTo: 'Transfer To',
        labelStartingBalanceStats: 'Starting balance',
        labelStartingBalance: 'Starting balance for',
        labelEndingBalance: 'Ending balance for',
        labelReceived: 'received',
        labelReceived_liability: 'charged',
        labelSpentOn: 'spent on',
        labelSpentOn_liability: 'received',
        labelReceivedDay: 'received',
        labelSpentOnDay: 'spent on',
        timelineTotalSpend: 'Total balance in period',
        timelineTotalTransactionSpend: 'Net balance in period',
        timelineTotalTransfersInSpend: 'Transfers In',
        timelineTotalTransfersOutSpend: 'Transfers Out',
        timelineTotalTransfersSubHeader: 'Transaction Statistics',
        report_text_totalExpenses: 'Total Expenses',
        report_text_totalIncomes: 'Total Incomes',
        pending: 'pending transaction',
        toolbar: {
            new_standard: 'Add transaction',
            new_split: 'Add split transaction',
            new_transfer: 'New transfer',
            account_options: 'Account Options',
            show_monthly_totals: 'Show monthly totals',
            show_daily_totals: 'Show daily totals',

            show_current_month: 'Current month',
            show_previous_month: 'Previous month',
            show_previous_6_months: 'Previous 6 months',

            select_category_primary: 'Select primary categories',
            select_category_secondary: 'Select secondary categories',
            clear_category_primary: 'Clear primary categories',
            clear_category_secondary: 'Clear secondary categories',
        },
        assetInCredit: 'Your account is in credit',
        assetInDebt: 'Your account is in debt',
        liabilityInDebt: 'You are owed money',
        liabilityInCredit: 'Your owe money',
        viewMoreAnalytics: 'Views more analytics',
    },
    dates: {
        periodCurrentMonth: 'current Month',
        periodCurrentYear: 'current Year',
        periodPrv3Months: 'last 3 Months',
        periodPrv6Months: 'last 6 Months',
        periodPrvMonth: 'previous Month',
        periodPrvYear: 'previous Year',

        report_AllDates: 'All dates',
        report_AllToDates: 'All to date',
        report_CurrentFiscalYear: 'Current fiscal year',
        report_CurrentMonth: 'Current Month',
        report_CurrentYear: 'Current Year',
        report_CustomDates: 'Custom dates',
        report_FiscalYearToDate: 'Fiscal year to date',
        report_Last12Months: 'Last 12 months',
        report_Last30Days: 'Last 30 Days',
        report_Last3Months: 'Last 3 months',
        report_Last6Months: 'Last 6 months',
        report_MonthToDate: 'Month to date',
        report_Next12Months: 'Next 12 months',
        report_Next30Days: 'Next 30 Days',
        report_Next3Months: 'Next 3 months',
        report_Next6Months: 'Next 6 months',
        report_PreviousFiscalYear: 'Previous fiscal year',
        report_PreviousMonth: 'Previous month',
        report_PreviousYear: 'Previous year',
        report_YearToDate: 'Year to date',
    },
    filter: {
        report_Expenses_MenuButton: 'Expense Categories',
        report_Incomes_MenuButton: 'Income Categories',
        report_Payees_MenuButton: 'Payees',
        report_Accounts_MenuButton: 'Accounts',
        no_transactions: 'No transactions found with current filter',
        button_filter: 'Filter Report',
        filter_panel_selections: 'Your selections:',
        filter_panel_selections_categories: 'selected categories:',
        filter_panel_selections_payees: 'selected payees:',
        filter_panel_selections_accounts: 'selected accounts:',
    },
    bookkeeping: {
        filter_all: 'All',
        filter_primary: 'Primary',
        filter_secondary: 'Secondary',
        filter_fixed: 'Fixed',
        filter_variable: 'Variable',
        filter_search: 'Search all categories...',
        filter_search_payee: 'Search all payees...',
        categories: {
            table_head_name: 'Category Name',
            table_head_costType: 'Type',
            table_head_costImportance: 'Tags',
            table_head_count: 'Sub categeries count',
            table_head_active: 'Status',
            costType_Fixed: 'Fixed',
            costType_Variable: 'Variable',
            costImportance_Essential: 'Essential',
            costImportance_NonEssential: 'Non Essential',
            status_Active: 'Active',
            new_category: 'Add a new category',
        },
        payees: {
            table_head_name: 'Category Name',
            table_head_active: 'Status',
            new_payee: 'New',
        },
    },
    footer: {
        company: 'Wyzr Money',
        menu_home: 'Home',
        menu_about: 'About us',
        menu_contact: 'Contact us',
        menu_contact1: 'Contact',
        menu_faqs: 'Frequently Asked Questions',
        menu_privacy: 'Privacy Policy',
        menu_legal: 'Legal',
        menu_terms: 'Terms and Condition',
        footer_privacy:
            'When you visit or interact with our sites, services or tools, we or our authorised service providers may use cookies for storing information to help provide you with a better, faster and safer experience and for marketing purposes.',
    },
    product_guide: {
        continue: 'Ok, got it!',
        back: 'Back',
        intro: 'Introducing Wyzr MVP: Your First Glimpse into Simplicity and Innovation!',
        step_highlights_title: 'Key Highlights',
        step_highlights_desc: 'This is a pared-down version of Wyzr that only performs core functionality. Stay tuned for upcoming features and updates.',
        step_highlights_info1: 'We are determining whether our customers find the product design intuitive to use and is useful for your needs.',
        step_highlights_info2: 'So your feedback is very important to us.',
        step_expectation_title: 'What to expect from this release?',
        step_expectation_desc: 'Browse the features below to get an understanding of the functionality available:',


        feature0_title: 'General features',
        feature0_item1: '1 subscription per account',
        feature0_item2: 'Potentially slow performance',
        feature0_item3: 'Registration and login only',
        feature0_item4: 'Choose accounting structure from templates',

        feature1_title: 'Account transactions',
        feature1_item1: 'manual transaction entry',
        feature1_item2: '25 entries per month',
        feature1_item3: 'filter transactions by date, payee, and category',
        feature1_item4: 'basic statistics',
        feature1_item5: 'transfer between accounts',

        feature2_title: 'Accounts',
        feature2_item1: 'Credit, Debit, and Cash accounts only',
        feature2_item2: 'limited to 4 each',
        feature2_item3: 'basic statistics',
        feature2_item4: 'open accounts in various currencies',
        feature2_item5: 'manual exchange rate for transfers',
        feature2_item6: 'uses previous month exchange rates for amount conversions',
        feature2_item7: 'basic account settings',

        feature3_title: 'Reports',
        feature3_item1: 'filtering by accounts, period, category, payee, and tags',
        feature3_item2: 'spending report',
        feature3_item3: 'incomes report',
        feature3_item4: 'net income report',
        feature3_item5: 'net worth report',

        feature4_title: 'Payees',
        feature4_item1: 'Add and edit payees',

        feature5_title: 'Categories',
        feature5_item1: 'Add and edit incomes',
        feature5_item2: 'Add and edit expenses',
        feature5_item3: 'Add pre-defined tags only',

        get_started: 'Lets get started!',
    }
};

export default en;
