import {Helmet} from "react-helmet-async";
import NewAccount from "../../../views/management/accounts/NewAccount";

function Root() {
    return (
        <>
            <Helmet>
                <title> New Subscription | Wyzr</title>
            </Helmet>
            <NewAccount/>
        </>
    )
}

export default Root;