import PropTypes from "prop-types";
import {reverse} from 'lodash';
import {Stack, Typography} from "@mui/material";
import Chart, {useChart} from "../../../../../components/chart";
import {useLocales} from "../../../../../locales";
import {fDate2} from "../../../../../utils/formatTime";
import {fNumber} from "../../../../../utils/formatNumber";
import {sumArray} from "../../../../../utils/withMaths";
import useResponsive from "../../../../../hooks/useResponsive";

const ExpensesByClassification = ({
    data,
    isRTL,
    firstClassificationDataTitle,
    secondClassificationDataTitle,
    noData,
    title
}) => {
    const {languageCode, t} = useLocales();
    const firstClassificationData = isRTL
        ? data.firstClassification.map(item => item.amount.asDouble)
        : reverse(data.firstClassification.map(item => item.amount.asDouble));
    const secondClassificationData = isRTL
        ? data.secondClassification.map(item => item.amount.asDouble)
        : reverse(data.secondClassification.map(item => item.amount.asDouble));
    const categories = isRTL
        ? data.firstClassification.map(item => fDate2(item.period, 'MMM yy', languageCode))
        : reverse(data.firstClassification.map(item => fDate2(item.period, 'MMM yy', languageCode)));

    const chartData = [
        {name: t(firstClassificationDataTitle), data: firstClassificationData},
        {name: t(secondClassificationDataTitle), data: secondClassificationData}
    ];
    const sumFixed = sumArray(data.firstClassification, "amount");
    const sumVariable = sumArray(data.secondClassification, "amount");
    const isDesktop = useResponsive('up', 'md');
    const chartOptions = useChart({
        chart: {stacked: true, zoom: {enabled: true}},
        plotOptions: {
            bar: {
                columnWidth: isDesktop ? '64%' : '34%',
                borderRadius: 4
            }
        },
        stroke: {show: false},
        xaxis: {
            categories
        },
        yaxis: {
            opposite: isRTL,
            labels: {
                align: isRTL ? 'center' : 'right',
                formatter: value => fNumber(value)
            }
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            inverseOrder: true,
            style: {
                fontSize: isDesktop ? '16px' : '12px'
            },
            y: {
                title: {
                    formatter: value => `  ${value}  `
                },
                formatter: value => fNumber(value)
            },
        },
        legend: {
            markers: {
                offsetX: isRTL ? 5 : 0,
                width: 6,
                height: 6
            }
        }
    });

    if (sumFixed === 0 && sumVariable === 0) {
        return (
            <>
                {noData}
            </>
        )
    }

    return (
        <Stack spacing={2}>
            <Typography variant="h3" gutterBottom>
                {t(title)}
            </Typography>
            <Chart type="bar" series={chartData} options={chartOptions} height={370}/>
        </Stack>
    )
}
ExpensesByClassification.propTypes = {
    data: PropTypes.object.isRequired,
    isRTL: PropTypes.bool.isRequired,
    firstClassificationDataTitle: PropTypes.string.isRequired,
    secondClassificationDataTitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    noData: PropTypes.node,
};

export default ExpensesByClassification;