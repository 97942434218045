import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Container, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { MotionContainer } from '../../components/animate';
import { HEADER } from '../../config';
import { useLocales } from '../../locales';
import ProductFeatures from './ProductFeatures';
import LoginButton from '../../components/login/LoginButton';
import { PATH_HOME } from '../../routes/paths';

const steps = [
    {
        label: 'product_guide.step_highlights_title',
        description: 'product_guide.step_highlights_desc',
        infos: ['product_guide.step_highlights_info1', 'product_guide.step_highlights_info2'],
    },
    {
        label: 'product_guide.step_expectation_title',
        description: 'product_guide.step_expectation_desc',
        features: [
            {
                id: 'feature_0',
                title: 'product_guide.feature0_title',
                desc: 'product_guide.feature0_desc',
                items: ['product_guide.feature0_item1', 'product_guide.feature0_item2', 'product_guide.feature0_item3', 'product_guide.feature0_item4'],
            },
            {
                id: 'feature_1',
                title: 'product_guide.feature1_title',
                desc: 'product_guide.feature1_desc',
                items: ['product_guide.feature1_item1', 'product_guide.feature1_item2', 'product_guide.feature1_item3', 'product_guide.feature1_item4', 'product_guide.feature1_item5'],
            },
            {
                id: 'feature_2',
                title: 'product_guide.feature2_title',
                desc: 'product_guide.feature2_desc',
                items: ['product_guide.feature2_item1', 'product_guide.feature2_item2', 'product_guide.feature2_item3', 'product_guide.feature2_item4', 'product_guide.feature2_item5', 'product_guide.feature2_item6'],
            },
            {
                id: 'feature_3',
                title: 'product_guide.feature3_title',
                desc: 'product_guide.feature3_desc',
                items: ['product_guide.feature3_item1', 'product_guide.feature3_item2', 'product_guide.feature3_item3', 'product_guide.feature3_item4', 'product_guide.feature3_item5'],
            },
            {
                id: 'feature_4',
                title: 'product_guide.feature4_title',
                desc: 'product_guide.feature4_desc',
                items: ['product_guide.feature4_item1'],
            },
            {
                id: 'feature_5',
                title: 'product_guide.feature5_title',
                desc: 'product_guide.feature5_desc',
                items: ['product_guide.feature5_item1', 'product_guide.feature5_item2', 'product_guide.feature5_item3'],
            },
        ],
    },
    {
        label: 'product_guide.get_started',
        description: '',
    },
];

export default function ProductGuide() {
    const { t } = useLocales();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Container component={MotionContainer} sx={{ height: 1, mt: 10 }}>
            <Grid container spacing={10} sx={{ height: 1 }}>
                <Box
                    sx={{
                        height: 1,
                        width: 1,
                        mt: `${HEADER.H_MAIN_DESKTOP}px`,
                    }}
                >
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={step.label} sx={{ display: index === activeStep ? 'block' : 'none' }}>
                                <StepLabel optional={index === steps.length ? <Typography variant="caption">Last step</Typography> : null}>
                                    <Typography variant="h3" gutterBottom>
                                        {t(step.label)}
                                    </Typography>
                                </StepLabel>
                                <StepContent>
                                    <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
                                        {t(step.description)}
                                    </Typography>

                                    {step.infos &&
                                        step.infos.length > 0 &&
                                        step.infos.map((item, infoKey) => (
                                            <Box sx={{ mb: 4, mt: 4 }} key={infoKey}>
                                                <Typography variant="h4" gutterBottom color="primary.main">
                                                    {t(item)}
                                                </Typography>
                                            </Box>
                                        ))}

                                    {step.features && step.features.length > 0 && (
                                        <Box sx={{ mb: 4, mt: 4 }}>
                                            <ProductFeatures features={step.features} />
                                        </Box>
                                    )}

                                    <Box sx={{ mb: 2, mt: 2 }}>
                                        <div>
                                            <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1, display: index === steps.length - 1 ? 'none' : 'block' }}>
                                                {index === steps.length - 1 ? 'invalid' : t('product_guide.continue')}
                                            </Button>

                                            {index === steps.length - 1 && (
                                                <Stack alignItems="center" direction={{ xs: 'row' }} spacing={5} sx={{ mb: 4 }}>
                                                    <Button variant="contained" rel="noopener" onClick={() => navigate(PATH_HOME.register)}>
                                                        {t('homepage.landingHero_Register')}
                                                    </Button>
                                                    <LoginButton />
                                                </Stack>
                                            )}

                                            <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1, display: index === 0 ? 'none' : 'inherit' }}>
                                                {t('product_guide.back')}
                                            </Button>
                                        </div>
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>

                    {activeStep === steps.length && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>All steps completed - you&apos;re finished</Typography>
                            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                Reset
                            </Button>
                        </Paper>
                    )}
                </Box>
            </Grid>
        </Container>
    );
}
