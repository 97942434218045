import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import withPageSuspense from '../../components/async-pages/withPageSuspense';
import type { DateRangeContextState } from './types';
import { DateRange } from '../../api/FilterApi';
import { datesSelector } from '../../views/subscription/selectors';

const DateRangeContext = createContext();
const DateRangeProvider = ({ datesType, children }) => {
    const [selectedType, setSelectedType] = useState(datesType);
    const dates = useSelector((state) => datesSelector(state));
    useEffect(() => {
        setSelectedType(datesType);
    }, [datesType]);

    const getDates = useCallback((): Array<DateRange> => {
        switch (selectedType) {
            case 'reports':
                return dates.reportDates;
            case 'compare':
                return dates.compareDates;
            case 'reports-exclude-current-month':
                return dates.excludeCurrentMonthDates;
            default:
                return dates.transactionDates;
        }
    }, [selectedType, dates]);

    const context: DateRangeContextState = useMemo(
        () => ({
            defaultId: 'current-month',
            datesType: selectedType,
            reportDates: getDates(),
        }),
        [getDates, selectedType]
    );

    return <DateRangeContext.Provider value={context}>{children}</DateRangeContext.Provider>;
};
DateRangeProvider.propTypes = {
    children: PropTypes.node,
    datesType: PropTypes.oneOf(['default', 'reports', 'compare', 'reports-exclude-current-month']),
};

export default withPageSuspense(DateRangeProvider);
export { DateRangeContext };