import PropTypes from "prop-types";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {useLocales} from "../../../../../locales";
import NoTransactions from "../components/NoTransactions";

const NoData = ({
    help,
    title,
}) => {
    const {t} = useLocales();
    return (
        <Grid container spacing={1}>
            <Grid xs={12}>
                <Alert severity="info">
                    <AlertTitle>{t('common.info')}</AlertTitle>
                    {t(help)}
                </Alert>
            </Grid>
            <Grid xs={6} justifyContent="center" alignItems="center" display="inline-flex">
                <Typography variant="h2" gutterBottom>
                    {t(title)}
                </Typography>
            </Grid>
            <Grid xs={6}>
                <NoTransactions />
            </Grid>
        </Grid>
    )
}
NoData.propTypes = {
    help: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default NoData;