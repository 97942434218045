import Stack from '@mui/material/Stack';
import TableSkeleton from './TableSkeleton';
import usePayeesContext from '../../../../../contexts/Payees/usePayeesContext';
import { usePayeeFilterContext } from '../hooks/usePayeeFilterContext';
import type { Payee } from '../../../../../api/PayeesApi';
import PayeeList from './PayeeList';
import { useLocales } from '../../../../../locales';
import CanReset from './CanReset';

const filterData = (data: Array<Payee>, filter: string) : Array<Payee> => {
    if (filter === 'system_all_payee_names') {
        return data;
    }
    const startsWith =  filter[0];
    const endsWith = filter[filter.length - 1];
    return data.filter(r => r.name[0].toLowerCase() >= startsWith && r.name[0].toLowerCase() <= endsWith);
}
const filterDataByText = (data: Array<Payee>, filter: string): Array<Payee> => {
    if (filter.length < 2) {
        return data;
    }
    const searchString = filter.toLowerCase();
    return data.filter((r) => r.name.toLowerCase().includes(searchString));
};

const PayeeListHoc = () => {
    const { t } = useLocales();
    const { data, isMutating } = usePayeesContext();
    const { searchFilter, selectedPayeeLetter, onUpdateSearchFilter, onUpdateSelectedPayeeLetter } = usePayeeFilterContext();
    const canReset = !!searchFilter || !!(selectedPayeeLetter !== 'system_all_payee_names');

    if (isMutating) {
        return <TableSkeleton />;
    }

    const mappedData = filterData(data, selectedPayeeLetter);
    const mappedSearchData = filterDataByText(mappedData, searchFilter);

    const onResetFilters = () => {
        onUpdateSearchFilter('');
        onUpdateSelectedPayeeLetter('system_all_payee_names');
    };

    return (
        <Stack spacing={1.5}>
            <CanReset canReset={canReset} dataCount={mappedSearchData.length} onResetFilters={onResetFilters} />
            <PayeeList isMutating={isMutating} data={mappedSearchData} />
        </Stack>
    );

};

export default PayeeListHoc;