import PropTypes from 'prop-types';
import { Auth0Provider } from '@auth0/auth0-react';
import { AUTH0_API } from '../../config';
import { AuthMetadataProvider } from './AuthMetadataProvider';

const AuthProvider = ({router, children}) => {
    const onRedirectCallback = (appState) => {
        router.navigate((appState && appState.returnTo) || window.location.pathname);
    };
    return (
        <Auth0Provider
            onRedirectCallback={onRedirectCallback}
            domain={AUTH0_API.domain}
            clientId={AUTH0_API.clientId}
            authorizationParams={{
                redirect_uri: window.location.origin,
            }}
        >
            <AuthMetadataProvider>{children}</AuthMetadataProvider>
        </Auth0Provider>
    );
};

AuthProvider.propTypes = {
    router: PropTypes.any,
    children: PropTypes.node,
};

export default AuthProvider;
export {};