import PropTypes from "prop-types";
import {withWidgetSwr} from "../components";
import {TransactionReportsUrlBuilder} from "../../../../../api/TransactionReportsApi";
import AnalyticsSummary from "./AnalyticsSummary";
import {useLocales} from "../../../../../locales";
import NoData from "./NoData";

const SpendDailyVariableAverage = ({data}) => {
    const {t} = useLocales();

    if (data.length === 0) {
        return (
            <NoData title={t('widgets.spent_dailyAverageVariable_title_empty')} color="error"/>
        )
    }

    return (
        <AnalyticsSummary
            color="error"
            icon="ant-design:fund-outlined"
            title={t('widgets.spent_dailyAverageVariable_title')}
            total={data[0].amount.asDouble}/>
    )
}
SpendDailyVariableAverage.propTypes = {
    data: PropTypes.array.isRequired
};

export default withWidgetSwr(SpendDailyVariableAverage, TransactionReportsUrlBuilder.getDailyAverageVariableSpendingTrend);