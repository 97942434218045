import {useEffect} from "react";
import PropTypes from "prop-types";
import {useWatch} from "react-hook-form";
import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import {useFormYup} from "../../../../components/hook-form/useFormYup";

const SubscriptionBookTemplate = props => {
    const {id, name, description} = props;
    const {setValue} = useFormYup();
    const bookTemplateId = useWatch({
        name: 'planBookTemplate'
    });

    useEffect(() => {
        if (bookTemplateId === id) {
            setValue("planBookTemplateName", name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card
            key={id}
            variant="outlined"
            sx={{
                pb: 0,
                backgroundColor: theme => bookTemplateId === id ? theme.palette.warning.light : 'inherit'
            }}
        >
            <CardContent
                sx={{
                    pt: 0.25,
                    pb: 0,
                    pl: 0.1,
                    pr: 0.1,
                    cursor: 'pointer'
                }}
                onClick={() => {
                    setValue("planBookTemplate", id);
                    setValue("planBookTemplateName", name);
                }}>
                <CardHeader title={
                    <Typography variant="h5">
                        {name}
                    </Typography>}
                />
                <CardContent sx={{pt: 0, pb: 0}}>
                    <Typography variant="body1" color="textSecondary">
                        {description}
                    </Typography>
                </CardContent>
            </CardContent>
        </Card>
    )
}
SubscriptionBookTemplate.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string
}

export default SubscriptionBookTemplate;