import {combineReducers} from 'redux';
import storage from 'redux-persist/lib/storage';
import settingsReducer from './slices/settings';
import datesReducer from './slices/dates';
import plansReducer from './slices/plans';
import asyncPagesReducer from './slices/asyncPages';

// TODO is storage key unique per logged in user?
const STORAGE_KEY = '0_2_2_0';

const rootPersistConfig = {
    key: STORAGE_KEY,
    storage,
    keyPrefix: 'wyzr_',
    whitelist: process.env.REACT_APP_ENABLE_REDUX_DEV_STORE
        ? ['settings', 'dates', 'plans']
        : ['settings', 'dates']
};

const appReducer = combineReducers({
    settings: settingsReducer,
    plans: plansReducer,
    asyncPages: asyncPagesReducer,
    dates: datesReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'del/subscriptions/fulfilled') {
        storage.removeItem(STORAGE_KEY);
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
}

export {rootPersistConfig, rootReducer};