import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import DigitTypography from '../../../../../../components/typography/DigitTypography';
import { fCurrencyBy100 } from '../../../../../../utils/formatNumber';

const TimelineTypography = ({ title, balance, showBalance }) => (
    <Box
        alignItems="center"
        sx={{
            height: 1,
            display: 'inline-flex',
            gap: 1,
        }}
    >
        <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            {title}
        </Typography>
        {showBalance && (
            <DigitTypography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                {fCurrencyBy100(balance)}
            </DigitTypography>
        )}
    </Box>
);
TimelineTypography.propTypes = {
    title: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
    showBalance: PropTypes.bool.isRequired,
};

export default TimelineTypography;
