import * as types from './types';
import type { TransactionsContextState } from './types';
import * as states from '../../utils/dataStates';

const reducer = (state: TransactionsContextState, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.PRE_DATA: {
            return {
                ...state,
                refreshState: states.STATE_BUSY,
            };
        }
        case types.INIT_DATA: {
            const { startingBalance, endingBalance, entries, hasNoTransactions } = payload;
            return {
                ...state,
                refreshState: states.STATE_FULFILLED,
                data: {
                    startingBalance,
                    endingBalance,
                    entries,
                },
                hasNoTransactions,
            };
        }
        case types.INIT_ACCOUNT: {
            const { selectedAccountId, selectedAccountClassType } = payload;
            return {
                ...state,
                selectedAccountId,
                selectedAccountClassType,
            };
        }
        case types.REFRESH: {
            const { refresh } = !state.refresh;
            return {
                ...state,
                refresh,
            };
        }
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
