import { Skeleton, Stack } from '@mui/material';

const ChartSkeleton = () => {
    const barCount = 21;
    const barLengths = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (
        <Stack spacing={1} direction="row" alignItems="flex-end" justifyContent="space-around" sx={{ height: '300px', width: 1 }} my={8}>
            {[...Array(barCount)].map((_, index) => {
                const height = 20;
                return <Skeleton variant="rectangular" width={40} sx={{ height: `calc(100% - ${height * barLengths[index]}px)` }} key={index} />;
            })}
        </Stack>
    );
};

export default ChartSkeleton;