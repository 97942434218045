import PropTypes from 'prop-types';
// @mui
import {alpha, useTheme} from '@mui/material/styles';
import {Card, Stack, Typography} from '@mui/material';
// utils
import {bgGradient} from '../../../../../utils/cssStyles';
// components
import Image from "../../../../../components/image";

// ----------------------------------------------------------------------

NoData.propTypes = {
    sx: PropTypes.object,
    color: PropTypes.string,
    title: PropTypes.string.isRequired
};

export default function NoData({title, color = 'primary', sx, ...other}) {
    const theme = useTheme();
    return (
        <Card
            sx={{
                py: 8,
                px: 2,
                boxShadow: 0,
                display: 'flex',
                textAlign: 'center',
                color: theme.palette[color].darker,
                bgcolor: theme.palette[color].lighter,
                ...sx,
                height: 1
            }}
            {...other}
        >
            <Image
                src="assets/icons/wyzr/ic_nocontent.png"
                alt=""
                sx={{
                    position: 'absolute',
                    width: 160,
                    borderRadius: '55%',
                    p: 2,
                    color: theme.palette[color].dark,
                    ...bgGradient({
                        direction: '135deg',
                        startColor: `${alpha(theme.palette[color].dark, 0)} 0%`,
                        endColor: `${alpha(theme.palette[color].dark, 0.24)} 100%`,
                    }),
                }}
            />
            <Stack spacing={3}>
                <Typography variant="h6" sx={{opacity: 0.64}}>
                    {title}
                </Typography>
                <Typography variant="subtitle1"/>
            </Stack>
        </Card>
    );
}
