import axios from "axios";
import {SiteConfig} from "./ConfigApi";

const defaultCatalogId = "7670be5152074f19ba872f1159bfae40";

const TemplateStoreUrlBuilder = {
    getDefaultCatalogUrl: language => `${SiteConfig.TemplateStoreWebApiUrl}/api/catalogs/${defaultCatalogId}?language=${language}`,
    getBookUrl: (bookTemplateId, language) => `${SiteConfig.TemplateStoreWebApiUrl}/api/bookTemplates/${bookTemplateId}?language=${language}`,
    getAccountTypesUrl: language => `${SiteConfig.TemplateStoreWebApiUrl}/api/accountTypes?language=${language}`,
}

class TemplateStoreApi {

    static getAccountTypes(language) {
        const url = TemplateStoreUrlBuilder.getAccountTypesUrl(language);
        return axios
            .get(url)
            .then(response => response.data);
    }

    static getDefaultCatalog(language) {
        const url = TemplateStoreUrlBuilder.getDefaultCatalogUrl(language);
        return axios
            .get(url)
            .then(response => response.data);
    }

    static getBook(bookTemplateId, language) {
        const url = TemplateStoreUrlBuilder.getBookUrl(bookTemplateId, language);
        return axios
            .get(url)
            .then(response => response.data);
    }
}

export default TemplateStoreApi;
export {
    TemplateStoreUrlBuilder
};