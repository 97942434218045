import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
import { fDate2 } from '../../../../../../utils/formatTime';
import { fCurrency } from '../../../../../../utils/formatNumber';
import { useLocales } from '../../../../../../locales';
import AmountTypography from '../../../../../../components/typography/AmountTypography';

const TransactionTableRow = ({ row }) => {
    const { currentLanguage, t } = useLocales();
    // eslint-disable-next-line no-unused-vars
    const { date, accountName, amount, payee, categoryGroup, categoryName, memo, tags } = row;
    return (
        <TableRow hover>
            <TableCell>{accountName}</TableCell>
            <TableCell>
                <ListItemText
                    primary={fDate2(date, 'dd MMMM yyyy', currentLanguage.value)}
                    primaryTypographyProps={{ typography: 'body2', noWrap: true }}
                    secondaryTypographyProps={{
                        mt: 0.5,
                        component: 'span',
                        typography: 'caption',
                    }}
                />
            </TableCell>
            <TableCell>
                {payee.toLowerCase() === 'payee not set' ? t('common.noPayee') : payee}
            </TableCell>
            <TableCell>
                <ListItemText
                    primary={categoryGroup.toLowerCase() === 'category not set' ? t('common.noCategory') : categoryName}
                    secondary={categoryName.toLowerCase() === 'category not set' ? '' : categoryName}
                    primaryTypographyProps={{ typography: 'body2', noWrap: true }}
                    secondaryTypographyProps={{
                        mt: 0.5,
                        component: 'span',
                        typography: 'caption',
                    }}
                />
            </TableCell>
            <TableCell/>
            <TableCell>
                <AmountTypography amount={fCurrency(amount)} useInt={false}/>
            </TableCell>
        </TableRow>
    );
};
TransactionTableRow.propTypes = {
    row: PropTypes.object,
};

export default TransactionTableRow;