import * as types from './types';

const reducer = (state, action) => {
    const {type, payload} = action;
    switch (type) {
        case types.UPDATE_SELECTED_CATEGORIES:
        case types.UPDATE_SELECTED_CATEGORIES_BULK: {
            return {
                ...state,
                selectedIds: payload
            }
        }
        default:
            return {
                ...state
            }
    }
}

export default reducer;