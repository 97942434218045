import PayeesProvider from '../../../../../../contexts/Payees';
import DateRangeProvider from '../../../../../../contexts/DateRange';
import CategoriesProvider from '../../../../../../contexts/Categories';
import { ClassificationFilterProvider } from '../../../../components/ClassificationFilter';
import ReportDataProviderHoc from '../ReportDataProviderHoc';

const withReportView = (WrappedComponent) => {
    function HOC(props) {
        return (
            <DateRangeProvider datesType="reports">
                <CategoriesProvider>
                    <PayeesProvider>
                        <ClassificationFilterProvider>
                            <ReportDataProviderHoc>
                                <WrappedComponent {...props} />
                            </ReportDataProviderHoc>
                        </ClassificationFilterProvider>
                    </PayeesProvider>
                </CategoriesProvider>
            </DateRangeProvider>
        );
    }

    HOC.propTypes = {};

    return HOC;
};

export default withReportView;
