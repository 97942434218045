import { sortBy } from 'lodash';
import Grid from '@mui/material/Grid';
import { Stack, Typography } from '@mui/material';
import { useLocales } from '../../../../../locales';
import TableSkeleton from '../components/TableSkeleton';
import { fDate2 } from '../../../../../utils/formatTime';
import AmountTypography from '../../../../../components/typography/AmountTypography';
import GroupedItems from './components/GroupedItems';

type Props = {
    isMutating: boolean,
    data: Array<any>,
};

const groupNetWorth = (netWorthItems, end) =>
    netWorthItems.reduce((group, netWorthItem) => {
        const { accountTypeName, periods: assetPeriods } = netWorthItem;
        const mappedAssetPeriods = sortBy(assetPeriods, ['monthKey'], 'desc').slice(0, end);
        const findIndex = group.findIndex((r) => r.id === accountTypeName);
        if (findIndex === -1) {
            group.push({
                id: accountTypeName,
                items: [
                    {
                        ...netWorthItem,
                        periods: mappedAssetPeriods,
                    },
                ],
            });
        } else {
            const groupToAddTo = group[findIndex];
            groupToAddTo.items.push({
                ...netWorthItem,
                periods: mappedAssetPeriods,
            });
        }
        return group;
    }, []);

const TableView = ({ isMutating, data }: Props) => {
    const { t, currentLanguage } = useLocales();

    if (isMutating) {
        return <TableSkeleton />;
    }

    const { totalNetWorth, assets, debts } = data;
    const end = 11;
    const periods = sortBy(totalNetWorth, ['monthKey'], 'desc').slice(0, end);
    const groupedAssets = groupNetWorth(assets, end);
    const groupedDebts = groupNetWorth(debts, end);

    return (
        <Stack spacing={1}>
            {/* HEADER */}
            <Grid container spacing={1} sx={{ margin: 0 }}>
                <Grid item xs={1}>
                    <Typography variant="h5">{t('reports.netWorthView.header_accounts')}</Typography>
                </Grid>
                {periods.map((item, index) => (
                    <Grid item xs={1} key={index}>
                        <Typography variant="body1">
                            {fDate2(item.name, 'MMMM yyyy', currentLanguage.value)}
                        </Typography>
                    </Grid>
                ))}
            </Grid>

            {/* NETWORTH */}
            <Grid container spacing={1}>
                <Grid item xs={1}>
                    <Typography variant="h6">{t('reports.netWorthView.header_netWorth')}</Typography>
                </Grid>
                {periods.map((item, index) => (
                    <Grid item xs={1} key={index}>
                        <AmountTypography variant="h6" amount={item.total} useInt={false} />
                    </Grid>
                ))}
            </Grid>

            {/* Assets */}
            <GroupedItems groupedItems={groupedAssets} />

            {/* Debts */}
            <GroupedItems groupedItems={groupedDebts} />
        </Stack>
    );
};

export default TableView;