import { Typography } from '@mui/material';
import type { Amount } from '../../api/SubscriptionsApi';
import { fCurrency, fCurrencyBy100 } from '../../utils/formatNumber';

type Props = {
    amount: Amount | any,
    useInt: boolean,
};

const AmountTypography = ({ amount, useInt, ...props }: Props) => {
    const normalisedAmount = amount || { asInt: 0, asDouble: 0.0 };
    const { asInt, asDouble } = normalisedAmount;
    if (asInt === undefined || asDouble === undefined) {
        const value = useInt ? fCurrencyBy100(amount || 0) : fCurrency(amount || 0);
        return <Typography {...props}>{value}</Typography>;
    }
    const value = useInt ? fCurrencyBy100(asInt) : fCurrency(asDouble);
    return <Typography {...props}>{value}</Typography>;
};

export default AmountTypography;
