import PropTypes from "prop-types";
import withWidgetPrefetch from "./withWidgetPrefetch";
import {SkeletonSwr} from "../../../../../components/async-components";
import {SkeletonLoad} from "../../../../../components/skeleton";
import {useSettingsContext} from "../../../../../components/settings";
import {useLocales} from "../../../../../locales";

// TODO throw error if action is not a func
const withWidgetSwr = (WrappedComponent, action, skeleton) => {
    function HOC(props) {
        const {isRTL} = useSettingsContext();
        const {languageCode} = useLocales();
        const {userId, subscriptionId, currency} = props;
        const swrKey = action(userId, subscriptionId, currency);
        return (
            <SkeletonSwr
                dataKey="not_required"
                mode="single"
                swrKey={swrKey}
                skeleton={skeleton || <SkeletonLoad/>}
            >
                {({data}) => (
                    <WrappedComponent data={data} isRTL={isRTL} languageCode={languageCode} {...props}/>
                )}
            </SkeletonSwr>
        )
    }
    HOC.propTypes = {
        userId: PropTypes.string.isRequired,
        subscriptionId: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired
    };

    return withWidgetPrefetch(HOC);
};

export default withWidgetSwr;