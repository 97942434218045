import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback, useMemo } from 'react';

export type AccountsToolbarContextState = {
    showAlerts: boolean,
    showAccountsSummary: boolean,
    onToggleAlerts: () => void,
    onToggleSummary: () => void,
};

const initialState: AccountsToolbarContextState = {
    showAlerts: false,
    showAccountsSummary: true,
};

const handlers = {
    TOGGLE_ALERTS: (state, action) => {
        const { showAlerts } = action.payload;
        return { ...state, showAlerts };
    },
    TOGGLE_SUMMARY: (state, action) => {
        const { showAccountsSummary } = action.payload;
        return { ...state, showAccountsSummary };
    }
};
const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);
const AccountsToolbarContext = createContext(initialState);

const AccountsToolbarProvider = ({ children }) => {
    const [state, setState] = useReducer(reducer, initialState);

    const onToggleAlerts = useCallback(() => {
        const showAlerts = !state.showAlerts;
        setState({
            type: 'TOGGLE_ALERTS',
            payload: {
                showAlerts,
            },
        });
    }, [setState, state]);
    const onToggleSummary = useCallback(() => {
        const showAccountsSummary = !state.showAccountsSummary;
        setState({
            type: 'TOGGLE_SUMMARY',
            payload: {
                showAccountsSummary,
            },
        });
    }, [setState, state]);

    const memoizedValue: AccountsToolbarContextState = useMemo(
        () => ({
            ...state,
            onToggleAlerts,
            onToggleSummary,
        }),
        [state, onToggleAlerts, onToggleSummary]
    );

    return <AccountsToolbarContext.Provider value={memoizedValue}>{children}</AccountsToolbarContext.Provider>;
};
AccountsToolbarProvider.propTypes = {
    children: PropTypes.node,
};

export default AccountsToolbarProvider;
export { AccountsToolbarContext };
