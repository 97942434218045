import PropTypes from "prop-types";
import {withWidgetSwr} from "../components";
import {TransactionReportsUrlBuilder} from "../../../../../api/TransactionReportsApi";
import {SkeletonBarLoad} from "../../../../../components/skeleton";
import TransactionsSummary from "./TransactionsSummary";

const TransactionsByExpense = ({data, isRTL}) => (
    <TransactionsSummary data={data} title="widgets.transactions_expense_title" isRTL={isRTL} />
);
TransactionsByExpense.propTypes = {
    data: PropTypes.array.isRequired,
    isRTL: PropTypes.bool.isRequired,
};

export default withWidgetSwr(TransactionsByExpense, TransactionReportsUrlBuilder.getSpendingTrend, <SkeletonBarLoad />);
