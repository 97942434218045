import React from 'react';
import PropTypes from 'prop-types';
import { TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector, TimelineOppositeContent } from '@mui/lab';
import TodayIcon from '@mui/icons-material/Today';
import { useLocales } from '../../../../../../locales';
import { fDayKeyToDate } from '../../../../../../utils/formatTime';
import { useSettingsContext } from '../../../../../../components/settings';
import TimelineTypography from './TimelineTypography';
import useTransactionsContext from '../../../../../../contexts/Transactions/useTransactionsContext';

const TimelineBalanceEndDay = (props) => {
    const { dayKey, totalIncomes, totalExpenses } = props;
    const { currentLanguage, t } = useLocales();
    const { isRTL } = useSettingsContext();
    const { selectedAccountClassType } = useTransactionsContext();

    const date = fDayKeyToDate(dayKey, currentLanguage.value);
    const debitLabel = selectedAccountClassType === 'Asset' ? t('transactions.labelSpentOn') : t('transactions.labelReceived_liability');
    const debitBalance = selectedAccountClassType === 'Asset' ? totalExpenses : totalIncomes;

    const creditLabel = selectedAccountClassType === 'Asset' ? t('transactions.labelReceived') : t('transactions.labelSpentOn_liability');
    const creditBalance = selectedAccountClassType === 'Asset' ? totalIncomes : totalExpenses;

    const title1 = !isRTL ? `${debitLabel} ${date}` : `${creditLabel} ${date}`;
    const balance1 = !isRTL ? debitBalance : creditBalance;

    const title2 = !isRTL ? `${creditLabel} ${date}` : `${debitLabel} ${date}`;
    const balance2 = !isRTL ? creditBalance : debitBalance;

    return (
        <TimelineItem>
            <TimelineOppositeContent>
                {balance1 !== 0 &&
                    <TimelineTypography title={title1} balance={balance1} showBalance />
                }
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color="info">
                    <TodayIcon />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                {balance2 !== 0 &&
                    <TimelineTypography title={title2} balance={balance2} showBalance />
                }
            </TimelineContent>
        </TimelineItem>
    );
};
TimelineBalanceEndDay.propTypes = {
    dayKey: PropTypes.number,
    totalIncomes: PropTypes.number,
    totalExpenses: PropTypes.number,
};

export default TimelineBalanceEndDay;
