import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import {experimentalStyled as styled} from '@mui/material/styles';
import {Typography, Button, Box, Card, CardContent} from '@mui/material';
import {useLocales} from "../../../../../locales";
import {PATH_SUBSCRIPTION} from "../../../../../routes/paths";

const RootStyle = styled(Card)(({theme}) => ({
    boxShadow: 'none',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.lighter,
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    [theme.breakpoints.up('xl')]: {height: 320}
}));

EmptyAccounts.propTypes = {
    returnUrl: PropTypes.string.isRequired
};
export default function EmptyAccounts(props) {
    const {t} = useLocales();
    const {returnUrl} = props;
    return (
        <RootStyle>
            <CardContent
                sx={{
                    p: {md: 0},
                    pl: {md: 5}
                }}>
                <Typography gutterBottom variant="h3" sx={{color: 'grey.800'}}>
                    {t('accounts.emptyAccounts_title')}
                </Typography>

                <Typography gutterBottom variant="h4" sx={{color: 'grey.600', pb: {xs: 3, xl: 5}}}>
                    {t('accounts.emptyAccounts_note')}
                </Typography>

                <Typography variant="body2" sx={{color: 'grey.800', pb: {xs: 1, xl: 2}}}>
                    {t('accounts.emptyAccounts_description')}
                </Typography>

                <Button
                    variant="contained"
                    component={RouterLink}
                    to={PATH_SUBSCRIPTION.accounts.new}
                    state={{returnUrl}}
                >
                    {t('accounts.emptyAccounts_addNow')}
                </Button>
            </CardContent>

            <Box
                component="img"
                alt="welcome"
                src="/assets/illustrations/illustration_empty_content.svg"
                sx={{
                    p: 2,
                    height: 280,
                    margin: {xs: 'auto', md: 'inherit'}
                }}
            />
        </RootStyle>
    );
}