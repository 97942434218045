import Button from "@mui/material/Button";
import {Grid, Stack, Typography} from "@mui/material";
import {useLocales} from "../../../../locales";
import {useFormYup} from "../../../../components/hook-form/useFormYup";
import SubscriptionBookDetails from "./SubscriptionBookDetails";
import {regions, currencies, languages} from "../../../../assets/data";
import ReviewHero from "./ReviewHero";
import {MotivationIllustration} from '../../../../assets/illustrations';

const SubscriptionReview = () => {
    const {translate, currentLanguage} = useLocales();
    const {getValue} = useFormYup();
    const planName = getValue('planName');
    const planRegion = getValue('planRegion');
    const planCurrency = getValue('planCurrency');
    const planBookLanguage = getValue('planBookLanguage');
    const planBookTemplate = getValue('planBookTemplate');
    const planBookTemplateName = getValue('planBookTemplateName');

    const selectedLanguage = languages.findIndex(r => r.value === planBookLanguage) === -1 ? 'ar' : planBookLanguage;
    const planBookLanguageName = languages.find(r => r.value === selectedLanguage).name;
    const planRegionName = regions[currentLanguage.value].findIndex(r => r.value === planRegion) === -1
        ? undefined
        : regions[currentLanguage.value].find(r => r.value === planRegion).name;
    const planCurrencyName = currencies[currentLanguage.value].findIndex(r => r.value === planCurrency) === -1
        ? undefined
        : currencies[currentLanguage.value].find(r => r.value === planCurrency).name;

    return (
        <Stack spacing={4} sx={{pt: 3, px: 3, pb: 15}}>
            <Typography variant="h4">
                {translate('subscriptions.subscription_step5_title1')}
            </Typography>
            <ReviewHero
                details={[
                    {title: translate('subscriptions.subscription_step5_title2'), description: planName || translate('subscriptions.subscription_step5_validate_planName')},
                    {title: translate('subscriptions.subscription_step5_title3'), description: planRegionName || translate('subscriptions.subscription_step5_validate_planRegionName')},
                    {title: translate('subscriptions.subscription_step5_title6'), description: planCurrencyName || translate('subscriptions.subscription_step5_validate_planCurrencyName')},
                    {title: translate('subscriptions.subscription_step5_title5'), description: planBookLanguageName},
                ]}
                img={
                    <MotivationIllustration
                        sx={{
                            p: 3,
                            width: 360,
                            margin: { xs: 'auto', md: 'inherit' },
                        }}
                    />
                }
                action={
                    <Button variant="contained" size="large" type="submit" sx={{
                        m: 4,
                        right: 20,
                        position: 'absolute',
                        margin: { xs: 'auto', md: 'inherit' },
                    }}>
                        {translate('subscriptions.subscription_submit')}
                    </Button>
                }
            />
            <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}>

                <Grid item xs={12}>
                    <Typography paragraph variant="body1" sx={{whiteSpace: 'pre-line'}}>
                        {translate('subscriptions.subscription_step5_title4')}
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="h4" sx={{color: theme => planBookTemplateName ? 'inherit' : theme.palette.error.main, mb: {xs: 3, xl: 5}}}>
                        {planBookTemplateName || translate('subscriptions.subscription_step5_validate_planBookName')}
                    </Typography>
                </Grid>

                {planBookTemplate &&
                    <Grid item xs={12}>
                            <SubscriptionBookDetails/>
                        </Grid>
                }

            </Grid>
        </Stack>
    )
}


export default SubscriptionReview;