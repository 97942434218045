// TODO implement configure site
export const configureSite = async () => {
    // const configPromise = await fetch('/appsettings.json');
    // let json = await configPromise.json();
};

// TODO allow dynamic reloading
export const SiteConfig = {
    TxnWebApiUrl: process.env.REACT_APP_TxnWebApi_Url,
    TxnWriterUrl: process.env.REACT_APP_TxnWriter_Url,
    SubscriptionsWebApiUrl: process.env.REACT_APP_SubscriptionsWebApi_Url,
    TemplateStoreWebApiUrl: process.env.REACT_APP_TemplateStoreWebApi_Url
}