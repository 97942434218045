import {useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from "react-redux";
// @mui
import {alpha, styled} from '@mui/material/styles';
import {Box, Divider, Typography, MenuItem, Badge} from '@mui/material';
// guards
// import {useAuthContext} from '../../../hooks/useAuthContext';
// components
import {CustomAvatar} from '../../../components/custom-avatar';
import {useSnackbar} from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import {IconButtonAnimate} from '../../../components/animate';
import Iconify from "../../../components/iconify";
import {useLocales} from "../../../locales";
import {responsiveFontSizes} from "../../../utils/fontSizes";
import {PATH_SUBSCRIPTION} from "../../../routes/paths";
import { resetSubscriptionsAsync } from "../../../store/slices/plans/actions";

// ----------------------------------------------------------------------

const OPTIONS = [
    {
        languageCode: 'common.profile',
        badge: true,
        linkTo: PATH_SUBSCRIPTION.root,
        icon: 'ant-design:user-outlined',
    },
    {
        languageCode: 'common.help',
        badge: true,
        linkTo: PATH_SUBSCRIPTION.root,
        icon: 'ant-design:question-circle-outlined',
    },
    {
        languageCode: 'common.settings',
        badge: true,
        linkTo: PATH_SUBSCRIPTION.root,
        icon: 'ant-design:setting-outlined',
    },
];

// ----------------------------------------------------------------------

// ** Styled Components
const BadgeContentSpan = styled('span')(({theme}) => ({
    // width: 8,
    // height: 8,
    // borderRadius: '50%',
    // backgroundColor: theme.palette.success.main,
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    color: theme.palette.success.main,
}))

export default function AccountPopover() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // TODO get user and logout methods from useAuthContext
    const {user, logout} = useAuth0();
    const {enqueueSnackbar} = useSnackbar();
    const [openPopover, setOpenPopover] = useState(null);
    const {translate} = useLocales();

    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };
    const handleClosePopover = () => {
        setOpenPopover(null);
    };
    const handleLogout = async () => {
        try {
            dispatch(resetSubscriptionsAsync());
            await logout({returnTo: window.location.origin});
            handleClosePopover();
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Unable to logout!', {variant: 'error'});
        }
    };
    const handleClickItem = (path, badge) => {
        handleClosePopover();
        if (badge === false) {
            navigate(path);
        }
    };

    const styles = {
        py: 1.5,
        px: 2,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        color: 'text.primary',
        textDecoration: 'none',
        '& svg': {
            // fontSize: '1.375rem',
            color: 'text.secondary'
        }
    };

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpenPopover}
                sx={{
                    p: 0,
                    ...(openPopover && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <CustomAvatar src="" alt={user?.name} name={user?.name}/>
            </IconButtonAnimate>

            <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{width: 230, p: 0}}>
                <Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="subtitle2" noWrap sx={{fontFamily: "DM Sans"}}>
                        {user?.name}
                    </Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary', fontFamily: "DM Sans"}} noWrap>
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{borderStyle: 'dashed', mb: 2}}/>

                {OPTIONS.map((option, index) => {
                    const {badge, icon, languageCode} = option;
                    return (
                        <MenuItem
                            sx={{p: 0}}
                            key={index}
                            // to={option.linkTo}
                            onClick={() => handleClickItem(option.linkTo, badge)}
                        >
                            {badge &&
                                <Badge
                                    overlap="circular"
                                    badgeContent={
                                        <BadgeContentSpan>
                                            {translate('common.coming_soon')}
                                        </BadgeContentSpan>
                                    }
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                >
                                    <Box sx={styles}>
                                        <Iconify icon={icon}/>
                                        <Typography sx={{...responsiveFontSizes({sm: 16, md: 16, lg: 18})}}>
                                            {translate(languageCode)}
                                        </Typography>
                                    </Box>
                                </Badge>
                            }
                        </MenuItem>
                    )
                })}

                <Divider sx={{borderStyle: 'dashed'}}/>

                <MenuItem onClick={handleLogout} sx={{p: 0}}>
                    <Box sx={styles}>
                        <Iconify icon="ant-design:logout-outlined" sx={{mr: 2}} width={24}/>
                        <Typography sx={{...responsiveFontSizes({sm: 16, md: 16, lg: 18})}}>
                            {translate('common.logout')}
                        </Typography>
                    </Box>
                </MenuItem>
            </MenuPopover>
        </>
    );
}
