import Grid from '@mui/material/Unstable_Grid2';
import PropTypes from "prop-types";
import {withSummaryWidget} from "../components";
import ExpensesBySecondaryCategory from "./ExpensesBySecondaryCategory";

const ExpensesByCategoryWidget = ({type}) => (
    <Grid container sx={{p: 0, height: '100%'}}>
        <Grid xs={12}>
            {type === 'Secondary' &&
                <ExpensesBySecondaryCategory />
            }
        </Grid>
    </Grid>
);
ExpensesByCategoryWidget.propTypes = {
    type: PropTypes.oneOf(['Primary', 'Secondary'])
};

export default withSummaryWidget(
    ExpensesByCategoryWidget,
    'title_hidden',
    'sub_title_hidden',
    false
);