import PropTypes from 'prop-types';
import { reverse } from 'lodash';
import Chart, { useChart } from '../../../../../../components/chart';
import { useSettingsContext } from '../../../../../../components/settings';
import useResponsive from '../../../../../../hooks/useResponsive';
import { fNumber } from '../../../../../../utils/formatNumber';
import { useLocales } from '../../../../../../locales';
import { fDate2 } from '../../../../../../utils/formatTime';

const ChartByTotalBar = ({ chartView, chartType, data, selectedClassificationType }) => {
    const { languageCode, t } = useLocales();
    const { isRTL } = useSettingsContext();
    const isDesktop = useResponsive('up', 'md');
    const mappedData = data || [];

    const chartDataItems = !isRTL ? mappedData.map((item) => item.total.asDouble) : reverse(mappedData.map((item) => item.total.asDouble));
    const labels = mappedData.map((item) => {

        if (selectedClassificationType === 'BY_NON') {
            try {
                const mappedDate = fDate2(`01-${item.name}`, 'MMMM yyyy', languageCode);
                return mappedDate;
            } catch (err) {
                // TODO do nothing, this is not a date item
            }
        }

        if (item.name === 'all_other_categories') {
            return t('reports.transactions.all_other_categories');
        }
        if (item.name.toLowerCase() === 'category not set') {
            return t('common.noCategory');
        }
        if (item.name.toLowerCase() === 'payee not set') {
            return t('common.noPayee');
        }
        if (item.name.toLowerCase() === 'none') {
            return t('common.noClassification');
        }
        return item.name;
    });
    const categories = !isRTL ? labels : reverse(labels);

    const chartData = [
        {
            name: '',
            data: chartDataItems,
        },
    ];
    const chartOptions = useChart({
        xaxis: {
            categories,
            labels: {
                style: {
                    fontSize: isDesktop ? '16px' : '12px',
                },
            },
        },
        tooltip: {
            enabled: true,
            marker: { show: false },
            style: {
                fontSize: isDesktop ? '16px' : '12px',
            },
            y: {
                title: {
                    formatter: () => '',
                },
                formatter: (value) => `${fNumber(value)}`,
            },
        },
        grid: {
            show: false,
        },
        stroke: {
            width: 14,
        },
        fill: { gradient: { opacityFrom: 0.56, opacityTo: 0.76 } },
        plotOptions: {
            bar: {
                distributed: true,
            },
        },
        yaxis: {
            opposite: isRTL,
            labels: {
                show: false,
            },
        },
        legend: {
            show: true,
            fontSize: isDesktop ? '16px' : '12px',
            markers: {
                offsetX: isRTL ? 5 : 0,
                width: 8,
                height: 8,
            },
        },
    });

    if (chartView === 'by_total' && chartType === 'by_total_bar') {
        return <Chart type="bar" series={chartData} options={chartOptions} height={400} />;
    }
    return null;
};
ChartByTotalBar.propTypes = {
    chartView: PropTypes.string,
    chartType: PropTypes.string,
    selectedClassificationType: PropTypes.string,
    data: PropTypes.array,
};

export default ChartByTotalBar;