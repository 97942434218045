import PropTypes from 'prop-types';
import { reverse } from 'lodash';
import Chart, { useChart } from '../../../../../../components/chart';
import useResponsive from '../../../../../../hooks/useResponsive';
import { fNumber } from '../../../../../../utils/formatNumber';
import { useSettingsContext } from '../../../../../../components/settings';
import { useLocales } from '../../../../../../locales';
import { fDate2 } from '../../../../../../utils/formatTime';

const ChartByAll = ({ netIncomes, totalIncomes, totalExpenses }) => {
    const { t, languageCode } = useLocales();
    const { isRTL } = useSettingsContext();
    const isDesktop = useResponsive('up', 'md');
    const mappedLabels = netIncomes.map((r) => fDate2(`01 ${r.name}`, 'MMM yy', languageCode));
    const options = {
        annotations: {
            yaxis: [
                {
                    y: 0,
                },
            ],
        },
        chart: {
            stacked: true,
        },
        labels: !isRTL ? mappedLabels : reverse(mappedLabels),
        grid: {
            show: false,
        },
        tooltip: {
            marker: { show: false },
            style: {
                fontSize: isDesktop ? '16px' : '12px',
            },
            y: {
                title: {
                    formatter: (value) => `  ${value}  `,
                },
                formatter: (value) => fNumber(value),
            },
        },
        yaxis: {
            opposite: isRTL,
            labels: {
                show: true,
            },
        },
        legend: {
            show: true,
            fontSize: isDesktop ? '16px' : '12px',
            markers: {
                offsetX: isRTL ? 5 : 0,
                width: 8,
                height: 8,
            },
        },
    };
    const chartOptions = useChart(options);

    const mappedIncomes = totalIncomes.map((r) => r.total.asDouble);
    const mappedExpenses = totalExpenses.map((r) => r.total.asDouble);
    const mappedNets = netIncomes.map((r) => r.total.asDouble);
    const series = [
        {
            name: t('reports.transactions.chart_legend_income'),
            type: 'column',
            data: !isRTL ? mappedIncomes : reverse(mappedIncomes),
        },
        {
            name: t('reports.transactions.chart_legend_expense'),
            type: 'column',
            data: !isRTL ? mappedExpenses : reverse(mappedExpenses),
        },
        {
            name: t('reports.transactions.chart_legend_net_income'),
            type: 'line',
            data: !isRTL ? mappedNets : reverse(mappedNets),
        },
    ];
    return <Chart type="line" series={series} options={chartOptions} height={400} />;
};
ChartByAll.propTypes = {
    netIncomes: PropTypes.array,
    totalIncomes: PropTypes.array,
    totalExpenses: PropTypes.array,
};

export default ChartByAll;