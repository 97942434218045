import React from 'react';
import PropTypes from "prop-types";
import { Typography, Stack } from '@mui/material';
import { styled } from "@mui/material/styles";
import lightBlue from "@mui/material/colors/lightBlue";
import blue from "@mui/material/colors/blue";
import { fDate, fDate2 } from "../../../../../../utils/formatTime";
import { useLocales } from "../../../../../../locales";

const COLOR_MAP = {
    "01": {bg: lightBlue["50"], fg: color => color.black},
    "02": {bg: lightBlue["50"], fg: color => color.black},
    "03": {bg: lightBlue["50"], fg: color => color.black},
    "04": {bg: lightBlue["50"], fg: color => color.black},
    "05": {bg: lightBlue["50"], fg: color => color.black},
    "06": {bg: lightBlue["50"], fg: color => color.black},
    "07": {bg: lightBlue["50"], fg: color => color.black},
    "08": {bg: lightBlue["700"], fg: color => color.white},
    "09": {bg: lightBlue["700"], fg: color => color.white},
    "10": {bg: lightBlue["700"], fg: color => color.white},
    "11": {bg: lightBlue["700"], fg: color => color.white},
    "12": {bg: lightBlue["700"], fg: color => color.white},
    "13": {bg: lightBlue["700"], fg: color => color.white},
    "14": {bg: lightBlue["700"], fg: color => color.white},
    "15": {bg: blue["100"], fg: color => color.black},
    "16": {bg: blue["100"], fg: color => color.black},
    "17": {bg: blue["100"], fg: color => color.black},
    "18": {bg: blue["100"], fg: color => color.black},
    "19": {bg: blue["100"], fg: color => color.black},
    "20": {bg: blue["100"], fg: color => color.black},
    "21": {bg: blue["100"], fg: color => color.white},
    "22": {bg: blue["800"], fg: color => color.white},
    "23": {bg: blue["800"], fg: color => color.white},
    "24": {bg: blue["800"], fg: color => color.white},
    "25": {bg: blue["800"], fg: color => color.white},
    "26": {bg: blue["800"], fg: color => color.white},
    "27": {bg: blue["800"], fg: color => color.white},
    "28": {bg: blue["800"], fg: color => color.white},
    "29": {bg: blue.A700, fg: color => color.white},
    "30": {bg: blue.A700, fg: color => color.white},
    "31": {bg: blue.A700, fg: color => color.white},
};

const RootStyle = styled(Stack)(({theme, ownerState}) => ({
    backgroundColor: ownerState.pending ? theme.palette.warning.light : COLOR_MAP[ownerState.colorKey].bg,
    color: COLOR_MAP[ownerState.colorKey].fg(theme.palette.common),
    width: 105,
    height: '100%'
}));

const TransactionDate = ({date, pending}) => {
    const {currentLanguage} = useLocales();
    const colorKey = fDate(date, 'dd');
    const formatString1 = currentLanguage.value === 'ar' ? 'iiii' : 'iii';
    const formatString2 = currentLanguage.value === 'ar' ? 'dd MMMM' : 'dd MMM';
    return (
        <RootStyle
            direction="column"
            alignItems="center"
            ownerState={{colorKey, pending}}
            sx={{
                py: 1,
            }}
        >
            <Typography variant="h6" gutterBottom>
                {fDate2(date, formatString1, currentLanguage.value)}
            </Typography>
            <Typography variant="h6" gutterBottom>
                {fDate2(date, formatString2, currentLanguage.value)}
            </Typography>
            <Typography>
                {fDate(date, 'yyyy', currentLanguage.value)}
            </Typography>
        </RootStyle>
    )
};
TransactionDate.propTypes = {
    date: PropTypes.string,
    pending: PropTypes.bool
}


export default TransactionDate;
