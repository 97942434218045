import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import {responsiveFontSizes} from "../../utils/fontSizes";
import {RHFTextField} from "../hook-form";


const StyledTextField = ({header, name, ...props}) => (
    <Stack spacing={1} p={0}>
        <Typography variant="h6">
            {header}
        </Typography>

        <RHFTextField
            name={name}
            inputProps={{
                sx: {
                    ...responsiveFontSizes({sm: 16, md: 18, lg: 20}),
                    height: 50
                },
            }}
            SelectProps={{
                sx: {
                    height: 55
                }
            }}
            {...props}
        />
    </Stack>
)

StyledTextField.propTypes = {
    name: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(["standard", "filled", "outlined"])
};
StyledTextField.defaultProps = {
    variant: "filled"
};

export default StyledTextField;