import PropTypes from "prop-types";
import { useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Stack, Typography, Button, Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { addYears } from "date-fns";
import { responsiveFontSizes } from "../../utils/fontSizes";
import { useLocales } from "../../locales";

const StyledActionBar = (props) => {
    const {onSetToday, actions, className} = props;
    const {t} = useLocales();

    if (actions == null || actions.length === 0) {
        return null;
    }

    return (
        <Box className={className} sx={{p: 1}}>
            <Button onClick={() => onSetToday()}>
                {t('common.today')}
            </Button>
        </Box>
    )
};
StyledActionBar.propTypes = {
    /**
     * Ordered array of actions to display.
     * If empty, does not display that action bar.
     * @default `['cancel', 'accept']` for mobile and `[]` for desktop
     */
    actions: PropTypes.arrayOf(
        PropTypes.oneOf(['accept', 'cancel', 'clear', 'today']),
    ),
    className: PropTypes.string,
    // onAccept: PropTypes.func.isRequired,
    // onCancel: PropTypes.func.isRequired,
    // onClear: PropTypes.func.isRequired,
    onSetToday: PropTypes.func.isRequired,
};

const StyledPicker = ({header, name, label, variant, inputFormat, inputViews, inputActions, useMaxDate, ...props}) => {
    const {control} = useFormContext();
    const currentDate = useMaxDate ? addYears(new Date(), 2) : new Date();
    const defaultDate = useRef(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
    return (
        <Stack spacing={1} p={0}>
            <Typography variant="h6">
                {header}
            </Typography>

            <Controller
                name={name}
                control={control}
                render={({field, fieldState: {error}}) => (
                    <DatePicker
                        {...field}
                        views={inputViews}
                        maxDate={defaultDate.current}
                        inputFormat={inputFormat}
                        dayOfWeekFormatter={(day) => `${day}`}
                        slotProps={{
                            textField: {
                                variant,
                                inputProps: {
                                    sx: {
                                        ...responsiveFontSizes({sm: 16, md: 18, lg: 20}),
                                    }
                                },
                                SelectProps: {}
                            },
                            actionBar: {
                                actions: inputActions,
                            },
                        }}
                        label={label}
                        slots={{
                            actionBar: StyledActionBar
                        }}
                    />
                )}
            />
        </Stack>
    )
}

StyledPicker.propTypes = {
    name: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(["standard", "filled", "outlined"]),
    inputFormat: PropTypes.string,
    inputViews: PropTypes.array,
    inputActions: PropTypes.array,
    useMaxDate: PropTypes.bool
};
StyledPicker.defaultProps = {
    variant: "filled",
    inputFormat: "dd MMMM yyyy",
    inputViews: ['year', 'month', 'day'],
    inputActions: ['today'],
    useMaxDate: true
};

export default StyledPicker;