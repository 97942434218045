import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import withReportView from './hooks/withReportView';
import ClassificationToolbar from '../../../components/ClassificationFilter';
import useReportDataContext from '../components/useReportDataContext';
import ChartView from './ChartView';
import TableView from '../components/TableView';

const Overview = ({ reportType }) => {
    const { isMutating, data } = useReportDataContext();
    return (
        <Stack sx={{ width: '100%' }}>
            <ClassificationToolbar hideIncomes />
            <ChartView isMutating={isMutating} data={data} />
            <TableView isMutating={isMutating} data={data} />
        </Stack>
    );
};
Overview.propTypes = {
    reportType: PropTypes.string.isRequired,
};

export default withReportView(Overview);
