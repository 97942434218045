import PropTypes from 'prop-types';
// form
import {FormProvider as Form} from 'react-hook-form';
import LoadingScreen from "../loading-screen";

// ----------------------------------------------------------------------

FormProvider.propTypes = {
    children: PropTypes.node,
    methods: PropTypes.object,
    onSubmit: PropTypes.func,
    submitInProgress: PropTypes.bool,
};

export default function FormProvider({children, onSubmit, methods, submitInProgress}) {
    if (submitInProgress) {
        return (
            <LoadingScreen/>
        )
    }
    return (
        <Form {...methods}>
            <form onSubmit={onSubmit}>{children}</form>
        </Form>
    );
}
