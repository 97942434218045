import axios from 'axios';
import { SiteConfig } from './ConfigApi';

export type Payee = {
    id: string,
    name: string,
};

const PayeesUrlBuilder = {
    getPayees: (userId: string, subscriptionId: string, bookId: string): string => `${SiteConfig.SubscriptionsWebApiUrl}/api/users/${userId}/subscriptions/${subscriptionId}/books/${bookId}/payees`,
    addPayee: (userId: string, subscriptionId: string, bookId: string, payeeId: string): string => `${SiteConfig.SubscriptionsWebApiUrl}/api/users/${userId}/subscriptions/${subscriptionId}/books/${bookId}/payees`,
    updatePayee: (userId: string, subscriptionId: string, bookId: string, payeeId: string): string => `${SiteConfig.SubscriptionsWebApiUrl}/api/users/${userId}/subscriptions/${subscriptionId}/books/${bookId}/payees`,
};

class PayeesApi {
    static getPayees(url: string): Array<Payee> {
        return axios.get(url).then((response) => response.data);
    }

    static updatePayee(url, body) {
        // TODO SWR should be doing this
        const { id } = body.arg;
        url = `${url}/${id}`;
        return axios.put(url, body.arg).then((response) => response.data);
    }

    static addPayee(url, body) {
        return axios.post(url, body.arg).then((response) => response.data);
    }
}

export default PayeesApi;
export { PayeesUrlBuilder };
