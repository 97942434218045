function path(root, subLink) {
    return `${root}${subLink}`;
}

const ROOT_HOME = '/';
export const PATH_HOME = {
    root: ROOT_HOME,
    register: path(ROOT_HOME, 'register'),
    registerSuccess: path(ROOT_HOME, 'register-success'),
};

const ROOT_MANAGEMENT = '/subscriptions';
export const PATH_MANAGEMENT = {
    root: ROOT_MANAGEMENT,
    subscriptions: {
        new: path(ROOT_MANAGEMENT, '/management/new'),
    }
}

const ROOT_SUBSCRIPTION = '/subscription';
export const PATH_SUBSCRIPTION = {
    root: ROOT_SUBSCRIPTION,
    // overview: {
    //     summary: path(ROOT_SUBSCRIPTIONS, '/:subscriptionId'),
    //     dashboards: path(ROOT_SUBSCRIPTIONS, '/:subscriptionId/dashboards'),
    //     analytics: path(ROOT_SUBSCRIPTIONS, '/:subscriptionId/analytics'),
    // },
    accounts: {
        all: path(ROOT_SUBSCRIPTION, '/accounts'),
        new: path(ROOT_SUBSCRIPTION, '/accounts/new'),
    },
    transactions: {
        all: path(ROOT_SUBSCRIPTION, '/accounts/:accountId/transactions'),
    },
    bookkeeping: {
        payees: path(ROOT_SUBSCRIPTION, '/bookkeeping/payees'),
        expenses: path(ROOT_SUBSCRIPTION, '/bookkeeping/category/expenses'),
        incomes: path(ROOT_SUBSCRIPTION, '/bookkeeping/category/incomes'),
    },
    reports: {
        all: path(ROOT_SUBSCRIPTION, '/reports'),
        incomes: path(ROOT_SUBSCRIPTION, '/reports/incomes'),
        netIncome: path(ROOT_SUBSCRIPTION, '/reports/netIncome'),
        netWorth: path(ROOT_SUBSCRIPTION, '/reports/netWorth'),
        savings: path(ROOT_SUBSCRIPTION, '/reports/savings'),
        monthlySummary: path(ROOT_SUBSCRIPTION, '/reports/monthlySummary'),
        refunds: path(ROOT_SUBSCRIPTION, '/reports/refunds'),
    }
}