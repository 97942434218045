import { useContext } from "react";
import { DateRangeContext } from "./DateRangeProvider";
import type { DateRangeContextState } from "./types";

const useDateRangeContext = (): DateRangeContextState => {
    const context: DateRangeContextState = useContext(DateRangeContext);
    if (context === undefined) {
        throw new Error('useDateRangeContext must be used within a DateRange Provider')
    }
    return context;
};

export default useDateRangeContext;