import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
import Logo from '../../components/logo';
import { useLocales } from '../../locales';

const PATH_PAGE = {};

const RootStyle = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.default,
}));

export default function MainFooter() {
    const { t } = useLocales();
    const LINKS = [
        {
            headline: t('footer.company'),
            children: [
                { name: t('footer.menu_about'), href: PATH_PAGE.about },
                { name: t('footer.menu_contact'), href: PATH_PAGE.contact },
                { name: t('footer.menu_faqs'), href: PATH_PAGE.faqs },
            ],
        },
        {
            headline: t('footer.menu_legal'),
            children: [
                { name: t('footer.menu_terms'), href: '#' },
                { name: t('footer.menu_privacy'), href: '#' },
            ],
        },
        {
            headline: t('footer.menu_contact1'),
            children: [
                { name: t('footer.menu_contact'), href: '#' },
                { name: 'info@wyzr.money', href: '#' },
            ],
        },
    ];

    return (
        <RootStyle>
            <Divider />
            <Container sx={{ pt: 10 }}>
                <Grid container justifyContent={{ xs: 'center', md: 'space-between' }} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                    <Grid item xs={12} sx={{ mb: 3 }}>
                        <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
                    </Grid>
                    <Grid item xs={8} md={3}>
                        <Typography variant="body2" sx={{ pr: { md: 5 } }}>
                            {t('footer.footer_privacy')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={7}>
                        <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
                            {LINKS.map((list) => (
                                <Stack key={list.headline} spacing={2}>
                                    <Typography component="p" variant="overline">
                                        {list.headline}
                                    </Typography>
                                    {list.children.map((link) => (
                                        <Link to={link.href} key={link.name} color="inherit" variant="body2" component={RouterLink} sx={{ display: 'block' }}>
                                            {link.name}
                                        </Link>
                                    ))}
                                </Stack>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>

                <Typography
                    component="p"
                    variant="body2"
                    sx={{
                        mt: 10,
                        pb: 5,
                        fontSize: 13,
                        textAlign: { xs: 'center', md: 'left' },
                    }}
                >
                    © 2021. All rights reserved
                </Typography>
            </Container>
        </RootStyle>
    );
}
