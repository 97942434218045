import {useTranslation} from 'react-i18next';
// components
import {useSettingsContext} from '../components/settings';
//
import {allLanguages, defaultLanguage} from './config';

// ----------------------------------------------------------------------

type LocaleContext = {
    onChangeLanguage: () => void,
    translate: (text: string, options: string) => void,
    t: (text: string, options: string) => void,
    exists: (text: string, options: string) => void,
    currentLanguage: string,
    allLanguages: Array<string>,
    languageCode: string,
}

export default function useLocales() : LocaleContext {
    const {i18n, t: translate} = useTranslation();

    const {onChangeDirectionByLang} = useSettingsContext();

    const languageStorage = typeof window !== 'undefined' ? localStorage.getItem('i18nextLng') : '';

    const currentLanguage = allLanguages.find((_language) => _language.value === languageStorage) || defaultLanguage;

    const handleChangeLanguage = (newlang) => {
        i18n.changeLanguage(newlang);
        onChangeDirectionByLang(newlang);
    };

    return {
        onChangeLanguage: handleChangeLanguage,
        translate: (text, options) => translate(text, options),
        t: (text, options) => translate(text, options),
        exists: (key, options) => i18n.exists(key, options),
        currentLanguage,
        allLanguages,
        languageCode: currentLanguage.value
    };
}
