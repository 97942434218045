import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useMemo } from "react";
import useLocalStorage from "../../../../../hooks/useLocalStorage";
import * as types from './TransactionLayoutTypes';

const initialState = {
    layoutViews: [types.LAYOUT_VIEWS_MONTHLY_TOTALS],
    showFilter: false,
    showAsidebar: false,
    asidebarType: types.ASIDEBAR_NONE,
    asidebarPayload: undefined,
};

const TransactionsLayoutSettingsContext = createContext(initialState);
const useLayoutSettingsContext = () => {
    const context = useContext(TransactionsLayoutSettingsContext);
    if (!context) throw new Error('useLayoutSettingsContext must be use inside TransactionsLayoutSettingsContext');
    return context;
};

const TransactionsLayoutSettingsProvider = ({children}) => {
    const [settings, setSettings] = useLocalStorage('transactions_settings', initialState);

    // VIEWS
    const onToggleViewMode = useCallback((views) => {
        const layoutViews = [...views];
        setSettings({...settings, layoutViews});
    }, [setSettings, settings]);
    const onToggleFilter = useCallback(() => {
        const showFilter = !settings.showFilter;
        setSettings({...settings, showFilter});
    }, [setSettings, settings]);
    const onToggleAsidebar = useCallback((asidebarType, payload = undefined) => {
        const showAsidebar = !settings.showAsidebar;
        setSettings({...settings, showAsidebar, asidebarType, asidebarPayload: payload});
    }, [setSettings, settings]);

    const value = useMemo(() => ({
        ...settings,
        onToggleViewMode,
        onToggleFilter,
        showMonthlyTotals: settings.layoutViews.includes(types.LAYOUT_VIEWS_MONTHLY_TOTALS),
        showDailyTotals: settings.layoutViews.includes(types.LAYOUT_VIEWS_DAILY_TOTALS),
        showStats: settings.layoutViews.includes(types.LAYOUT_VIEWS_TOGGLE_STATS),
        onToggleAsidebar,
    }), [
        settings,
        onToggleViewMode,
        onToggleFilter,
        onToggleAsidebar,
    ]);

    return (
        <TransactionsLayoutSettingsContext.Provider value={value}>
            {children}
        </TransactionsLayoutSettingsContext.Provider>
    )
};
TransactionsLayoutSettingsProvider.propTypes = {
    children: PropTypes.node,
};

export default TransactionsLayoutSettingsProvider;
export {
    TransactionsLayoutSettingsContext,
    useLayoutSettingsContext
};