import SummaryWidget from "./SummaryWidget";
import {useLocales} from "../../../../../locales";


const withSummaryWidget = (WrappedComponent, title, subtitle, showHeader) => {
    function HOC(props) {
        const {t} = useLocales();
        const translatedTitle = t(`widgets.${title}`);
        const translatedSubTitle = t(`widgets.${subtitle}`);

        return (
            <SummaryWidget title={translatedTitle} subtitle={translatedSubTitle} showHeader={showHeader}>
                <WrappedComponent {...props}/>
            </SummaryWidget>
        )
    }
    return HOC;
};

export default withSummaryWidget;