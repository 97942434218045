import { useLocation } from 'react-router-dom';
import { Breadcrumbs } from '../../../components/custom-breadcrumbs';
import Overview from '../../../views/subscription/features/Reports/NetWorthReport';
import Tabs from '../../../views/subscription/features/Reports/components/Tabs';
import { Page } from '../../../components/page';
import { useSubscriptionsContext } from '../../../hooks/useSubscriptionsContext';

function Root() {
    const { pathname } = useLocation();
    const { subscriptionName } = useSubscriptionsContext();
    return (
        <Page title="Your Reports | Wyzr">
            <Breadcrumbs
                heading="breadcrumbs.reports"
                links={[
                    {
                        name: subscriptionName,
                    },
                    {
                        name: 'breadcrumbs.reports',
                    },
                    {
                        name: 'reports.netWorth',
                        href: pathname,
                    },
                ]}
            />
            <Tabs defaultTabIndex={3} />
            <Overview/>
        </Page>
    );
}

export default Root;