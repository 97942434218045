import PropTypes from 'prop-types';
import Chart, { useChart } from '../../../../../../components/chart';
import useResponsive from '../../../../../../hooks/useResponsive';
import { fNumber } from '../../../../../../utils/formatNumber';
import { useSettingsContext } from '../../../../../../components/settings';
import { useLocales } from '../../../../../../locales';
import { fDate2 } from '../../../../../../utils/formatTime';

const ChartByTotalPie = ({ chartView, chartType, data, selectedClassificationType}) => {
    const { languageCode, t } = useLocales();
    const { isRTL } = useSettingsContext();
    const isDesktop = useResponsive('up', 'md');
    const mappedData = data || [];

    const series = mappedData.map((item) => item.total.asDouble);
    const options = {
        labels: mappedData.map((item) => {

            if (selectedClassificationType === 'BY_NON') {
                try {
                    const mappedDate = fDate2(`01-${item.name}`, 'MMMM yyyy', languageCode);
                    return mappedDate;
                } catch (err) {
                    // TODO do nothing, this is not a date item
                }
            }

            if (item.name === 'all_other_categories') {
                return t('reports.transactions.all_other_categories');
            }
            if (item.name.toLowerCase() === 'category not set') {
                return t('common.noCategory');
            }
            if (item.name.toLowerCase() === 'payee not set') {
                return t('common.noPayee');
            }
            if (item.name.toLowerCase() === 'none') {
                return t('common.noClassification');
            }
            return item.name;
        }),
        legend: {
            show: true,
            position: isRTL ? 'right' : 'left',
            fontSize: isDesktop ? '16px' : '12px',
            markers: {
                offsetX: isRTL ? 5 : 0,
                width: 8,
                height: 8,
            },
        },
        tooltip: {
            enabled: true,
            style: {
                fontSize: isDesktop ? '16px' : '12px',
            },
            y: {
                title: {
                    formatter(value) {
                        return `  ${value}  `;
                    },
                },
                formatter(value) {
                    return fNumber(value);
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: false,
                    },
                },
            },
        },
    };
    const chartOptions = useChart(options);

    if (chartView === 'by_total' && chartType === 'by_total_pie') {
        return <Chart type="pie" series={series} options={chartOptions} height={400} />;
    }
    return null;
};
ChartByTotalPie.propTypes = {
    chartView: PropTypes.string,
    chartType: PropTypes.string,
    selectedClassificationType: PropTypes.string,
    data: PropTypes.any,
};

export default ChartByTotalPie;