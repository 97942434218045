import PropTypes from 'prop-types';

const StepperLayoutSelector = ({children, activeStep}) => (
    <>
        {Array.isArray(children) ?
            children[activeStep] :
            children
        }
    </>
)

StepperLayoutSelector.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    activeStep: PropTypes.number.isRequired
}

export default StepperLayoutSelector;