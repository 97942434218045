import PropTypes from "prop-types";
import { createContext, useReducer, useCallback } from "react";
import { Stack } from "@mui/material";

export type AccountsFilterContextState = {
    selectedAccountType: string,
    onUpdateSelectedAccountType: (selectedAccountType: string) => void,
}

const initialState : AccountsFilterContextState = {
    selectedAccountType: 'system_all_account_types',
    onUpdateSelectedAccountType: (selectedAccountType) => {},
};

const handlers = {
    INITIALIZE: (state, action) => {
        const {selectedAccountType} = action.payload;
        return {...state, selectedAccountType};
    },
};
const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);
const AccountsFilterContext = createContext(initialState);

const AccountsFilterProvider = ({children}) => {
    const [state, setState] = useReducer(reducer, initialState);

    const onUpdateSelectedAccountType = useCallback((selectedAccountType: string) => {
        setState({
            type: 'INITIALIZE',
            payload: {
                selectedAccountType
            }
        });
    }, []);

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const values = {
        ...state,
        onUpdateSelectedAccountType
    };

    return (
        <AccountsFilterContext.Provider value={values}>
            <Stack spacing={1}>
                {children}
            </Stack>
        </AccountsFilterContext.Provider>
    )
}
AccountsFilterProvider.propTypes = {
    children: PropTypes.node,
};

export default AccountsFilterProvider;
export {
    AccountsFilterContext
}