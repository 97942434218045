import * as types from './types';

const reducer = (state, action) => {
    const {type, payload} = action;
    switch (type) {
        case types.INIT_DATA: {
            return {
                ...state,
                ...payload,
            }
        }
        default:
            return {
                ...state
            }
    }
}

export default reducer;