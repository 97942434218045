import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { TableHeadCustom, TableNoData, useTable, getComparator } from '../../../../../components/table';
import PayeeTableRow from './PayeeTableRow';
import { useLocales } from '../../../../../locales';
import type { Payee } from '../../../../../api/PayeesApi';
import { applyFilter } from './utils';

type Props = {
    isMutating: boolean,
    data: Array<Payee>,
};

const TABLE_HEAD = [
    { id: 'avatar', label: '', alignRight: false, languageCode: '', type: 'string', order: 0 },
    { id: 'name', label: '', alignRight: false, languageCode: 'bookkeeping.payees.table_head_name', type: 'string', order: 1 },
    { id: 'active', label: '', alignRight: false, languageCode: 'bookkeeping.payees.table_head_active', type: 'string', order: 2 },
];

const PayeeList = ({ isMutating, data }: Props) => {
    const { t } = useLocales();
    const table = useTable({ defaultOrderBy: 'name' });

    const notFound = data.length === 0;

    const mappedData = applyFilter({
        inputData: data,
        comparator: getComparator(table.order, table.orderBy),
    });

    return (
        <TableContainer>
            <Table>
                <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                />
                <TableBody>
                    {(mappedData || []).map((row, index) => (
                        <PayeeTableRow row={row} key={index} />
                    ))}
                    <TableNoData notFound={notFound} title={t('reports.transactions.empty')} />
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PayeeList;