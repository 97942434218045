import PropTypes from "prop-types";
import { createContext, useCallback, useMemo, useReducer } from "react";
import reducer from "./reducer";
import * as types from "./types";

const initialState = {
    selectedIds: []
};

const TransactionsCategoryContext = createContext();
const TransactionsCategoryProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const handleCategoriesChange = useCallback((id) => {
        if (state.selectedIds.findIndex(r => r === id) !== -1) {
            const filteredItems = state.selectedIds.filter(item => item !== id)
            dispatch({type: types.UPDATE_SELECTED_CATEGORIES, payload: filteredItems});
        } else {
            const payload = [...state.selectedIds, id];
            dispatch({type: types.UPDATE_SELECTED_CATEGORIES, payload});
        }
    }, [state.selectedIds]);
    const handleCategoryRemove = useCallback((id) => {
        if (state.selectedIds.includes(id)) {
            const payload = state.selectedIds.filter(item => item !== id);
            dispatch({type: types.UPDATE_SELECTED_CATEGORIES, payload});
        }
    }, [state.selectedIds]);
    const onClearAll = useCallback(() => {
        if (state.selectedIds.length > 0) {
            dispatch({type: types.UPDATE_SELECTED_CATEGORIES, payload: []});
        }
    }, [state.selectedIds]);
    const onSelectCategoryIds = useCallback((ids) => {
        dispatch({type: types.UPDATE_SELECTED_CATEGORIES, payload: ids});
    }, []);
    const onClearCategoryIds = useCallback((ids) => {
        const newIds = state.selectedIds.reduce((acc, id) => {
            if (ids.includes(id)) {
                return acc;
            }
            acc.push(id);
            return acc;
        }, [])
        dispatch({type: types.UPDATE_SELECTED_CATEGORIES, payload: newIds});
    }, [state.selectedIds]);

    const value = useMemo(() => ({
        ...state,
        handleCategoriesChange,
        handleCategoryRemove,
        onClearAll,
        onSelectCategoryIds,
        onClearCategoryIds,
    }), [
        state,
        handleCategoriesChange,
        handleCategoryRemove,
        onClearAll,
        onSelectCategoryIds,
        onClearCategoryIds,
    ]);

    return (
        <TransactionsCategoryContext.Provider value={value}>
            {children}
        </TransactionsCategoryContext.Provider>
    )
};
TransactionsCategoryProvider.propTypes = {
    children: PropTypes.node,
};
export {
    TransactionsCategoryContext
};

export default TransactionsCategoryProvider;