import { createSelector } from "reselect";
import { STATE_BUSY } from "../../../utils/dataStates";

export const asyncStatusSelector = createSelector(
    state => state.asyncPages,
    (_, key) => key,
    (asyncPages, key) => getStatus(asyncPages, key)
);

const getStatus = (state, key) => {
    const status = state[key];
    return status || STATE_BUSY;
};