import { Box } from '@mui/material';
import { NetWorthReport } from '../../../../../api/TransactionReportsApi';
import ChartSkeleton from '../components/ChartSkeleton';
import ChartEmpty from '../components/ChartEmpty';
import ChartOptions from './components/ChartOptions';
import ChartByAll from './components/ChartByAll';

type Props = {
    isMutating: boolean,
    data: NetWorthReport,
};

const ChartView = ({ isMutating, data }: Props) => {
    if (isMutating) {
        return <ChartSkeleton />;
    }

    const { totalNetWorth } = data || {};
    if ((totalNetWorth || []).length === 0) {
        return (
            <Box mb={4}>
                <ChartOptions totalNetWorth={totalNetWorth}/>
                <ChartEmpty />
            </Box>
        )
    }

    return (
        <Box mb={4}>
            <ChartOptions totalNetWorth={totalNetWorth}/>
            <ChartByAll totalNetWorth={totalNetWorth}/>
        </Box>
    )
}

export default ChartView;