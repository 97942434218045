import PropTypes from "prop-types";
import axios from "axios";
import useSWR from "swr";
import {SkeletonLoad} from "../skeleton";

const fetcher = url => axios.get(url).then(res => res.data);
const SkeletonSwr = ({swrKey, dataKey, mode, children, swrOptions, skeleton}) => {
    const {data, error} = useSWR(swrKey, fetcher, swrOptions || {});
    if (error) return <div>failed to load</div>
    if (!data) return (
        <>
            {skeleton}
        </>
    )

    if (mode === 'multiple') {
        const dataItems = data ? data[dataKey] || [] : [];
        return (
            <>
                {dataItems.map((item, index) => (
                    children({...item, index})
                ))}
            </>
        )
    }

    // TODO handle spread as Array [...data] or Object {...data} or as is {data}
    return (
        <>
            {children({data})}
        </>
    )
}
SkeletonSwr.propTypes = {
    children: PropTypes.any,
    skeleton: PropTypes.node,
    swrKey: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(['single', 'multiple']).isRequired,
    swrOptions: PropTypes.object
};
SkeletonSwr.defaultProps = {
    skeleton: <SkeletonLoad/>
}

export default SkeletonSwr;