import PropTypes from "prop-types";
import {Box, Stack, Typography} from "@mui/material";
import {m} from "framer-motion";
import {withWidgetSwr} from "../components";
import {TransactionReportsUrlBuilder} from "../../../../../api/TransactionReportsApi";
import {SkeletonBarLoad} from "../../../../../components/skeleton";
import {varHover, varTranHover} from "../../../../../components/animate";
import Image from "../../../../../components/image";
import {useLocales} from "../../../../../locales";

const SpendList = () => {
    const {t} = useLocales();
    return (
        <Stack spacing={2} alignItems="center">
            <Box component={m.div} >
                <m.div variants={varHover(1.1)} transition={varTranHover()}>
                    <Image src="/assets/icons/wyzr/ic_woohoo.png" alt="" sx={{width: 170}}/>
                </m.div>
            </Box>

            <Typography variant="h6" sx={{color: 'text.secondary'}}>
                {t('widgets.spend_upcoming_no_spending')}
            </Typography>
        </Stack>
    )
}
SpendList.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    data: PropTypes.array.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    isRTL: PropTypes.bool.isRequired,
}

export default withWidgetSwr(SpendList, TransactionReportsUrlBuilder.getUpcomingSpend, <SkeletonBarLoad/>);