import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { withWidgetSwr } from '../components';
import { TransactionReportsUrlBuilder } from '../../../../../api/TransactionReportsApi';
import { SkeletonBarLoad } from '../../../../../components/skeleton';
import { useLocales } from '../../../../../locales';
import Chart, { useChart } from '../../../../../components/chart';
import useResponsive from '../../../../../hooks/useResponsive';
import { fNumber } from '../../../../../utils/formatNumber';
import NoTransactions from '../components/NoTransactions';

const ExpensesBySecondaryCategory = ({ data, isRTL }) => {
    const { t } = useLocales();
    const isDesktop = useResponsive('up', 'md');
    const chartData = data.map((item) => item.amount.asDouble);
    const options = {
        labels: data.map((item) => (item.category.toLowerCase() === 'unknown categories' ? t('common.noCategory') : item.category)),
        legend: {
            show: true,
            position: isRTL ? 'right' : 'left',
            markers: {
                offsetX: isRTL ? 5 : 0,
                width: 6,
                height: 6,
            },
        },
        tooltip: {
            enabled: true,
            style: {
                fontSize: isDesktop ? '16px' : '12px',
            },
            y: {
                title: {
                    formatter(value) {
                        return `  ${value}  `;
                    },
                },
                formatter(value) {
                    return fNumber(value);
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: false,
                    },
                },
            },
            polarArea: {
                rings: {
                    strokeWidth: 0,
                },
                spokes: {
                    strokeWidth: 0,
                },
            },
        },
        dataLabels: {
            enabled: true,
        },
        stroke: {
            width: 0,
        },
        yaxis: {
            show: false,
        },
    };
    const chartOptions = useChart(options);

    return (
        <Stack spacing={2}>
            <Typography variant="h3" gutterBottom>
                {t('widgets.expensesCategory_secondary_title')}
            </Typography>
            {data.length === 0 && <NoTransactions />}
            {data.length > 0 && <Chart type="polarArea" series={chartData} options={chartOptions} height={385} />}
        </Stack>
    );
};
ExpensesBySecondaryCategory.propTypes = {
    data: PropTypes.array.isRequired,
    isRTL: PropTypes.bool.isRequired,
};

export default withWidgetSwr(ExpensesBySecondaryCategory, TransactionReportsUrlBuilder.getExpensesSecondaryTrend, <SkeletonBarLoad />);
