export const mapData = (data) => {
    const reduce = data.reduce((map, item) => {
        const { isPrimary, primaryName, id, costImportance, costType } = item;
        const map_id = `${primaryName}_${item.type}`;
        if (isPrimary) {
            const init = {
                name: primaryName,
                label: primaryName,
                id,
                categories: [],
                type: item.type,
                costImportance,
                costType,
                count: data.filter(r => r.isPrimary === false && r.primaryName === primaryName).length,
            };

            map.set(map_id, init);
        } else {
            const existing = map.get(map_id);
            existing.categories.push({
                id,
                name: item.secondaryName,
                costImportance,
                costType,
            });
        }
        return map;
    }, new Map());

    return [...reduce.values()];
};

export const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);
export const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);
export const union = (a, b) => [...a, ...not(b, a)];
export const reduceIds = (categories) =>
    categories.reduce((acc, r) => {
        acc.push(r.id);
        return acc;
    }, []);
export const reduceSubCategories = (primaryCategoryName, categories) =>
    categories.reduce((acc, r) => {
        const { costImportance, costType } = r;
        acc.push({
            id: r.id,
            name: `${primaryCategoryName}${': '} ${r.name}`,
            primaryName: primaryCategoryName,
            secondaryName: r.name,
            primary: false,
            costImportance,
            costType,
        });
        return acc;
    }, []);
export const reduceIdsAndPrimaryName = (primaryCategoryName, categories) =>
    categories.reduce((acc, r) => {
        const { costImportance, costType } = r;
        acc.push({
            id: r.id,
            name: `${primaryCategoryName}${': '} ${r.name}`,
            primary: false,
            costImportance,
            costType,
        });
        return acc;
    }, []);