import { useSelector } from 'react-redux';
import { Box, Drawer } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { subscriptionAccountsLimitSelector } from "../../../selectors";
import { useSubscriptionsContext } from "../../../../../hooks/useSubscriptionsContext";
import { useLocales } from "../../../../../locales";
import { useAccountsToolbarContext } from "../hooks/useAccountsToolbarContext";

const AccountsLimit = () => {
    const {t} = useLocales();
    const {defaultSubscriptionId} = useSubscriptionsContext();
    const {showAlerts} = useAccountsToolbarContext();
    const [...limits] = useSelector(state => subscriptionAccountsLimitSelector(state, defaultSubscriptionId, 'system_all_account_types'));

    return (
        <Drawer
            open={showAlerts}
            variant="permanent"
            PaperProps={{
                sx: {
                    width: 1,
                    position: 'relative',
                    border: 0,
                },
            }}
        >
            {showAlerts && limits && limits.map((limit, index) => {
                const {accountsLimited, remaining, accountType} = limit;
                return (
                    <Box key={index} p={1}>
                        {!accountsLimited &&
                            <Alert severity="info">
                                <AlertTitle>{t('accounts.subscription_limits')}</AlertTitle>
                                <strong>{t(`enums.accountTypes.${accountType}`)}</strong> - {t('accounts.message_AccountsRemaining1')} {remaining} {t('accounts.message_AccountsRemaining2')}
                            </Alert>
                        }
                        {accountsLimited &&
                            <Alert severity="warning">
                                <AlertTitle>{t('accounts.subscription_limits')}</AlertTitle>
                                <strong>{t(`enums.accountTypes.${accountType}`)}</strong> - {t('accounts.message_AccountsLimitReached')}
                            </Alert>
                        }
                    </Box>
                )
            })}
        </Drawer>
    )
};
AccountsLimit.propTypes = {};

export default AccountsLimit;