import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Stack } from '@mui/material';
import { parseISO } from 'date-fns';
import { useLocales } from '../../../../../../locales';
import { FormYupProvider } from '../../../../../../components/hook-form';
import { StyledAutoComplete, StyledPicker, StyledTextField } from '../../../../../../components/styled-inputs';
import { useAccountsContext } from '../../../../../../hooks/useAccountsContext';
import { useLayoutSettingsContext } from '../../components/TransactionLayoutSettings';
import TransferFormSubmit from '../../components/TransferFormSubmit';
import useTransactionsContext from '../../../../../../contexts/Transactions/useTransactionsContext';
import * as asidebarTypes from '../../components/TransactionLayoutTypes';
import TransferAmount from '../../components/TransferAmount';
import TransferCurrency from '../../components/TransferCurrency';

const TransferForm = ({ transfer }) => {
    const { accountId } = useParams();
    const { translate } = useLocales();
    const { accounts: accountData } = useAccountsContext();
    const { onToggleAsidebar } = useLayoutSettingsContext();
    const { onCreateTransfer, onUpdateTransfer } = useTransactionsContext();

    const mapper = (item) => ({
        id: item.id,
        label: `${item.alias} (${item.currency})`,
    });

    const fromAccounts = accountData.map(mapper);
    let toAccounts = accountData.filter((r) => r.id !== accountId).map(mapper);
    if (transfer.transactionId && transfer?.transferFrom !== accountId) {
        toAccounts = accountData.filter((r) => r.id !== transfer?.transferFrom).map(mapper);
    }

    const sourceAccount = accountData.find((r) => r.id === accountId);

    const defaultDate = useRef(new Date());
    const defaultValues = {
        transactionId: transfer.transactionId || '',
        transactionDate: parseISO(transfer.transactionDate || defaultDate.current.toISOString()),
        transactionMemo: transfer.transactionMemo || '',

        transactionAmount: transfer?.transferFrom === accountId ? transfer?.transactionAmount || '' : transfer?.transferFromAmount || '',

        transferFrom: transfer.transferFrom || accountId,
        transferFromCurrency: transfer.transferFrom ? accountData.find((r) => r.id === transfer.transferFrom).currency : sourceAccount.currency,
        transferFromExchangeRate: transfer?.transferFromExchangeRate || '1.0',

        transferTo: transfer.transferTo || '',
        transferToCurrency: transfer.transferTo ? accountData.find((r) => r.id === transfer.transferTo).currency : '',
    };

    const Schema = Yup.object().shape({
        transactionId: Yup.string(),
        transactionDate: Yup.date().required(translate('management.transaction.date_validation')),
        transactionAmount: Yup.string().required(translate('management.transaction.amount_validation')),
        transferFrom: Yup.string().required(translate('management.transaction.transfer_from_validation')),
        transferTo: Yup.string().required(translate('management.transaction.transfer_to_validation')),
        transferFromCurrency: Yup.string().required(translate('management.transaction.transfer_from_currency_validation')),
        transferFromExchangeRate: Yup.string().required(translate('management.transaction.transfer_from_exchange_validation')),
        transferToCurrency: Yup.string().required(translate('management.transaction.transfer_to_currency_validation')),
        transactionMemo: Yup.string(),
    });

    const handleSubmit = async (data) => {
        const {
            transactionDate,
            transactionAmount,
            transferFrom,
            transferTo,
            transactionMemo,
            transferFromCurrency,
            transferFromExchangeRate,
            transferToCurrency,
        } = data;
        const fromAccount = accountData.find((r) => r.id === transferFrom);
        const toAccount = accountData.find((r) => r.id === transferTo);

        if (transfer.transactionId !== undefined) {
            const previousFromAccount = accountData.find((r) => r.id === defaultValues.transferFrom);
            const previousToAccount = accountData.find((r) => r.id === defaultValues.transferTo);

            await onUpdateTransfer({
                transactionId: defaultValues.transactionId,

                transactionAmount,
                transferFrom,
                transferFromCurrency,
                transferFromExchangeRate,
                fromAccountClassType: fromAccount.accountClassType,

                transferToCurrency,
                transferTo,
                toAccountClassType: toAccount.accountClassType,

                transactionDate,
                transactionMemo,

                previousTransactionAmount: defaultValues.transactionAmount,
                previousTransactionDate: defaultValues.transactionDate,
                previousTransferFrom: defaultValues.transferFrom,
                previousFromCurrency: previousFromAccount.currency,
                previousFromExchangeRate: defaultValues.transferFromExchangeRate,
                previousTransferTo: defaultValues.transferTo,
                previousToCurrency: previousToAccount.currency,
            });
        }
        if (transfer.transactionId === undefined) {
            await onCreateTransfer({
                transactionAmount,
                transferFrom,
                transferFromCurrency,
                transferFromExchangeRate,
                fromAccountClassType: fromAccount.accountClassType,

                transferToCurrency,
                transferTo,
                toAccountClassType: toAccount.accountClassType,

                transactionDate,
                transactionMemo,
            });
        }
        onToggleAsidebar(asidebarTypes.ASIDEBAR_NONE);
    };

    return (
        <FormYupProvider schema={Schema} onSubmit={handleSubmit} defaultValues={defaultValues}>
            <Stack spacing={6} p={2}>
                <StyledPicker
                    name="transactionDate"
                    header={translate('management.transaction.date_title')}
                    label={translate('management.transaction.date_label')}
                />
                <TransferAmount />
                <StyledAutoComplete
                    name="transferFrom"
                    header={translate('management.transaction.transferFrom_title')}
                    label={translate('management.transaction.transferFrom_label')}
                    options={fromAccounts}
                    disabled
                    dialogDisabled
                />
                <StyledAutoComplete
                    name="transferTo"
                    header={translate('management.transaction.transferTo_title')}
                    label={translate('management.transaction.transferTo_label')}
                    options={toAccounts}
                    dialogDisabled
                />
                <TransferCurrency name="transferToCurrency" />
                <StyledTextField
                    name="transactionMemo"
                    header={translate('management.transaction.memo_title')}
                    fullWidth
                    multiline
                    rows={4}
                    label={translate('management.transaction.memo_label')}
                />
                <TransferFormSubmit isUpdate={transfer.transactionId !== undefined} />
            </Stack>
        </FormYupProvider>
    );
};
TransferForm.propTypes = {
    transfer: PropTypes.object.isRequired,
};

export default TransferForm;
