import { useEffect } from 'react';
import { Typography, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useLocales } from '../../../../locales';
import { useFormYup } from '../../../../components/hook-form/useFormYup';
import SubscriptionBookTemplates from './SubscriptionBookTemplates';
import SubscriptionBookDetails from './SubscriptionBookDetails';

const SubscriptionBook = () => {
    const { translate } = useLocales();
    const { getValue, registerFieldAndValidation } = useFormYup();
    const bookLanguage = getValue('planBookLanguage');

    useEffect(() => {
        registerFieldAndValidation('planBookTemplate');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 15 }}>
            <Alert severity="info">
                <Typography gutterBottom variant="h5" sx={{ color: 'text.secondary' }}>
                    {translate('subscriptions.subscriptionBook_title1')}
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {translate('subscriptions.subscriptionBook_title2')}
                </Typography>
            </Alert>

            <Typography variant="h6">{translate('subscriptions.subscriptionBook_title3')}</Typography>

            <SubscriptionBookTemplates bookLanguage={bookLanguage} />
            <SubscriptionBookDetails />
        </Stack>
    );
};

export default SubscriptionBook;
