import Grid from '@mui/material/Unstable_Grid2';
import {withSummaryWidget} from "../components";
import NewGoal from "./NewGoal";


const SavingsGoalWidget = () => (
    <Grid container sx={{height: '100%'}}>
        <Grid xs={12} md={6}>
            <NewGoal/>
        </Grid>
    </Grid>
)


export default withSummaryWidget(
    SavingsGoalWidget,
    'savings_goal_title',
    'savings_goal_subtitle');
