import {Helmet} from "react-helmet-async";
import NewSubscription from "../../../views/management/subscriptions/NewSubscription";

function Root() {
    return (
        <>
            <Helmet>
                <title> New Subscription | Wyzr</title>
            </Helmet>
            <NewSubscription/>
        </>
    )
}

export default Root;