import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Stack } from '@mui/material';
import { useLocales } from '../../../../../locales';
import { FormYupProvider } from '../../../../../components/hook-form';
import { StyledAutoComplete, StyledTextField } from '../../../../../components/styled-inputs';
import FormSubmit from './FormSubmit';
import { ASIDEBAR_NONE, ASIDEBAR_UPDATE_CATEGORY, useLayoutSettingsContext } from '../components/LayoutSettings';
import useCategoriesContext from '../../../../../contexts/Categories/useCategoriesContext';

const CategoryForm = ({ category, variant }) => {
    const { t } = useLocales();
    const { onToggleAsidebar, asidebarType } = useLayoutSettingsContext();
    const { getVariantCategoriesByLevels, onCreateCategory, onUpdateCategory } = useCategoriesContext();

    const options = getVariantCategoriesByLevels(variant)
        .filter(r => r.primary)
        .map(item => ({
            id: item.id,
            label: item.name,
            primary: item.primary,
        }));
    
    const getPrimaryGroupId = (primaryName) => {
        if (primaryName) {
            return options.find((r) => r.label === primaryName).id;
        }
        return '';
    };
    
    const defaultValues = {
        categoryId: category.id || undefined,
        categoryName: category.name || '',
        categoryPrimaryGroupId: getPrimaryGroupId(category.primaryName) || '',
        categoryCostType: category.costType || 'None',
        categoryImportanceType: category.costImportance || 'None',
        categoryVariant: variant,
    };
    const Schema = Yup.object().shape({
        categoryName: Yup.string().required(t('management.category.name_validation')),
        categoryPrimaryGroupId: Yup.string(),
        categoryCostType: Yup.string(),
        categoryImportanceType: Yup.string(),
        categoryVariant: Yup.string(),
    });

    const costTypeOptions = [
        {
            id: 'None',
            label: t('management.category.cost_type_default'),
        },
        {
            id: 'Fixed',
            label: t('management.category.cost_type_fixed'),
        },
        {
            id: 'Variable',
            label: t('management.category.cost_type_variable'),
        },
    ];
    const costImportanceOptions = [
        {
            id: 'None',
            label: t('management.category.cost_importance_default'),
        },
        {
            id: 'Essential',
            label: t('management.category.cost_importance_essential'),
        },
        {
            id: 'NonEssential',
            label: t('management.category.cost_importance_non_essential'),
        }
    ];

    const handleSubmit = async (data) => {
        const {categoryId, categoryName, categoryPrimaryGroupId, categoryCostType, categoryImportanceType, categoryVariant} = data;

        if (categoryId === undefined) {
            if (categoryPrimaryGroupId) {
                await onCreateCategory({
                    primaryId: categoryPrimaryGroupId,
                    secondaryName: categoryName,
                    costType: categoryCostType || 'None',
                    costImportance: categoryImportanceType || 'None',
                    type: categoryVariant
                });
            } else {
                await onCreateCategory({
                    primaryName: categoryName,
                    costType: categoryCostType || 'None',
                    costImportance: categoryImportanceType || 'None',
                    type: categoryVariant
                });
            }
        }
        if (categoryId !== undefined) {
            if (categoryPrimaryGroupId) {
                await onUpdateCategory({
                    primaryId: categoryPrimaryGroupId,
                    secondaryId: categoryId,
                    secondaryName: categoryName,
                    costType: categoryCostType || 'None',
                    costImportance: categoryImportanceType || 'None',
                });
            } else {
                await onUpdateCategory({
                    primaryName: categoryName,
                    primaryId: categoryId,
                    costType: categoryCostType || 'None',
                    costImportance: categoryImportanceType || 'None',
                });
            }
        }

        onToggleAsidebar(ASIDEBAR_NONE);
    };

    return (
        <FormYupProvider schema={Schema} onSubmit={handleSubmit} defaultValues={defaultValues}>
            <Stack spacing={6} p={2}>
                <StyledTextField name="categoryName" header={t('management.category.name_header')} fullWidth label={t('management.category.name_label')} />
                {asidebarType !== ASIDEBAR_UPDATE_CATEGORY &&
                    <StyledAutoComplete
                        name="categoryPrimaryGroupId"
                        header={t('management.category.primary_title')}
                        label={t('management.category.primary_label')}
                        options={options}
                        dialogTitle={t('')}
                        dialogContent={t('')}
                        dialogLabel={t('')}
                        dialogDisabled
                    />
                }
                <StyledAutoComplete
                    name="categoryCostType"
                    header={t('management.category.cost_type_title')}
                    label={t('management.category.cost_type_label')}
                    options={costTypeOptions}
                    dialogTitle={t('')}
                    dialogContent={t('')}
                    dialogLabel={t('')}
                    dialogDisabled
                />
                <StyledAutoComplete
                    name="categoryImportanceType"
                    header={t('management.category.cost_importance_title')}
                    label={t('management.category.cost_importance_label')}
                    options={costImportanceOptions}
                    dialogTitle={t('')}
                    dialogContent={t('')}
                    dialogLabel={t('')}
                    dialogDisabled
                />
                <FormSubmit isUpdate={category.id !== undefined} />
            </Stack>
        </FormYupProvider>
    );
};
CategoryForm.propTypes = {
    category: PropTypes.object.isRequired,
    variant: PropTypes.oneOf(['income', 'expense']).isRequired,
};


export default CategoryForm;