export function responsiveWidthSizes({sm, md, lg}) {
    return {
        '@media (min-width:600px)': {
            maxWidth: sm
        },
        '@media (min-width:900px)': {
            maxWidth: md
        },
        '@media (min-width:1200px)': {
            maxWidth: lg
        },
    };
}