import { Drawer, Stack, IconButton } from "@mui/material";
import { alpha, useTheme } from '@mui/material/styles';
import { useLayoutSettingsContext } from "../components/TransactionLayoutSettings";
import { bgBlur } from "../../../../../utils/cssStyles";
import { NAV } from '../../../../../config';
import Iconify from "../../../../../components/iconify";
import { TransactionForm, TransferForm } from "./Form";
import * as asidebarTypes from "../components/TransactionLayoutTypes";
import useTransactionsContext from "../../../../../contexts/Transactions/useTransactionsContext";

const Asidebar = () => {
    const theme = useTheme();
    const {showAsidebar, onToggleAsidebar, asidebarType, asidebarPayload} = useLayoutSettingsContext();
    const { selectedAccountClassType } = useTransactionsContext();

    const handleClose = () => {
        onToggleAsidebar(asidebarTypes.ASIDEBAR_NONE);
    }

    return (
        <Drawer
            open={showAsidebar}
            anchor="right"
            onClose={handleClose}
            BackdropProps={{ invisible: true }}
            PaperProps={{
                sx: {
                    ...bgBlur({ color: theme.palette.background.default, opacity: 0.9 }),
                    width: NAV.W_BASE * 3,
                    boxShadow: `-24px 12px 40px 0 ${alpha(
                        theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
                        0.16
                    )}`
                },
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ pl: 2, pr: 1, py: 2 }}
            >
                <IconButton onClick={handleClose}>
                    <Iconify icon="eva:close-fill" />
                </IconButton>
            </Stack>

            {asidebarType === asidebarTypes.ASIDEBAR_ADD_TRANSACTION &&
                <TransactionForm transaction={{ transactionAccountClassType: selectedAccountClassType }}/>
            }
            {asidebarType === asidebarTypes.ASIDEBAR_EDIT_TRANSACTION &&
                <TransactionForm transaction={asidebarPayload}/>
            }
            {asidebarType === asidebarTypes.ASIDEBAR_ADD_TRANSFER &&
                <TransferForm transfer={{}}/>
            }
            {asidebarType === asidebarTypes.ASIDEBAR_EDIT_TRANSFER &&
                <TransferForm transfer={asidebarPayload}/>
            }
        </Drawer>
    )
};

export default Asidebar;
