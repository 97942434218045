import { useContext } from 'react';
import { ClassificationFilterContext } from './ClassificationFilterProvider';
import type { FilterContextState } from './types';

const useClassificationFilterContext = () : FilterContextState => {
    const context : FilterContextState = useContext(ClassificationFilterContext);
    if (context === undefined) {
        throw new Error('useClassificationFilterContext must be used within a Classification Provider');
    }
    return context;
};

export default useClassificationFilterContext;