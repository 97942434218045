import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import {useSubscriptionsContext} from "../../hooks/useSubscriptionsContext";
import {subscriptionAccountsSelector} from "./selectors";
import EmptyAccounts from "./features/Accounts/components/EmptyAccounts";
import {
    NetWorthWidget,
    SpendWidget,
    ExpensesByClassificationWidget,
    RecurringSpendWidget,
    ExpensesByCategoryWidget,
    TransactionsWidget,
    SavingsGoalWidget,
    SpendingWatchlistWidget,
    UpcomingSpendWidget,
} from "./features/Summary";

const Overview = () => {
    const {pathname} = useLocation();
    const {defaultSubscriptionId} = useSubscriptionsContext();
    const accounts = useSelector(state => subscriptionAccountsSelector(state, defaultSubscriptionId, "system_all_account_types"));

    if (defaultSubscriptionId === null || defaultSubscriptionId === undefined || defaultSubscriptionId === '') {
        throw new Error('failed to load default subscription Id.');
    }

    return (
        <Grid container spacing={2}>
            {accounts.length === 0 &&
                <Grid xs={12}>
                    <EmptyAccounts returnUrl={pathname}/>
                </Grid>
            }

            <Grid xs={12}>
                <NetWorthWidget/>
            </Grid>
            <Grid xs={6}>
                <UpcomingSpendWidget/>
            </Grid>
            <Grid xs={6}/>
            <Grid xs={12}>
                <SavingsGoalWidget/>
            </Grid>
            <Grid xs={12}>
                <SpendingWatchlistWidget/>
            </Grid>
            <Grid xs={6}>
                <TransactionsWidget type='Income'/>
            </Grid>
            <Grid xs={6}>
                <TransactionsWidget type='Expense'/>
            </Grid>
            <Grid xs={6}>
                <SpendWidget/>
            </Grid>
            <Grid xs={6}/>
            <Grid xs={12}>
                <ExpensesByClassificationWidget type='FixedAndVariable' />
            </Grid>
            <Grid xs={12}>
                <ExpensesByClassificationWidget type='EssentialAndNonEssential' />
            </Grid>
            <Grid xs={6}>
                <RecurringSpendWidget />
            </Grid>
            <Grid xs={6}>
                <ExpensesByCategoryWidget type='Secondary' />
            </Grid>
        </Grid>
    )
};

export default Overview;