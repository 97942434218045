import PropTypes from 'prop-types';
import TimelineTransactionItem from "./TimelineTransactionItem";
import TimelineBalanceEndDay from "./TimelineBalanceEndDay";
import { useLayoutSettingsContext } from "../../components/TransactionLayoutSettings";
import { useSortableProvider } from "../../../../../../contexts/Generic/SortableProvider";

const TimelineDayItem = (props) => {
    const {showDailyTotals} = useLayoutSettingsContext();
    const {sortOrder} = useSortableProvider();
    const {transactions, ...day} = props;
    const isAscending = sortOrder === 'asc';

    const renderStart = () => {
        if (!isAscending && showDailyTotals) {
            return <TimelineBalanceEndDay {...day}/>
        }
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
    const renderEnd = () => {
        if (isAscending && showDailyTotals) {
            return <TimelineBalanceEndDay {...day}/>
        }
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
    return (
        <>
            {renderStart()}
            {transactions.map((transaction, index) => (
                <TimelineTransactionItem key={`txn_item_${index}`} {...transaction}/>
            ))}
            {renderEnd()}
        </>
    )
};
TimelineDayItem.propTypes = {
    transactions: PropTypes.array
}

export default TimelineDayItem;