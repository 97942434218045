import PropTypes from "prop-types";
import { uniq, map, sumBy, orderBy } from 'lodash';
import useCategoriesContext from "../../../../../contexts/Categories/useCategoriesContext";
import usePayeesContext from "../../../../../contexts/Payees/usePayeesContext";
import { useAccountsContext } from "../../../../../hooks/useAccountsContext";
import { useSortableProvider } from "../../../../../contexts/Generic/SortableProvider";
import { useTransactionsCategoryContext } from "../../../../../contexts/TransactionsCategory";
import { useLocales } from "../../../../../locales";
import { useTransactionsPayeeContext } from "../../../../../contexts/TransactionsPayee";


const enrichCumulativeBalance = (startingBalance, entries) => {
    let cumulativeBalance = startingBalance;
    entries.forEach(r => {
        r.cumulativeBalance = cumulativeBalance + r.totalAmount.asInt;
        cumulativeBalance += r.totalAmount.asInt;
    });
};
const enrichCategory = (allCategories, entries, nullCategory) => {
    entries.forEach(r => {
        if (Array.isArray(r.allocations) && r.allocations.length > 0) {
            const {categoryId} = r.allocations[0];
            const findIndex = allCategories.findIndex(c => c.id === categoryId);
            r.categoryName = findIndex >= 0 ? allCategories[findIndex].secondaryName : null;
            r.primaryCategoryName = findIndex >= 0 ? allCategories[findIndex].primaryName : nullCategory;
        }
    });
};
const enrichPayee = (allPayees, entries, nullPayee) => {
    entries.forEach(r => {
        const {payeeId} = r;
        const findIndex = allPayees.findIndex(p => p.id === payeeId);
        r.payeeName = findIndex >= 0 ? allPayees[findIndex].name : nullPayee;
    });
};
const enrichAccount = (allAccounts, entries) => {
    entries.forEach(r => {
        const {transferFrom, transferTo} = r;
        if (transferFrom) {
            const findIndex = allAccounts.findIndex(p => p.id === transferFrom);
            r.transferFromAccount = findIndex >= 0 ? allAccounts[findIndex].alias : null;
        }
        if (transferTo) {
            const findIndex = allAccounts.findIndex(p => p.id === transferTo);
            r.transferToAccount = findIndex >= 0 ? allAccounts[findIndex].alias : null;
        }
    });
};

const getUniquesByKey = (transactions, key = 'monthKey') => {
    // const uniques = [...new Set(transactions.map(r => r.monthKey))];
    const uniques = uniq(map(transactions, key));
    // console.log(`${key}Groups`, uniques);
    return uniques;
};
const calculateTotalsPerMonthKey = (monthKeyGroups, transactions, startingBalance) => {
    let cumulativeStartBalance = startingBalance;
    const sortedMonthKeys = monthKeyGroups.sort((a, b) => a - b);
    const totalsPerMonthKey = sortedMonthKeys
        .map(key => {
            const monthKeyTransactions = transactions.filter(r => r.monthKey === key);
            const totalPerMonth = sumBy(monthKeyTransactions, r => r.totalAmount.asInt);
            const totalIncomes = sumBy(monthKeyTransactions.filter(r => r.totalAmount.asInt >= 0), r => r.totalAmount.asInt);
            const totalExpenses = sumBy(monthKeyTransactions.filter(r => r.totalAmount.asInt < 0), r => r.totalAmount.asInt);
            const startBalance = cumulativeStartBalance;
            const endBalance = cumulativeStartBalance + totalPerMonth;
            cumulativeStartBalance += totalPerMonth;

            return {
                monthKey: key,
                totalPerMonth,
                totalIncomes,
                totalExpenses,
                startBalance,
                endBalance
            };
        });
    // console.log("totalsPerMonthKey", totalsPerMonthKey);
    return totalsPerMonthKey;
};
const groupTransactionsPerDayKey = (totalsPerMonthKey, transactions) => {
    const groupedTransactions = totalsPerMonthKey
        .map(item => {
            const {monthKey, ...other} = item;
            const monthKeyTransactions = transactions.filter(r => r.monthKey === monthKey);
            const uniqueDayKeys = getUniquesByKey(monthKeyTransactions, 'dayKey');
            const dayKeyTransactions = uniqueDayKeys
                .map(dayKey => {
                    const transactionsByDayKey = monthKeyTransactions.filter(r => r.dayKey === dayKey);
                    const incomeTransactions = transactionsByDayKey.filter(r => r.totalAmount.asInt >= 0);
                    const expenseTransactions = transactionsByDayKey.filter(r => r.totalAmount.asInt <= 0);
                    return {
                        dayKey,
                        transactions: transactionsByDayKey,
                        totalIncomes: sumBy(incomeTransactions, r => r.totalAmount.asInt),
                        totalExpenses: sumBy(expenseTransactions, r => r.totalAmount.asInt)
                    }
                });

            return {
                monthKey,
                ...other,
                dayKeyTransactions
            }
        });

    // console.log("Grouped transactions", groupedTransactions);
    return groupedTransactions;
};
const transactionFilter = (transaction, selectedCategoryIds, selectedPayeeIds) => {
    let categoryFound = false;
    let payeeFound = false;

    // standard transaction entry
    if (transaction.allocations && transaction.allocations.length > 0) {

        const categoryToAdd = transaction.totalAmount.asInt < 0 ? 'system_no_category_debit' : 'system_no_category_credit';

        // TODO check filter supports all category per transaction allocation
        const currentCategoryIds = [];
        if (transaction.allocations && transaction.allocations.length === 1) {
            if (transaction.allocations[0].categoryId) {
                currentCategoryIds.push(transaction.allocations[0].categoryId);
            } else {
                currentCategoryIds.push(categoryToAdd);
            }
        } else {
            currentCategoryIds.push(categoryToAdd);
        }

        const currentPayeeIds = [transaction.payeeId || 'system_no_payee'];

        if (selectedPayeeIds.length === 0) {
            payeeFound = true;
        }
        if (selectedCategoryIds.length === 0) {
            categoryFound = true;
        }

        if (currentCategoryIds.length > 0 && selectedCategoryIds.length > 0) {
            categoryFound = currentCategoryIds.filter(x => selectedCategoryIds.includes(x)).length >= 1;
        }
        if (currentPayeeIds.length > 0 && selectedPayeeIds.length > 0) {
            payeeFound = currentPayeeIds.filter(x => selectedPayeeIds.includes(x)).length >= 1;
        }
    }

    // transfer transaction entry
    if (transaction.transferFrom || transaction.transferTo) {
        categoryFound = selectedCategoryIds.length === 0;
        payeeFound = selectedPayeeIds.length === 0;
    }

    return categoryFound && payeeFound;
};

const withTimelineView = WrappedComponent => {
    function HOC({data, ...props}) {
        const {t} = useLocales();
        const {sortOrder} = useSortableProvider();
        const {startingBalance, entries, endingBalance} = data;

        const {selectedIds: selectedCategoryIds} = useTransactionsCategoryContext();
        const {data: categoriesData} = useCategoriesContext();

        const {selectedIds: selectedPayeeIds} = useTransactionsPayeeContext();
        const {data: payeesData} = usePayeesContext();

        const {accounts} = useAccountsContext();

        // console.time("withTimelineView");
        enrichCumulativeBalance(startingBalance, entries);
        enrichCategory(categoriesData, entries, t('common.no_category'));
        enrichPayee(payeesData, entries, t('common.no_payee'));
        enrichAccount(accounts, entries);

        const filteredTransactions = entries.filter(txn => transactionFilter(txn, selectedCategoryIds, selectedPayeeIds));
        const monthKeyGroups = getUniquesByKey(filteredTransactions);

        const sortedTransactions = orderBy(filteredTransactions, ['dayKey', 'order'], [sortOrder, sortOrder]);
        const totalsPerMonthKey = calculateTotalsPerMonthKey(monthKeyGroups, sortedTransactions, startingBalance);
        const groupedTransactions = groupTransactionsPerDayKey(totalsPerMonthKey, sortedTransactions);
        const sortedGroupedTransactiosn =  orderBy(groupedTransactions, ['monthKey'], [sortOrder]);
        // console.timeEnd("withTimelineView");

        return (
            <WrappedComponent {...props} transactions={sortedGroupedTransactiosn} endingBalance={endingBalance} />
        )
    }

    HOC.propTypes = {
        data: PropTypes.object.isRequired
    };
    return HOC;
};

export default withTimelineView;