import PropTypes from 'prop-types';
import { reverse } from 'lodash';
import Chart, { useChart } from '../../../../../../components/chart';
import useResponsive from '../../../../../../hooks/useResponsive';
import { fNumber, fNumeral } from '../../../../../../utils/formatNumber';
import { useSettingsContext } from '../../../../../../components/settings';
import { useLocales } from '../../../../../../locales';
import { fDate2 } from '../../../../../../utils/formatTime';

const ChartByAll = ({ totalNetWorth }) => {
    const { t, languageCode } = useLocales();
    const { isRTL } = useSettingsContext();
    const isDesktop = useResponsive('up', 'md');

    const mappedLabels = totalNetWorth.map((r) => fDate2(`${r.name}`, 'MMMM yy', languageCode));
    const mappedIncomes = totalNetWorth.map((r) => fNumeral(r.total.asDouble));
    const options = {
        chart: {
            type: 'area',
        },
        labels: !isRTL ? mappedLabels : reverse(mappedLabels),
        stroke: {
            curve: 'straight'
        },
        grid: {
            show: false,
        },
        tooltip: {
            marker: { show: false },
            style: {
                fontSize: isDesktop ? '16px' : '12px',
            },
            y: {
                title: {
                    formatter: (value) => `  ${value}  `,
                },
                formatter: (value) => fNumber(value),
            },
        },
        yaxis: {
            opposite: isRTL,
            tickAmount: 4,
            floating: false,
            labels: {
                show: true,
                offsetY: 0,
                offsetX: isRTL ? 32 : 0,
                formatter: (value) => fNumber(value),
            },
        },
        fill: {
            opacity: 0.5
        },
        legend: {
            show: true,
            fontSize: isDesktop ? '16px' : '12px',
            markers: {
                offsetX: isRTL ? 5 : 0,
                width: 8,
                height: 8,
            },
        },
    };
    const chartOptions = useChart(options);

    const series = [
        {
            name: t('reports.netWorth'),
            type: 'area',
            data: !isRTL ? mappedIncomes : reverse(mappedIncomes),
        },
    ];
    return <Chart type="area" series={series} options={chartOptions} height={400} />;
};
ChartByAll.propTypes = {
    totalNetWorth: PropTypes.array,
};

export default ChartByAll;