import DateRangeProvider from "../../../../contexts/DateRange";
import CategoriesProvider from "../../../../contexts/Categories";
import PayeesProvider from "../../../../contexts/Payees";
import TransactionsList from "./TransactionsList";
import TransactionsProvider from "../../../../contexts/Transactions";
import TransactionsLayout from "./components/TransactionsLayout";
import TransactionLayoutSettings from "./components/TransactionLayoutSettings";
import TransactionsDateProvider from "../../../../contexts/TransactionsDate";
import SortableProvider from "../../../../contexts/Generic/SortableProvider";
import TransactionsCategoryProvider from "../../../../contexts/TransactionsCategory";
import FilterProvider from "../../components/Filter/FilterProvider";
import TransactionsPayeeProvider from "../../../../contexts/TransactionsPayee";
import Asidebar from "./Views/Asidebar";

const Overview = () => {
    const renderTransactions = () => (
        <TransactionLayoutSettings>
            <FilterProvider>
                <TransactionsProvider>
                    <TransactionsLayout>
                        <Asidebar/>
                        <TransactionsList/>
                    </TransactionsLayout>
                </TransactionsProvider>
            </FilterProvider>
        </TransactionLayoutSettings>
    )
    return (
        <DateRangeProvider datesType="default">
            <CategoriesProvider>
                <PayeesProvider>
                    <TransactionsDateProvider>
                        <SortableProvider>
                            <TransactionsCategoryProvider>
                                <TransactionsPayeeProvider>
                                    {renderTransactions()}
                                </TransactionsPayeeProvider>
                            </TransactionsCategoryProvider>
                        </SortableProvider>
                    </TransactionsDateProvider>
                </PayeesProvider>
            </CategoriesProvider>
        </DateRangeProvider>
    )
}

export default Overview;