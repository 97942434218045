import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useLocales } from '../../locales';

ProductFeatures.propTypes = {
    features: PropTypes.array,
};

export default function ProductFeatures({ features }) {
    const [value, setValue] = React.useState(0);
    const { t } = useLocales();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tab_tites = features.reduce((acc, item) => {
        acc.push(item.title);
        return acc;
    }, []);

    return (
        <Box sx={{ maxWidth: { xs: 480, sm: 560 }, bgcolor: 'background.paper' }}>
            <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                {tab_tites.map((item, index) => (
                    <Tab label={t(item)} key={index} />
                ))}
            </Tabs>

            <Box sx={{ mt: 4, mb: 4 }}>
                <Stack>
                    {features[value].items &&
                        features[value].items.map((item, i) => (
                            <Box display="flex" key={`feature_${i}`} mb={1} gap={2}>
                                <CheckCircleIcon sx={{ color: 'primary.main' }} />
                                <Typography gutterBottom fontWeight="300" variant="h5">
                                    {t(item)}
                                </Typography>
                            </Box>
                        ))}
                </Stack>
            </Box>
        </Box>
    );
}