import { Link as RouterLink } from 'react-router-dom';
import { Box, Stack, Tooltip, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Iconify from '../../../../../components/iconify';
import { useLocales } from '../../../../../locales';
import { useAccountsToolbarContext } from '../hooks/useAccountsToolbarContext';
import { PATH_SUBSCRIPTION } from '../../../../../routes/paths';
import { useAccountsContext } from '../../../../../hooks/useAccountsContext';

const AccountsToolbar = () => {
    const { t } = useLocales();
    const { onToggleSummary, onToggleAlerts, showAlerts, showAccountsSummary } = useAccountsToolbarContext();
    const { onToggleRefresh } = useAccountsContext();

    return (
        <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            flexShrink={0}
            sx={{
                px: 2,
                height: 80,
                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
        >
            <Stack direction="row" alignItems="center" flexGrow={1} spacing={1}>
                <Button
                    component={RouterLink}
                    variant="soft"
                    color="inherit"
                    sx={{
                        textTransform: 'unset',
                        color: 'text.secondary',
                        width: { xs: 1, md: 'auto' },
                        justifyContent: 'flex-start',
                        fontWeight: 'fontWeightMedium',
                        height: 49,
                    }}
                    to={PATH_SUBSCRIPTION.accounts.new}
                    startIcon={<Iconify icon="solar:add-square-linear" />}
                >
                    {t('accounts.action_add_new_title')}
                    <Box sx={{ flexGrow: 1 }} />
                </Button>

                <ToggleButtonGroup size="small">
                    <Tooltip title={t('accounts.action_toggle_account_summary')}>
                        <ToggleButton size="small" color="primary" value="check" selected={showAccountsSummary} onChange={onToggleSummary}>
                            <Iconify icon="solar:clipboard-list-linear" />
                        </ToggleButton>
                    </Tooltip>

                    <Tooltip title={t('accounts.action_toggle_account_limits')}>
                        <ToggleButton size="small" color="primary" value="check" selected={showAlerts} onChange={onToggleAlerts}>
                            <Iconify icon="solar:shield-warning-linear" />
                        </ToggleButton>
                    </Tooltip>

                    <Tooltip title={t('common.refresh')}>
                        <ToggleButton size="small" color="primary" value="check" onClick={onToggleRefresh}>
                            <Iconify icon="solar:refresh-linear" />
                        </ToggleButton>
                    </Tooltip>
                </ToggleButtonGroup>
            </Stack>

            <ToggleButtonGroup size="small" value="grid" color="primary" exclusive>
                <ToggleButton value="list">
                    <Iconify icon="eva:list-fill" />
                </ToggleButton>

                <ToggleButton value="grid">
                    <Iconify icon="eva:grid-fill" />
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>
    );
};

export default AccountsToolbar;
