// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/dist/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// material
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ar from 'date-fns/locale/ar';
import { LocalizationProvider } from '@mui/x-date-pickers'
// redux
import { store, persistor } from './store';
// components
import { LoadingLogo } from './components/loading-screen';
import { SettingsProvider } from './components/settings';

// App
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// api mock
import { makeServer } from "./api/mock";

if (process.env.NODE_ENV === "development" && typeof makeServer === "function") {
    makeServer({environment: "development"})
}

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <HelmetProvider>
        <ReduxProvider store={store}>
            <PersistGate loading={<LoadingLogo/>} persistor={persistor}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ar}>
                    <SettingsProvider>
                        {/* <ScrollToTop/> */}
                        <App/>
                    </SettingsProvider>
                </LocalizationProvider>
            </PersistGate>
        </ReduxProvider>
    </HelmetProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
