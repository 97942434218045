import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import {defaultLanguage} from './config';
import enLocales from './languages/en/en';
import arLocales from './languages/ar/ar';

// ----------------------------------------------------------------------

let lng = defaultLanguage.value;

if (typeof window !== 'undefined') {
    lng = localStorage.getItem('i18nextLng') || defaultLanguage.value;
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {translations: enLocales},
            ar: {translations: arLocales},
        },
        lng,
        fallbackLng: defaultLanguage.value,
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false,
        },
        react: {
            // TODO use(HttpApi) does not work with ThemeLocalization and useSuspense = true
            useSuspense : false
        },
        whitelist: ['en', 'ar'],
    });

export default i18n;
