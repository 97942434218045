import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Tooltip, Link, ListItemText } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
// guards
import RoleBasedGuard from '../../../guards/RoleBasedGuard';
//
import Iconify from '../../iconify';
import { StyledItem, StyledIcon } from './styles';
import { responsiveFontSizes } from "../../../utils/fontSizes";
import { useSettingsContext } from "../../settings";

// ----------------------------------------------------------------------

const NavItem = forwardRef(({item, depth, open, active, isExternalLink, ...other}, ref) => {
    const {translate} = useLocales();
    const {isRTL} = useSettingsContext();
    const {path, icon, children, disabled, caption, roles, languageCode} = item;
    const subItem = depth !== 1;
    const renderContent = (
        <StyledItem ref={ref} open={open} depth={depth} active={active} disabled={disabled} {...other}>
            {icon && <StyledIcon>{icon}</StyledIcon>}

            {subItem &&
                <ListItemText
                    primary={translate(languageCode)}
                    primaryTypographyProps={{
                        noWrap: true,
                        sx: {
                            width: 172,
                            fontSize: 10,
                            lineHeight: '16px',
                            textAlign: 'center',
                            ...(active && {
                                // fontWeight: 'fontWeightMedium',
                            }),
                            ...(subItem && {
                                mt: 1,
                                mb: 1,
                                ml: 2,
                                width: 'auto',
                                textAlign: 'left',
                                ...responsiveFontSizes({sm: 16, md: 16, lg: 18})
                            }),
                        },
                    }}
                />
            }

            {caption && (
                <Tooltip title={translate(caption)} arrow placement="right">
                    <Iconify
                        icon="eva:info-outline"
                        width={16}
                        sx={{
                            top: 11,
                            left: 6,
                            position: 'absolute',
                        }}
                    />
                </Tooltip>
            )}

            {!!children && (
                <Iconify
                    width={16}
                    icon={isRTL ? "eva:chevron-left-fill" : "eva:chevron-right-fill"}
                    sx={{
                        top: 11,
                        right: 6,
                        position: 'absolute',
                    }}
                />
            )}
        </StyledItem>
    );

    const renderItem = () => {
        // ExternalLink
        if (isExternalLink)
            return (
                <Link href={path} target="_blank" rel="noopener" underline="none">
                    {renderContent}
                </Link>
            );

        // Default
        return (
            <Link component={RouterLink} to={path} underline="none">
                {renderContent}
            </Link>
        );
    };

    return <RoleBasedGuard roles={roles}> {renderItem()} </RoleBasedGuard>;
});

NavItem.propTypes = {
    open: PropTypes.bool,
    active: PropTypes.bool,
    item: PropTypes.object,
    depth: PropTypes.number,
    isExternalLink: PropTypes.bool,
};

export default NavItem;
