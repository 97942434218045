import PropTypes from 'prop-types';
// @mui
import {useTheme} from '@mui/material/styles';
import {IconButton} from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// utils
import {bgBlur} from '../../../utils/cssStyles';
// config
// import {NAV} from '../../../config';
// components
import Iconify from '../../../components/iconify';
import {useSettingsContext} from '../../../components/settings';

// ----------------------------------------------------------------------

NavToggleButton.propTypes = {
    sx: PropTypes.object,
};

export default function NavToggleButton({sx, ...other}) {
    const theme = useTheme();

    const {themeLayout, onToggleLayout, isRTL} = useSettingsContext();

    const isDesktop = useResponsive('up', 'lg');

    if (!isDesktop) {
        return null;
    }

    let themeLayoutIcon = '';
    if (isRTL) {
        themeLayoutIcon = themeLayout === 'vertical' ? 'line-md:menu-fold-right' : 'line-md:menu-fold-left';
    }  else {
        themeLayoutIcon = themeLayout === 'vertical' ? 'line-md:menu-fold-left' : 'line-md:menu-fold-right';
    }
    return (
        <IconButton
            size="medium"
            onClick={onToggleLayout}
            sx={{
                p: 0.5,
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({opacity: 0.48, color: theme.palette.background.default}),
                '&:hover': {
                    bgcolor: 'background.default',
                },
                width: 40,
                height: 40,
                ...sx,
            }}
            {...other}
        >
            <Iconify
                width={32}
                icon={themeLayoutIcon}
            />
        </IconButton>
    );
}
