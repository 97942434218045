const ar = {
    common: {
        logout: 'تسجيل خروج',
        coming_soon: 'قريبا',
        coming_soon_help: 'نحن نعمل جاهدين حاليا على هذه الصفحة!',
        profile: 'ملفي',
        help: 'المساعدة',
        settings: 'إعدادات',
        notifications: 'إشعارات',
        notifications_count_pre: 'عندك',
        notifications_count_post: 'إشعارات غير مقروءة',
        notifications_view_all: 'عرض جميع الإشعارات',
        english: 'الإنجليزية',
        arabic: 'العربية',
        search: 'ابحث عن أي شيء ...',
        search_not_found: 'لم يتم العثور على أي شيء',
        search_no_results: 'لا يوجد نتائج ل',
        search_tip1: 'حاول التحقق من الأخطاء المطبعية أو استخدام كلمات كاملة.',
        search_tip2: 'الرجاء إدخال الكلمات الرئيسية',
        page_500_title: 'خطأ في الخادم الداخلي',
        page_500_description: 'كان هناك خطأ، الرجاء المحاولة مجددا لاحقا.',
        page_404_title: 'عذرا، لم يتم العثور على الصفحة!',
        page_404_description: 'معذرةً ، لم نتمكن من العثور على الصفحة التي تبحث عنها. ربما أخطأت في كتابة عنوان URL؟ تأكد من التحقق من التهجئة.',
        return_home: 'اذهب إلى الصفحة الرئيسية',
        next: 'التالي',
        previous: 'العودة',
        info: 'معلومات',
        refresh: 'تحديث البيانات',
        sort_ascending: 'فرز تصاعدي',
        sort_descending: 'ترتيب تنازلي',
        toggle_filter: 'لوحة التصفية',
        toggle_stats: 'لوحة الإحصائيات',
        from: 'من',
        to: 'إلى',
        remaining: 'متبقي',
        showEverything: 'اعرض كل شيء',
        everythingTo: 'كل شيء ل',
        dates: 'التواريخ',
        clear_all: 'امسح الكل',
        no_category: 'لا توجد فئة',
        no_payee: 'لا يوجد مستفيد',
        add: 'انقر للإضافة',
        cancel: 'الغي',
        today: 'اليوم',
        filters: 'المرشحات',
        reset: 'إعادة ضبط',
        noCategory: 'لم يتم تحديد فئة',
        noPayee: 'لم يتم تعيين مستفيد',
        noClassification: 'لم يتم تعيين علامات',
        resultsFound: 'النتائج التي تم العثور عليها',
    },
    homepage: {
        landingHero_Title: 'مرحبًا بك في إصدار ألفا من',
        landingHero_Description: 'قم بالتمرير أدناه لاكتشاف المزيد مما يمكن توقعه من هذا الإصدار.',
        landingHero_Login: 'تسجيل الدخول',
        landingHero_Register: 'اشترك الآن',
        features_title: 'قائمة الميزات',
        p1_title: 'الخطط',
        p1_desc: 'تسمح لك الخطط بفصل الحسابات ، على سبيل المثال إذا كان لديك حسابات شخصية أو تجارية.',
        p1_feature_1: 'خطتان لكل مستخدم',
        p2_title: 'الحسابات',
        p2_desc: 'الحسابات هي بنوكك وبطاقاتك الائتمانية للقروض والاستثمارات.',
        p2_feature_1: 'حسابين مصرفيين',
        p2_feature_2: 'حسابين ائتمان',
        p2_feature_3: 'إنشاء حساب يدويًا',
        p3_title: 'المعاملات',
        p3_desc: 'المعاملات هي إنفاقك ودخلك اليومي.',
        p3_feature_1: 'إدخال المعاملات يدويًا',
        p3_feature_2: 'لا حدود على المعاملات التي يمكن إضافتها',
        p3_feature_3: 'يدعم النفقات والدخل والتحويلات',
        p4_title: 'ضبط الحسابات',
        p4_desc: 'يتتبع مسك الدفاتر الأموال الواردة والصادرة من حساباتك.',
        p4_feature_1: 'عدد غير محدود من المستفيدين',
        p4_feature_2: 'عدد غير محدود من فئات الدخل',
        p4_feature_3: 'عدد غير محدود من فئات المصاريف',
        p5_title: 'التقارير',
        p5_desc: 'تسمح لك التقارير بالحصول على رؤى حول أين تذهب أموالك.',
        p5_feature_1: 'المعاملات حسب الفئة',
        p5_feature_2: 'المعاملات من قبل المستفيدين',
        p5_feature_3: 'قارن المصروفات',
        p5_feature_4: 'قارن المصاريف الثابتة / المتغيرة',
        register_title: 'إدارة حساباتك بشكل أكثر فعالية',
        register_user_title: 'ابدأ مجانًا تمامًا',
        register_user_existing: 'هل لديك حساب؟',
        register_user_existing_title: 'تسجيل الدخول',
        register_user_firstName: 'الاسم الأول',
        register_user_lastName: 'اسم العائلة',
        register_user_email: 'عنوان البريد الإلكتروني',
        register_user_password: 'كلمة المرور',
        register_user_create: 'إنشاء حساب',
        register_success_email: 'تفقد بريدك الالكتروني من فضلك!',
        register_success_email_info: 'لقد أرسلنا إليك رابط تأكيد عبر البريد الإلكتروني ، يرجى النقر فوق الرابط للتحقق من بريدك الإلكتروني. يمكنك تسجيل الدخول دون التحقق من بريدك الإلكتروني.',
        validations: {
            register_user_firstName: 'الاسم الأول مطلوب',
            register_user_lastName: 'اسم اللقب مطلوب',
            register_user_email: 'البريد الالكتروني مطلوب',
            register_user_email_regex: 'يجب أن يكون البريد الإلكتروني عنوانًا صالحًا',
            register_user_password: 'كلمة المرور مطلوبة',
        },
    },
    subscriptions: {
        addYourPlan_welcome: 'مرحباً،',
        addYourPlan: 'لنبدأ بإضافة اشتراكك المجاني',

        planName_title: 'دعنا نتعرف على اشتراكك',
        planName_label: 'اسم الاشتراك',
        planName_validation: 'مطلوب اسم الاشتراك حتى تتمكن من التعرف عليها',

        planRegion_title: 'المنطقة',
        planRegion_label: 'ما هو بلدك ؟',
        planRegion_validation: 'اسم المنطقة مطلوب لتلقي العروض والعروض الترويجية السارية في منطقتك',

        planCurrency_title: 'ما هي عملتك الرئيسية ؟',
        planCurrency_label: 'العملة',
        planCurrency_validation: 'العملة مطلوبة حتى يتم تحويل الحسابات متعددة العملات إلى هذه العملة',

        planBookLanguage_title: 'ما هي اللغة التي تريد استخدامها في شجرة الحسابات (المصاريف و الإيرادات و أسماء المستفيدين) ؟',
        planBookLanguage_label: 'لغة الكتاب',
        planBookLanguage_validation: '',

        subscriptionBasics_title1: 'ماذا يعني الاشتراك المجاني؟',
        subscriptionBasics_title2:
            'الوظيفة الرئيسية متاحة مجانًا للإصدار التجريبي. عدد الحسابات والمعاملات محدود شهريًا ، يمكنك بعد ذلك الدفع لترقية تجربتك من خلال الوصول إلى ميزات إضافية ، أو فتح محتوى إضافي ، عندما تكون جاهزًا.',

        subscriptionBook_title1: 'حدد دفتر المحاسبة المناسب لك',
        subscriptionBook_title2: 'اختيار دفتر المحاسبة المناسب لك من خلال بنود المصاريف الرئيسية و الفرعية وبنود الدخل',
        subscriptionBook_title3: 'القوائم شائعة الاستخدام',

        subscriptionBookDetails_title1: 'محتويات كتاب المحاسبة الخاص بك',
        subscriptionBookDetails_payees: 'قائمة المدفوع لهم',
        subscriptionBookDetails_payees_none: 'دفتر المحاسبة المحدد هذا ليس لديه أي المستفيدين',
        subscriptionBookDetails_incomes: 'قائمة الدخل',
        subscriptionBookDetails_expenses: 'قائمة المصاريف',

        subscription_step5_title1: 'مراجعة المعلومات المدخلة',
        subscription_step5_title2: 'اسم خطتك',
        subscription_step5_title3: 'اسم بلدك',
        subscription_step5_title4: 'شجرة الحسابات التي تم اختيارها',
        subscription_step5_title5: 'لغة شجرة الحسابات',
        subscription_step5_title6: 'العملة الأساسية',
        subscription_step5_validate_planBookName: 'تحتاج إلى اختيار شجرة حسابات فارغة أو اختيار واحد من القوالب الرئيسية أو ذات الاستخدام الشائع',
        subscription_step5_validate_planCurrencyName: 'نحتاج إلى معرفة العملة الرئيسية لخطتك',
        subscription_step5_validate_planName: 'نحن بحاجة لمعرفة اسم خطتك',
        subscription_step5_validate_planRegionName: 'نحن بحاجة إلى معرفة المنطقة التي لديك في لأغراض المحاسبة',

        subscription_submit: 'تبدو جيدة ، قم بإنشاء خطتي!',
    },
    management: {
        common: {
            submit_new: 'أضف',
            submit_update: 'تحديث',
        },
        account: {
            name_validation: 'اسم الحساب مطلوب',
            openingBalance_validation: 'الرصيد الافتتاحي مطلوب',
            openingDate_validation: '',
            code_validation: 'نوع الحساب مطلوب. الأنواع المتوفرة مذكورة أدناه:',
            currency_validation: 'عملة الحساب مطلوبة',

            addYourAccount: 'انشاء حساب جديد',
            name_title: 'اسم الحساب',
            name_label: 'نحتاج لمعرفة اسم حسابك',
            description_title: 'الوصف',
            description_label: 'يمكنك إضافة وصف لحسابك',
            openingDate_title: 'تاريخ فتح الحساب',
            openingDate_label: '',
            currency_title: 'عملة الحساب',
            currency_label: 'العملة',
            openingBalance_title: 'الرصيد الافتتاحي',
            openingBalance_label: 'يمكنك إضافة رصيد افتتاحي لحسابك',
            openingBalance_default: 'رصيدك الافتتاحي هو 0',

            accountTypes_header: 'اختر نوع الحساب',
            accountTypes_title: 'أنواع الحسابات',
            accountTypes_label: 'ثم اختر حسابًا',
            accountTypes_limited: 'لقد وصلت إلى الحدود المسموح بها ولم يعد بإمكانك الإنشاء من أنواع الحسابات هذه',

            reviewAccount_header: 'راجع تفاصيل الحساب',
            reviewAccount_title: 'راجع تفاصيل الحساب الأخرى',
            reviewAccount_submit: 'تبدو جيدة ، قم بإنشاء الحساب!',
            reviewAccount_noDescription: 'لم تقم بإضافة وصف لهذا الحساب',
            reviewAccount_accountType_title: 'نوع الحساب',

            proceedToNextStep: 'يمكنك المتابعة إلى الخطوة التالية!',
            assetInDebt: 'نظرًا لأن هذا حساب أصول ، فأنت مدين بمبلغ',
            assetInCredit: 'نظرًا لأن هذا حساب أصول ، في رصيدك',
            liabilityInDebt: 'نظرًا لأن هذا حساب التزام ، فإن الحساب مدين لك بـ',
            liabilityInCredit: 'نظرًا لأن هذا حساب التزام ، فأنت مدين بمبلغ',
        },
        transaction: {
            date_validation: 'التاريخ مطلوب',
            amount_validation: 'المبلغ مطلوب',
            transfer_from_validation: 'حدد الحساب الذي تقوم بالتحويل منه',
            transfer_to_validation: 'حدد الحساب الذي تقوم بالتحويل إليه',
            transfer_from_currency_validation: 'عملة التحويل مطلوبة',
            transfer_from_exchange_validation: 'حدد سعر صرف التحويل',
            transfer_to_currency_validation: 'عملة التحويل مطلوبة',

            date_title: 'تاريخ الحركة',
            date_label: '',
            amount_title: 'مبلغ الحركة',
            amount_label_expense: 'كم هو انفاقك',
            amount_label_income: 'كم هو الدخل',
            payee_title: 'المستفيدين',
            payee_label: 'تخصيص المستفيد (اختياري)',
            category_title: 'الفئات',
            category_label: 'تخصيص الفئة (اختياري)',

            category_type_title: 'نوع المعاملة',
            category_type_expense: 'مصروف',
            category_type_expense_info: 'النفقات تقلل من رصيد أصولك',
            category_type_income: 'دخل',
            category_type_income_info: 'الدخل يزيد من رصيد أصولك',
            category_type_expense_liability: 'تكلفة',
            category_type_expense_liability_info: 'تزيد الرسوم من المبلغ المستحق عليك',
            category_type_income_liability: 'قسط',
            category_type_income_liability_info: 'الدفع يقلل من المبلغ المستحق عليك',

            memo_title: 'الملاحظات',
            memo_label: 'أضف ملاحظتك هنا (اختياري)',
            submit_add_expense: 'أضف نفقة',
            submit_add_income: 'أضف دخلاً',
            submit_update_expense: 'تحديث الإنفاق',
            submit_update_income: 'تحديث الدخل',
            submit_update_transfer: 'تحديث التحويل',
            submit_add_transfer: 'أضف التحويل',

            transfer_amount_title: 'مبلغ التحويل',
            transfer_amount_label: 'كم من المال تفكر في تحويله؟',
            transferFrom_title: 'نقل من',
            transferFrom_label: 'اختر الحساب الذي تقوم بالتحويل منه',
            transferTo_title: 'تحويل إلى',
            transferTo_label: 'اختر الحساب الذي تقوم بالتحويل إليه',
            transfer_to_currency: 'عملة الحساب الذي تقوم بالتحويل إليه',
            transfer_exchange_title: 'سعر الصرف',
            transfer_exchange_label: 'أدخل سعر الصرف لهذا التحويل',
            transfer_exchange_help: 'إجمالي المبلغ المحول هو: ',
        },
        payee: {
            dialog_title: 'إضافة مستفيد جديد',
            dialog_content: 'هل اسم المستفيد غير موجود بالقائمة؟ الرجاء إضافته.',
            dialog_label: 'اسم',

            name_validation: 'اسم المستفيد مطلوب ويجب أن يكون فريداً',
            name_header: 'أسم المستفيد',
            name_label: 'أي شخص أو شركة تتلقى أموالاً منك',
        },
        category: {
            name_validation: 'اسم الفئة مطلوب',
            name_header: 'اسم الفئة',
            name_label: 'الفئات التي تصنف المعاملات',
            primary_title: 'أضف إلى الفئة الأساسية إذا كنت تريد إضافة فئة فرعية',
            primary_label: 'اختر فئة أساسية',
            cost_type_title: 'اختر التصنيف',
            cost_type_label: 'يوفر الخصائص إلى فئة',
            cost_importance_title: 'اختر علامة',
            cost_importance_label: 'توفير علامات محددة من قبل المستخدم لتتبع المعاملات',
            cost_type_default: 'لم يتم اختياره',
            cost_type_fixed: 'ثابت',
            cost_type_variable: 'متغير',
            cost_importance_default: 'لم يتم اختياره',
            cost_importance_essential: 'ضروري',
            cost_importance_non_essential: 'غير ضروري',
        },
    },
    accounts: {
        emptyAccounts_title: 'ليس لديك أي حسابات تم إعدادها حتى الآن!',
        emptyAccounts_note: 'حاول إضافة بعض الحسابات والمعاملات للحصول على رؤى حول إنفاقك.',
        emptyAccounts_description: 'أضف المزيد من الحسابات إذا كانت خطتك تسمح بذلك',
        emptyAccounts_addNow: 'أضف الآن',
        balanceInCurrency: 'الرصيد بالعملة',
        emptyTransactions: 'لا توجد معاملات في آخر 6 أشهر لهذا الحساب.',
        emptyTransactionsAdd: 'ستظهر المعاملات التي تمت إضافتها سجل رصيدك',
        filter_all: 'الجميع',
        subscription_limits: 'الحدود',
        message_AccountsLimitReached: 'ترقية خطتك لإضافة المزيد من الحسابات',
        message_AccountsRemaining1: 'يمكنك إضافة',
        message_AccountsRemaining2: 'المزيد من الحسابات مع خطتك',
        accountsStats_change: 'التغيير منذ الشهر الماضي',
        accountsStats_totals: 'إجمالي الرصيد لكافة الحسابات',
        action_add_new_title: 'حساب جديد',
        action_add_new_tooltip: '',
        action_toggle_account_limits: 'عرض حدود الحساب',
        action_toggle_account_summary: 'عرض ملخص الحسابات',
    },
    navigation: {
        dashboard: 'لوحات مخصصة',
        accounts: 'الحسابات',
        reports: 'التقارير',
        bookkeeping: 'إمساك الدفاتر',
        bookkeeping_expenses: 'إدارة المصروفات',
        bookkeeping_incomes: 'إدارة الدخل',
        bookkeeping_payees: 'إدارة المستفيدين',
    },
    breadcrumbs: {
        dashboard: 'مرحباً بك مرة أخرى',
        dashboard_link: 'لوحات مخصصة',
        overview_link: 'حقائق مالية',
        accounts: 'حساباتك',
        accounts_link: '',
        reports: 'التقارير',
        bookkeeping: 'إمساك الدفاتر',
        bookkeeping_expenses: 'إدارة المصروفات',
        bookkeeping_expenses_info: 'فئات المصاريف في كتابك',
        bookkeeping_incomes: 'إدارة الدخل',
        bookkeeping_incomes_info: 'فئات الدخل في كتابك',
        bookkeeping_payees: 'إدارة المستفيدين',
        bookkeeping_payees_info: 'المستفيدين في كتابك',
    },
    reports: {
        spending: 'تقرير المصروفات',
        income: 'تقرير الدخل',
        netIncome: 'تقرير صافي الدخل',
        netWorth: 'صافي القيمة',
        savings: 'تقرير التوفير',
        monthlySummary: 'تقرير موجز شهري',
        refundTracker: 'تقرير تعقب الاسترداد',

        classificationFilter: {
            breakdown: 'التصنيف',
            dates: 'تاريخ البحث',
        },
        transactions: {
            totalExpenses: 'مجموع المصاريف',
            totalIncomes: 'مجموع الدخل',
            totalNetIncome: 'إجمالي صافي الدخل',
            table_head: {
                accountName: 'الحساب',
                date: 'تاريخ',
                payee: 'المستفيد',
                categoryName: 'الفئة',
                tags: 'العلامات',
                amount: 'المبلغ',
            },
            empty_chart_header: 'لا شيء بعد لهذا الشهر',
            empty_chart_label: 'حاول اختيار نطاق زمني مختلف',
            empty: 'لا توجد معاملات حتى الآن',
            all_other_categories: 'جميع الفئات الأخرى',
            chart_legend_income: 'دخل',
            chart_legend_expense: 'مصروف',
            chart_legend_net_income: 'صافي الدخل',
        },
        netWorthView: {
            header_accounts: 'حسابات',
            header_netWorth: 'صافي القيمة',
        },
    },
    reporting: {
        netWorth: {
            other_assets: 'أصول أخرى',
            other_debts: 'ديون أخرى',
        },
    },
    widgets: {
        networth_title: 'صافي ثروتك',
        networth_subtitle: '',
        networth_yaxis_tooltip: 'صافي ثروتك',
        networth_total_title: 'المجموع',
        networth_asset_title: 'ممتلكاتي',
        networth_liability_title: 'ديوني',
        networth_help: 'معرفة صافي الثروة  يساعد في تقييم صحة وضعك المالي و التخطيط للمستقبل',
        networth_nodata_title: 'لا توجد بيانات كافية لحساب صافي ثروتك!',
        networth_trend_nodata_title: 'حاول إضافة المعاملات للحصول على بعض الإحصاءات',
        spent_title: 'أنفقت',
        spent_subtitle: '',
        spent_period_from: 'من',
        spent_period_to: 'اليوم',
        spent_average_title: '',
        spent_dailyAverage_title: 'متوسط المصاريف اليومية',
        spent_dailyAverage_title_empty: 'ليس لدينا بيانات كافية لتحليل متوسط إنفاقك',
        spent_dailyAverageVariable_title: 'متوسط المصاريف المتغيرة اليومية',
        spent_dailyAverageVariable_title_empty: 'ليس لدينا بيانات كافية لتحليل متوسط إنفاقك المتغير',
        expensesClassification_fixed_title: 'ثابت',
        expensesClassification_variable_title: 'متغير',
        expensesClassification_nodata_title: 'حاول إضافة المعاملات للحصول على بعض الإحصاءات',
        expensesClassification_fixed_variable_help:
            'يظهر هذا المؤشر  المصاريف خلال الستة أشهر سابقة مقسمة إلى مصاريف شهرية ثابتة و مصاريف متغيرة، حيث أن المصاريف الثابتة هي المصاريف المتكررة في كل شهر بنفس القيم و تدفع بشكل شهري مثل الفواتير و الايجار و الأقساط، أما المصاريف المتغيرة هي المصاريف التي قد تدفع أكثر من مرة في الشهر و التي تكون ذا ت قيم مختلفة في كل مرة تدفع بها مثل مصاريف الطعام و المشتريات  و مصاريف صيانة السيارة.',
        expensesClassification_fixed_variable_title: 'مؤشر المصاريف الثابتة و المتغيرة',
        expensesClassification_essential_title: 'أساسي',
        expensesClassification_non_essential_title: 'غير أساسي',
        expensesClassification_essential_non_essential_help: 'يظهر هذا المؤشر المصاريف مقسمة إلى مصاريف أساسية و مصاريف كمالية.',
        expensesClassification_essential_non_essential_title: 'مؤشر المصاريف الرئيسية و الكمالية',
        spent_recurring_title: 'المصاريف الاكثر تكرارا',
        spent_recurring_subtitle: '',
        expensesCategory_secondary_title: 'المصاريف الاكثر انفاقا',
        transactions_income_title: 'الدخل',
        transactions_expense_title: 'الانفاق',
        savings_goal_title: 'أهداف الادخار',
        savings_goal_subtitle: '',
        savings_goal_create_info: 'ابدأ التوفير للعناصر التي تهتم بها',
        savings_goal_create_title: 'إنشاء هدف',
        spending_watchlist_title: 'قائمة مراقبة الإنفاق',
        spending_watchlist_subtitle: '',
        spending_watchlist_create_info: 'تتبع إنفاقك بسهولة عن طريق إنشاء قائمة مراقبة الفئات',
        spending_watchlist_create_title: 'إنشاء قائمة مراقبة',
        spend_upcoming_title: 'الإنفاق القادم',
        spend_upcoming_subtitle: '',
        spend_upcoming_no_spending: 'نجاح باهر! ليس لديك أي معاملات قادمة',
    },
    enums: {
        accountTypes: {
            banking: 'المصرفية',
            credit: 'الائتمان',
            cash: 'النقدية',
            other: 'حسابات أخرى',
        },
    },
    currencies: {
        USD: 'الدولار الأمريكي',
        GBP: 'الجنيه البريطاني',
        EUR: 'اليورو',
        SYP: 'الليرة السورية',
        SAR: 'الريال السعودي',
        JOD: 'دينار أردني',
        AED: 'درهم اماراتى',
        TRY: 'الليرة التركية',
    },
    transactions: {
        emptyStateTxn1: 'لم يتم العثور على أية حركات للفترة',
        emptyStateTxn2: 'حاول إضافة معاملة أو تغيير إعدادات التصفية الخاصة بك.',
        emptyTransactions_addNow: 'أضف الآن',
        transferFrom: 'التحويل من',
        transferTo: 'التحويل إلى',
        labelStartingBalanceStats: 'الرصيد الإفتتاحي',
        labelStartingBalance: 'الرصيد الإفتتاحي في',
        labelEndingBalance: 'الرصيد الختامي في',
        labelReceived: 'مجموع الوارد في',
        labelReceived_liability: 'إجمالي الديون في',
        labelSpentOn: 'مجموع الصادر في',
        labelSpentOn_liability: 'مجموع الوارد في',
        labelReceivedDay: 'مجموع الوارد في يوم',
        labelSpentOnDay: 'مجموع الصادر في يوم',
        timelineTotalSpend: 'إجمالي حركة الحساب خلال الفترة',
        timelineTotalTransactionSpend: 'إجمالي حركة الحساب بدون تحويلات',
        timelineTotalTransfersInSpend: 'مجموع التحويلات الواردة',
        timelineTotalTransfersOutSpend: 'مجموع التحويلات الصادرة',
        timelineTotalTransfersSubHeader: 'إحصاءات المعاملات',
        report_text_totalExpenses: 'إجمالي المصروفات',
        report_text_totalIncomes: 'إجمالي الدخل',
        pending: 'المعاملة قيد الانتظار',
        toolbar: {
            new_standard: 'أضف معاملة',
            new_split: 'أضف معاملة مجزأة',
            new_transfer: 'أضف تحويل جديد',
            account_options: 'خيارات الحساب',
            show_monthly_totals: 'إظهار المجاميع الشهرية',
            show_daily_totals: 'إظهار المجاميع اليومية',

            show_current_month: 'الشهر الحالي',
            show_previous_month: 'الشهر الماضى',
            show_previous_6_months: 'الأشهر الستة السابقة',

            select_category_primary: 'تمكين الفئات الأساسية',
            select_category_secondary: 'تمكين الفئات الثانوية',
            clear_category_primary: 'مسح الفئات الأساسية',
            clear_category_secondary: 'مسح الفئات الثانوية',
        },
        assetInCredit: '',
        assetInDebt: 'رصيد حسابك مدين',
        liabilityInDebit: 'أنت مستحق المال',
        liabilityInCredit: 'أنت مدين بالمال',
        viewMoreAnalytics: 'عرض المزيد من التحليلات',
    },
    dates: {
        periodCurrentMonth: 'الشهر الحالي',
        periodCurrentYear: 'السنة الحالية',
        periodPrv3Months: 'آخر 3 أشهر',
        periodPrv6Months: 'آخر 6 أشهر',
        periodPrvMonth: 'الشهر السابق',
        periodPrvYear: 'السنة السابقة',

        report_AllDates: 'كل التواريخ',
        report_AllToDates: 'كل التواريخ لغاية اليوم',
        report_CurrentFiscalYear: 'السنة المالية',
        report_CurrentMonth: 'الشهر الحالي',
        report_CurrentYear: 'السنة الحالية',
        report_CustomDates: 'حدد التاريخ',
        report_FiscalYearToDate: 'أول السنة المالية لغاية اليوم',
        report_Last12Months: 'اخر 12 أشهر',
        report_Last30Days: 'اخر 30 يوم',
        report_Last3Months: 'اخر 3 أشهر',
        report_Last6Months: 'آخر 6 أشهر',
        report_MonthToDate: 'أول الشهر لغاية اليوم',
        report_Next12Months: '12شهر لاحق',
        report_Next30Days: '30 يوم لاحق',
        report_Next3Months: '3 أشهر لاحقة',
        report_Next6Months: '6 أشهر لاحقة',
        report_PreviousFiscalYear: 'السنة المالية السابقة',
        report_PreviousMonth: 'الشهر السابق',
        report_PreviousYear: 'السنة السابقة',
        report_YearToDate: 'أول السنة لغاية اليوم',
    },
    filter: {
        report_Expenses_MenuButton: 'فئات الإنفاق',
        report_Incomes_MenuButton: 'فئات الدخل',
        report_Payees_MenuButton: 'المستفيدون',
        report_Accounts_MenuButton: 'حسابات',
        no_transactions: 'لا توجد معاملات مع عامل التصفية الحالي',
        button_filter: 'تصفية التقرير',
        filter_panel_selections: 'اختيارات التصفية الخاصة بك:',
        filter_panel_selections_categories: 'الفئات المختارة:',
        filter_panel_selections_payees: 'المستفيدون المختارة:',
        filter_panel_selections_accounts: 'الحسابات المختارة:',
    },
    bookkeeping: {
        filter_all: 'الجميع',
        filter_primary: 'أساسي',
        filter_secondary: 'ثانوي',
        filter_fixed: 'ثابت',
        filter_variable: 'المتغيرات',
        filter_search: 'قم بالبحث في كل الفئات ...',
        filter_search_payee: 'البحث في جميع المستفيدين ...',
        categories: {
            table_head_name: 'اسم التصنيف',
            table_head_costType: 'نوع',
            table_head_costImportance: 'العلامات',
            table_head_active: 'حالة',
            table_head_count: 'عدد الفئات الفرعية',
            costType_Fixed: 'ثابت',
            costType_Variable: 'متغير',
            costImportance_Essential: 'ضروري',
            costImportance_NonEssential: 'غير ضروري',
            status_Active: 'ممكن',
            new_category: 'أضف فئة جديدة',
        },
        payees: {
            table_head_name: 'أسم المستفيد',
            table_head_active: 'حالة',
            new_payee: 'مستفيد جديد',
        },
    },
    footer: {
        company: 'Wyzr',
        menu_home: 'الرئيسية',
        menu_about: 'من نحن',
        menu_contact: 'اتصل بنا',
        menu_contact1: 'الاتصال',
        menu_faqs: 'الاسئلة الشائعة',
        menu_privacy: 'سياسة حرية المعلومات',
        menu_legal: 'ال قانونيات',
        menu_terms: 'الشروط والأحكام',
        footer_privacy:
            'عندما تزور مواقعنا أو خدماتنا أو أدواتنا أو تتفاعل معها، يجوز لنا أو لمقدمي الخدمة المعتمدين لدينا استخدام ملفات تعريف الارتباط لتخزين المعلومات للمساعدة في تزويدك بتجربة أفضل وأسرع وأكثر أمانًا ولأغراض تسويقية.',
    },
    product_guide: {
        continue: 'حسنًا، فهمت!',
        back: 'عُد',
        intro: 'تقديم Wyzr MVP: لمحة أولى عن البساطة والابتكار!',
        step_highlights_title: 'النقاط الرئيسية',
        step_highlights_desc: 'هذه نسخة مختصرة من Wyzr تؤدي الوظائف الأساسية فقط. ترقبوا الميزات والتحديثات القادمة.',
        step_highlights_info1: 'نحن نحدد إذا كان عملاؤنا يجدون أن تصميم المنتج سهل الاستخدام ومفيد لاحتياجاتك.',
        step_highlights_info2: 'لذا فإن تعليقاتك مهمة جدًا بالنسبة لنا.',
        step_expectation_title: 'ماذا اتوقع من هذا الإصدار؟',
        step_expectation_desc: 'تصفح الميزات أدناه للحصول على فهم للوظائف المتاحة:',

        feature0_title: 'الميزات العامة',
        feature0_item1: 'اشتراك واحد لكل حساب',
        feature0_item2: 'من المحتمل أن يكون الأداء بطيئًا',
        feature0_item3: 'التسجيل وتسجيل الدخول فقط',
        feature0_item4: 'اختر الهيكل المحاسبي من القوالب',

        feature1_title: 'معاملات الحساب',
        feature1_item1: 'إدخال المعاملة اليدوية',
        feature1_item2: '25 إدخالات شهريا',
        feature1_item3: 'تصفية المعاملات حسب التاريخ، المستفيد، والفئة',
        feature1_item4: 'الإحصائيات الأساسية',
        feature1_item5: 'التحويل بين الحسابات',

        feature2_title: 'حسابات',
        feature2_item1: 'حسابات الائتمان والخصم والنقد فقط',
        feature2_item2: 'يقتصر على 4 لكل منهما',
        feature2_item3: 'الإحصائيات الأساسية',
        feature2_item4: 'فتح حسابات بعملات مختلفة',
        feature2_item5: 'سعر الصرف اليدوي للتحويلات',
        feature2_item6: 'يستخدم أسعار صرف الشهر السابق لتحويلات المبلغ',
        feature2_item7: 'إعدادات الحساب الأساسية',

        feature3_title: 'التقارير',
        feature3_item1: 'التصفية حسب الحسابات والفترة والفئة والمدفوع لأمره والعلامات',
        feature3_item2: 'تقرير الإنفاق',
        feature3_item3: 'تقرير الدخل',
        feature3_item4: 'تقرير صافي الدخل',
        feature3_item5: 'تقرير صافي القيمة',

        feature4_title: 'المستفيدين',
        feature4_item1: 'إضافة وتحرير المستفيدين',

        feature5_title: 'الفئات',
        feature5_item1: 'إضافة وتحرير الدخل',
        feature5_item2: 'إضافة وتحرير النفقات',
        feature5_item3: 'أضف العلامات المحددة مسبقًا فقط',

        get_started: 'هيا بنا نبدأ!',
    },
};

export default ar;
