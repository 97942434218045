import Stack from '@mui/material/Stack';
import withReportView from './hooks/withReportView';
import ClassificationToolbar from '../../../components/ClassificationFilter';
import useReportDataContext from '../components/useReportDataContext';
import ChartView from './ChartView';
import TableView from './TableView';

const Overview = () => {
    const { isMutating, data } = useReportDataContext();
    return (
        <Stack sx={{ width: '100%' }}>
            <ClassificationToolbar hideBreakdowns/>
            <ChartView isMutating={isMutating} data={data}/>
            <TableView isMutating={isMutating} data={data}/>
        </Stack>
    );
};
Overview.propTypes = {
};

export default withReportView(Overview);