import PropTypes from 'prop-types';
import CustomBreadcrumbs from "./CustomBreadcrumbs";
import {useLocales} from "../../locales";


const Breadcrumbs = ({heading, links, ...props}) => {
    const {t} = useLocales();
    const translatedHeading = t(heading);
    const translatedLinks = links.map(item => {
        if (item.href) {
            return {
                name: t(item.name),
                href: item.href
            }
        }
        return {
            name: t(item.name)
        }
    });

    return (
        <CustomBreadcrumbs
            heading={translatedHeading}
            links={translatedLinks}
            {...props}
        />
    )
}
Breadcrumbs.propTypes = {
    links: PropTypes.array.isRequired,
    heading: PropTypes.string.isRequired,
};

export default Breadcrumbs;