import PropTypes from 'prop-types';
// @mui
import {Paper, Typography} from '@mui/material';
import {useLocales} from "../../locales";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
    query: PropTypes.string,
    sx: PropTypes.object,
};

export default function SearchNotFound({query, sx, ...other}) {
    const {translate} = useLocales();
    return query ? (
        <Paper
            sx={{
                textAlign: 'center',
                ...sx,
            }}
            {...other}
        >
            <Typography variant="h6" paragraph>
                {translate('common.search_not_found')}
            </Typography>

            <Typography variant="body2">
                {translate('common.search_no_results')} &nbsp;
                <strong>&quot;{query}&quot;</strong>.
                <br/> {translate('common.search_tip1')}
            </Typography>
        </Paper>
    ) : (
        <Typography variant="body2" sx={sx}>
            {translate('common.search_tip2')}
        </Typography>
    );
}
