import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useReducer, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from './FormProvider';

const initialState = {
    fieldsToValidate: [],
    asyncCounters: [],
    asyncInProgress: false,
    submitInProgress: false,
};
const handlers = {
    INITIALIZE: (state, action) => {
        const fields = action.payload;
        return {
            ...state,
            fieldsToValidate: [...fields],
        };
    },
    ASYNCSTART: (state, action) => {
        const key = action.payload;
        const { asyncCounters } = state;
        return {
            ...state,
            asyncCounters: [...asyncCounters, key],
            asyncInProgress: true,
        };
    },
    ASYNCEND: (state, action) => {
        const key = action.payload;
        const { asyncCounters } = state;
        const idx = asyncCounters.findIndex((r) => r === key);
        asyncCounters.splice(idx, 1);
        return {
            ...state,
            asyncCounters: [...asyncCounters],
            asyncInProgress: asyncCounters.length > 0,
        };
    },
    SUBMITSTART: (state) => ({
        ...state,
        submitInProgress: true,
    }),
};
const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const FormYupContext = createContext({ ...initialState });
FormYupProvider.propTypes = {
    children: PropTypes.node.isRequired,
    schema: PropTypes.object.isRequired,
    defaultValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default function FormYupProvider({ children, schema, defaultValues, onSubmit }) {
    const [state, setState] = useReducer(reducer, initialState);

    // TODO make mode configurable
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues,
    });
    const { reset, handleSubmit, trigger, getValues, register, setValue: formSetValue } = methods;

    const onSubmitWrapper = async (data) => {
        try {
            setState({
                type: 'SUBMITSTART',
            });
            await onSubmit(data);
        } catch (error) {
            console.error(error);
        }
    };
    const registerFieldValidations = useCallback((fields) => {
        setState({
            type: 'INITIALIZE',
            payload: fields,
        });
    }, []);
    const registerFieldAndValidation = useCallback(
        (key) => {
            register(key);
            registerFieldValidations([key]);
        },
        [register, registerFieldValidations]
    );
    const onRegisteredFieldsValidation = useCallback(async () => trigger(state.fieldsToValidate), [state.fieldsToValidate, trigger]);

    const getValue = useCallback(
        (key) => {
            const values = getValues([key]);
            return values.length >= 1 ? values[0] : undefined;
        },
        [getValues]
    );
    const setValue = useCallback(
        (key, value) => {
            formSetValue(key, value, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
            });
        },
        [formSetValue]
    );

    const asyncSwrStart = (key) => {
        setState({
            type: 'ASYNCSTART',
            payload: key,
        });
    };
    const asyncSwrEnd = (key) => {
        setState({
            type: 'ASYNCEND',
            payload: key,
        });
    };
    const swrValidating = useCallback((data) => {
        const { key, isValidating } = data;
        if (isValidating) {
            asyncSwrStart(key);
        } else {
            asyncSwrEnd(key);
        }
    }, []);

    useEffect(() => {
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const values = useMemo(
        () => ({
            registerFieldAndValidation,
            registerFieldValidations,
            onRegisteredFieldsValidation,
            getValue,
            setValue,
            swrValidating,
            swrInProgress: state.asyncInProgress,
        }),
        [registerFieldAndValidation, registerFieldValidations, onRegisteredFieldsValidation, getValue, setValue, swrValidating, state]
    );

    return (
        <FormYupContext.Provider value={values}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitWrapper)} submitInProgress={state.submitInProgress}>
                {children}
            </FormProvider>
        </FormYupContext.Provider>
    );
}

export {
    FormYupContext,
};