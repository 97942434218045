import PropTypes from "prop-types";
import {withSummaryWidget} from "../components";
import ExpensesFixedVariableTrend from "./ExpensesFixedVariableTrend";
import ExpensesEssentialNonEssentialTrend from "./ExpensesEssentialNonEssentialTrend";

const ExpensesByClassificationWidget = ({type}) => (
    <>
        {type === 'FixedAndVariable' &&
            <ExpensesFixedVariableTrend />
        }
        {type === 'EssentialAndNonEssential' &&
            <ExpensesEssentialNonEssentialTrend />
        }
    </>
);
ExpensesByClassificationWidget.propTypes = {
    type: PropTypes.oneOf(['FixedAndVariable', 'EssentialAndNonEssential'])
};

export default withSummaryWidget(
  ExpensesByClassificationWidget,
  'title_hidden',
  'sub_title_hidden',
    false
);