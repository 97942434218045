import PropTypes from "prop-types";
import {useEffect} from "react";
import axios from "axios";
import useSWR from "swr";
import {SkeletonLoad} from "../skeleton";

const fetcher = url => axios.get(url).then(res => res.data);
const SkeletonFormSwr = ({swrKey, dataKey, mode, children, swrOptions, onSwrValidating}) => {
    const {data, error, isValidating} = useSWR(swrKey, fetcher, swrOptions || {});
    useEffect(() => {
        if (onSwrValidating) {
            onSwrValidating({
                key: swrKey,
                isValidating
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValidating])

    if (error) return <div>failed to load</div>
    if (!data) return <SkeletonLoad/>

    if (mode === 'multiple') {
        const dataItems = data ? data[dataKey] || [] : [];
        return (
            <>
                {dataItems.map((item, index) => (
                    children({...item, index})
                ))}
            </>
        )
    }

    return (
        <>
            {children({...data})}
        </>
    )
}
SkeletonFormSwr.propTypes = {
    // TODO what is the prop type for a REACT NODE
    children: PropTypes.any,
    swrKey: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(['single', 'multiple']).isRequired,
    swrOptions: PropTypes.object,
    onSwrValidating: PropTypes.func,
}

export default SkeletonFormSwr;