import PropTypes from "prop-types";
import { useRef } from 'react';
import { Button } from '@mui/material';
import { Icon } from "@iconify/react";
import baselineExpand from '@iconify/icons-ant-design/down-outlined';
import { useLocales } from "../../../../locales";

const MegaMenuButton = ({label, labelLanguageCode, id, toggleFilter}) => {
    const {t} = useLocales();
    const anchorRef = useRef(null);
    const handleMenuOpen = () => {
        toggleFilter(id);
    }
    return (
        <Button
            color="inherit"
            endIcon={<Icon icon={baselineExpand}/>}
            onClick={handleMenuOpen}
            ref={anchorRef}
            variant="text">
            {t(labelLanguageCode, label)}
        </Button>
    )
};
MegaMenuButton.propTypes = {
    label: PropTypes.string.isRequired,
    labelLanguageCode: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    toggleFilter: PropTypes.func.isRequired
}

export default MegaMenuButton;