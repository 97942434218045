// todo key must always be of type Amount
export const sumArray = (data, key) => {
    if (!Array.isArray(data)) {
        return 0.0;
    }
    return data.reduce((r, next) => {
        // eslint-disable-next-line no-unused-vars
        const {asInt, asDouble} = next[key];
        return r + Number.parseFloat(asDouble);
    }, 0.0);
}