import PropTypes from 'prop-types';
import {withAuth0} from '@auth0/auth0-react';
// import {Navigate} from 'react-router-dom';
import LoadingScreen from '../components/loading-screen';
// import {useAuthContext} from '../hooks/useAuthContext';


const GuestGuard = ({children, ...props}) => {
    // const {userId} = useAuthContext();
    const {isLoading} = props;

    if (isLoading) {
        return <LoadingScreen/>;
    }
    // if (isAuthenticated) {
    //     return <Navigate to="/dashboard"/>;
    // }

    return (
        <>{children}</>
    )
}
GuestGuard.propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool,
};

export default withAuth0(GuestGuard);