import type { Category } from "../../api/CategoriesApi";

export type SecondaryCategory = {
    id: string,
    name: string,
};

export type PrimaryCategory = {
    label: string,
    id: string,
    type: string,
    categories: Array<SecondaryCategory>
};

export type SimplifiedCategory = {
    id: string,
    name: string,
    primary: string,
    costImportance: string,
    costType: string,
};

export type CategoriesContextState = {
    onToggleRefresh(): void,
    onUpdateCategory: () => void,
    onCreateCategory: () => void,
    getVariantCategories(variant: string): Array<PrimaryCategory>,
    getVariantCategoriesByLevels(variant: string): Array<SimplifiedCategory>,
    getCategoriesForTable(variant: string): Array<SimplifiedCategory>,
    refresh: boolean,
    data: Array<Category>,
    isMutating: boolean,
};

export const INIT_DATA = 'INIT_DATA';
export const REFRESH = 'REFRESH';
