import { Box } from '@mui/material';
import { NetIncomeReport } from '../../../../../api/TransactionReportsApi';
import ChartSkeleton from '../components/ChartSkeleton';
import ChartEmpty from '../components/ChartEmpty';
import ChartOptions from './components/ChartOptions';
import ChartByAll from './components/ChartByAll';


type Props = {
    isMutating: boolean,
    data: NetIncomeReport,
};

const ChartView = ({ isMutating, data }: Props) => {
    if (isMutating) {
        return <ChartSkeleton />;
    }

    const { totalIncomes, totalExpenses, netIncomes, transactions, incomes, expenses } = data || {};
    if ((transactions || []).length === 0) {
        return (
            <Box mb={4}>
                <ChartOptions totalIncomes={totalIncomes} totalExpenses={totalExpenses}/>
                <ChartEmpty />
            </Box>
        )
    }

    return (
        <Box mb={4}>
            <ChartOptions totalIncomes={totalIncomes} totalExpenses={totalExpenses}/>
            <ChartByAll netIncomes={netIncomes} totalIncomes={incomes} totalExpenses={expenses}/>
        </Box>
    )
}

export default ChartView;