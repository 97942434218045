import PropTypes from "prop-types";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import Button from "@mui/material/Button";
import { useLocales } from "../../../../../locales";


const TransactionFormSubmit = ({isUpdate}) => {
    const {translate} = useLocales();
    const categoryType = useWatch({
        name: 'transactionCategoryType',
    });
    const label = useMemo(() => {
        if (isUpdate) {
            return categoryType.toLowerCase() === 'expense'
                ? translate('management.transaction.submit_update_expense')
                : translate('management.transaction.submit_update_income');
        }
        return categoryType.toLowerCase() === 'expense'
            ? translate('management.transaction.submit_add_expense')
            : translate('management.transaction.submit_add_income');
    }, [categoryType, translate, isUpdate]);

    return (
        <Button variant="contained" size="large" type="submit">
            {label}
        </Button>
    )
};
TransactionFormSubmit.propTypes = {
    isUpdate: PropTypes.bool.isRequired
}

export default TransactionFormSubmit;