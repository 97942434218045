import { useEffect } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useLocales } from '../../../../locales';
import { useFormYup } from '../../../../components/hook-form/useFormYup';
import { StyledTextField, StyledPicker, StyledNumeric } from '../../../../components/styled-inputs';
import { currencies } from '../../../../assets/data';
import { getHelperText } from './utils';

const AccountBasics = () => {
    const { translate, currentLanguage } = useLocales();
    const { registerFieldValidations } = useFormYup();
    const { control } = useFormContext();
    const { dirtyFields } = useFormState({
        control,
    });
    const accountClassType = useWatch({
        name: 'accountClassType',
    });
    const accountOpeningBalance = useWatch({
        name: 'accountOpeningBalance',
    });
    const helperText = getHelperText(translate, accountClassType, accountOpeningBalance);

    useEffect(() => {
        registerFieldValidations(['accountName', 'accountOpeningBalance', 'accountOpeningDate', 'accountCurrency']);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 15 }}>
            <StyledTextField
                name="accountName"
                header={translate('management.account.name_title')}
                fullWidth
                required
                label={translate('management.account.name_label')}
            />

            <StyledNumeric
                name="accountOpeningBalance"
                header={translate('management.account.openingBalance_title')}
                label={translate('management.account.openingBalance_label')}
                required
                helperText={helperText}
            />

            <StyledPicker
                name="accountOpeningDate"
                header={translate('management.account.openingDate_title')}
                label={translate('management.account.openingDate_label')}
                inputViews={['year', 'month']}
                inputFormat="MMMM yyyy"
                inputActions={[]}
                useMaxDate={false}
            />

            <StyledTextField
                name="accountCurrency"
                header={translate('management.account.currency_title')}
                select
                required
                label={translate('management.account.currency_label')}
                placeholder={translate('management.account.currency_label')}
            >
                {currencies[currentLanguage.value].map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                        {option.name}
                    </MenuItem>
                ))}
            </StyledTextField>

            <StyledTextField
                name="accountDescription"
                header={translate('management.account.description_title')}
                fullWidth
                multiline
                rows={4}
                label={translate('management.account.description_label')}
            />

            {dirtyFields.accountName && dirtyFields.accountCurrency && (
                <Typography variant="subtitle1" sx={{ color: (theme) => theme.palette.success.main, mb: { xs: 3, xl: 5 } }}>
                    {translate('management.account.proceedToNextStep')}
                </Typography>
            )}
        </Stack>
    );
};

export default AccountBasics;
