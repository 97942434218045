import PropTypes from "prop-types";
import {SkeletonBarLoad} from "../../../../../components/skeleton";
import {TransactionReportsUrlBuilder} from "../../../../../api/TransactionReportsApi";
import {withWidgetSwr} from "../components";
import NoData from "./NoData";
import ExpensesByClassification from "./ExpensesByClassification";

const ExpensesEssentialNonEssentialTrend = ({data, isRTL}) => (
    <ExpensesByClassification
        data={data}
        isRTL={isRTL}
        firstClassificationDataTitle="widgets.expensesClassification_essential_title"
        secondClassificationDataTitle="widgets.expensesClassification_non_essential_title"
        title="widgets.expensesClassification_essential_non_essential_title"
        noData={
            <NoData
                help="widgets.expensesClassification_essential_non_essential_help"
                title="widgets.expensesClassification_essential_non_essential_title"
            />
        }
    />
)
ExpensesEssentialNonEssentialTrend.propTypes = {
    data: PropTypes.object.isRequired,
    isRTL: PropTypes.bool.isRequired,
};

export default withWidgetSwr(ExpensesEssentialNonEssentialTrend, TransactionReportsUrlBuilder.getExpensesEssentialNonEssentialTrend, <SkeletonBarLoad />);