import { useContext } from "react";
import { PayeesContext } from "./PayeesProvider";
import type { PayeesContextState } from "./types";

const usePayeesContext = () : PayeesContextState => {
    const context: PayeesContextState = useContext(PayeesContext);
    if (context === undefined) {
        throw new Error('usePayeesContext must be used within a Payees Provider')
    }
    return context;
}

export default usePayeesContext;