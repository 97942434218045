import PropTypes from 'prop-types';
import {noCase} from 'change-case';
import {useState} from 'react';
// @mui
import {
    Box,
    Stack,
    List,
    Badge,
    Button,
    Avatar,
    Tooltip,
    Divider,
    IconButton,
    Typography,
    ListItemText,
    ListSubheader,
    ListItemAvatar,
    ListItemButton,
} from '@mui/material';
import {styled} from '@mui/material/styles';
// utils
import {fToNow} from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import {IconButtonAnimate} from '../../../components/animate';
import {useLocales} from "../../../locales";
import {responsiveFontSizes} from "../../../utils/fontSizes";

// ----------------------------------------------------------------------

// ** Styled Components
const BadgeContentSpan = styled('span')(({theme}) => ({
    // width: 8,
    // height: 8,
    // borderRadius: '50%',
    // backgroundColor: theme.palette.success.main,
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    color: theme.palette.success.main,
    ...responsiveFontSizes({sm: 8, md: 8, lg: 10})
}))

export default function NotificationsPopover() {
    const [openPopover, setOpenPopover] = useState(null);
    const _notifications = [];
    const [notifications, setNotifications] = useState(_notifications);
    const {translate} = useLocales();

    const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;
    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };
    const handleClosePopover = () => {
        setOpenPopover(null);
    };
    const handleMarkAllAsRead = () => {
        setNotifications(
            notifications.map((notification) => ({
                ...notification,
                isUnRead: false,
            }))
        );
    };

    return (
        <>
            <IconButtonAnimate
                color={openPopover ? 'primary' : 'default'}
                onClick={handleOpenPopover}
                sx={{width: 40, height: 40}}
            >
                <Badge badgeContent={totalUnRead} color="error">
                    <Iconify icon="ant-design:notification-outlined"/>
                </Badge>
            </IconButtonAnimate>

            <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{width: 380, p: 0}}>
                <Box sx={{display: 'flex', alignItems: 'center', py: 2, px: 2.5}}>
                    <Box sx={{flexGrow: 1}}>
                        <Badge
                            overlap="rectangular"
                            badgeContent={
                                <BadgeContentSpan>
                                    {translate('common.coming_soon')}
                                </BadgeContentSpan>
                            }
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{...responsiveFontSizes({sm: 16, md: 16, lg: 18})}}
                            >
                                {translate('common.notifications')}
                            </Typography>
                        </Badge>

                        <Typography variant="body2" sx={{color: 'text.secondary'}}>
                            {translate("common.notifications_count_pre")} {totalUnRead} {translate("common.notifications_count_post")}
                        </Typography>
                    </Box>

                    {totalUnRead > 0 && (
                        <Tooltip title=" Mark all as read">
                            <IconButton color="primary" onClick={handleMarkAllAsRead}>
                                <Iconify icon="eva:done-all-fill"/>
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>

                <Divider sx={{borderStyle: 'dashed'}}/>

                {notifications.length > 0 &&
                    <Scrollbar sx={{height: {xs: 340, sm: 'auto'}}}>
                        <List
                            disablePadding
                            subheader={
                                <ListSubheader disableSticky sx={{py: 1, px: 2.5, typography: 'overline'}}>
                                    New
                                </ListSubheader>
                            }
                        >
                            {notifications.slice(0, 2).map((notification) => (
                                <NotificationItem key={notification.id} notification={notification}/>
                            ))}
                        </List>

                        <List
                            disablePadding
                            subheader={
                                <ListSubheader disableSticky sx={{py: 1, px: 2.5, typography: 'overline'}}>
                                    Before that
                                </ListSubheader>
                            }
                        >
                            {notifications.slice(2, 5).map((notification) => (
                                <NotificationItem key={notification.id} notification={notification}/>
                            ))}
                        </List>
                    </Scrollbar>
                }

                <Divider sx={{borderStyle: 'dashed'}}/>

                <Box
                    sx={{
                        py: 3.5,
                        px: 2,
                        borderBottom: 0,
                    }}
                >
                    <Button fullWidth disableRipple variant='contained'>
                        {translate('common.notifications_view_all')}
                    </Button>
                </Box>

            </MenuPopover>
        </>
    );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
    notification: PropTypes.shape({
        id: PropTypes.string,
        avatar: PropTypes.node,
        type: PropTypes.string,
        title: PropTypes.string,
        isUnRead: PropTypes.bool,
        description: PropTypes.string,
        createdAt: PropTypes.instanceOf(Date),
    }),
};

function NotificationItem({notification}) {
    const {avatar, title} = renderContent(notification);

    return (
        <ListItemButton
            sx={{
                py: 1.5,
                px: 2.5,
                mt: '1px',
                ...(notification.isUnRead && {
                    bgcolor: 'action.selected',
                }),
            }}
        >
            <ListItemAvatar>
                <Avatar sx={{bgcolor: 'background.neutral'}}>{avatar}</Avatar>
            </ListItemAvatar>

            <ListItemText
                disableTypography
                primary={title}
                secondary={
                    <Stack direction="row" sx={{mt: 0.5, typography: 'caption', color: 'text.disabled'}}>
                        <Iconify icon="eva:clock-fill" width={16} sx={{mr: 0.5}}/>
                        <Typography variant="caption">{fToNow(notification.createdAt)}</Typography>
                    </Stack>
                }
            />
        </ListItemButton>
    );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
    const title = (
        <Typography variant="subtitle2">
            {notification.title}
            <Typography component="span" variant="body2" sx={{color: 'text.secondary'}}>
                &nbsp; {noCase(notification.description)}
            </Typography>
        </Typography>
    );

    if (notification.type === 'order_placed') {
        return {
            avatar: <img alt={notification.title} src="/assets/icons/notification/ic_package.svg"/>,
            title,
        };
    }
    if (notification.type === 'order_shipped') {
        return {
            avatar: <img alt={notification.title} src="/assets/icons/notification/ic_shipping.svg"/>,
            title,
        };
    }
    if (notification.type === 'mail') {
        return {
            avatar: <img alt={notification.title} src="/assets/icons/notification/ic_mail.svg"/>,
            title,
        };
    }
    if (notification.type === 'chat_message') {
        return {
            avatar: <img alt={notification.title} src="/assets/icons/notification/ic_chat.svg"/>,
            title,
        };
    }
    return {
        avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar}/> : null,
        title,
    };
}
