import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, Stack, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useLocales } from '../../../../../locales';
import useDateRangeContext from '../../../../../contexts/DateRange/useDateRangeContext';
import { reportBreakdowns } from '../../../../../assets/data/reports';
import ClassificationFilter from './ClassificationFilter';
import useClassificationFilterContext from '../useClassificationFilterContext';
import useReportDataContext from '../../../features/Reports/components/useReportDataContext';

const RootStyle = styled(Card)(({ theme, ownerState }) => ({
    boxShadow: 'none',
    color: theme.palette.primary.darker,
    backgroundColor: '#eef5fd',
}));

const INPUT_WIDTH = 160;
const INPUT_WIDTH_WIDE = 240;

const ClassificationToolbar = ({ hideExpenses, hideIncomes, hideBreakdowns }) => {
    const { t, currentLanguage } = useLocales();
    const { reportDates: dates } = useDateRangeContext();

    const {
        selectedClassificationType,
        setSelectedClassificationType,
        selectedDates,
        setSelectedDates,
        selectedCategories,
        selectedPayees,
        selectedAccounts,
        handleAddItems,
        handleClearItems,
        hasSelections,
        getFilters,
    } = useClassificationFilterContext();
    const { onFilterReport } = useReportDataContext();

    return (
        <RootStyle>
            <Stack
                spacing={2}
                alignItems="center"
                direction={{
                    xs: 'column',
                    md: 'row',
                }}
                sx={{ px: 1.5, py: 2 }}
            >
                {!hideBreakdowns &&
                    <TextField
                        label={t('reports.classificationFilter.breakdown')}
                        select
                        fullWidth
                        value={selectedClassificationType}
                        onChange={(e) => setSelectedClassificationType(e.target.value)}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    // sx: { maxHeight: 300 },
                                },
                            },
                        }}
                        sx={{
                            maxWidth: { md: INPUT_WIDTH },
                            backgroundColor: '#fff',
                            borderRadius: '4px',
                        }}
                    >
                        {reportBreakdowns[currentLanguage.value].map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                }

                <TextField
                    label={t('reports.classificationFilter.dates')}
                    select
                    fullWidth
                    value={selectedDates}
                    onChange={(e) => setSelectedDates(e.target.value)}
                    SelectProps={{
                        MenuProps: {
                            PaperProps: {
                                // sx: { maxHeight: 300 },
                            },
                        },
                    }}
                    sx={{
                        maxWidth: { md: INPUT_WIDTH_WIDE },
                        backgroundColor: '#fff',
                        borderRadius: '4px',
                    }}
                >
                    {dates.map((option, index) => (
                        <MenuItem key={index} value={option.id}>
                            {t(option.languageCode)}
                        </MenuItem>
                    ))}
                </TextField>

                <ClassificationFilter
                    hideExpenses={hideExpenses}
                    hideIncomes={hideIncomes}
                    onFiltersSelected={handleAddItems}
                    onFiltersCleared={handleClearItems}
                    selectedCategories={selectedCategories}
                    selectedPayees={selectedPayees}
                    selectedAccounts={selectedAccounts}
                    hasSelections={hasSelections}
                    selectedFilters={getFilters()}
                    onFilterReport={onFilterReport}
                />
            </Stack>
        </RootStyle>
    );
};
ClassificationToolbar.propTypes = {
    hideExpenses: PropTypes.bool,
    hideIncomes: PropTypes.bool,
    hideBreakdowns: PropTypes.bool,
};
ClassificationToolbar.defaultProps = {
    hideExpenses: false,
    hideIncomes: false,
    hideBreakdowns: false,
};

export default ClassificationToolbar;