import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { xor } from 'lodash';
import { useLocales } from '../../../../../locales';
import { useTable, TableHeadCustom, TableNoData, getComparator } from '../../../../../components/table';
import CategoryTableRow from './CategoryTableRow';
import type { SimplifiedCategory } from '../../../../../contexts/Categories/types';
import { applyFilter } from './utils';

type Props = {
    isMutating: boolean,
    data: Array<SimplifiedCategory>,
    canReset: boolean,
};

const TABLE_HEAD = [
    { id: 'avatar', label: '', alignRight: false, languageCode: '', type: 'string', order: 0 },
    { id: 'name', label: '', alignRight: false, languageCode: 'bookkeeping.categories.table_head_name', type: 'string', order: 1 },
    { id: 'costType', label: '', alignRight: false, languageCode: 'bookkeeping.categories.table_head_costType', type: 'datetime', order: 2 },
    { id: 'costImportance', label: '', alignRight: false, languageCode: 'bookkeeping.categories.table_head_costImportance', type: 'string', order: 3 },
    { id: 'count', label: '', alignRight: false, languageCode: 'bookkeeping.categories.table_head_count', type: 'string', order: 4 },
    { id: 'active', label: '', alignRight: false, languageCode: 'bookkeeping.categories.table_head_active', type: 'string', order: 5 },
    { id: 'expand', label: '', alignRight: false, languageCode: '', type: 'string', order: 6 },
];

const CategoryList = ({ isMutating, data, canReset }: Props) => {
    const { t } = useLocales();
    const table = useTable({ defaultOrderBy: 'name' });
    const [hiddenIds, setHiddenIds] = useState([]);

    const notFound = data.length === 0;

    const mappedData = applyFilter({
        inputData: data,
        comparator: getComparator(table.order, table.orderBy),
    });

    const shownData = canReset
        ? mappedData
        : mappedData.reduce((acc, item) => {
        if (hiddenIds.findIndex(r => r === item.id) >= 0) {
            return acc;
        }

        acc.push(item);
        return acc;
    }, []);

    const toggleIdsToHide = (ids: Array<string>) => {
        const newIds = xor(hiddenIds, ids);
        setHiddenIds([...newIds]);
    }

    return (
        <TableContainer>
            <Table>
                <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                />
                <TableBody>
                    {(shownData || []).map((row, index) => (
                        <CategoryTableRow row={row} key={`parent_row_${index}`} onToggle={toggleIdsToHide} canReset={canReset} />
                    ))}
                    <TableNoData notFound={notFound} title={t('reports.transactions.empty')} />
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CategoryList;