import { useContext } from "react";
import { ReportDataContext, ReportDataContextState } from './ReportDataProvider';

const useReportDataContext : ReportDataContextState = () => {
    const context : ReportDataContextState = useContext(ReportDataContext);
    if (context === undefined) {
        throw new Error('useReportDataContext must be used within a ReportData Provider')
    }
    return context;
};

export default useReportDataContext;