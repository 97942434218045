import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Badge } from '@mui/material';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
    borderRadius: '25%',
    backgroundColor: theme.palette.warning.light,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    color: theme.palette.grey['900'],
    padding: theme.spacing(1),
    fontSize: '16px',
}));

const ComingSoonBadge = ({ children, sx }) => {
    const { translate } = useLocales();
    return (
        <Badge sx={{ ...sx }} badgeContent={<BadgeContentSpan>{translate('common.coming_soon')}</BadgeContentSpan>}>
            {children}
        </Badge>
    );
};

ComingSoonBadge.propTypes = {
    children: PropTypes.any,
    sx: PropTypes.object,
};

export default ComingSoonBadge;
