import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, Stack, ToggleButton, ToggleButtonGroup, Typography, FormHelperText } from '@mui/material';
import { useLocales } from '../../../../../locales';
import { useFormYup } from '../../../../../components/hook-form/useFormYup';
import useTransactionsContext from '../../../../../contexts/Transactions/useTransactionsContext';

const CategoryTypeToggle = ({ name }) => {
    const { translate } = useLocales();
    const { setValue } = useFormYup();
    const { control } = useFormContext();
    const { selectedAccountClassType } = useTransactionsContext();

    let expenseLabel = translate('management.transaction.category_type_expense');
    let incomeLabel = translate('management.transaction.category_type_income');

    if (selectedAccountClassType === 'Liability') {
        expenseLabel = translate('management.transaction.category_type_expense_liability');
        incomeLabel = translate('management.transaction.category_type_income_liability');
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Stack spacing={1} p={0}>
                <Typography variant="h6">{translate('management.transaction.category_type_title')}</Typography>

                <Controller
                    name={name}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                        const { onChange, ...fields } = field;
                        const onToggleGroupChange = (event, value) => {
                            onChange(event, value);
                            setValue('transactionCategoryId', '');
                        };
                        let helperLabel = field.value === 'Income' ? translate('management.transaction.category_type_income_info') : translate('management.transaction.category_type_expense_info')
                        if (selectedAccountClassType === 'Liability') {
                            helperLabel = field.value === 'Income' ? translate('management.transaction.category_type_income_liability_info') : translate('management.transaction.category_type_expense_liability_info')
                        }
                        return (
                            <div>
                                <ToggleButtonGroup exclusive sx={{ display: 'block' }} onChange={onToggleGroupChange} {...fields}>
                                    <ToggleButton value="Expense">{expenseLabel}</ToggleButton>
                                    <ToggleButton value="Income">{incomeLabel}</ToggleButton>
                                </ToggleButtonGroup>
                                <FormHelperText>
                                    {helperLabel}
                                </FormHelperText>
                            </div>
                        );
                    }}
                />
            </Stack>
        </Box>
    );
};

CategoryTypeToggle.propTypes = {
    name: PropTypes.string,
};

export default CategoryTypeToggle;
