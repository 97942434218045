import { m } from 'framer-motion';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Container, Typography, Stack } from '@mui/material';
// components
import Image from '../../components/image';
import { MotionViewport, varFade } from '../../components/animate';
import Iconify from '../../components/iconify';
import {useLocales} from "../../locales";

// ----------------------------------------------------------------------

const CARDS = [
    {
        icon: ' /assets/icons/home/ic_development.svg',
        title: 'homepage.p1_title',
        description: 'homepage.p1_desc',
        notes: [
            'homepage.p1_feature_1'
        ]
    },
    {
        icon: ' /assets/icons/home/ic_development.svg',
        title: 'homepage.p2_title',
        description: 'homepage.p2_desc',
        notes: [
            'homepage.p2_feature_1',
            'homepage.p2_feature_2',
            'homepage.p2_feature_3'
        ]
    },
    {
        icon: ' /assets/icons/home/ic_development.svg',
        title: 'homepage.p3_title',
        description: 'homepage.p3_desc',
        notes: [
            'homepage.p3_feature_1',
            'homepage.p3_feature_2',
            'homepage.p3_feature_3'
        ]
    },
    {
        icon: ' /assets/icons/home/ic_development.svg',
        title: 'homepage.p4_title',
        description: 'homepage.p4_desc',
        notes: [
            'homepage.p4_feature_1',
            'homepage.p4_feature_2',
            'homepage.p4_feature_3'
        ]
    },
    {
        icon: ' /assets/icons/home/ic_development.svg',
        title: 'homepage.p5_title',
        description: 'homepage.p5_desc',
        notes: [
            'homepage.p5_feature_1',
            'homepage.p5_feature_2',
            'homepage.p5_feature_3',
            'homepage.p5_feature_4',
        ]
    },
];

const StyledRoot = styled('div')(({ theme }) => ({
    padding: theme.spacing(10, 0),
    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(15, 0),
    },
}));

const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    padding: theme.spacing(10, 5),
    [theme.breakpoints.up('md')]: {
        boxShadow: 'none',
    },
}));

// ----------------------------------------------------------------------

export default function LandingFeatures() {
    const {t} = useLocales();
    return (
        <StyledRoot>
            <Container component={MotionViewport}>
                <Stack
                    spacing={3}
                    sx={{
                        textAlign: 'center',
                        mb: { xs: 5, md: 10 },
                    }}
                >
                    <m.div variants={varFade().inUp}>
                        <Typography component="div" variant="overline" sx={{ color: 'text.disabled' }}>
                            Wyzr Money
                        </Typography>
                    </m.div>

                    <m.div variants={varFade().inDown}>
                        <Typography variant="h2">
                            {t('homepage.features_title')}
                        </Typography>
                    </m.div>
                </Stack>

                <Box
                    gap={{ xs: 3, lg: 10 }}
                    display="grid"
                    alignItems="center"
                    gridTemplateColumns={{
                        xs: 'repeat(1, 1fr)',
                        md: 'repeat(3, 1fr)',
                    }}
                >
                    {CARDS.map((card, index) => (
                        <m.div variants={varFade().inUp} key={card.title}>
                            <StyledCard
                                sx={{
                                    ...(index >= 0 && {
                                        boxShadow: (theme) => ({
                                            md: `-40px 40px 80px ${
                                                theme.palette.mode === 'light'
                                                    ? alpha(theme.palette.grey[500], 0.16)
                                                    : alpha(theme.palette.common.black, 0.4)
                                            }`,
                                        }),
                                    }),
                                }}
                            >
                                <Image
                                    src={card.icon}
                                    alt={card.title}
                                    sx={{ mx: 'auto', width: 80, height: 80 }}
                                />

                                <Typography variant="h5" sx={{ mt: 8, mb: 2 }}>
                                    {t(card.title)}
                                </Typography>

                                <Typography sx={{ color: 'text.secondary' }}>{t(card.description)}</Typography>

                                <Stack component="ul" spacing={2} sx={{ p: 0, my: 5 }}>
                                    {card.notes.map((item) => (
                                        <Stack
                                            key={item}
                                            component="li"
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            sx={{
                                                typography: 'body2',
                                                color: 'text.primary',
                                            }}
                                        >
                                            <Iconify
                                                icon="eva:checkmark-fill"
                                                width={16}
                                                sx={{
                                                    color: 'primary.main',
                                                }}
                                            />
                                            <Typography variant="body2">{t(item)}</Typography>
                                        </Stack>
                                    ))}
                                </Stack>
                            </StyledCard>
                        </m.div>
                    ))}
                </Box>
            </Container>
        </StyledRoot>
    );
}
