import { createContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

// TODO use reducer to manage state
const FilterContext = createContext();
const FilterProvider = ({children}) => {

    /* Provider State */
    const [chips, setChips] = useState([]);
    const [chipsToggle, setChipsToggle] = useState(undefined);
    const [selectedFilterViewId, setFilterViewId] = useState(undefined);

    /* Provider Methods  */
    const handleToggleFilter = useCallback(id => {
        if (selectedFilterViewId === id) {
            setFilterViewId(undefined);
        } else {
            setFilterViewId(id);
        }
    }, [selectedFilterViewId, setFilterViewId]);
    const handleUpdateChip = useCallback(item => {
        const newChip = {
            key: item.key,
            value: item.value,
            label: item.value,
            isSticky: item.isSticky,
        }
        const newChips = [
            newChip,
            ...chips.slice(1)
        ];
        setChips(newChips);
    }, [chips]);
    const handleAppendChip = useCallback(item => {
        if (chips.findIndex(r => r.key === item.key) !== -1) {
            setChips((prevChips) => prevChips.filter((prevChip) => prevChip.key !== item.key));
            return;
        }

        const newChip = {
            key: item.key,
            value: item.value,
            label: item.value,
            isSticky: item.isSticky,
        }
        const newChips = [
            ...chips,
            newChip,
        ];
        setChips(newChips);
    }, [chips]);
    const handleDeleteChip = useCallback((key, value) => {
        setChipsToggle(key);
        setChips((prevChips) => prevChips.filter((prevChip) => prevChip.value !== value));
    }, []);
    const handleOverwriteChips = useCallback((key, items) => {
        const previousChips = chips.filter(r => !r.key.startsWith(key));
        const newChips = items.map(item => ({
            key: item.key,
            value: item.value,
            label: item.value,
            isSticky: item.isSticky,
        }));
        setChips([
            ...previousChips,
            ...newChips
        ]);
    }, [chips]);
    const handleDeleteChips = useCallback((key, items) => {
        const newChips = chips.reduce((acc, chip) => {
            if (items.includes(chip.key)) {
                return acc;
            }
            acc.push(chip);
            return acc;
        }, []);
        setChips([...newChips]);
    }, [chips]);
    const onChipClearAll = useCallback(key => {
        setChips((prevChips) => prevChips.filter((prevChip) => !prevChip.key.startsWith(key)));
    }, []);

    const context = useMemo(() => ({
        chips,
        chipsToggle,
        selectedFilterViewId,
        handleToggleFilter,
        handleUpdateChip,
        handleAppendChip,
        handleDeleteChip,
        handleOverwriteChips,
        handleDeleteChips,
        onChipClearAll,
    }), [
        chips,
        chipsToggle,
        selectedFilterViewId,
        handleToggleFilter,
        handleUpdateChip,
        handleAppendChip,
        handleDeleteChip,
        handleOverwriteChips,
        handleDeleteChips,
        onChipClearAll,
    ]);

    return (
        <FilterContext.Provider value={context}>
            {children}
        </FilterContext.Provider>
    )
};
FilterProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default FilterProvider;
export {
    FilterContext
};