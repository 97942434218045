import { Skeleton, Stack } from '@mui/material';

const TableSkeleton = () => {
    const barCount = 21;
    const barLengths = [10, 8, 10, 8, 16, 15, 14, 10, 8, 10, 8, 11, 12, 13, 14, 15, 16, 10, 8, 10, 10];
    return (
        <Stack spacing={1} sx={{ height: '300px', width: '100%' }} alignItems="center" my={8}>
            {[...Array(barCount)].map((_, index) => {
                const height = 40;
                return <Skeleton variant="rectangular" sx={{ width: `calc(100% - ${height * barLengths[index]}px)` }} key={index} />;
            })}
        </Stack>
    );
};

export default TableSkeleton;