import {
    format,
    getTime,
    formatDistanceToNow,
    parseISO,
    addMonths,
    addDays
} from 'date-fns';
import {enGB, arSA} from 'date-fns/locale';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
    const fm = newFormat || 'dd MMM yyyy';

    return date ? format(new Date(date), fm) : '';
}

export function fDate2(date, formatString = 'dd MMMM yyyy', locale = 'en') {
    const mappedLocale = locale === 'en' ? enGB : arSA;
    return format(new Date(date), formatString, {locale: mappedLocale});
}

export function fDateTime(date, newFormat) {
    const fm = newFormat || 'dd MMM yyyy p';

    return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
    return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
    return date
        ? formatDistanceToNow(new Date(date), {
            addSuffix: true,
        })
        : '';
}

export function fToday(adjust = 0, locale = 'en') {
    const date = addDays(new Date(), adjust);
    const mappedLocale = locale === 'en' ? enGB : arSA;

    return format(date, 'eeee', {locale: mappedLocale});
}

const magicKey = parseISO('1980-01-01T00:00:00');
export function fMonthKeyToDate(monthKey, locale = 'en') {
    const date = addMonths(magicKey, monthKey);
    return fDate2(date, 'MMMM', locale);
}
export function fDayKeyToDate(dayKey, locale = 'en') {
    const date = addDays(magicKey, dayKey);
    return fDate2(date,'EEEE', locale);
}