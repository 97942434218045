import { useContext } from "react";
import { CategoriesContext } from "./CategoriesProvider";
import type { CategoriesContextState } from "./types";

const useCategoriesContext = () : CategoriesContextState => {
    const context: CategoriesContextState = useContext(CategoriesContext);
    if (context === undefined) {
        throw new Error('useCategoriesContext must be used within a Categories Provider')
    }
    return context;
}

export default useCategoriesContext;