export const currencies = {
    "en": [
        {value: "USD", name: "US Dollar"},
        {value: "GBP", name: "British Pound"},
        {value: "EUR", name: "Euro"},
        {value: "SYP", name: "Syrian Pound"},
        {value: "SAR", name: "Saudi Riyal"},
        {value: "JOD", name: "Jordanian Dinar"},
        {value: "AED", name: "Emirati Dirham"},
        {value: "TRY", name: "Turkish Lira"},
    ],
    "ar": [
        {value: "USD", name: "الدولار الأمريكي"},
        {value: "GBP", name: "الجنيه البريطاني"},
        {value: "EUR", name: "اليورو"},
        {value: "SYP", name: "الليرة السورية"},
        {value: "SAR", name: "الريال السعودي"},
        {value: "JOD", name: "دينار أردني"},
        {value: "AED", name: "درهم اماراتى"},
        {value: "TRY", name: "الليرة التركية"},
    ]
};