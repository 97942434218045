import PropTypes from "prop-types";
import { createContext, useCallback, useEffect, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";
import withPageSuspense from "../../components/async-pages/withPageSuspense";
import { initSubscriptionAccountsAsync } from "../../store/slices/plans/actions";
import { startFetchAsync, endFetchAsync } from "../../store/slices/asyncPages";
import type { SubscriptionAccount } from "../../api/SubscriptionsApi";
import { asyncStatusSelector } from "../../views/subscription/selectors";
import { useSubscriptionsContext } from "../../hooks/useSubscriptionsContext";
import { STATE_FULFILLED } from "../../utils/dataStates";

export type SubscriptionAccountsContextState = {
    onToggleRefresh: () => void,
    refresh: boolean,
    accounts: Array<SubscriptionAccount>
};
const initialState: SubscriptionAccountsContextState = {
    onToggleRefresh: () => {},
    refresh: false,
    accounts: [],
};
const handlers = {
    INITIALIZE: (state, action) => {
        const {accounts} = action.payload;
        return {...state, accounts};
    },
    REFRESH: (state, action) => {
        const refresh = !state.refresh;
        return {...state, refresh};
    }
};
const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const SubscriptionAccountsContext = createContext({
    ...initialState
});
const SubscriptionAccountsProvider = ({resource, children}) => {
    const {data, mutate} = useSWR(resource.key, resource.action, {suspense: true});
    const dispatch = useDispatch();
    const [state, setState] = useReducer(reducer, initialState);
    const {defaultSubscriptionId} = useSubscriptionsContext();
    const status = useSelector(s => asyncStatusSelector(s, defaultSubscriptionId));

    const dispatchReduxAction = useMemo(() => {
        const newVar = async (subscriptionAccounts, subscriptionId) => dispatch(startFetchAsync(subscriptionId))
            .then(() => {
                const payload = {
                    subscriptionAccounts,
                    subscriptionId
                };
                dispatch(initSubscriptionAccountsAsync(payload))
                    .then(() => {
                        dispatch(endFetchAsync(subscriptionId));
                    });
            });
        return newVar;
    }, [dispatch]);
    useEffect(() => {
        const initialize = async () => {
            const subscriptionAccounts = data || [];
            dispatchReduxAction(subscriptionAccounts, defaultSubscriptionId);

            // TODO state is saved in two places, REDUX and context state,
            // TODO multiple ways are now available to get the same data, what is the preferred way?
            setState({
                type: 'INITIALIZE',
                payload: {accounts: subscriptionAccounts}
            });
        };
        initialize().then(() => {
            console.log("SubscriptionAccountsProvider loaded.");
        });
    }, [data, dispatchReduxAction, defaultSubscriptionId]);

    useEffect(() => {
        mutate();
    }, [state.refresh, mutate]);

    const onToggleRefresh = useCallback(() => {
        setState({
            type: 'REFRESH',
            payload: null
        });
    }, [setState]);

    const values: SubscriptionAccountsContextState = useMemo(() => ({
        accounts: state.accounts,
        onToggleRefresh
    }), [
        state.accounts,
        onToggleRefresh
    ]);

    if (status !== STATE_FULFILLED) {
        return <div/>
    }

    // TODO does the state account need a spread operator?
    return (
        <SubscriptionAccountsContext.Provider value={values}>
            {children}
        </SubscriptionAccountsContext.Provider>
    )
};
SubscriptionAccountsProvider.propTypes = {
    children: PropTypes.node,
    resource: PropTypes.any,
};

export default withPageSuspense(SubscriptionAccountsProvider);
export { SubscriptionAccountsContext }