import PropTypes from "prop-types";
import {reverse, min} from 'lodash';
import {useTheme} from '@mui/material/styles';
import {SkeletonBarLoad} from "../../../../../components/skeleton";
import {TransactionReportsUrlBuilder} from "../../../../../api/TransactionReportsApi";
import Chart, {useChart} from "../../../../../components/chart";
import {useLocales} from "../../../../../locales";
import {fDate2} from "../../../../../utils/formatTime";
import {fNumber} from "../../../../../utils/formatNumber";
import {withWidgetSwr} from "../components";
import NoTransactions from "../components/NoTransactions";

const NetWorthTrend = ({data, isRTL}) => {
    const theme = useTheme();
    const {languageCode, t} = useLocales();

    const chartDataItems = isRTL
        ? data.map(item => item.amount.asDouble)
        : reverse(data.map(item => item.amount.asDouble));
    const categories = isRTL
        ? data.map(item => fDate2(item.period, 'MMM yy', languageCode))
        : reverse(data.map(item => fDate2(item.period, 'MMM yy', languageCode)));
    const chartData = [
        {
            name: t('widgets.networth_yaxis_tooltip'),
            data: chartDataItems
        }
    ];
    const chartOptions = useChart({
        plotOptions: {
            bar: {
                columnWidth: '70%',
                colors: {
                    ranges: [
                        {from: min(chartDataItems.filter(r => r < 0.0)), to: 0, color: theme.palette.warning.main},
                    ],
                },
            },
        },
        stroke: {
            show: false,
        },
        xaxis: {
            categories,
        },
        tooltip: {
            marker: {show: false},
            y: {
                formatter: value => `${fNumber(value)}`,
            },
        },
        yaxis: {
            labels: {
                show: false,
                formatter: value => `${fNumber(value)}`
            }
        }
    });

    if (data.length === 0) {
        return (
            <NoTransactions/>
        )
    }

    return (
        <Chart type="bar" series={chartData} options={chartOptions} height={340}/>
    )
};
NetWorthTrend.propTypes = {
    data: PropTypes.array.isRequired,
    isRTL: PropTypes.bool.isRequired,
}

export default withWidgetSwr(NetWorthTrend, TransactionReportsUrlBuilder.getNetWorthTrend, <SkeletonBarLoad/>);