import Grid from '@mui/material/Unstable_Grid2';
import {withSummaryWidget} from "../components";
import SinceLastWeek from "./SpendRecent";
import SpendDailyAverage from "./SpendDailyAverage";
import SpendDailyVariableAverage from "./SpendDailyVariableAverage";

const RecentSpendWidget = () => (
    <Grid container sx={{p: 0, height: '100%'}} spacing={1}>
        <Grid xs={12}>
            <SinceLastWeek/>
        </Grid>
        <Grid xs={6}>
            <SpendDailyAverage />
        </Grid>
        <Grid xs={6}>
            <SpendDailyVariableAverage/>
        </Grid>
    </Grid>
)

export default withSummaryWidget(
    RecentSpendWidget,
    'spent_title',
    'spent_subtitle',
    false);