import PropTypes from "prop-types";
import {m} from 'framer-motion';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {Button, Typography} from '@mui/material';
// components
import {MotionContainer, varBounce} from '../components/animate';
// assets
import {SeverErrorIllustration} from '../assets/illustrations';
import {useLocales} from "../locales";

// ----------------------------------------------------------------------


Page500.propTypes = {
    error: PropTypes.object,
    resetErrorBoundary: PropTypes.func,
}

Page500.defaultProps = {
    error: {},
    resetErrorBoundary: () => {}
}

export default function Page500({error, resetErrorBoundary}) {
    const {translate} = useLocales();
    return (
        <>
            <Helmet>
                <title> 500 Internal Server Error | Wyzr</title>
            </Helmet>

            <MotionContainer>
                <m.div variants={varBounce().in}>
                    <Typography variant="h3" paragraph>
                        {translate('common.page_500_title')}
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <Typography sx={{color: 'text.secondary'}}>
                        {translate('common.page_500_description')}
                    </Typography>
                    <pre style={{color: 'red'}}>{error.message || ''}</pre>
                </m.div>

                <m.div variants={varBounce().in}>
                    <SeverErrorIllustration sx={{height: 260, my: {xs: 5, sm: 10}}}/>
                </m.div>

                <Button to="/" component={RouterLink} size="large" variant="contained" onClick={event => {
                    resetErrorBoundary(event);
                }}>
                    {translate('common.return_home')}
                </Button>
            </MotionContainer>
        </>
    );
}
