import {Page} from "../../../../../components/page";
import Overview from "../../../../../views/subscription/features/Transactions";

function Root() {
    return (
        <Page title="Your Transactions | Wyzr">
            <Overview/>
        </Page>
    )
}

export default Root;