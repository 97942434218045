import React from 'react';
import PropTypes from 'prop-types';
import { TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector, TimelineOppositeContent } from '@mui/lab';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useLocales } from '../../../../../../locales';
import { fMonthKeyToDate } from '../../../../../../utils/formatTime';
import { useSettingsContext } from '../../../../../../components/settings';
import TimelineTypography from './TimelineTypography';

const TimelineBalanceStart = ({ balance, monthKey }) => {
    const { currentLanguage, t } = useLocales();
    const { isRTL } = useSettingsContext();
    const isDebit = !isRTL ? balance < 0 : balance >= 0;
    const isCredit = !isRTL ? balance >= 0 : balance < 0;

    const title = `${t('transactions.labelStartingBalance')} ${fMonthKeyToDate(monthKey, currentLanguage.value)}`;
    return (
        <TimelineItem>
            <TimelineOppositeContent>{isDebit && <TimelineTypography title={title} balance={balance} showBalance />}</TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color="info">
                    <TrendingUpIcon />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>{isCredit && <TimelineTypography title={title} balance={balance} showBalance />}</TimelineContent>
        </TimelineItem>
    );
};
TimelineBalanceStart.propTypes = {
    balance: PropTypes.number.isRequired,
    monthKey: PropTypes.number.isRequired,
};

export default TimelineBalanceStart;
