import {replace} from 'lodash';
import numeral from 'numeral';

export function fCurrency(number) {
    const [wholeNumber, decimals] = number.toString().split(".");
    const gg = decimals || '00';
    return `${numeral(wholeNumber).format('0,0')}.${gg.padEnd(2, '0').slice(0,2)}`;
}
export function fCurrencyBy100(number) {
    const val = numeral(number/100).value();
    return fCurrency(val);
}

export function fPercent(number) {
    return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
    return numeral(number).format();
}

export function fNumeral(number) {
    return numeral(number).format('0.00');
}

export function fShortenNumber(number) {
    return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
    return numeral(number).format('0.0 b');
}

export function fNumberBy100(number) {
    return Number.parseFloat(numeral(number/100).value());
}

export function fNumberConvert(numberString) {
    return numeral(numberString).value();
}