import {useContext} from 'react';
import {AuthMetadataContext} from "../contexts/Auth";

export const useAuthContext = () => {
    const context = useContext(AuthMetadataContext);

    if (!context) throw new Error('useAuthContext context must be use inside AuthProvider');

    return context;
};
