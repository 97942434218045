import {useLocation} from "react-router-dom";
import {Breadcrumbs} from "../../../components/custom-breadcrumbs";
import Overview from "../../../views/subscription/features/Accounts";
import {Page} from "../../../components/page";
import { useSubscriptionsContext } from "../../../hooks/useSubscriptionsContext";

function Root() {
    const {pathname} = useLocation();
    const {subscriptionName} = useSubscriptionsContext();
    return (
        <Page title="Your Accounts | Wyzr">
            <Breadcrumbs
                heading="breadcrumbs.accounts"
                links={[
                    {
                        name: subscriptionName,
                    },
                    {
                        name: 'breadcrumbs.accounts',
                        href: pathname,
                    }
                ]}
            />
            <Overview/>
        </Page>
    )
}

export default Root;