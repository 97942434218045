import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import * as Yup from 'yup';
import { FormYupProvider, RHFStepperLayout } from '../../../components/hook-form';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useLocales } from '../../../locales';
import SubscriptionsApi from '../../../api/SubscriptionsApi';
import AccountBasics from './components/AccountBasics';
import AccountDetails from './components/AccountDetails';
import AccountReview from './components/AccountReview';
import { PATH_SUBSCRIPTION } from '../../../routes/paths';
import { useAccountsContext } from '../../../hooks/useAccountsContext';
import { useSubscriptionsContext } from '../../../hooks/useSubscriptionsContext';

export default function NewAccount() {
    const { translate } = useLocales();
    const navigate = useNavigate();
    const { userId } = useAuthContext();
    const { defaultSubscriptionId } = useSubscriptionsContext();
    const { onToggleRefresh } = useAccountsContext();

    const defaultDate = useRef(new Date());
    const defaultValues = {
        accountName: '',
        accountOpeningBalance: '0.0',
        accountOpeningDate: defaultDate.current,
        accountDescription: '',
        accountCurrency: '',

        accountTypeCode: '',
        accountTypeDescription: '',
        accountCode: '',
    };

    const Schema = Yup.object().shape({
        accountName: Yup.string().required(translate('management.account.name_validation')),
        accountOpeningBalance: Yup.string().required(translate('management.account.openingBalance_validation')),
        accountOpeningDate: Yup.date(),
        accountTypeCode: Yup.string().required(translate('management.account.code_validation')),
        accountCurrency: Yup.string().required(translate('management.account.currency_validation')),
        accountDescription: Yup.string(),
    });

    const onSubmit = async data => {
        // TODO handle account number and sort code
        const {
            accountName,
            accountCode,
            accountTypeCode,
            // accountNumber,
            // accountSortCode,
            accountOpeningBalance,
            accountDescription,
            accountOpeningDate,
            accountCurrency,
            accountClassType,
        } = data;
        const payload = {
            accountName,
            openingBalance: accountOpeningBalance,
            openingDate: accountOpeningDate.toISOString(),
            description: accountDescription,
            currency: accountCurrency,
            accountTypeCode,
            accountClassType,
        };

        if (accountCode === 'BNK') {
            payload.accountType = 'banking';
            payload.bankAccountNumber = '';
            payload.bankSortCode = '';
        }
        if (accountCode === 'CR') {
            payload.accountType = 'credit';
            payload.creditAccountNumber = '';
        }
        if (accountCode === 'CSH') {
            payload.accountType = 'cash';
            payload.walletName = accountName;
        }

        SubscriptionsApi
            .addSubscriptionAccount(userId, defaultSubscriptionId, payload)
            .then(() => {
                onToggleRefresh();
                navigate(PATH_SUBSCRIPTION.accounts.all);
            })
            .catch(() => {
                navigate('/500');
            })
    };
    return (
        <Box sx={{ width: '100%', flexGrow: 1 }}>
            <Box sx={{ pt: 5, px: 3, pb: 5 }}>
                <Typography variant='h4' gutterBottom>
                    {translate('management.account.addYourAccount')}
                </Typography>
            </Box>

            <FormYupProvider schema={Schema} onSubmit={onSubmit} defaultValues={defaultValues}>
                <RHFStepperLayout>
                    <AccountDetails />
                    <AccountBasics />
                    <AccountReview />
                </RHFStepperLayout>
            </FormYupProvider>
        </Box>
    );
}