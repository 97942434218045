import PropTypes from 'prop-types';
import { Drawer, Stack, IconButton } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { bgBlur } from '../../../../utils/cssStyles';
import { NAV } from '../../../../config';
import Iconify from '../../../../components/iconify';
import { ASIDEBAR_NONE, ASIDEBAR_UPDATE_PAYEE, ASIDEBAR_ADD_PAYEE, ASIDEBAR_UPDATE_CATEGORY, ASIDEBAR_ADD_CATEGORY, useLayoutSettingsContext } from './components/LayoutSettings';
import PayeeForm from './Views/PayeeForm';
import CategoryForm from './Views/CategoryForm';

const Asidebar = ({ variant }) => {
    const { showAsidebar, onToggleAsidebar, asidebarType, asidebarPayload } = useLayoutSettingsContext();
    const theme = useTheme();

    const handleClose = () => {
        onToggleAsidebar(ASIDEBAR_NONE);
    };

    return (
        <Drawer
            open={showAsidebar}
            anchor="right"
            onClose={handleClose}
            BackdropProps={{ invisible: true }}
            PaperProps={{
                sx: {
                    ...bgBlur({ color: theme.palette.background.default, opacity: 0.9 }),
                    width: NAV.W_BASE * 3,
                    boxShadow: `-24px 12px 40px 0 ${alpha(theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black, 0.16)}`,
                },
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ pl: 2, pr: 1, py: 2 }}>
                <IconButton onClick={handleClose}>
                    <Iconify icon="eva:close-fill" />
                </IconButton>
            </Stack>

            {asidebarType === ASIDEBAR_ADD_PAYEE &&
                <PayeeForm payee={{}} />
            }
            {asidebarType === ASIDEBAR_UPDATE_PAYEE &&
                <PayeeForm payee={asidebarPayload} />
            }
            {asidebarType === ASIDEBAR_ADD_CATEGORY &&
                <CategoryForm category={{}} variant={variant} />
            }
            {asidebarType === ASIDEBAR_UPDATE_CATEGORY &&
                <CategoryForm category={asidebarPayload} variant={variant} />
            }
        </Drawer>
    );
};
Asidebar.propTypes = {
    variant: PropTypes.oneOf(['income', 'expense', 'undefined']).isRequired,
}

export default Asidebar;