import { useState } from 'react';
import { Box } from '@mui/material';
import { TransactionsReport } from '../../../../../api/TransactionReportsApi';
import ChartSkeleton from '../components/ChartSkeleton';
import ChartEmpty from '../components/ChartEmpty';
import ChartOptions from './components/ChartOptions';
import ChartByTotalPie from './components/ChartByTotalPie';
import ChartByTotalBar from './components/ChartByTotalBar';
import useClassificationFilterContext from '../../../components/ClassificationFilter/useClassificationFilterContext';
// import ChartByTimeBar from './components/ChartByTimeBar';
// import ChartByTimeStacked from './components/ChartByTimeStacked';

type Props = {
    isMutating: boolean,
    data: TransactionsReport,
};

const ChartView = ({ isMutating, data }: Props) => {
    const { selectedClassificationType } = useClassificationFilterContext();
    const [chartView, setChartView] = useState('by_total');
    const [chartType, setChartType] = useState('by_total_pie');

    const handleOnChartViewChange = (value) => {
        if (value === 'by_total') {
            setChartType('by_total_pie');
            setChartView(value);
        }
        if (value === 'by_time') {
            setChartType('by_time_column');
            setChartView(value);
        }
    };
    const handleOnChartTypeChange = (value) => {
        setChartType(value);
    };

    if (isMutating) {
        return <ChartSkeleton />;
    }

    const { total, chartByTotal, transactions } = data || {};

    if ((transactions || []).length === 0) {
        return (
            <Box mb={4}>
                <ChartOptions
                    total={total}
                    chartView={chartView}
                    onChartViewChange={handleOnChartViewChange}
                    chartType={chartType}
                    onChartTypeChange={handleOnChartTypeChange}
                />
                <ChartEmpty />
            </Box>
        );
    }

    return (
        <Box mb={4}>
            <ChartOptions
                total={total}
                chartView={chartView}
                onChartViewChange={handleOnChartViewChange}
                chartType={chartType}
                onChartTypeChange={handleOnChartTypeChange}
            />
            <ChartByTotalPie chartView={chartView} chartType={chartType} data={chartByTotal} selectedClassificationType={selectedClassificationType} />
            <ChartByTotalBar chartView={chartView} chartType={chartType} data={chartByTotal} selectedClassificationType={selectedClassificationType} />
            {/*
            <ChartByTimeBar chartView={chartView} chartType={chartType} data={chartByTime} />
            <ChartByTimeStacked chartView={chartView} chartType={chartType} data={chartByTime} />
            */}
        </Box>
    );
};

export default ChartView;