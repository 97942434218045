import PropTypes from "prop-types";
import {withWidgetSwr} from "../components";
import {TransactionReportsUrlBuilder} from "../../../../../api/TransactionReportsApi";
import {SkeletonBarLoad} from "../../../../../components/skeleton";
import TransactionsSummary from "./TransactionsSummary";

const TransactionsByIncome = ({data, isRTL}) => (
    <TransactionsSummary data={data} title="widgets.transactions_income_title" isRTL={isRTL} />
);
TransactionsByIncome.propTypes = {
    data: PropTypes.array.isRequired,
    isRTL: PropTypes.bool.isRequired,
};

export default withWidgetSwr(TransactionsByIncome, TransactionReportsUrlBuilder.getIncomeTrend, <SkeletonBarLoad />);
