import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import useSWR from 'swr';
import withPageSplash from '../../components/async-pages/withPageSplash';
import { initSubscriptionsAsync } from '../../store/slices/plans/actions';
import { initSubscriptionSettings } from '../../store/slices/settings';
import { initDates } from '../../store/slices/dates';
import { SplashScreen } from '../../components/loading-screen';
import { PATH_MANAGEMENT } from "../../routes/paths";

const SubscriptionsLoader = ({ resource, children }) => {
    const dispatch = useDispatch();
    const [finished, setFinished] = useState(false);
    const onSuccessHandle = (data, key, config) => {
        setFinished(true);
    }

    const { data: settings } = useSWR(resource.settingsKey, resource.settingsAction, { suspense: true, revalidateOnFocus: false });
    const { data: subscriptions, isLoading, isValidating } = useSWR(resource.subscriptionsKey, resource.subscriptionsAction, {
        suspense: false,
        revalidateOnFocus: false,
        revalidateOnMount: true,
        onSuccess: onSuccessHandle
    });

    /* INITIALISE settings data */
    useEffect(() => {
        const initialize = async () => {
            dispatch(initSubscriptionSettings(settings || {}));
            dispatch(initDates());
        };
        initialize().then(() => {
            console.log('Settings loaded.');
        });
    }, [settings, dispatch]);

    /* INITIALISE subscriptions data */
    useEffect(() => {
        const initialize = async () => {
            if (subscriptions && subscriptions.length > 0) {
                dispatch(initSubscriptionsAsync(subscriptions));
            }
        };
        initialize().then(() => {
            console.log('Subscriptions loaded.');
        });
    }, [subscriptions, dispatch]);

    if (isValidating || isLoading || finished === false) {
        return <SplashScreen />;
    }

    if (finished && subscriptions && subscriptions.length === 0) {
        return <Navigate to={PATH_MANAGEMENT.subscriptions.new} />;
    }

    return <>{children}</>;
};

SubscriptionsLoader.propTypes = {
    children: PropTypes.node,
    resource: PropTypes.any,
};

export default withPageSplash(SubscriptionsLoader);
