import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Drawer } from '@mui/material';
import { useSubscriptionsContext } from '../../../../../hooks/useSubscriptionsContext';
import AccountCard from './AccountCard';
import useResponsive from '../../../../../hooks/useResponsive';
import AccountsSummary from './AccountsSummary';
import { useAccountsFilterContext } from '../hooks/useAccountsFilterContext';
import AccountStats from './AccountStats';
import { subscriptionAccountsSelector } from '../../../selectors';
import { useAccountsToolbarContext } from '../hooks/useAccountsToolbarContext';

const NAV_WIDTH = 320;
const NAV_COLLAPSE_WIDTH = 0;

const AccountsList = () => {
    const { defaultCurrency, defaultSubscriptionId } = useSubscriptionsContext();
    const theme = useTheme();
    const isDesktop = useResponsive('up', 'md');
    const { selectedAccountType } = useAccountsFilterContext();
    const { showAccountsSummary } = useAccountsToolbarContext();

    const openNav = showAccountsSummary;
    const isCollapse = isDesktop && !openNav;
    const filteredAccounts = useSelector((state) => subscriptionAccountsSelector(state, defaultSubscriptionId, selectedAccountType));

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                gap: isCollapse ? '0px' : '12px',
            }}
        >
            <Drawer
                open={openNav}
                variant="persistent"
                PaperProps={{
                    sx: {
                        p: 2,
                        width: 1,
                        position: 'static',
                        borderRadius: '15px',
                        ...(isCollapse && {
                            transform: 'none !important',
                            visibility: 'visible !important',
                        }),
                        ...(!isCollapse && {
                            backgroundColor: theme.palette.grey.A200,
                        }),
                        border: 0,
                        overflowX: 'hidden',
                        overflowY: 'hidden',
                    },
                }}
                sx={{
                    width: NAV_WIDTH,
                    transition: theme.transitions.create('width'),
                    ...(isCollapse && {
                        width: NAV_COLLAPSE_WIDTH,
                    }),
                }}
            >
                <Box hidden={!openNav} p={1}>
                    <AccountsSummary />
                </Box>
            </Drawer>
            <Grid container spacing={3}>
                <AccountStats />
                {filteredAccounts.map((item, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <AccountCard
                            {...item}
                            showExchange={item.currency.toLowerCase() !== defaultCurrency.toLowerCase()}
                            subscriptionCurrency={defaultCurrency}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default AccountsList;
