import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { reverse } from 'lodash';
import { useTheme } from '@mui/material/styles';
import { Card, Typography, Stack, Box, Grid } from '@mui/material';
import Iconify from '../../../../../components/iconify';
import { useSubscriptionsContext } from '../../../../../hooks/useSubscriptionsContext';
import { useAccountsFilterContext } from '../hooks/useAccountsFilterContext';
import { subscriptionAccountsSelector, subscriptionAccountsTotalSelector } from '../../../selectors';
import { fCurrencyBy100, fPercent } from '../../../../../utils/formatNumber';
import { useLocales } from '../../../../../locales';
import Chart, { useChart } from '../../../../../components/chart';
import { useSettingsContext } from '../../../../../components/settings';
import EmptyAccounts from './EmptyAccounts';
import AmountTypography from '../../../../../components/typography/AmountTypography';

TrendingInfo.propTypes = {
    change: PropTypes.object,
};

function TrendingInfo({ change }) {
    const { t } = useLocales();
    const { percent, changeInAmount } = change;
    return (
        <Stack direction="row" alignItems="center" flexWrap="wrap" spacing={0.5}>
            <Iconify icon={changeInAmount < 0 ? 'eva:trending-down-fill' : 'eva:trending-up-fill'} />

            <Typography variant="subtitle2" component="span">
                {changeInAmount >= 0 && '+'}

                <span style={{ direction: 'ltr', display: 'inline-block' }}>
                    {percent > 100 || percent < -100 ? fCurrencyBy100(changeInAmount) : fPercent(percent)}
                </span>

                <Box component="span" sx={{ opacity: 0.72, typography: 'body2', px: 1 }}>
                    {t('accounts.accountsStats_change')}
                </Box>
            </Typography>
        </Stack>
    );
}

// const chartData = [{name: "", data: names.map(item => fNumeral(item.balance))}];
const calculateNames = (accounts) => {
    const names = accounts.reduce((acc, item) => {
        const { previousBalances, accountClassType } = item;
        // eslint-disable-next-line array-callback-return
        previousBalances.map((previousBalance) => {
            const { periodKey, currencyAmount } = previousBalance;
            const arrayIndex = acc.findIndex((r) => r.name === periodKey);
            let mappedBalance = 0;
            if (accountClassType === 'Asset') {
                mappedBalance = currencyAmount.asInt;
            }
            if (accountClassType === 'Liability') {
                mappedBalance = currencyAmount.asInt * -1;
            }
            if (arrayIndex >= 0) {
                const itemOfInterest = acc[arrayIndex];
                itemOfInterest.balance += mappedBalance;
            } else {
                acc.push({
                    name: periodKey,
                    balance: mappedBalance,
                });
            }
        });
        return acc;
    }, []);
    return names;
};
const calculatePercent = (names) => {
    if (!Array.isArray(names) || names.length <= 1) {
        return {
            percent: 0.0,
            changeInAmount: 0,
        };
    }
    const first = names[names.length - 2].balance;
    const last = names[names.length - 1].balance;

    const percentage = ( last / first ) - 1.0;
    return {
        percent: percentage * 100.0,
        changeInAmount: last - first,
    };
};

const AccountStats = () => {
    const { t } = useLocales();
    const theme = useTheme();
    const { pathname } = useLocation();
    const { isRTL } = useSettingsContext();
    const { defaultSubscriptionId } = useSubscriptionsContext();

    const { selectedAccountType } = useAccountsFilterContext();
    const accounts = useSelector((state) => subscriptionAccountsSelector(state, defaultSubscriptionId, selectedAccountType));
    const totals = useSelector((state) => subscriptionAccountsTotalSelector(state, defaultSubscriptionId, selectedAccountType));

    const names = calculateNames(accounts);

    const change = calculatePercent(names);
    const color = change.changeInAmount < 0 ? 'warning' : 'primary';
    const icon = change.changeInAmount < 0 ? 'eva:diagonal-arrow-left-down-fill' : 'eva:diagonal-arrow-right-up-fill';
    const series = isRTL ? reverse(names.map((item) => item.balance)) : names.map((item) => item.balance);

    const chartOptions = useChart({
        colors: [theme.palette[color].main],
        chart: {
            sparkline: {
                enabled: true,
            },
        },
        xaxis: {
            labels: { show: false },
        },
        yaxis: {
            labels: { show: false },
        },
        stroke: {
            width: 4,
        },
        legend: {
            show: false,
        },
        grid: {
            show: false,
        },
        tooltip: {
            marker: { show: false },
            y: {
                formatter: (value) => fCurrencyBy100(value),
                title: {
                    formatter: () => '',
                },
            },
        },
        fill: {
            gradient: {
                opacityFrom: 0.56,
                opacityTo: 0.56,
            },
        },
    });

    if (accounts.length === 0) {
        return (
            <Grid item xs={12} md={12}>
                <EmptyAccounts returnUrl={pathname} />
            </Grid>
        );
    }

    return (
        <Grid item xs={12} md={6}>
            <Card
                sx={{
                    width: 1,
                    boxShadow: 0,
                    color: theme.palette[color].darker,
                    bgcolor: theme.palette[color].lighter,
                }}
            >
                <Iconify
                    icon={icon}
                    sx={{
                        p: 1.5,
                        top: 24,
                        right: 24,
                        width: 48,
                        height: 48,
                        borderRadius: '50%',
                        position: 'absolute',
                        color: theme.palette[color].lighter,
                        bgcolor: theme.palette[color].dark,
                    }}
                />

                <Stack spacing={1} sx={{ p: 3 }} alignItems="start">
                    <Typography variant="subtitle2">{t('accounts.accountsStats_totals')}</Typography>
                    <span style={{ direction: 'ltr', display: 'inline-block' }}>
                        <AmountTypography amount={totals} useInt variant="h3" />
                    </span>
                    <TrendingInfo change={change} />
                </Stack>

                <Chart type="area" series={[{ data: series }]} options={chartOptions} height={240} />
            </Card>
        </Grid>
    );
};

export default AccountStats;
