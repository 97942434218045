import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// config
import { NAV, HEADER } from '../../config';
// auth
// import { useAuthContext } from '../../auth/useAuthContext';
//
import ProgressBar from '../progress-bar';
import { useSettingsContext } from '../settings';
import LoadingLogo from "./LoadingLogo";

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({theme}) => ({
    right: 0,
    bottom: 0,
    zIndex: 9998,
    width: '100%',
    height: '100%',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function LoadingScreen() {
    const {pathname} = useLocation();
    const isDesktop = useResponsive('up', 'lg');

    // const {isInitialized} = useAuthContext();

    const {themeLayout} = useSettingsContext();

    // const isDashboard = isInitialized && pathname.includes('/dashboard') && isDesktop;
    const isDashboard = pathname.includes('/subscription_disable') && isDesktop;
    const isDashboard1 = true;

    const size =
        (themeLayout === 'mini' && NAV.W_DASHBOARD_MINI) ||
        (themeLayout === 'vertical' && NAV.W_DASHBOARD) ||
        144;

    return (
        <>
            <ProgressBar/>

            <StyledRoot
                sx={{
                    ...(isDashboard1 && {
                        width: `calc(100% - ${size}px)`,
                        height: `calc(100% - ${HEADER.H_DASHBOARD_DESKTOP}px)`,
                        ...(themeLayout === 'horizontal' && {
                            width: 1,
                            height: `calc(100% - ${size}px)`,
                        }),
                    }),
                }}
            >
                {isDashboard ? (
                    <LinearProgress color="inherit" sx={{width: 1, maxWidth: 360}}/>
                ) : (
                    <LoadingLogo/>
                )}
            </StyledRoot>
        </>
    );
}
