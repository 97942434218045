import {createSelector} from "reselect";
import {subscriptionSelector} from "./subscriptionSelector";

export const subscriptionAccountsSelector = createSelector(
    subscriptionSelector,
    (a, b, accountsType) => accountsType,
    (subscription, accountsType) => accountsSelector(subscription, accountsType)
);
const accountsSelector = (subscription, accountsType) => {
    if (!("accounts" in subscription)) {
        return [];
    }

    const filterType = accountsType.toLowerCase();
    switch (accountsType.toLowerCase()) {
        case 'banking':
        case 'credit':
        case 'cash':
            break;
        case 'system_no_account_type':
            return [];
        case 'system_all_account_types':
            return subscription.accounts || [];
        default:
            throw new Error(`Type ${accountsType} not recognised`);
    }
    return subscription.accounts.filter(r => r.accountType.toLowerCase() === filterType);
};