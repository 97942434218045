import PropTypes from 'prop-types';
import { useState } from 'react';
import { Checkbox, Divider, FormControlLabel, Stack, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import { useAccountsContext } from '../../../../hooks/useAccountsContext';
import { useLocales } from '../../../../locales';
import Iconify from '../../../../components/iconify';
import OptionStyle from '../Filter/styles';
import FilterSearch from './FilterSearch';
import { matchFilterOptions, crudeMatch } from './utils';

const AccountsFilter = ({ selectedItems, onFiltersSelected, onFiltersCleared, ...props }) => {
    const FilterKey = 'account';
    const { t } = useLocales();
    const [searchValue, setSearchValue] = useState('');
    const { accounts } = useAccountsContext();
    const filteredData = matchFilterOptions(accounts, { inputValue: searchValue }, ['alias']);
    const selectedIds = selectedItems.map(r => r.value);

    const handleOnChange = (value, label) => {
        onFiltersSelected(FilterKey, [{
            value,
            label
        }]);
    };

    return (
        <>
            <Stack direction="row" sx={{ my: 1 }} spacing={1}>
                <ToggleButton value="list" onChange={() => {
                    onFiltersCleared(FilterKey)
                }}>
                    <Iconify icon="solar:close-square-linear" mr={1}/>
                    {t('common.clear_all')}
                </ToggleButton>
            </Stack>
            <Stack sx={{ my: 1 }}>
                <FilterSearch searchValue={searchValue} setSearchValue={setSearchValue} />
            </Stack>
            <Divider />
            <Stack direction="row" spacing={0} flexWrap="wrap" justifyContent="flex-start" alignItems="baseline">
                {filteredData.map((account, index) => {
                    const { id, alias } = account;
                    return (
                        <OptionStyle
                            key={index}
                            sx={{
                                display: 'block',
                                // mb: 3,
                                // ...(selectedIds.includes(id) && {
                                //     boxShadow: (theme) => theme.customShadows.z8
                                // })
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedIds.includes(id)}
                                        color="primary"
                                        onChange={() => handleOnChange(id, alias)}
                                        value={id}
                                    />
                                }
                                label={<Typography variant="subtitle1">{crudeMatch(alias, searchValue)}</Typography>}
                                sx={{ py: 2, mr: 0 }}
                            />
                        </OptionStyle>
                    );
                })}
            </Stack>
        </>
    );
};

AccountsFilter.propTypes = {
    selectedItems: PropTypes.array,

    // funcs
    onFiltersSelected: PropTypes.func,
    onFiltersCleared: PropTypes.func,
};

export default AccountsFilter;