import AccountsList from "./components/AccountsList";
import AccountsFilter from "./components/AccountsFilter";
import AccountsFilterProvider from "./AccountsFilterProvider";
import AccountsLimit from "./components/AccountsLimit";
import AccountsToolbar from "./components/AccountsToolbar";
import AccountsToolbarProvider from "./AccountsToolbarProvider";

const Overview = () => (
    <AccountsToolbarProvider>
        <AccountsFilterProvider>
            <AccountsToolbar/>
            <AccountsLimit/>
            <AccountsFilter/>
            <AccountsList/>
        </AccountsFilterProvider>
    </AccountsToolbarProvider>
)

export default Overview;