import { fNumberConvert } from '../../utils/formatNumber';

export const mapToTransactionCreateContract = (payload, accountId: string) => {
    const { transactionAmount, transactionAccountClassType, transactionCategoryType } = payload;
    const amount = fNumberConvert(transactionAmount);
    let transactionType = transactionCategoryType.toLowerCase() === 'expense' ? 'Debit' : 'Credit';
    if (transactionAccountClassType === 'Liability') {
        transactionType = transactionCategoryType.toLowerCase() === 'expense' ? 'Debit' : 'Credit';
    }

    return {
        accountId,
        accountClassType: transactionAccountClassType,
        transactionType,
        date: payload.transactionDate,
        memo: payload.transactionMemo,
        payeeId: payload.transactionPayeeId === '' ? undefined : payload.transactionPayeeId,
        allocations: [
            {
                amount,
                categoryId: payload.transactionCategoryId === '' ? undefined : payload.transactionCategoryId,
            },
        ],
    };
};

export const mapToTransactionUpdateContract = (payload, accountId: string) => {
    const previousAmount = fNumberConvert(payload.previousTransactionAmount);
    const mappedPayload = mapToTransactionCreateContract(payload, accountId);

    mappedPayload.correlationId = payload.transactionId;
    mappedPayload.previousDate = payload.previousTransactionDate;
    mappedPayload.previousAmount = previousAmount;
    // TODO not supported yet in UI
    mappedPayload.previousAccountId = accountId;
    return mappedPayload;
};

export const mapToTransferCreateContract = (payload, transientProps) => {
    const transactionAmount = fNumberConvert(payload.transactionAmount);
    return {
        // accountId: transientProps.accountId,
        amount: transactionAmount,
        fromAccountId: payload.transferFrom,
        fromCurrency: payload.transferFromCurrency,
        fromExchangeRate: payload.transferFromExchangeRate,
        fromAccountClassType: payload.fromAccountClassType,

        toAccountId: payload.transferTo,
        toCurrency: payload.transferToCurrency,
        toAccountClassType: payload.toAccountClassType,

        date: payload.transactionDate,
        memo: payload.transactionMemo,
    };
};

export const mapToTransferUpdateContract = (payload, transientProps) => {
    const previousAmount = fNumberConvert(payload.previousTransactionAmount);
    const mappedPayload = mapToTransferCreateContract(payload, transientProps);

    mappedPayload.correlationId = payload.transactionId;
    mappedPayload.previousAmount = previousAmount;
    mappedPayload.previousDate = payload.previousTransactionDate;
    mappedPayload.previousFromAccountId = payload.previousTransferFrom;
    mappedPayload.previousFromCurrency = payload.previousFromCurrency;
    mappedPayload.previousFromExchangeRate = payload.previousFromExchangeRate;
    mappedPayload.previousToAccountId = payload.previousTransferTo;
    mappedPayload.previousToCurrency = payload.previousFromCurrency;

    return mappedPayload;
};
