import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
import useDateRangeContext from '../DateRange/useDateRangeContext';
import reducer from './reducer';
import * as types from './types';
import LoadingScreen from '../../components/loading-screen';

const initialState = {
    selectedDateId: null,
    selectedDate: null,
    getDateById: () => {},
};

const TransactionsDateContext = createContext();
const TransactionsDateProvider = ({ children }) => {
    const { defaultId, reportDates: dates } = useDateRangeContext();
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        dispatch({
            type: types.INIT_DATA,
            payload: {
                selectedDateId: defaultId,
                selectedDate: dates.find((r) => r.id === defaultId),
            },
        });
    }, [dates, defaultId]);

    const getDateById = useCallback(
        (id) => {
            const date = dates.find((r) => r.id === id);
            return date;
        },
        [dates]
    );
    const updateDate = useCallback(
        (id) => {
            dispatch({
                type: types.INIT_DATA,
                payload: {
                    selectedDateId: id,
                    selectedDate: dates.find((r) => r.id === id),
                },
            });
        },
        [dates]
    );

    const value = useMemo(
        () => ({
            ...state,
            getDateById,
            updateDate,
        }),
        [state, getDateById, updateDate]
    );

    if (!state.selectedDateId) {
        return <LoadingScreen/>
    }

    return <TransactionsDateContext.Provider value={value}>{children}</TransactionsDateContext.Provider>;
};

TransactionsDateProvider.propTypes = {
    children: PropTypes.node,
};

export default TransactionsDateProvider;
export {
    TransactionsDateContext,
};