import PropTypes from 'prop-types';
import ReportDataProvider from '../components/ReportDataProvider';
import { useAuthContext } from '../../../../../hooks/useAuthContext';
import { useSubscriptionsContext } from '../../../../../hooks/useSubscriptionsContext';
import useDateRangeContext from '../../../../../contexts/DateRange/useDateRangeContext';
import Api, { TransactionReportsUrlBuilder } from '../../../../../api/TransactionReportsApi';
import { useClassificationFilterContext } from '../../../components/ClassificationFilter';
import type { DateRange } from '../../../../../api/FilterApi';

const createSwrResource = (userId, subscriptionId) => ({
    readKey: TransactionReportsUrlBuilder.getReportNetWorth(userId, subscriptionId),
    readAction: Api.getReportNetWorth,
});

const ReportDataProviderHoc = ({ children }) => {
    const { userId } = useAuthContext();
    const { defaultSubscriptionId, defaultCurrency } = useSubscriptionsContext();
    const { reportDates: dates } = useDateRangeContext();
    const { selectedDates, getFilters} = useClassificationFilterContext();
    const selectedDate : DateRange = dates.find(r => r.id === selectedDates);
    const query = `?basecurrency=${defaultCurrency}&startDate=${selectedDate.from.toISOString()}&endDate=${selectedDate.to.toISOString()}`;
    const body = getFilters();

    const resource = createSwrResource(userId, defaultSubscriptionId, body, query);
    return (
        <ReportDataProvider
            resource={resource}
            body={body}
            query={query}
            selectedDate={selectedDate}
        >
            {children}
        </ReportDataProvider>);
};
ReportDataProviderHoc.propTypes = {
    children: PropTypes.node,
};

export default ReportDataProviderHoc;