import {useAuthContext} from "../../hooks/useAuthContext";

const withAuth = (WrappedComponent) => {
    function HOC(props) {
        const {userId} = useAuthContext();
        return (
            <WrappedComponent {...props} userId={userId}/>
        )
    }
    return HOC;
}
export default withAuth;