// import { useEffect } from "react";
// import { preload } from "swr";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import TransactionsProvider from "./TransactionsProvider";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useSubscriptionsContext } from "../../hooks/useSubscriptionsContext";
import useDateRangeContext from "../DateRange/useDateRangeContext";
import Api, { TransactionsUrlBuilder } from "../../api/TransactionsApi";
import { useTransactionDatesContext } from "../TransactionsDate";

const createSwrResource = (userId, subscriptionId, accountId, periodId) => ({
    readKey: TransactionsUrlBuilder.getTransactions(userId, subscriptionId, accountId, periodId),
    readAction: Api.getTransactions,

    writeKey: TransactionsUrlBuilder.addTransaction(userId, subscriptionId),
    writeAction: Api.addTransaction,

    updateKey: TransactionsUrlBuilder.updateTransaction(userId, subscriptionId),
    updateAction: Api.updateTransaction,

    transferWriteKey: TransactionsUrlBuilder.addTransfer(userId, subscriptionId),
    transferWriteAction: Api.addTransfer,

    transferUpdateKey: TransactionsUrlBuilder.updateTransfer(userId, subscriptionId),
    transferUpdateAction: Api.updateTransfer,
});

const TransactionsProviderHoc = ({children}) => {
    const {accountId} = useParams();
    const {userId} = useAuthContext()
    const {defaultSubscriptionId} = useSubscriptionsContext();
    const {defaultId} = useDateRangeContext();
    const {selectedDateId} = useTransactionDatesContext()

    const resource = createSwrResource(userId, defaultSubscriptionId, accountId, selectedDateId || defaultId);
    // useEffect(() => {
    //     preload(resource.key, resource.action);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <TransactionsProvider resource={resource}>
            {children}
        </TransactionsProvider>
    )
};
TransactionsProviderHoc.propTypes = {
    children: PropTypes.node
};

export default TransactionsProviderHoc;