import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';
import withPageSplash from '../../components/async-pages/withPageSplash';
import { SplashScreen } from '../../components/loading-screen';
import { subscriptionsSelector } from '../../views/subscription/selectors';

export type SubscriptionsContextState = {
    defaultCurrency: string,
    defaultSubscriptionId: string,
    defaultBookId: string,
    subscriptionName: string,
};

const initialState: SubscriptionsContextState = {
    defaultSubscriptionId: null,
    defaultCurrency: null,
    defaultBookId: null,
    subscriptionName: null,
};
const handlers = {
    INITIALIZE: (state, action) => {
        const { defaultSubscriptionId, defaultCurrency, defaultBookId, subscriptionName } = action.payload;
        return { ...state, defaultSubscriptionId, defaultCurrency, defaultBookId, subscriptionName };
    },
};
const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const SubscriptionsContext = createContext({
    ...initialState,
});
const SubscriptionsProvider = ({ children }) => {
    const reduxState = useSelector((state) => subscriptionsSelector(state));
    const [state, setState] = useReducer(reducer, initialState);

    useEffect(() => {
        if (reduxState && reduxState.subscriptionsCount > 0) {
            const {bookId, currency, name} = reduxState.subscriptions[reduxState.defaultSubscriptionId];
            setState({
                type: 'INITIALIZE',
                payload: {
                    defaultSubscriptionId: reduxState.defaultSubscriptionId,
                    defaultCurrency: currency,
                    defaultBookId: bookId,
                    subscriptionName: name
                }
            });
        }
    }, [reduxState]);

    const values = useMemo(
        () => ({
            defaultSubscriptionId: state.defaultSubscriptionId,
            defaultCurrency: state.defaultCurrency,
            defaultBookId: state.defaultBookId,
            subscriptionName: state.subscriptionName,
        }),
        [state.defaultSubscriptionId, state.defaultCurrency, state.defaultBookId, state.subscriptionName]
    );

    if (state.defaultSubscriptionId === null ||state.defaultSubscriptionId === undefined ) {
        return <SplashScreen/>
    }

    return <SubscriptionsContext.Provider value={values}>{children}</SubscriptionsContext.Provider>;
};
SubscriptionsProvider.propTypes = {
    children: PropTypes.node,
};

export default withPageSplash(SubscriptionsProvider);
export { SubscriptionsContext };
