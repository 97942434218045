import axios from "axios";
import {SiteConfig} from "./ConfigApi";


const buildBasePath = (userId: string, subscriptionId: string, accountId: string) : string => {
    const rootUrl = `${SiteConfig.TxnWebApiUrl}/api/users/${userId}/subscriptions/${subscriptionId}/accounts/${accountId}`;
    return rootUrl;
}
const buildBaseTxnPath = (userId: string, subscriptionId: string) : string => {
    const rootUrl = `${SiteConfig.TxnWriterUrl}/users/${userId}/subscriptions/${subscriptionId}`;
    return rootUrl;
}

const TransactionsUrlBuilder = {
    getTransactions: (userId: string, subscriptionId: string, accountId: string, periodId: string) : string => `${buildBasePath(userId, subscriptionId, accountId)}/transactions?period=${periodId}`,
    addTransaction: (userId: string, subscriptionId: string, payload: any) : string => `${buildBaseTxnPath(userId, subscriptionId)}/transactions`,
    updateTransaction: (userId: string, subscriptionId: string, payload: any) : string => `${buildBaseTxnPath(userId, subscriptionId)}/transactions`,
    addTransfer: (userId: string, subscriptionId: string, payload: any) : string => `${buildBaseTxnPath(userId, subscriptionId)}/transfers`,
    updateTransfer: (userId: string, subscriptionId: string, payload: any) : string => `${buildBaseTxnPath(userId, subscriptionId)}/transfers`,
}
class TransactionsApi {

    static getTransactions(url) {
        return axios
            .get(url)
            .then(response => response.data);
    }

    static addTransaction(url, body) {
        return axios
            .post(url, body.arg)
            .then(response => response.data);
    }

    static updateTransaction(url, body) {
        return axios
            .put(url, body.arg)
            .then(response => response.data);
    }

    static addTransfer(url, body) {
        return axios
            .post(url, body.arg)
            .then(response => response.data);
    }

    static updateTransfer(url, body) {
        return axios
            .put(url, body.arg)
            .then(response => response.data);
    }
}

export default TransactionsApi;
export {
    TransactionsUrlBuilder
};