import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createContext, useReducer, useMemo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import reducer from './reducer';
import withPageSuspense from '../../components/async-pages/withPageSuspense';
import * as types from './types';
import * as utils from './utils';
import type { TransactionsContextState } from './types';
import LoadingScreen from '../../components/loading-screen';
import * as states from '../../utils/dataStates';
import { subscriptionAccountSelector } from '../../views/subscription/selectors';
import { useSubscriptionsContext } from '../../hooks/useSubscriptionsContext';

const initialState: TransactionsContextState = {
    refresh: false,
    selectedAccountId: undefined,
    selectedAccountClassType: undefined,
    refreshState: states.STATE_IDLE,
    hasNoTransactions: true,
    data: {
        startingBalance: 0,
        endingBalance: 0,
        entries: [],
    },
};
const TransactionsContext = createContext();

const TransactionsProvider = ({ resource, children }) => {
    const { accountId } = useParams();
    const { defaultSubscriptionId } = useSubscriptionsContext();
    const [state, dispatch] = useReducer(reducer, initialState);

    const { data, mutate } = useSWR(resource.readKey, resource.readAction, {
        suspense: true,
        revalidateOnFocus: false,
        // revalidateIfStale: false,
    });
    const { trigger: createTrigger, isMutating: isCreateMutating } = useSWRMutation(resource.writeKey, resource.writeAction, {
        suspense: true,
    });
    const { trigger: updateTrigger, isMutating: isUpdateMutating } = useSWRMutation(resource.updateKey, resource.updateAction, {
        suspense: true,
    });
    const { trigger: createTransfer, isMutating: isTransferCreateMutating } = useSWRMutation(resource.transferWriteKey, resource.transferWriteAction, {
        suspense: true,
    });
    const { trigger: updateTransfer, isMutating: isTransferUpdateMutating } = useSWRMutation(resource.transferUpdateKey, resource.transferUpdateAction, {
        suspense: true,
    });

    const account = useSelector((selectorState) => subscriptionAccountSelector(selectorState, defaultSubscriptionId, 'system_all_account_types', accountId));

    useEffect(() => {
        // TODO verify data is of correct type
        // if (typeof data === 'string') {
        //     return;
        // }

        const initialize = async () => {
            dispatch({
                type: types.INIT_ACCOUNT,
                payload: {
                    selectedAccountId: accountId,
                    selectedAccountClassType: account.accountClassType
                },
            });
            dispatch({ type: types.PRE_DATA });
        };
        initialize().then(() => {
            const entries = data.entries || [];
            const endingBalance = data.startingBalance + entries.reduce((acc, entry) => acc + entry.totalAmount.asInt, 0);
            dispatch({
                type: types.INIT_DATA,
                payload: {
                    startingBalance: data.startingBalance,
                    endingBalance,
                    entries,
                    hasNoTransactions: entries ? entries.length === 0 : true,
                },
            });
            console.log('Transactions loaded.');
        });
    }, [data, accountId, account.accountClassType]);

    const onToggleRefresh = useCallback(() => {
        mutate();
    }, [mutate]);

    const onCreateTransaction = useCallback(
        (payload) => {
            const mappedPayload = utils.mapToTransactionCreateContract(payload, accountId);
            createTrigger(mappedPayload, {
                rollbackOnError: true,
            }).then(() => {
                // TODO do not pass data to this method as this changes the SWR data
                // read SWR documentation re the usage of mutate method
                mutate();
            });
        },
        [accountId, createTrigger, mutate]
    );
    const onUpdateTransaction = useCallback(
        (payload) => {
            const mappedPayload = utils.mapToTransactionUpdateContract(payload, accountId);
            updateTrigger(mappedPayload, {
                rollbackOnError: true,
            }).then(() => {
                mutate();
            });
        },
        [accountId, updateTrigger, mutate]
    );

    const onCreateTransfer = useCallback(
        (payload) => {
            const mappedPayload = utils.mapToTransferCreateContract(payload);
            createTransfer(mappedPayload, {
                rollbackOnError: true,
            }).then(() => {
                mutate();
            });
        },
        [createTransfer, mutate]
    );
    const onUpdateTransfer = useCallback(
        (payload) => {
            const mappedPayload = utils.mapToTransferUpdateContract(payload);
            updateTransfer(mappedPayload, {
                rollbackOnError: true,
            }).then(() => {
                mutate();
            });
        },
        [updateTransfer, mutate]
    );

    const values: TransactionsContextState = useMemo(
        () => ({
            ...state,
            onToggleRefresh,
            onCreateTransaction,
            onUpdateTransaction,
            onCreateTransfer,
            onUpdateTransfer,
            isMutating: isUpdateMutating || isCreateMutating || isTransferCreateMutating || isTransferUpdateMutating,
        }),
        [
            state,
            onToggleRefresh,
            onCreateTransaction,
            onUpdateTransaction,
            onCreateTransfer,
            onUpdateTransfer,
            isCreateMutating,
            isUpdateMutating,
            isTransferCreateMutating,
            isTransferUpdateMutating,
        ]
    );

    if (state.refreshState !== states.STATE_FULFILLED) {
        return <LoadingScreen />;
    }

    return <TransactionsContext.Provider value={values}>{children}</TransactionsContext.Provider>;
};
TransactionsProvider.propTypes = {
    children: PropTypes.node,
    resource: PropTypes.any.isRequired,
};

export default withPageSuspense(TransactionsProvider);
export { TransactionsContext };
