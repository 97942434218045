import Typography from '@mui/material/Typography';
import { ComingSoonIllustration } from '../../../assets/illustrations';
import { useLocales } from '../../../locales';

export default function ComingSoonView() {
    const { t } = useLocales();
    return (
        <>
            <Typography variant="h3" paragraph>
                {t('common.coming_soon')}
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>{t('common.coming_soon_help')}</Typography>

            <ComingSoonIllustration sx={{ my: 10, height: 240 }} />
        </>
    );
}
