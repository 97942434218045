import Box from "@mui/material/Box";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import { useLocales } from "../../../../locales";

const Welcome = () => {
    const { user } = useAuth0();
    const { translate } = useLocales();
    return (
        <>
            <Box sx={{ pt: 5, px: 3, pb: 0 }}>
                <Typography variant="h3" gutterBottom>
                    {translate('subscriptions.addYourPlan_welcome')} {user?.name}
                </Typography>
            </Box>
            <Box sx={{ pt: 1, px: 3, pb: 5, color: 'text.secondary' }}>
                <Typography variant="h4" gutterBottom>
                    {translate('subscriptions.addYourPlan')}
                </Typography>
            </Box>
        </>
    );
};

export default Welcome;