import { useState } from 'react';
import { Button } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useLocales } from '../../../../../locales';
import Iconify from '../../../../../components/iconify';
import Scrollbar from '../../../../../components/scrollbar';
import { CategoriesFilter, PayeesFilter, AccountsFilter } from '../../FilterV2';
import type { FilterAccount, FilterPayee, FilterCategory, SelectedFilters } from '../types';
import ClassificationFilterSummary from './ClassificationFilterSummary';

type Props = {
    canReset: boolean,
    hideExpenses: boolean,
    hideIncomes: boolean,
    selectedCategories: Array<FilterCategory>,
    selectedPayees: Array<FilterPayee>,
    selectedAccounts: Array<FilterAccount>,
    onFiltersSelected(key: string, items: [any]): void,
    onFiltersCleared(key: string): void,
    hasSelections: boolean,
    selectedFilters: SelectedFilters,
    onFilterReport(body: any): void,
};

const ClassificationFilter = ({
    canReset,
    hideExpenses,
    hideIncomes,
    selectedCategories,
    selectedPayees,
    selectedAccounts,
    onFiltersSelected,
    onFiltersCleared,
    hasSelections,
    selectedFilters,
    onFilterReport
}: Props) => {
    const { t } = useLocales();
    const [openPopover, setOpenPopover] = useState(false);
    const [value, setValue] = useState('expense_tab');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleOpenPopover = (event) => {
        setOpenPopover(true);
    };
    const handleClosePopover = () => {
        setOpenPopover(false);
        setValue('expense_tab');
    };

    const renderHead = (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                py: 2,
                pr: 1,
                pl: 2.5,
            }}
        >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                {t('common.filters')}
            </Typography>

            <Tooltip title={t('common.reset')}>
                <IconButton onClick={() => onFiltersCleared('all')}>
                    <Badge color="error" variant="dot" invisible={!canReset}>
                        <Iconify icon="solar:restart-bold" />
                    </Badge>
                </IconButton>
            </Tooltip>

            <IconButton onClick={handleClosePopover}>
                <Iconify icon="mingcute:close-line" />
            </IconButton>
        </Stack>
    );

    return (
        <>
            <Button
                disableRipple
                endIcon={
                    <Badge color="error" variant="dot" invisible={!canReset}>
                        <Iconify icon="ic:round-filter-list" />
                    </Badge>
                }
                onClick={handleOpenPopover}
                sx={{
                    width: 120,
                    backgroundColor: '#fff',
                    color: 'text.secondary',
                    height: 56,
                }}
            >
                {t('common.filters')}
            </Button>
            <Drawer
                anchor="right"
                open={openPopover}
                onClose={handleClosePopover}
                slotProps={{
                    backdrop: { invisible: true },
                }}
                PaperProps={{
                    sx: { width: '50%' },
                }}
            >
                {renderHead}
                <Divider />
                <Scrollbar>
                    <Box sx={{ pl: 1, pr: 0, pt: 1, pb: 0, height: 1, display: 'flex', columnGap: '5px' }}>
                        <Box sx={{ flexBasis: '75%' }}>
                            <TabContext value={value}>
                                <TabList onChange={handleChange}>
                                    {!hideExpenses && <Tab label={t('filter.report_Expenses_MenuButton')} id="tab_0" value="expense_tab" />}
                                    {!hideIncomes && <Tab label={t('filter.report_Incomes_MenuButton')} id="tab_1" value="income_tab" />}
                                    <Tab label={t('filter.report_Payees_MenuButton')} id="tab_2" value="payee_tab" />
                                    <Tab label={t('filter.report_Accounts_MenuButton')} id="tab_3" value="account_tab" />
                                </TabList>
                                {!hideExpenses && (
                                    <TabPanel value="expense_tab" index={0}>
                                        <CategoriesFilter
                                            variant="expense"
                                            selectedItems={selectedCategories}
                                            onFiltersSelected={onFiltersSelected}
                                            onFiltersCleared={onFiltersCleared}
                                        />
                                    </TabPanel>
                                )}
                                {!hideIncomes && (
                                    <TabPanel value="income_tab" index={1}>
                                        <CategoriesFilter
                                            variant="income"
                                            selectedItems={selectedCategories}
                                            onFiltersSelected={onFiltersSelected}
                                            onFiltersCleared={onFiltersCleared}
                                        />
                                    </TabPanel>
                                )}
                                <TabPanel value="payee_tab" index={2}>
                                    <PayeesFilter selectedItems={selectedPayees} onFiltersSelected={onFiltersSelected} onFiltersCleared={onFiltersCleared} />
                                </TabPanel>
                                <TabPanel value="account_tab" index={3}>
                                    <AccountsFilter
                                        selectedItems={selectedAccounts}
                                        onFiltersSelected={onFiltersSelected}
                                        onFiltersCleared={onFiltersCleared}
                                    />
                                </TabPanel>
                            </TabContext>
                        </Box>
                        <Box sx={{ flexBasis: '25%', backgroundColor: '#eef5fd', p: 1 }}>
                            <ClassificationFilterSummary
                                selectedCategories={selectedCategories}
                                selectedPayees={selectedPayees}
                                selectedAccounts={selectedAccounts}
                            />
                        </Box>
                    </Box>
                </Scrollbar>
                <Divider />
                <Stack direction="row" spacing={2} sx={{ p: 2 }}>
                    <Button variant="contained" size="large" onClick={() => {
                        setOpenPopover(false);
                        onFilterReport(selectedFilters);
                    }}>
                        {t('filter.button_filter')}
                    </Button>
                    <Button variant="contained" size="large" onClick={handleClosePopover}>
                        {t('common.cancel')}
                    </Button>
                </Stack>
            </Drawer>
        </>
    );
};

export default ClassificationFilter;
