import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useLocales } from '../../locales';
import EmailInboxIcon from '../../assets/icons/EmailInboxIcon';
import LoginButton from '../../components/login/LoginButton';

const RegisterSuccessView = () => {
    const { t } = useLocales();
    return (
        <Stack spacing={4}>
            <EmailInboxIcon sx={{ height: 96 }} />

            <Stack spacing={1} sx={{ my: 5 }}>
                <Typography variant="h3">{t('homepage.register_success_email')}</Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {t('homepage.register_success_email_info')}
                </Typography>
            </Stack>

            <LoginButton />
        </Stack>
    );
};

export default RegisterSuccessView;
