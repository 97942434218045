import PropTypes from "prop-types";
import {Box, Divider, Stack, Typography} from '@mui/material';
import {TransactionReportsUrlBuilder} from "../../../../../api/TransactionReportsApi";
import {withWidgetSwr} from "../components";
import NetWorthTotal from "./NetWorthTotal";
import {useLocales} from "../../../../../locales";
import {fNumber} from "../../../../../utils/formatNumber";

const calculateRatios = (assets, liabilities) => {
    const width = 98.0;
    const minWidth = 25.0;
    const total = Math.abs(liabilities) + assets;
    const assetRatio = (assets / total) * width;
    const liabilityRatio = (Math.abs(liabilities) / total) * width;

    return {
        assetRatio: (assetRatio || 0) < minWidth ? minWidth : assetRatio,
        liabilityRatio: (liabilityRatio || 0) < minWidth ? minWidth : liabilityRatio
    };
}

const NetWorth = ({data, isRTL}) => {
    const {t} = useLocales();
    const {net, assets, liabilities} = data || {net: {asDouble: 0.0}, assets: {asDouble: 0.0}, liabilities: {asDouble: 0.0}};
    const {assetRatio, liabilityRatio} = calculateRatios(assets.asDouble, liabilities.asDouble);

    if (assets.asDouble === 0.0 && liabilities.asDouble === 0) {
        return (
            <Stack spacing={4} sx={{height: 1}}>
                <NetWorthTotal net={net.asDouble}/>
                <Box sx={{display: 'flex', justifyContent: 'left', alignItems: 'center', height: 1}}>
                    <Stack spacing={1} sx={{ml: 4, mr: 4}}>
                        <Typography variant="h4" sx={{color: 'text.primary'}}>
                            {t('widgets.networth_nodata_title')}
                        </Typography>
                        <Typography variant="h6" sx={{color: 'text.secondary'}}>
                            {t('widgets.networth_help')}
                        </Typography>
                    </Stack>
                </Box>
            </Stack>
        )
    }

    return (
        <Stack spacing={4}>
            <NetWorthTotal net={net.asDouble}/>
            <Divider orientation="horizontal" flexItem/>
            <Box sx={{display: 'flex'}}>
                <Box sx={{width: `${assetRatio}%`, textAlign: 'center'}}>
                    <Typography variant="h6" gutterBottom sx={{color: 'text.secondary'}}>
                        {t('widgets.networth_asset_title')}
                    </Typography>
                    <Typography variant="h4">{fNumber(assets.asDouble)}</Typography>
                </Box>
                <Divider orientation="vertical" flexItem/>
                <Box sx={{width: `${liabilityRatio}%`, textAlign: 'center'}}>
                    <Typography variant="h6" gutterBottom sx={{color: 'error.main'}}>
                        {t('widgets.networth_liability_title')}
                    </Typography>
                    <Typography variant="h4" sx={{color: 'error.main'}}>
                        {!isRTL && <>-</>}{fNumber(Math.abs(liabilities.asDouble))}{isRTL && <>-</>}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{display: 'flex', height: '10px'}}>
                <Box style={{backgroundColor: "#00c853", width: `${assetRatio}%`}}/>
                <Divider orientation="vertical" flexItem/>
                <Box style={{backgroundColor: "#f44336", width: `${liabilityRatio}%`}}/>
            </Box>
        </Stack>
    )
};
NetWorth.propTypes = {
    data: PropTypes.object.isRequired,
    isRTL: PropTypes.bool.isRequired,
};

export default withWidgetSwr(NetWorth, TransactionReportsUrlBuilder.getNetWorth);