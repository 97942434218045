import PropTypes from 'prop-types';
import CategoriesProvider from './CategoriesProvider';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useSubscriptionsContext } from '../../hooks/useSubscriptionsContext';
import Api, { CategoriesUrlBuilder } from '../../api/CategoriesApi';

const createSwrResource = (userId, subscriptionId, bookId) => ({
    readKey: CategoriesUrlBuilder.getCategories(userId, subscriptionId, bookId),
    readAction: Api.getAllCategories,

    writeKey: CategoriesUrlBuilder.addCategory(userId, subscriptionId, bookId),
    writeAction: Api.postCategory,

    updateKey: CategoriesUrlBuilder.updateCategory(userId, subscriptionId, bookId),
    updateAction: Api.putCategory,
});

const CategoriesProviderHoc = ({ children }) => {
    const { userId } = useAuthContext();
    const { defaultSubscriptionId, defaultBookId } = useSubscriptionsContext();
    const resource = createSwrResource(userId, defaultSubscriptionId, defaultBookId);

    return (
        <CategoriesProvider resource={resource}>
            {children}
        </CategoriesProvider>
    );
};
CategoriesProviderHoc.propTypes = {
    children: PropTypes.node,
};

export default CategoriesProviderHoc;