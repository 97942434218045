import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { List, ListItem, ListItemButton, ListItemText, Tooltip } from "@mui/material";
import { StyledIcon, StyledItem } from "../../../../../components/nav-section/mini/styles";
import { StyledPopover } from "./styles";
import Iconify from "../../../../../components/iconify";
import { useSettingsContext } from "../../../../../components/settings";
import { responsiveFontSizes } from "../../../../../utils/fontSizes";
import { useLocales } from "../../../../../locales";

const ToolbarItem = ({icon, action, tooltip, items = []}) => {
    const navRef = useRef(null);
    const {isRTL} = useSettingsContext();
    const {t} = useLocales();
    const [open, setOpen] = useState(false);

    const hasItems = items.length > 0;
    const handleOpen = () => {
        if (hasItems) {
            setOpen(true);
        }
    };
    const handleClose = () => {
        if (hasItems) {
            setOpen(false);
        }
    };
    return (
        <>
            <StyledItem
                sx={{width: 48, flexGrow: 0}}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                open={open}
                ref={navRef}
            >
                {!hasItems &&
                    <Tooltip title={t(tooltip)}>
                        <StyledIcon onClick={action}>
                            <Iconify icon={icon} sx={{width: 28, height: 28}}/>
                        </StyledIcon>
                    </Tooltip>
                }

                {hasItems && (
                    <>
                        <StyledIcon>
                            <Iconify icon={icon} sx={{width: 28, height: 28}}/>
                        </StyledIcon>

                        <Iconify
                            width={16}
                            icon={isRTL ? "eva:chevron-left-fill" : "eva:chevron-right-fill"}
                            sx={{
                                top: 11,
                                right: 0,
                                position: 'absolute',
                            }}
                        />
                    </>
                )}
            </StyledItem>

            {hasItems && (
                <StyledPopover
                    open={open}
                    anchorEl={navRef.current}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    transformOrigin={{vertical: 'top', horizontal: 'left'}}
                    PaperProps={{
                        onMouseEnter: handleOpen,
                        onMouseLeave: handleClose,
                    }}
                >
                    <List>
                        {items.map((item, index) => (
                            <ListItem disablePadding key={index}>
                                <ListItemButton disableGutters>
                                    <ListItemText
                                        primary={t(item.languageCode)}
                                        onClick={item.action}
                                        primaryTypographyProps={{
                                            noWrap: true,
                                            sx: {
                                                // textAlign: 'center',
                                                mt: 1,
                                                mb: 1,
                                                ml: 2,
                                                ...responsiveFontSizes({sm: 14, md: 14, lg: 16}),
                                            },
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </StyledPopover>
            )}
        </>
    )
};

ToolbarItem.propTypes = {
    icon: PropTypes.string,
    items: PropTypes.array,
    action: PropTypes.func,
    tooltip: PropTypes.string
};


export default ToolbarItem;