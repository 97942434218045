import PropTypes from "prop-types";
import {Stack, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {min, reverse} from "lodash";
import {useLocales} from "../../../../../locales";
import useResponsive from "../../../../../hooks/useResponsive";
import {fDate2} from "../../../../../utils/formatTime";
import Chart, {useChart} from "../../../../../components/chart";
import {fNumber} from "../../../../../utils/formatNumber";
import NoTransactions from "../components/NoTransactions";


const TransactionsSummary = ({data, isRTL, title}) => {
    const theme = useTheme();
    const {languageCode, t} = useLocales();
    const isDesktop = useResponsive('up', 'md');

    const chartDataItems = isRTL
        ? data.map(item => item.amount.asDouble)
        : reverse(data.map(item => item.amount.asDouble));
    const categories = isRTL
        ? data.map(item => fDate2(item.period, 'MMM yy', languageCode))
        : reverse(data.map(item => fDate2(item.period, 'MMM yy', languageCode)));

    const chartData = [
        {
            name: t('widgets.networth_yaxis_tooltip'),
            data: chartDataItems
        }
    ];
    const chartOptions = useChart({
        plotOptions: {
            bar: {
                columnWidth: '70%',
                colors: {
                    ranges: [
                        {from: min(chartDataItems.filter(r => r < 0.0)), to: 0, color: theme.palette.warning.main},
                    ],
                },
            },
        },
        stroke: {
            show: false,
        },
        xaxis: {
            categories,
        },
        tooltip: {
            marker: {show: false},
            style: {
                fontSize: isDesktop ? '16px' : '12px'
            },
            y: {
                title: {
                    formatter: () => ''
                },
                formatter: value => `${fNumber(value)}`,
            },
        },
        yaxis: {
            opposite: isRTL,
            labels: {
                align: isRTL ? 'center' : 'right',
                formatter: value => fNumber(value)
            }
        }
    });

    return (
        <Stack spacing={2}>
            <Typography variant="h3" gutterBottom>
                {t(title)}
            </Typography>
            {data.length === 0 && <NoTransactions/>}
            {data.length > 0 && <Chart type="bar" series={chartData} options={chartOptions} height={370}/>}
        </Stack>
    )
}
TransactionsSummary.propTypes = {
    data: PropTypes.array.isRequired,
    isRTL: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};

export default TransactionsSummary;