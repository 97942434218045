import React from 'react';
import PropTypes from 'prop-types';
import { TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector, TimelineOppositeContent } from '@mui/lab';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import RepeatIcon from '@mui/icons-material/Repeat';
import TransactionCard from './TransactionCard';
import useTransactionsContext from "../../../../../../contexts/Transactions/useTransactionsContext";
import { useSettingsContext } from "../../../../../../components/settings";

const TimelineTransactionItem = (props) => {
    const { transferFrom, transferTo, totalAmount } = props;
    const { isRTL } = useSettingsContext();
    const { selectedAccountClassType } = useTransactionsContext();

    const balance = totalAmount.asInt;
    const isTransfer = transferFrom !== undefined && transferTo !== undefined;

    let isDebit;
    let isCredit;
    if (selectedAccountClassType === 'Asset') {
        isDebit = !isRTL ? balance < 0 : balance >= 0;
        isCredit = !isRTL ? balance >= 0 : balance < 0;
    }
    if (selectedAccountClassType === 'Liability') {
        isDebit = !isRTL ? balance >= 0 : balance < 0;
        isCredit = !isRTL ? balance < 0 : balance >= 0;
    }

    return (
        <TimelineItem>
            <TimelineOppositeContent>{isDebit && <TransactionCard {...props} isTransfer={isTransfer} accountClassType={selectedAccountClassType} />}</TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color="grey">{isTransfer ? <RepeatIcon /> : <AccountBalanceWalletIcon />}</TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>{isCredit && <TransactionCard {...props} isTransfer={isTransfer} accountClassType={selectedAccountClassType} />}</TimelineContent>
        </TimelineItem>
    );
};
TimelineTransactionItem.propTypes = {
    transferFrom: PropTypes.string,
    transferTo: PropTypes.string,
    totalAmount: PropTypes.object,
};

export default TimelineTransactionItem;
