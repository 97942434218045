
export const ASIDEBAR_NONE = undefined;
export const ASIDEBAR_ADD_TRANSACTION = 'ADD_TRANSACTION';
export const ASIDEBAR_EDIT_TRANSACTION = 'EDIT_TRANSACTION';
export const ASIDEBAR_ADD_TRANSFER = 'ADD_TRANSFER';
export const ASIDEBAR_EDIT_TRANSFER = 'EDIT_TRANSFER';

export const LAYOUT_VIEWS_MONTHLY_TOTALS = 'show_monthly_totals';
export const LAYOUT_VIEWS_DAILY_TOTALS = 'show_daily_totals';
export const LAYOUT_VIEWS_TOGGLE_STATS = 'toggle_stats';
