import PropTypes from "prop-types";
import TimelineDayItem from "./TimelineDayItem";
import { useLayoutSettingsContext } from "../../components/TransactionLayoutSettings";
import TimelineBalanceStart from "./TimelineBalanceStart";
import TimelineBalanceEnd from "./TimelineBalanceEnd";
import { useSortableProvider } from "../../../../../../contexts/Generic/SortableProvider";

const TimelineMonthItem = props => {
    const {showMonthlyTotals} = useLayoutSettingsContext();
    const {sortOrder} = useSortableProvider();
    const {monthKey, startBalance, endBalance, totalExpenses, totalIncomes, dayKeyTransactions} = props;
    const isAscending = sortOrder === 'asc';

    const renderStart = () => {
        if (showMonthlyTotals) {
            return isAscending
                ? <TimelineBalanceStart balance={startBalance} monthKey={monthKey}/>
                : <TimelineBalanceEnd balance={endBalance} monthKey={monthKey} totalExpenses={totalExpenses} totalIncomes={totalIncomes}/>
        }
        return <div/>
    }
    const renderEnd = () => {
        if (showMonthlyTotals) {
            return isAscending
                ? <TimelineBalanceEnd balance={endBalance} monthKey={monthKey} totalExpenses={totalExpenses} totalIncomes={totalIncomes}/>
                : <TimelineBalanceStart balance={startBalance} monthKey={monthKey}/>
        }
        return <div/>
    }

    return (
        <>
            {renderStart()}
            {dayKeyTransactions.map((day, dayIndex) => (
                <TimelineDayItem key={`day_item_${dayIndex}`} {...day} />
            ))}
            {renderEnd()}
        </>
    )
};

TimelineMonthItem.propTypes = {
    monthKey: PropTypes.number,
    startBalance: PropTypes.number,
    endBalance: PropTypes.number,
    totalExpenses: PropTypes.number,
    totalIncomes: PropTypes.number,
    dayKeyTransactions: PropTypes.array,
};

export default TimelineMonthItem;