import { m } from 'framer-motion';
import { Typography, Box } from '@mui/material';
import { useLocales } from '../../../../../locales';
import { varHover, varTranHover } from '../../../../../components/animate';
import Image from '../../../../../components/image';

const ChartEmpty = () => {
    const { t } = useLocales();
    return (
        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'column' }}>
            <Box component={m.div} sx={{ height: '350px' }}>
                <m.div variants={varHover(1.1)} transition={varTranHover()}>
                    <Image src="../../assets/icons/wyzr/ic_nocontent.png" alt="" sx={{ width: 300 }} />
                </m.div>
            </Box>
            <Typography variant="h4" sx={{ color: 'text.secondary' }} gutterBottom>
                {t('reports.transactions.empty_chart_header')}
            </Typography>
            <Typography variant="h5" sx={{ color: 'text.secondary' }} gutterBottom>
                {t('reports.transactions.empty_chart_label')}
            </Typography>
        </Box>
    );
};

export default ChartEmpty;