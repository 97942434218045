import { Helmet } from 'react-helmet-async';
import RegisterSuccessView from '../views/landing/RegisterSuccessView';

export default function RegisterSuccessPage() {
    return (
        <>
            <Helmet>
                <title> Register | Wyzr</title>
            </Helmet>

            <RegisterSuccessView />
        </>
    );
}
