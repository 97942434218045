import {createSelector} from 'reselect';
import {subscriptionAccountsSelector} from "./subscriptionAccountsSelector";

const selectSettings = state => state.settings;
export const subscriptionAccountsLimitSelector = createSelector(
    subscriptionAccountsSelector,
    selectSettings,
    (a, b, accountsType) => accountsType,
    // eslint-disable-next-line no-unused-vars
    (accounts, settings, accountsType) => {
        const {maxBankingAccounts, maxCreditAccounts, maxCashAccounts} = settings;

        // TODO iterate banking types
        const bankingAccounts = accounts.filter(r => r.accountType.toLowerCase() === 'banking' && r.accountTypeCode !== 'BNK_CSH').length;
        const creditAccounts = accounts.filter(r => r.accountType.toLowerCase() === 'credit' && r.accountTypeCode !== 'CR_CSH').length;
        const cashAssetAccounts = accounts.filter(r => r.accountType.toLowerCase() === 'banking' && r.accountTypeCode === 'BNK_CSH').length;
        const cashCreditAccounts = accounts.filter(r => r.accountType.toLowerCase() === 'credit' && r.accountTypeCode === 'CR_CSH').length;
        const cashAccounts = cashAssetAccounts + cashCreditAccounts;

        return [
            {
                accountType: 'banking',
                accountCode: 'BNK',
                accountsLimited: bankingAccounts >= maxBankingAccounts,
                remaining: maxBankingAccounts - bankingAccounts,
                maxLimit: maxBankingAccounts,
            },
            {
                accountType: 'credit',
                accountCode: 'CR',
                accountsLimited: creditAccounts >= maxCreditAccounts,
                remaining: maxCreditAccounts - creditAccounts,
                maxLimit: maxCreditAccounts,
            },
            {
                accountType: 'cash',
                accountCode: 'CSH',
                accountsLimited: cashAccounts >= maxCashAccounts,
                remaining: maxCashAccounts - cashAccounts,
                maxLimit: maxCashAccounts,
            },
        ];
    }
)