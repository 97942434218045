import {useContext} from "react";
import {FilterContext} from './FilterProvider';

const useFilterContext = () => {
    const context = useContext(FilterContext);
    if (context === undefined) {
        throw new Error('useReportFilter must be used within a Filter Provider')
    }
    return context;
};

export default useFilterContext;