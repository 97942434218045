import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react'
import { Timeline } from '@mui/lab';
import autoAnimate from '@formkit/auto-animate';
import TimelineMonthItem from "./TimelineMonthItem";
import withTimelineView from "../../hooks/withTimelineView";
import EmptyFilterTransactions from "../../components/EmptyFilterTransactions";
import TransactionSkeleton from "../../components/TransactionSkeleton";

const View = ({transactions, endingBalance, isMutating}) => {
    const parent = useRef(null);
    const hasTransactions = transactions.length > 0;

    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        parent.current && !isMutating && autoAnimate(parent.current)
    }, [parent, isMutating]);

    return (
        <Timeline ref={parent}>
            {isMutating &&
                <TransactionSkeleton/>
            }
            {!hasTransactions &&
                <EmptyFilterTransactions balance={endingBalance} />
            }
            {hasTransactions && transactions.map((month, monthIndex) => (
                <TimelineMonthItem key={`month_item_${monthIndex}`} {...month} />
            ))}
        </Timeline>
    )
};
View.propTypes = {
    transactions: PropTypes.array.isRequired,
    endingBalance: PropTypes.number.isRequired,
    isMutating: PropTypes.bool.isRequired,
}

export default withTimelineView(View);