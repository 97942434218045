import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import * as Yup from 'yup';
import { FormYupProvider, RHFStepperLayout } from '../../../components/hook-form';
import { useLocales } from '../../../locales';
import { useAuthContext } from '../../../hooks/useAuthContext';
import SubscriptionBasics from './components/SubscriptionBasics';
import SubscriptionBook from './components/SubscriptionBook';
import SubscriptionReview from './components/SubscriptionReview';
import { PATH_SUBSCRIPTION } from '../../../routes/paths';
import SubscriptionsApi from '../../../api/SubscriptionsApi';
import Welcome from './components/Welcome';

const defaultValues = {
    planName: '',
    planRegion: '',
    planCurrency: '',
    planBookLanguage: '',
    planBookTemplate: '01b0b9ab44f64c38a313ebcb3e2bc2ba',
};

export default function NewSubscription() {
    const { translate } = useLocales();
    const navigate = useNavigate();
    const { userId } = useAuthContext();
    const Schema = Yup.object().shape({
        planName: Yup.string().required(translate('subscriptions.planName_validation')),
        planRegion: Yup.string().required(translate('subscriptions.planRegion_validation')),
        planCurrency: Yup.string().required(translate('subscriptions.planCurrency_validation')),
        planBookLanguage: Yup.string().required(translate('subscriptions.planBookLanguage_validation')),
        planBookTemplate: Yup.string().required('Book template is required'),
    });

    const onSubmit = async (data) => {
        const { planName, planRegion, planBookLanguage, planBookTemplate, planCurrency } = data;
        const payload = {
            bookName: 'General',
            planName,
            planRegion,
            planLanguage: planBookLanguage,
            currency: planCurrency,
            templateId: planBookTemplate,
        };
        SubscriptionsApi.addSubscription(userId, payload).then((result) => {
            navigate(PATH_SUBSCRIPTION.root, { replace: true });
        });
    };

    return (
        <Box sx={{ width: '100%', flexGrow: 1 }}>
            <Welcome />
            <FormYupProvider schema={Schema} defaultValues={defaultValues} onSubmit={onSubmit}>
                <RHFStepperLayout>
                    <SubscriptionBasics />
                    <SubscriptionBook />
                    <SubscriptionReview />
                </RHFStepperLayout>
            </FormYupProvider>
        </Box>
    );
}
