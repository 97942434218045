import {createSlice} from '@reduxjs/toolkit';

const initialState = {};

const slice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        initSubscriptionSettings(state, action) {
            const {maxSubscriptions, maxBankingAccounts, maxCreditAccounts, maxCashAccounts} = action.payload;
            state.maxSubscriptions = maxSubscriptions;
            state.maxBankingAccounts = maxBankingAccounts;
            state.maxCreditAccounts = maxCreditAccounts;
            state.maxCashAccounts = maxCashAccounts;
        },
    }
});
// Reducer
export default slice.reducer;

// Actions
export const {
    initSubscriptionSettings
} = slice.actions;