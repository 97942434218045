import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useLocales } from '../../../../../locales';
import { useTable, TableHeadCustom, TableNoData } from '../../../../../components/table';
import TableSkeleton from './TableSkeleton';
import TransactionTableRow from '../TransactionsReport/components/TransactionTableRow';

const TABLE_HEAD = [
    { id: 'accountName', label: '', alignRight: false, languageCode: 'reports.transactions.table_head.accountName', type: 'string', order: 0 },
    { id: 'date', label: '', alignRight: false, languageCode: 'reports.transactions.table_head.date', type: 'datetime', order: 1 },
    { id: 'payee', label: '', alignRight: false, languageCode: 'reports.transactions.table_head.payee', type: 'string', order: 2 },
    { id: 'categoryName', label: '', alignRight: false, languageCode: 'reports.transactions.table_head.categoryName', type: 'string', order: 3 },
    { id: 'tags', label: '', alignRight: false, languageCode: 'reports.transactions.table_head.tags', type: 'string', order: 4 },
    { id: 'amount', label: '', alignRight: true, languageCode: 'reports.transactions.table_head.amount', type: 'currency', order: 5 },
];

type Props = {
    isMutating: boolean,
    data: Array<any>,
};

const TableView = ({ isMutating, data }: Props) => {
    const { t } = useLocales();
    const table = useTable({ defaultOrderBy: 'date' });

    if (isMutating) {
        return <TableSkeleton />;
    }

    const { transactions } = data || {};
    const notFound = !(transactions || []).length;
    const tableData = [];

    return (
        <TableContainer>
            <Table>
                <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                />

                <TableBody>
                    {(transactions || []).map((row, index) => (
                        <TransactionTableRow row={row} key={index} />
                    ))}
                    <TableNoData notFound={notFound} title={t('reports.transactions.empty')} />
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableView;