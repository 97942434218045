import PropTypes from 'prop-types';
import { useEffect } from "react";
import { preload } from "swr";
import SubscriptionsProvider from "./SubscriptionsProvider";
import withAuth from "../Auth/withAuth";
import Api, { SubscriptionUrlBuilder } from "../../api/SubscriptionsApi";
import SubscriptionsLoader from "./SubscriptionsLoader";

const createSwrResource = (userId) => ({
    subscriptionsKey: SubscriptionUrlBuilder.getSubscriptions(userId),
    subscriptionsAction: Api.getSubscriptions,

    settingsKey: SubscriptionUrlBuilder.getSettings(),
    settingsAction: Api.getSettings
});

const withSubscriptions = (WrappedComponent) => {
    function HOC({userId, ...props}) {
        const resource = createSwrResource(userId);
        useEffect(() => {
            preload(resource.subscriptionsKey, resource.subscriptionsAction);
            preload(resource.settingsKey, resource.settingsAction);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <SubscriptionsLoader resource={resource}>
                <SubscriptionsProvider>
                    <WrappedComponent {...props} />
                </SubscriptionsProvider>
            </SubscriptionsLoader>
        )
    }

    HOC.propTypes = {
        userId: PropTypes.string.isRequired
    }
    return withAuth(HOC);
}

export default withSubscriptions;


// const SubscriptionsProviderHOC = ({children, userId}) => {
//     const resource = createResource(userId);
//     return (
//         <SubscriptionsProvider resource={resource}>
//             {children}
//         </SubscriptionsProvider>
//     )
// }
// SubscriptionsProviderHOC.propTypes = {
//     children: PropTypes.node.isRequired,
//     userId: PropTypes.string.isRequired
// }
// export default withAuth(SubscriptionsProviderHOC);