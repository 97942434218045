import PropTypes from "prop-types";
import {withSummaryWidget} from "../components";
import TransactionsByIncome from "./TransactionsByIncome";
import TransactionsByExpense from "./TransactionsByExpense";

const TransactionsWidget = ({type}) => (
    <>
        {type === 'Income' &&
            <TransactionsByIncome />
        }
        {type === 'Expense' &&
            <TransactionsByExpense />
        }
    </>
);

TransactionsWidget.propTypes = {
    type: PropTypes.oneOf(['Income', 'Expense'])
};

export default withSummaryWidget(
    TransactionsWidget,
    'title_hidden',
    'sub_title_hidden',
    false
);
