import { RouterProvider } from 'react-router-dom';
import { SWRConfig } from 'swr'
// routes
import router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import SnackbarProvider from './components/snackbar';
import { ThemeSettings } from './components/settings';
import { MotionLazyContainer } from './components/animate';
import AuthProvider from './contexts/Auth';
import { LoadingLogo } from './components/loading-screen';

// ----------------------------------------------------------------------

export default function App() {
    return (
        <MotionLazyContainer>
            <ThemeProvider>
                <ThemeSettings>
                    <ThemeLocalization>
                        <SnackbarProvider>
                            <SWRConfig value={{
                                onError: (error, key) => {
                                    router.navigate('/500');
                                }
                            }}>
                                <AuthProvider router={router}>
                                    <RouterProvider router={router} fallbackElement={<LoadingLogo />} />
                                </AuthProvider>
                            </SWRConfig>
                        </SnackbarProvider>
                    </ThemeLocalization>
                </ThemeSettings>
            </ThemeProvider>
        </MotionLazyContainer>
    );
}
