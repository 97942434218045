import { StyledNumeric } from "../../../../../components/styled-inputs";
import { useLocales } from "../../../../../locales";

const TransferAmount = () => {
    const {translate} = useLocales();
    return (
        <StyledNumeric
            name="transactionAmount"
            header={translate('management.transaction.transfer_amount_title')}
            label={translate('management.transaction.transfer_amount_label')}
            required
            allowNegative={false}
        />
    )
};


export default TransferAmount;