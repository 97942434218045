import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Typography, Button, Box, Card, CardContent } from '@mui/material';
import { useLocales } from '../../../../../locales';
import { useLayoutSettingsContext } from './TransactionLayoutSettings';
import * as asidebarTypes from './TransactionLayoutTypes';

const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.lighter,
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    [theme.breakpoints.up('xl')]: { height: 320 },
}));

EmptyTransactions.propTypes = {
    returnUrl: PropTypes.string,
};

export default function EmptyTransactions(props) {
    const { t } = useLocales();
    const { onToggleAsidebar } = useLayoutSettingsContext();

    // eslint-disable-next-line no-unused-vars
    const { returnUrl } = props;
    return (
        <RootStyle>
            <CardContent
                sx={{
                    p: { md: 0 },
                    pl: { md: 5 },
                }}
            >
                <Typography gutterBottom variant="h3" sx={{ color: 'grey.800' }}>
                    {t('transactions.emptyStateTxn1')}
                </Typography>

                <Typography gutterBottom variant="h4" sx={{ color: 'grey.600', pb: { xs: 3, xl: 5 } }}>
                    {t('transactions.emptyStateTxn2')}
                </Typography>

                <Button
                    variant="contained"
                    component={RouterLink}
                    // to={PATH_SUBSCRIPTION.accounts.new}
                    // state={{returnUrl}}
                    onClick={() => onToggleAsidebar(asidebarTypes.ASIDEBAR_ADD_TRANSACTION)}
                >
                    {t('transactions.emptyTransactions_addNow')}
                </Button>
            </CardContent>

            <Box
                component="img"
                alt="welcome"
                src="/assets/illustrations/illustration_empty_content.svg"
                sx={{
                    p: 2,
                    height: 280,
                    margin: { xs: 'auto', md: 'inherit' },
                }}
            />
        </RootStyle>
    );
}
