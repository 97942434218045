import PayeesProvider from '../../../../contexts/Payees';
import PayeeFilterProvider from './PayeeFilterProvider';
import PayeesFilter from './components/PayeesFilter';
import PayeeList from './components/PayeeListHoc';
import Asidebar from './Asidebar';
import LayoutSettings from './components/LayoutSettings';

const PayeesOverview = () => (
    <PayeesProvider>
        <LayoutSettings>
            <PayeeFilterProvider>
                <Asidebar variant='undefined'/>
                <PayeesFilter />
                <PayeeList />
            </PayeeFilterProvider>
        </LayoutSettings>
    </PayeesProvider>
);

export default PayeesOverview;