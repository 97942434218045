import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

const SortableCrudContext = createContext();
const SortableProvider = ({children, ...props}) => {
    const [sortOrder, setSortOrder] = useState('desc');

    const setAscending = useCallback(() => {
        setSortOrder('asc');
    }, [setSortOrder]);
    const setDescending = useCallback(() => {
        setSortOrder('desc');
    }, [setSortOrder]);
    const toggleOrder = useCallback(() => {
        if (sortOrder === 'asc') {
            setSortOrder('desc');
        } else {
            setSortOrder('asc');
        }
    }, [sortOrder, setSortOrder]);


    const context = useMemo(() => ({
        setAscending,
        setDescending,
        toggleOrder,
        sortOrder
    }), [sortOrder, setAscending, setDescending, toggleOrder]);

    return (
        <SortableCrudContext.Provider value={context}>
            {children}
        </SortableCrudContext.Provider>
    )
};
SortableProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};


const useSortableProvider = () => {
    const context = useContext(SortableCrudContext);
    if (context === undefined) {
        throw new Error('useSortableProvider must be used within a SortableProvider')
    }
    return context;
}

export default SortableProvider;
export {
    useSortableProvider
};