export const getHelperText = (t, accountClassType, openingBalance) => {
    if (Number(openingBalance) === 0) {
        return '';
    }
    if (accountClassType === 'asset') {
        return `${openingBalance < 0 ? t('management.account.assetInDebt') : t('management.account.assetInCredit')} ${openingBalance}`;
    }
    if (accountClassType === 'liability') {
        return `${openingBalance > 0 ? t('management.account.liabilityInCredit') : t('management.account.liabilityInDebt')} ${openingBalance}`;
    }
    return '';
};