import PropTypes from "prop-types";
import {withWidgetSwr} from "../components";
import {TransactionReportsUrlBuilder} from "../../../../../api/TransactionReportsApi";
import AnalyticsSummary from "./AnalyticsSummary";
import {useLocales} from "../../../../../locales";
import NoData from "./NoData";


const SpendDailyAverage = ({data}) => {
    const {t} = useLocales();


    if (data.length === 0) {
        return (
            <NoData title={t('widgets.spent_dailyAverage_title_empty')} color="info"/>
        )
    }

    return (
        <AnalyticsSummary
            color="info"
            icon="ant-design:fund-filled"
            title={t('widgets.spent_dailyAverage_title')}
            total={data[0].amount.asDouble}/>
    )
}
SpendDailyAverage.propTypes = {
    data: PropTypes.array.isRequired
};

export default withWidgetSwr(SpendDailyAverage, TransactionReportsUrlBuilder.getDailyAverageSpendingTrend);
