import {Skeleton, Stack} from "@mui/material";

const SkeletonBarLoad = () => {
    const barCount = 10;
    return (
        <Stack spacing={1} direction="row" alignItems="flex-end" justifyContent="space-around" sx={{height: 1}}>
            {[...Array(barCount)].map((_, index) => {
                const height = Math.random() * 40;
                return (
                    <Skeleton variant="rectangular" width={60} sx={{height: `calc(100% - ${height * index}px)`}} key={index}/>
                )
            })}
        </Stack>
    )
}

export default SkeletonBarLoad;