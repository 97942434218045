import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Box, Typography, FormControlLabel, Checkbox, Stack, Divider, Button } from '@mui/material';
import Iconify from "../../../../components/iconify";
import MegaMenu from "./MegaMenu";
import OptionStyle from "./styles";
import * as utils from './utils';
import useFilterContext from "./useFilterContext";
import useCategoriesContext from "../../../../contexts/Categories/useCategoriesContext";
import { useTransactionsCategoryContext } from "../../../../contexts/TransactionsCategory";
import { useLocales } from "../../../../locales";

const CategoriesFilter = ({variant, filterId, ...props}) => {
    const {t} = useLocales();
    const {selectedIds, handleCategoriesChange, handleCategoryRemove, onClearAll, onSelectCategoryIds, onClearCategoryIds} = useTransactionsCategoryContext();
    const {data, getVariantCategories, getCategoryIdsByType} = useCategoriesContext();
    const {selectedFilterViewId, handleAppendChip, chipsToggle, onChipClearAll, handleOverwriteChips, handleDeleteChips} = useFilterContext();
    const mappedData = getVariantCategories(variant);
    const open = filterId === selectedFilterViewId;

    const handleOnChange = event => {
        const {value} = event.target;
        handleCategoriesChange(value);

        const key = utils.buildFilterKey(filterId, value);
        let name = '';
        if (value === 'system_no_category_credit' || value === 'system_no_category_debit') {
            name = t('common.no_category');
        } else {
            const dataItem = data.find(r => r.id === value);
            name = `${dataItem.primaryName} ${dataItem.secondaryName ? ` - ${dataItem.secondaryName}` : ''}`;
        }

        handleAppendChip({
            key,
            value: name,
            isSticky: false
        });
    }

    useEffect(() => {
        if (chipsToggle && chipsToggle.startsWith(filterId)) {
            const key = chipsToggle.replace(`${filterId}_`, '');
            handleCategoryRemove(key);
        }
    }, [chipsToggle, handleCategoryRemove, filterId]);

    mappedData.splice(0, 0, {
        label: t('common.no_category'),
        id: variant === 'income' ? 'system_no_category_credit' : 'system_no_category_debit',
        categories: [],
        type: variant,
    });

    return (
        <>
            {open &&
                <MegaMenu id={filterId} open={open}>
                    <Stack direction="row" sx={{mb: 1}} spacing={1}>
                        <Button variant="soft" startIcon={<Iconify icon="tabler:square-off"/>} onClick={() => {
                            onClearAll();
                            onChipClearAll(filterId);
                        }}>
                            {t('common.clear_all')}
                        </Button>
                        <Button variant="soft" startIcon={<Iconify icon="tabler:square-rounded-check"/>} onClick={() => {
                            const categoryIds = getCategoryIdsByType('Primary', variant);
                            onSelectCategoryIds(categoryIds);

                            const newChips = categoryIds.map(id => {
                                const key = utils.buildFilterKey(filterId, id);
                                const dataItem = data.find(r => r.id === id);
                                const name = `${dataItem.primaryName} ${dataItem.secondaryName ? ` - ${dataItem.secondaryName}` : ''}`;
                                return {
                                    key,
                                    value: name,
                                    isSticky: false
                                }
                            });
                            handleOverwriteChips(filterId, newChips);
                        }}>
                            {t('transactions.toolbar.select_category_primary')}
                        </Button>
                        <Button variant="soft" startIcon={<Iconify icon="tabler:square-off"/>} onClick={() => {
                            const categoryIds = getCategoryIdsByType('Primary', variant);
                            onClearCategoryIds(categoryIds);

                            const chipsToRemove = categoryIds.map(id => {
                                const key = utils.buildFilterKey(filterId, id);
                                return key
                            });
                            handleDeleteChips(filterId, chipsToRemove);
                        }}>
                            {t('transactions.toolbar.clear_category_primary')}
                        </Button>
                    </Stack>
                    <Stack direction="column" spacing={1} alignItems="stretch">
                        {mappedData.map((item, index1) => {
                            const {label, categories, id} = item;
                            return (
                                <OptionStyle
                                    key={index1}
                                    sx={{
                                        display: 'block',
                                        ...(selectedIds.includes(id) && {
                                            boxShadow: (theme) => theme.customShadows.z8
                                        })
                                    }}>

                                    <Divider light/>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                checked={selectedIds.includes(id)}
                                                color="primary"
                                                onChange={handleOnChange}
                                                value={id}
                                            />
                                        )}
                                        label={
                                            <Box sx={{ml: 0}}>
                                                <Typography variant="subtitle1">{label}</Typography>
                                            </Box>
                                        }
                                        sx={{py: 1, mr: 0}}
                                    />

                                    <Stack direction="row" spacing={0} flexWrap="wrap" justifyContent="flex-start" alignItems="baseline">
                                        {categories && categories.map((category, index2) => {
                                            const {id: categoryId, name} = category;
                                            return (
                                                <FormControlLabel
                                                    key={index2}
                                                    control={(
                                                        <Checkbox
                                                            checked={selectedIds.includes(categoryId)}
                                                            color="primary"
                                                            onChange={handleOnChange}
                                                            value={categoryId}
                                                            sx={{m: 0}}
                                                        />
                                                    )}
                                                    label={
                                                        <Typography variant="body1" sx={{color: 'text.secondary'}}>{name}</Typography>
                                                    }
                                                    sx={{py: 1}}
                                                />
                                            )
                                        })}
                                    </Stack>
                                </OptionStyle>
                            )
                        })}
                    </Stack>
                </MegaMenu>
            }
        </>
    )
};
CategoriesFilter.propTypes = {
    variant: PropTypes.oneOf(['income', 'expense']).isRequired,
    label: PropTypes.string,
    labelLanguageCode: PropTypes.string,
    filterId: PropTypes.string.isRequired
};
CategoriesFilter.defaultProps = {
    label: "Categories",
    labelLanguageCode: ""
};

export default CategoriesFilter;