import PropTypes from "prop-types";
import { reverse } from 'lodash';
import { styled } from '@mui/material/styles';
import { Typography, Stack, Button, Box } from '@mui/material';
import { fCurrencyBy100, fNumberBy100 } from "../../../../../utils/formatNumber";
import { fDate, fDate2 } from '../../../../../utils/formatTime';
import { useLocales } from '../../../../../locales';
import { useLayoutSettingsContext } from '../components/TransactionLayoutSettings';
import { useTransactionDatesContext } from '../../../../../contexts/TransactionsDate';
import useTransactionsContext from "../../../../../contexts/Transactions/useTransactionsContext";
import DigitTypography from "../../../../../components/typography/DigitTypography";
import { useSettingsContext } from "../../../../../components/settings";
import Chart, { useChart } from "../../../../../components/chart";
import { ComingSoonBadge } from "../../../../../components/badge";

const RootStyle = styled(Box)(({ theme }) => ({
    boxShadow: 'none',
    color: theme.palette.primary.darker,
    backgroundColor: theme.palette.primary.lighter,
    borderRadius: 6,
}));

function sumTotalAmount(transactions) {
    return transactions.reduce((acc, item) => {
        acc += item.totalAmount.asInt;
        return acc;
    }, 0);
}

function buildStats(sourceTransactions, accountId, selectedDate) {
    const transactions = sourceTransactions || [];
    const { from, to } = selectedDate;

    const incomes = sumTotalAmount(transactions.filter((r) => r.totalAmount.asInt >= 0));
    const expenses = sumTotalAmount(transactions.filter((r) => r.totalAmount.asInt < 0));
    const transfersIn = sumTotalAmount(transactions.filter((r) => r.transferTo && r.transferTo === accountId));
    const transfersOut = sumTotalAmount(transactions.filter((r) => r.transferFrom && r.transferFrom === accountId));
    const incomesExcTransfers = sumTotalAmount(transactions.filter((r) => r.totalAmount.asInt >= 0 && (!r.transferTo || !r.transferFrom)));
    const expensesExcTransfers = sumTotalAmount(transactions.filter((r) => r.totalAmount.asInt < 0 && (!r.transferTo || !r.transferFrom)));

    return {
        incomes,
        expenses,
        transfersIn,
        transfersOut,
        total: incomes + expenses,
        startDate: fDate(from),
        endDate: to ? fDate(to) : 'Now',
        incomesExcTransfers,
        expensesExcTransfers,
    };
}

const TransactionsSummary = ({ sourceTransactions, startingBalance }) => {
    const { isRTL } = useSettingsContext();
    const accumulator = [startingBalance];
    const data = sourceTransactions.reduce((acc, item) => {
        const lastItem = acc[acc.length - 1];
        const newItem = lastItem + item.totalAmount.asInt
        acc.push(newItem)
        return acc;
    }, accumulator);

    const chartDataItems = !isRTL ? data : reverse(data);
    const chartData = [
        {
            name: 'Cumulative Balance',
            data: chartDataItems,
        },
    ];
    const chartOptions = useChart({
        xaxis: {
            labels: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            marker: { show: false },
            y: {
                title: {
                    formatter: () => '',
                },
                formatter: (value) => `${fNumberBy100(value)}`,
            },
            theme: 'dark',
        },
        yaxis: {
            opposite: isRTL,
            labels: {
                show: false,
            },
        },
        grid: {
            show: false,
        },
        stroke: {
            curve: 'smooth',
            width: 4,
        },
    });

    return <Chart type="line" series={chartData} options={chartOptions} height={280} />;
};
TransactionsSummary.propTypes = {
    sourceTransactions: PropTypes.array,
    startingBalance: PropTypes.number
};

const StatsView = ({ data, selectedAccountId }) => {
    const { currentLanguage, t } = useLocales();
    const { selectedDate } = useTransactionDatesContext();
    const { showStats } = useLayoutSettingsContext();
    const { selectedAccountClassType } = useTransactionsContext();

    if (showStats === false) {
        return <div />;
    }

    const stats = buildStats(data.entries, selectedAccountId, selectedDate);
    const endingBalance = data.startingBalance + stats.total;

    // eslint-disable-next-line no-nested-ternary
    const label = selectedAccountClassType === 'Asset'
        ? endingBalance >= 0 ? t('transactions.assetInCredit') : t('transactions.assetInDebt')
        : endingBalance >= 0 ? t('transactions.liabilityInCredit') : t('transactions.liabilityInDebit');

    const formatString2 = currentLanguage.value === 'ar' ? 'dd MMMM' : 'dd MMM';
    const cardHeaderTitle = `${t('transactions.timelineTotalSpend')} ${fDate2(
        stats.startDate,
        formatString2,
        currentLanguage.value
    )} - ${fDate2(stats.endDate, formatString2, currentLanguage.value)}`;

    return (
        <RootStyle p={2}>
            <Stack direction="row" alignItems="top" justifyContent="space-between">
                <Stack>
                    <Typography variant="body1">
                        {t('transactions.labelStartingBalanceStats')}
                    </Typography>
                    <DigitTypography gutterBottom variant="h6" mb={2}>
                        {fCurrencyBy100(data.startingBalance)}
                    </DigitTypography>
                </Stack>

                <Typography variant="body1">
                    {label}
                </Typography>
            </Stack>


            <Typography variant="body1">
                {cardHeaderTitle}
            </Typography>
            <DigitTypography variant="h6">
                {fCurrencyBy100(stats.total)}
            </DigitTypography>
            <TransactionsSummary sourceTransactions={data.entries} startingBalance={data.startingBalance}/>

            <ComingSoonBadge>
                <Button
                    variant="contained"
                    size="large"
                >
                    {t('transactions.viewMoreAnalytics')}
                </Button>
            </ComingSoonBadge>
            {/*
            <CardHeader title={cardHeaderTitle} subheader={t('transactions.timelineTotalTransfersSubHeader')} />
            <Stack spacing={3} sx={{ px: 3, my: 5 }}>
                <LinearProgressItem value={percent(stats.transfersOut, stats.expenses)} color="error" />
                <LinearProgressItem value={percent(stats.expensesExcTransfers, stats.expenses)} color="warning" />
                <LinearProgressItem value={percent(stats.transfersIn, stats.incomes)} color="success" />
                <LinearProgressItem value={percent(stats.incomesExcTransfers, stats.incomes)} color="info" />
            </Stack>
            <Stack direction="row" justifyContent="space-around" sx={{ px: 3, pb: 3 }}>
                <LinearProgressTextItem color="error.main" value={stats.transfersOut} label={t('transactions.timelineTotalTransfersOutSpend')} />
                <LinearProgressTextItem color="warning.main" value={stats.expensesExcTransfers} label={t('transactions.report_text_totalExpenses')} />
                <LinearProgressTextItem color="success.main" value={stats.transfersIn} label={t('transactions.timelineTotalTransfersInSpend')} />
                <LinearProgressTextItem color="info.main" value={stats.incomesExcTransfers} label={t('transactions.report_text_totalIncomes')} />
            </Stack>
            */}
        </RootStyle>
    );
};
StatsView.propTypes = {
    data: PropTypes.object,
    selectedAccountId: PropTypes.string.isRequired,
};

export default StatsView;
