import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useResponsive from '../../../hooks/useResponsive';
import { bgGradient } from '../../../utils/cssStyles';
import Logo from '../../../components/logo';
import { useLocales } from '../../../locales';

export default function AuthClassicLayout({ children, image }) {
    const { t } = useLocales();
    const theme = useTheme();

    const upMd = useResponsive('up', 'md');

    const renderLogo = (
        <Logo
            sx={{
                zIndex: 9,
                position: 'absolute',
                m: { xs: 2, md: 5 },
            }}
        />
    );

    const renderContent = (
        <Stack
            sx={{
                width: 1,
                mx: 'auto',
                maxWidth: 480,
                px: { xs: 2, md: 8 },
                py: { xs: 15, md: 30 },
            }}
        >
            {children}
        </Stack>
    );

    const renderSection = (
        <Stack
            flexGrow={1}
            alignItems="center"
            justifyContent="center"
            spacing={10}
            sx={{
                ...bgGradient({
                    color: alpha(theme.palette.background.default, theme.palette.mode === 'light' ? 0.88 : 0.94),
                    imgUrl: '/assets/background/overlay_2.jpg',
                }),
            }}
        >
            <Typography variant="h3" sx={{ maxWidth: 480, textAlign: 'center' }}>
                {t('homepage.register_title')}
            </Typography>

            <Box component="img" alt="auth" src={image || '/assets/illustrations/illustration_dashboard.png'} sx={{ maxWidth: 720 }} />
        </Stack>
    );

    return (
        <Stack
            component="main"
            direction="row"
            sx={{
                minHeight: '100vh',
            }}
        >
            {renderLogo}

            {upMd && renderSection}

            {renderContent}
        </Stack>
    );
}

AuthClassicLayout.propTypes = {
    children: PropTypes.node,
    image: PropTypes.string,
};
