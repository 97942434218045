import PropTypes from 'prop-types';
import { Card, CardContent, CardActionArea, Typography, Stack, Avatar } from '@mui/material';
import Filter1Icon from '@mui/icons-material/Filter1';
import { useWatch } from 'react-hook-form';
import { useLocales } from '../../../../locales';
import { useFormYup } from '../../../../components/hook-form/useFormYup';
import Iconify from '../../../../components/iconify';

const AccountTypes = ({ name, data }) => {
    const { t } = useLocales();
    const { setValue } = useFormYup();
    const accountCode = useWatch({
        name,
    });

    const ICONS = {
        CSH: 'tabler:cash',
        BNK: 'tabler:building-bank',
        CR: 'tabler:credit-card',
        LN: 'tabler:percentage',
        AST: 'tabler:car-garage',
        INV: 'tabler:device-analytics',
    }

    return (
        <>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ color: '#22C55E' }}>
                <Filter1Icon />
                <Typography variant="h5" gutterBottom>
                    {t('management.account.accountTypes_header')}
                </Typography>
            </Stack>
            <Stack direction="row" spacing={4} useFlexGap flexWrap="wrap">
                {data.map((type, index) => {
                    const { limit } = type;
                    return (
                        <Card
                            key={index}
                            variant="outlined"
                            sx={{
                                backgroundColor: accountCode === type.code ? 'action.selected' : 'inherit',
                            }}
                        >
                            <CardActionArea
                                onClick={() => {
                                    setValue(name, type.code);
                                    setValue('accountClassType', type.accountClassType);
                                    setValue('accountTypeCode', '');
                                    setValue('accountTypeName', '');
                                }}
                                sx={{
                                    height: 1,
                                }}
                            >
                                <CardContent>
                                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                        <Avatar variant="square">
                                            <Iconify icon={ICONS[type.code]}/>
                                        </Avatar>
                                        <Typography variant="h5">
                                            {type.name}
                                        </Typography>
                                    </Stack>
                                    <Typography variant="body1" gutterBottom sx={{ color: 'text.secondary' }}>
                                        {type.description}
                                    </Typography>
                                    {limit && (
                                        <Typography variant="subtitle2">
                                            {limit.remaining >= 0 ? limit.remaining : 0} {t('common.remaining')} / {t('common.from')} {limit.maxLimit}
                                        </Typography>
                                    )}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    );
                })}
            </Stack>
        </>
    );
};
AccountTypes.propTypes = {
    name: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
};
export default AccountTypes;
