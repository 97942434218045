import PropTypes from "prop-types";
import { Box, Collapse } from '@mui/material';

const MegaMenu = ({id, children, open}) => (
    <Collapse in={open}>
        <Box
            sx={{
                marginBottom: 1,
                borderRadius: 1,
                bgcolor: 'transparent',
                p: 2
            }}
            data-filter-id={id}>
            {children}
        </Box>
    </Collapse>
);

MegaMenu.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    open: PropTypes.bool
}

export default MegaMenu;