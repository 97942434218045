import { Typography } from '@mui/material';
import type { DateRange } from '../../api/FilterApi';
import { useLocales } from '../../locales';
import { fDate, fDate2 } from '../../utils/formatTime';

type Props = {
    date: DateRange,
};

const DateRangeTypography = ({ date, ...props }: Props) => {
    const { t, currentLanguage } = useLocales();
    const filterDescription = () : string => {
        const { from, to } = date;
        if (from && to) {
            return `${t('common.from')} ${fDate2(from, 'dd MMMM yyyy', currentLanguage.value)} ${t('common.to')} ${fDate2(
                to,
                'dd MMMM yyyy',
                currentLanguage.value
            )}`;
        }

        if (!from && to) {
            return `${t('common.everythingTo')} ${fDate(to)}`;
        }

        return t('common.showEverything');
    };

    const description = filterDescription();

    return <Typography {...props}>{description}</Typography>;
};

export default DateRangeTypography;