export const regions = {
    "en": [
        {value: "SYR", name: "Syria"},
        {value: "LEB", name: "Lebanon"},
        {value: "JRD", name: "Jordan"},
        {value: "KSA", name: "Saudi Arabia"},
        {value: "QAT", name: "Qatar"},
        {value: "UAE", name: "United Arab Emirates"},
        {value: "IRQ", name: "Iraq"},
        {value: "EGY", name: "Egypt"},
        {value: "OTH", name: "Other"},
    ],
    "ar": [
        {value: "SYR", name: "سوريا"},
        {value: "LEB", name: "لبنان"},
        {value: "JRD", name: "الأردن"},
        {value: "KSA", name: "المملكة العربية السعودية"},
        {value: "QAT", name: "قطر"},
        {value: "UAE", name: "الإمارات العربية المتحدة"},
        {value: "IRQ", name: "العراق"},
        {value: "EGY", name: "مصر"},
        {value: "OTH", name: "آخر"},
    ]
};