import { createSelector } from 'reselect';
import { subscriptionAccountsSelector } from './subscriptionAccountsSelector';

export const subscriptionAccountSelector = createSelector(
    subscriptionAccountsSelector,
    (a, b, c, accountId) => accountId,
    (accounts, accountId) => accountSelector(accounts, accountId)
);

const accountSelector = (accounts, accountId) => accounts.find((r) => r.id === accountId);
