import { useContext } from "react";
import { TransactionsCategoryContext } from "./TransactionsCategoryProvider";

const useTransactionsCategoryContext = () => {
    const context = useContext(TransactionsCategoryContext)
    if (context === undefined) {
        throw new Error('useTransactionsCategoryContext must be used within a TransactionsCategory Provider');
    }
    return context;
}

export default useTransactionsCategoryContext;