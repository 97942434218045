import PropTypes from 'prop-types';
import { createContext, useState, useMemo, useCallback } from 'react';
import type { FilterAccount, FilterPayee, FilterCategory, FilterContextState } from './types';

// TODO use reducer to manage state
const ClassificationFilterContext = createContext();
const ClassificationFilterProvider = ({ children }) => {
    /* Provider State */
    // TODO how to initialise default values from providers
    const [selectedClassificationType, setSelectedClassificationType] = useState('BY_CAT');
    const [selectedDates, setSelectedDates] = useState('REPORT_LAST_3_MONTHS');
    const [selectedAccounts: Array<FilterAccount>, setSelectedAccounts] = useState([]);
    const [selectedCategories: Array<FilterCategory>, setSelectedCategories] = useState([]);
    const [selectedPayees: Array<FilterPayee>, setSelectedPayees] = useState([]);

    /* Provider Methods  */
    const handleAddItems = useCallback(
        (key, items: Array<any>) => {
            switch (key) {
                case 'income':
                case 'expense': {
                    const newItems = items.reduce((acc, item: FilterCategory) => {
                        const { value, primary, secondary } = item;
                        const findIndex = selectedCategories.findIndex((r) => r.value === value);
                        if (findIndex > -1) {
                            return acc.filter((r) => r.value !== value);
                        }
                        acc.push({
                            value,
                            primary,
                            secondary,
                        });
                        return acc;
                    }, selectedCategories);
                    setSelectedCategories([...newItems]);
                    break;
                }
                case 'payee': {
                    const newItems = items.reduce((acc, item: FilterPayee) => {
                        const { value, label } = item;
                        const findIndex = selectedPayees.findIndex((r) => r.value === value);
                        if (findIndex > -1) {
                            return acc.filter((r) => r.value !== value);
                        }
                        acc.push({
                            value,
                            label,
                        });
                        return acc;
                    }, selectedPayees);
                    setSelectedPayees([...newItems]);
                    break;
                }
                case 'account': {
                    const newItems = items.reduce((acc, item: FilterAccount) => {
                        const { value, label } = item;
                        const findIndex = selectedAccounts.findIndex((r) => r.value === value);
                        if (findIndex > -1) {
                            return acc.filter((r) => r.value !== value);
                        }
                        acc.push({
                            value,
                            label,
                        });
                        return acc;
                    }, selectedAccounts);
                    setSelectedAccounts([...newItems]);
                    break;
                }
                default:
                    break;
            }
        },
        [selectedCategories, selectedPayees, selectedAccounts]
    );
    const handleClearItems = useCallback((key) => {
        switch (key) {
            case 'income':
            case 'expense': {
                setSelectedCategories([]);
                break;
            }
            case 'payee': {
                setSelectedPayees([]);
                break;
            }
            case 'account': {
                setSelectedAccounts([]);
                break;
            }
            case 'all': {
                setSelectedCategories([]);
                setSelectedPayees([]);
                setSelectedAccounts([]);
                break;
            }
            default:
                break;
        }
    }, []);
    const hasSelections = selectedAccounts.length > 0 || selectedCategories.length > 0 || selectedPayees.length > 0;
    const getFilters = useCallback(
        () => ({
            selectedAccountIds: selectedAccounts.map((r) => r.value),
            selectedPayeeIds: selectedPayees.map((r) => r.value),
            selectedCategoryIds: selectedCategories.map((r) => r.value),
        }),
        [selectedAccounts, selectedCategories, selectedPayees]
    );

    /* Context State  */
    const context: FilterContextState = useMemo(
        () => ({
            selectedClassificationType,
            setSelectedClassificationType,
            selectedDates,
            setSelectedDates,
            selectedCategories,
            selectedPayees,
            selectedAccounts,
            handleAddItems,
            handleClearItems,
            hasSelections,
            getFilters,
        }),
        [selectedClassificationType, selectedDates, selectedCategories, selectedPayees, selectedAccounts, handleAddItems, handleClearItems, hasSelections, getFilters]
    );

    return <ClassificationFilterContext.Provider value={context}>{children}</ClassificationFilterContext.Provider>;
};
ClassificationFilterProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ClassificationFilterProvider;
export {
    ClassificationFilterContext,
};