import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useLocales} from "../../../../../locales";
import {withWidgetSwr} from "../components";
import {TransactionReportsUrlBuilder} from "../../../../../api/TransactionReportsApi";
import {fNumber} from "../../../../../utils/formatNumber";
import {fToday} from "../../../../../utils/formatTime";

const SpendRecent = ({data}) => {
    const {t, languageCode} = useLocales();
    const {average, days} = data;
    return (
        <Stack alignItems="start">
            <Typography variant="h3" gutterBottom>
                {t('widgets.spent_title')} {fNumber(average.asDouble)}
            </Typography>
            <Typography variant="body1">
                {t('widgets.spent_period_from')} {fToday(days, languageCode)} - {t('widgets.spent_period_to')}
            </Typography>
        </Stack>
    )
}
SpendRecent.propTypes = {
    data: PropTypes.object.isRequired
};

export default withWidgetSwr(SpendRecent, TransactionReportsUrlBuilder.getRecentSpend);