
export type TransactionsData = {
    startingBalance: number,
    endingBalance: number,
    entries: Array<any>,
};

export type TransactionsContextState = {
    onToggleRefresh: () => void,
    onCreateTransaction: () => void,

    refresh: boolean,
    selectedAccountId: string,
    selectedAccountClassType: string,
    refreshState: string,
    hasNoTransactions: boolean,

    data: TransactionsData,
};

export const PRE_DATA = 'PRE_DATA';
export const INIT_DATA = 'INIT_DATA';
export const POST_DATA = 'POST_DATA';
export const INIT_ACCOUNT = 'INIT_ACCOUNT';
export const REFRESH = 'REFRESH';