import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback } from 'react';
import { Stack } from '@mui/material';

export type PayeeFilterContextState = {
    searchFilter: string,
    selectedPayeeLetter: string,
    onUpdateSelectedPayeeLetter: (selectedPayeeLetter: string) => void,
    onUpdateSearchFilter: (searchFilter: string) => void,
};

const initialState: PayeeFilterContextState = {
    selectedPayeeLetter: 'system_all_payee_names',
    searchFilter: '',
    onUpdateSelectedPayeeLetter: (selectedPayeeLetter) => {},
    onUpdateSearchFilter: (searchFilter) => {},
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { selectedPayeeLetter } = action.payload;
        return { ...state, selectedPayeeLetter };
    },
    INITIALIZE_SEARCH_FILTER: (state, action) => {
        const { searchFilter } = action.payload;
        return { ...state, searchFilter };
    },
};
const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const PayeeFilterContext = createContext(initialState);
const PayeeFilterProvider = ({ children }) => {
    const [state, setState] = useReducer(reducer, initialState);

    const onUpdateSelectedPayeeLetter = useCallback((selectedPayeeLetter: string) => {
        setState({
            type: 'INITIALIZE',
            payload: {
                selectedPayeeLetter,
            },
        });
    }, []);
    const onUpdateSearchFilter = useCallback((searchFilter: string) => {
        setState({
            type: 'INITIALIZE_SEARCH_FILTER',
            payload: {
                searchFilter,
            },
        });
    }, []);

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const values = {
        ...state,
        onUpdateSelectedPayeeLetter,
        onUpdateSearchFilter,
    };

    return (
        <PayeeFilterContext.Provider value={values}>
            <Stack spacing={1}>{children}</Stack>
        </PayeeFilterContext.Provider>
    );
};
PayeeFilterProvider.propTypes = {
    children: PropTypes.node,
};

export default PayeeFilterProvider;
export { PayeeFilterContext };