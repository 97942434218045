import { styled } from "@mui/material/styles";
import { Popover } from "@mui/material";
import { bgBlur } from "../../../../../utils/cssStyles";

export const StyledPopover = styled(Popover)(({theme, sx}) => ({
    pointerEvents: 'none',
    '& .MuiPopover-paper': {
        width: 220,
        pointerEvents: 'auto',
        padding: theme.spacing(1),
        marginTop: theme.spacing(0.5),
        boxShadow: theme.customShadows.dropdown,
        borderRadius: Number(theme.shape.borderRadius) * 1.5,
        ...bgBlur({color: theme.palette.background.default}),
    },
}));
