import { useNavigate } from "react-router-dom";
import ToolbarItem from "./ToolbarItem";
import useTransactionsContext from "../../../../../contexts/Transactions/useTransactionsContext";
import { useAccountsContext } from "../../../../../hooks/useAccountsContext";


const AccountsSwitcher = () => {
    const {selectedAccountId} = useTransactionsContext();
    const {accounts} = useAccountsContext();
    const navigate = useNavigate();

    const otherAccounts = accounts.filter(r => r.id !== selectedAccountId);
    const toolbarItems = otherAccounts
        .map(item => ({
            languageCode: item.alias,
            action: () => {
                navigate(`/subscription/accounts/${item.id}/transactions`);
            }
        }));
    return (
        <ToolbarItem
            icon="tabler:wallet"
            items={toolbarItems}
        />
    )
};


export default AccountsSwitcher;