import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { Typography, Box } from '@mui/material';
import AmountTypography from '../../../../../../components/typography/AmountTypography';
import { useLocales } from '../../../../../../locales';

const GroupedItems = ({ groupedItems }) => {
    const { t } = useLocales();
    return (
        <>
            {groupedItems.map((asset, index) => {
                const { id, items } = asset;
                return (
                    <Box key={index}>
                        <Grid container spacing={1}>
                            <Grid item xs={1}>
                                <Typography variant="h6">{t(id)}</Typography>
                            </Grid>
                        </Grid>
                        {items.map((item, itemIndex) => (
                            <Grid container spacing={1} key={itemIndex}>
                                <Grid item xs={1}>
                                    <Typography variant="body2">{t(item.accountName)}</Typography>
                                </Grid>
                                {item.periods.map((periodItem, periodItemIndex) => (
                                    <Grid item xs={1} key={periodItemIndex}>
                                        <AmountTypography variant="body2" amount={periodItem.total} useInt={false} />
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Box>
                );
            })}
        </>
    );
}

GroupedItems.propTypes = {
    groupedItems: PropTypes.array
};

export default GroupedItems;