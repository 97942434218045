import { Helmet } from 'react-helmet-async';
import { Box, Container, Typography } from '@mui/material';
import { ProductGuide } from '../views/landing';
import { useLocales } from '../locales';

export default function LandingPage() {
    const { t } = useLocales();
    return (
        <>
            <Helmet>
                <title> Product Roadmap | Wyzr</title>
            </Helmet>
            <Box
                sx={{
                    overflow: 'hidden',
                    bgcolor: 'background.default',
                    mt: 30,
                }}
            >
                <Container>
                    <Typography variant='h1'>
                        {t('product_guide.intro')}
                    </Typography>
                </Container>
                <ProductGuide />
            </Box>
        </>
    );
}