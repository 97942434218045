import { matchSorter } from 'match-sorter';

export const matchFilterOptions = (options: Array<any>, { inputValue }, keys: Array<string>) : Array<any> => {
    const defaultKeys : Array<string> = [];
    const mappedKeys = keys ?? defaultKeys;

    if (!inputValue) {
        return options;
    }
    if (inputValue.length <= 2) {
        return options;
    }

    const words = inputValue.split(' ');
    return words.reduceRight((items, word) =>
        matchSorter(items, word, {
            keys: mappedKeys,
            threshold: matchSorter.rankings.CONTAINS,
        }),
        options
    );
};

export const crudeMatch = (target, searchString) => {
    if (!searchString) {
        return <span>{target}</span>;
    }
    if (searchString.length <= 2) {
        return <span>{target}</span>;
    }

    const s = `${searchString.replaceAll(' ', '|')}`;
    const allParts = target.split(new RegExp(`(${s})`, 'gi')).filter((r) => r !== '');
    const allSearches = searchString.split(' ');

    const map = allParts.map((part, i) => {
        const highlight = allSearches.findIndex((r) => r.toLowerCase() === part.toLowerCase()) > -1;
        return (
            <span
                key={i}
                style={{
                    backgroundColor: highlight ? 'yellow' : 'inherit',
                }}
            >
                {part}
            </span>
        );
    });
    return map;
};