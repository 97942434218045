import * as Yup from 'yup';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useSWRMutation from 'swr/mutation';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { useBoolean } from '../../hooks/useBoolean';
import Iconify from '../../components/iconify';
import { RouterLink } from '../../routes/components';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import { useLocales } from '../../locales';
import { useSettingsContext } from '../../components/settings';
import { PATH_HOME, PATH_SUBSCRIPTION } from '../../routes/paths';
import Api, { SubscriptionUrlBuilder } from '../../api/SubscriptionsApi';
import withPageSuspense from '../../components/async-pages/withPageSuspense';

const resource = {
    writeKey: SubscriptionUrlBuilder.addUser(),
    writeAction: Api.addUser,
};

const RegisterView = () => {
    const navigate = useNavigate();
    const { trigger, isMutating } = useSWRMutation(resource.writeKey, resource.writeAction, {
        suspense: true,
    });

    const password = useBoolean();
    const { t } = useLocales();
    const { isRTL } = useSettingsContext();

    const RegisterSchema = Yup.object().shape({
        firstName: Yup.string().required(t('homepage.validations.register_user_firstName')),
        lastName: Yup.string().required(t('homepage.validations.register_user_lastName')),
        email: Yup.string().required(t('homepage.validations.register_user_email')).email(t('homepage.validations.register_user_email_regex')),
        password: Yup.string().required(t('homepage.validations.register_user_password')),
    });
    const defaultValues = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    };
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = useCallback(
        async (data) => {
            await trigger(data, {
                rollbackOnError: true,
            })
            .then(() => {
                navigate(PATH_HOME.registerSuccess);
            })
            .catch((error) => {
                console.error('Register ERROR', error);
                navigate('/500');
            });
        },
        [trigger, navigate]
    );

    const renderHead = (
        <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
            <Typography variant="h4">{t('homepage.register_user_title')}</Typography>

            <Stack direction="row" spacing={0.5}>
                <Typography variant="body2">{t('homepage.register_user_existing')}</Typography>

                <Link href={PATH_SUBSCRIPTION.root} component={RouterLink} variant="subtitle2">
                    {t('homepage.register_user_existing_title')}
                </Link>
            </Stack>
        </Stack>
    );

    const renderForm = (
        <Stack spacing={2.5}>
            <RHFTextField name="firstName" label={t('homepage.register_user_firstName')} />
            <RHFTextField name="lastName" label={t('homepage.register_user_lastName')} />

            <RHFTextField name="email" label={t('homepage.register_user_email')} />

            <RHFTextField
                name="password"
                label={t('homepage.register_user_password')}
                type={password.value ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={password.onToggle} edge="end">
                                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting || isMutating}
                endIcon={<Iconify icon={isRTL ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill'} />}
                sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
            >
                {t('homepage.register_user_create')}
            </LoadingButton>
        </Stack>
    );

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {renderHead}
            {renderForm}
        </FormProvider>
    );
};

export default withPageSuspense(RegisterView);
